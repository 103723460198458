import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import { Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import HighlightedAutoComplete from "../../../../../../components/ui-kit/mch/HighlightedAutoComplete";
import {
  billingMethodOptions,
  CanadaProvinces,
  ownerTenant,
  UsStates,
} from "../../../../../../../core/constants/strings";
import MaskedTextField from "../../../../../../components/ui-kit/masked-text-field";
import { accountValidators } from "../../validators";
import Alert from "../../../../../../components/ui-kit/Alert";
import IAccountFormValues from "../../interfaces/IAccountFormValues";
import ModalTitle from "../../../../../../components/ui-kit/modal-title";
import { AddressCountry } from "../../../../../../../core/constants";
import { ReactComponent as CanadaFlagLogo } from "../../../../../../../assets/images/canada_flag_icon.svg";
import { ReactComponent as UsFlagLogo } from "../../../../../../../assets/images/usa_flag_icon.svg";
import classes from "./index.module.scss";

interface IFormModal {
  open: boolean;
  handleClose: any;
  onSubmit: any;
  initValues: IAccountFormValues;
  adminSide?: boolean;
  showReviewAlert?: boolean;
  changedFormKeys?: string[];
  helperValues?: any;
  version?: string;
}

const FormModal = ({
  open = false,
  handleClose = () => {},
  onSubmit,
  initValues,
  adminSide = false,
  showReviewAlert = false,
  changedFormKeys = [],
  version = "portal",
}: IFormModal) => {
  const theme = useTheme();
  const formikRef = useRef();
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [initialValues, setInitialValues] = useState({}) as any;
  const [viewCanada, setViewCanada] = React.useState(
    initValues?.country ? initValues?.country : AddressCountry.CA
  );

  const handleOnSubmit = (values: any, props: any) => {
    onSubmit(values, props);
  };

  useEffect(() => {
    setViewCanada(
      initValues?.country ? initValues?.country : AddressCountry.CA
    );
  }, [open, initValues.country]);

  const handleToggle = (event: any, country: any, setFieldValue: any) => {
    if (country !== null && country !== viewCanada) {
      setViewCanada(country);
      setCountry(country);
      setProvince("");
      setFieldValue("address1", "");
      setFieldValue("address2", "");
      setFieldValue("city", "");
      setFieldValue("province_state", "");
      setFieldValue("postal_zip", "");
      setFieldValue("country", country);
    }
  };

  useEffect(() => {
    setCountry(initValues.country);
    const fullProvince =
      initValues.country === "CA"
        ? CanadaProvinces.find((p) => p.abbrev === initValues.province_state)
        : UsStates.find((p) => p.abbrev === initValues.province_state);
    setProvince(fullProvince?.label || initValues.province_state);
    const is_owner =
      typeof initValues.is_owner === "object"
        ? initValues.is_owner.value
        : initValues.is_owner;
    setInitialValues({
      account_holder: initValues.account_holder,
      country: initValues.country,
      address1: initValues.address1,
      address2: initValues.address2,
      city: initValues.city,
      province_state: initValues.province_state,
      postal_zip: initValues.postal_zip,
      phone_number: initValues.phone_number,
      is_owner: is_owner ? ownerTenant[0] : ownerTenant[1],
      billing_method: initValues.billing_method,
    });
  }, [initValues]);

  return (
    <Formik
      //@ts-ignore
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={accountValidators}
      validateOnChange={true}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
    >
      {({
        resetForm,
        dirty,
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
        isValid,
      }) => {
        return (
          <Dialog open={open} fullWidth={true}>
            <ModalTitle
              title={"Account Details"}
              handleClose={() => {
                handleClose();
                resetForm();
                setViewCanada(
                  initValues?.country ? initValues?.country : AddressCountry.CA
                );
              }}
            />
            <DialogContent>
              <Form noValidate={true}>
                <Grid container spacing={1}>
                  {(!adminSide || showReviewAlert) && (
                    <Grid xs={12}>
                      <Alert
                        variant={"info"}
                        title={
                          showReviewAlert
                            ? "Please review changes"
                            : "Confirm changes"
                        }
                        body={
                          showReviewAlert
                            ? "The applicant submitted changes, highlighted below."
                            : "After saving, City Hall staff will review and validate your changes to ensure accuracy."
                        }
                      />
                    </Grid>
                  )}
                  {version === "admin" && (
                    <>
                      <Grid xs={12}>
                        <p className={typographyClasses.overline_bold}>
                          Account holder
                        </p>
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          inputProps={
                            changedFormKeys?.includes("account_holder")
                              ? {
                                  className: classes.changed_field,
                                }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("account_holder")
                              ? {
                                  className: classes.changed_field,
                                  disableUnderline: true,
                                }
                              : undefined
                          }
                          style={{ width: "100%" }}
                          name={"account_holder"}
                          label={"Name"}
                          value={values.account_holder}
                          onChange={handleChange}
                          error={Boolean(
                            errors.account_holder && touched.account_holder
                          )}
                          helperText={
                            touched.account_holder && errors.account_holder
                              ? String(errors.account_holder)
                              : null
                          }
                        />
                      </Grid>
                    </>
                  )}

                  <Grid xs={12}>
                    <p className={typographyClasses.overline_bold}>
                      Mailing address
                    </p>
                  </Grid>
                  <>
                    <Grid xs={12}>
                      {/*// @ts-ignore*/}
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "auto",
                          padding: "0.5rem",
                          gap: "2px",
                          border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                          borderRadius: "15px",
                        }}
                      >
                        <ToggleButtonGroup
                          value={viewCanada ?? AddressCountry.CA}
                          exclusive
                          onChange={(e, v) => {
                            handleToggle(e, v, setFieldValue);
                          }}
                          fullWidth
                        >
                          <ToggleButton
                            value={AddressCountry.CA}
                            style={{
                              marginRight: "3px",
                            }}
                          >
                            <CanadaFlagLogo
                              style={{
                                marginRight: "0.5rem",
                              }}
                            />
                            CANADA
                          </ToggleButton>
                          <ToggleButton value={AddressCountry.US}>
                            <UsFlagLogo
                              style={{
                                marginRight: "0.5rem",
                              }}
                            />
                            USA
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        inputProps={
                          changedFormKeys?.includes("address1")
                            ? {
                                className: classes.changed_field,
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("address1")
                            ? {
                                className: classes.changed_field,
                                disableUnderline: true,
                              }
                            : undefined
                        }
                        fullWidth
                        name={"address1"}
                        label={"Mailing address"}
                        value={values.address1}
                        onChange={handleChange}
                        error={Boolean(errors.address1 && touched.address1)}
                        helperText={
                          touched.address1 && errors.address1
                            ? String(errors.address1)
                            : null
                        }
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        inputProps={
                          changedFormKeys?.includes("address2")
                            ? {
                                className: classes.changed_field,
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("address2")
                            ? {
                                className: classes.changed_field,
                                disableUnderline: true,
                              }
                            : undefined
                        }
                        fullWidth
                        //@ts-ignore
                        optional
                        name={"address2"}
                        label={"Address 2 (optional)"}
                        value={values.address2}
                        onChange={handleChange}
                        error={Boolean(errors.address2 && touched.address2)}
                        helperText={
                          touched.address2 && errors.address2
                            ? String(errors.address2)
                            : null
                        }
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        inputProps={
                          changedFormKeys?.includes("city")
                            ? {
                                className: classes.changed_field,
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("city")
                            ? {
                                className: classes.changed_field,
                                disableUnderline: true,
                              }
                            : undefined
                        }
                        fullWidth
                        name={"city"}
                        label={"City"}
                        value={values.city}
                        onChange={handleChange}
                      />
                    </Grid>
                    {country === AddressCountry.CA ? (
                      <>
                        <Grid xs={6}>
                          {/*//@ts-ignore*/}
                          <HighlightedAutoComplete
                            inputProps={
                              changedFormKeys?.includes("province_state")
                                ? {
                                    className: classes.changed_field,
                                  }
                                : undefined
                            }
                            InputProps={
                              changedFormKeys?.includes("province_state")
                                ? {
                                    className: classes.changed_field,
                                  }
                                : undefined
                            }
                            label="Province"
                            fullWidth
                            name="province_state"
                            id="province_state"
                            onChange={(e: any, value: any) => {
                              setProvince(value.label);
                              setFieldValue("province_state", value.label);
                            }}
                            value={province}
                            touched={touched.province_state ? "true" : "false"}
                            options={CanadaProvinces}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <MaskedTextField
                            fullWidth
                            type={"POSTAL"}
                            label="Postal code"
                            name="postal_zip"
                            id="postal_zip"
                            onChange={handleChange}
                            value={values.postal_zip}
                            initialValue={values.postal_zip}
                            touched={touched.postal_zip ? "true" : "false"}
                            inputProps={{
                              maxLength: 10,
                              ...(changedFormKeys?.includes("postal_zip")
                                ? {
                                    className: classes.changed_field,
                                  }
                                : {}),
                            }}
                            InputProps={
                              changedFormKeys?.includes("postal_zip")
                                ? {
                                    className: classes.changed_field,
                                    disableUnderline: true,
                                  }
                                : undefined
                            }
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid xs={6}>
                          {/*//@ts-ignore*/}
                          <HighlightedAutoComplete
                            inputProps={
                              changedFormKeys?.includes("province_state")
                                ? {
                                    className: classes.changed_field,
                                  }
                                : undefined
                            }
                            InputProps={
                              changedFormKeys?.includes("province_state")
                                ? {
                                    className: classes.changed_field,
                                  }
                                : undefined
                            }
                            label="State"
                            fullWidth
                            name="province_state"
                            id="province_state"
                            onChange={(e: any, value: any) => {
                              setProvince(value.label);
                              setFieldValue("province_state", value.label);
                            }}
                            value={province}
                            touched={
                              touched.province_state && errors.province_state
                                ? "true"
                                : "false"
                            }
                            options={UsStates}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <MaskedTextField
                            fullWidth
                            type={"ZIP"}
                            label="Zip code"
                            name="postal_zip"
                            id="postal_zip"
                            onChange={handleChange}
                            value={values.postal_zip}
                            initialValue={values.postal_zip}
                            touched={touched.postal_zip ? "true" : "false"}
                            inputProps={{
                              maxLength: 10,
                              ...(changedFormKeys?.includes("postal_zip")
                                ? {
                                    className: classes.changed_field,
                                  }
                                : {}),
                            }}
                            InputProps={
                              changedFormKeys?.includes("postal_zip")
                                ? {
                                    className: classes.changed_field,
                                    disableUnderline: true,
                                  }
                                : undefined
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </>
                  <Grid xs={12}>
                    <p className={typographyClasses.overline_bold}>
                      ADDITIONAL INFORMATION
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <MaskedTextField
                      type={"PHONE"}
                      fullWidth
                      name={"phone_number"}
                      label={"Phone number"}
                      value={values.phone_number}
                      initialValue={values.phone_number}
                      onChange={handleChange}
                      error={Boolean(
                        errors.phone_number && touched.phone_number
                      )}
                      helperText={
                        touched.phone_number && errors.phone_number
                          ? String(errors.phone_number)
                          : null
                      }
                      inputProps={{
                        ...(changedFormKeys?.includes("phone_number")
                          ? {
                              className: classes.changed_field,
                            }
                          : {}),
                      }}
                      InputProps={
                        changedFormKeys?.includes("phone_number")
                          ? {
                              className: classes.changed_field,
                              disableUnderline: true,
                            }
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    {/*@ts-ignore*/}
                    <HighlightedAutoComplete
                      inputProps={
                        changedFormKeys?.includes("billing_method")
                          ? {
                              className: classes.changed_field,
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("billing_method")
                          ? {
                              className: classes.changed_field,
                            }
                          : undefined
                      }
                      fullWidth
                      options={billingMethodOptions}
                      name={"billing_method"}
                      label={"Billing Method"}
                      value={values.billing_method}
                      onChange={(e: any, value: any) => {
                        setFieldValue("billing_method", value);
                      }}
                      error={Boolean(
                        errors.billing_method && touched.billing_method
                      )}
                      helperText={
                        touched.billing_method && errors.billing_method
                          ? String(errors.billing_method)
                          : null
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    {/*@ts-ignore*/}
                    <HighlightedAutoComplete
                      inputProps={
                        changedFormKeys?.includes("is_owner")
                          ? {
                              className: classes.changed_field,
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("is_owner")
                          ? {
                              className: classes.changed_field,
                            }
                          : undefined
                      }
                      fullWidth
                      options={ownerTenant}
                      name={"is_owner"}
                      label={"Owner or Tenant?"}
                      value={values.is_owner}
                      onChange={(e: any, value: any) => {
                        setFieldValue("is_owner", value);
                      }}
                      error={Boolean(errors.is_owner && touched.is_owner)}
                      helperText={
                        touched.is_owner && errors.is_owner
                          ? String(errors.is_owner)
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>

            <DialogActions>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  handleClose();
                  resetForm();
                  setViewCanada(
                    initValues?.country
                      ? initValues?.country
                      : AddressCountry.CA
                  );
                }}
              >
                Cancel
              </Button>

              <Button
                disabled={(!isValid && values.phone_number !== "") || !dirty}
                size={"small"}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  if (formikRef.current) {
                    //@ts-ignore
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default FormModal;
