import React from "react";
import styled from "styled-components";
import CircleProgress from "@mui/material/CircularProgress";
import { WHITE } from "../../../mui-theme/theme";

const FullScreenBackground = styled.div`
  position: fixed;
  height: ${(props) => (props.isLoading ? "100vh" : "")};
  width: ${(props) => (props.isLoading ? "100vw" : "")};
  top: 0;
  left: 0;
  z-index: 100;
  background: white;
`;

const LoadingBackground = styled.div`
  position: absolute;
  height: ${(props) => (props.isLoading ? "100%" : "")};
  width: ${(props) => (props.isLoading ? "100%" : "")};
  top: 0;
  left: 0;
  z-index: 100;
  background: ${WHITE};
`;

const BlurredBackgroud = styled.div`
  position: absolute;
  height: ${(props) => (props.isLoading ? "100%" : "")};
  width: ${(props) => (props.isLoading ? "100%" : "")};
  top: 0;
  left: 0;
  z-index: 100;
  background: #ccc;
  opacity: 0.5;
`;

const CircleProgressContainer = styled.div`
  position: absolute;
  z-index: 102;
  height: ${(props) => (props.isLoading ? "100%" : "")};
  width: ${(props) => (props.isLoading ? "100%" : "")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageLoadingScreen = ({ loading }) => (
  <FullScreenBackground isLoading={loading}>
    <CircleProgressContainer isLoading={loading}>
      {loading ? <CircleProgress size={80} /> : null}
    </CircleProgressContainer>
  </FullScreenBackground>
);

const ComponentLoadingScreen = ({ loading }) => (
  <LoadingBackground isLoading={loading}>
    <CircleProgressContainer isLoading={loading}>
      {loading ? <CircleProgress /> : null}
    </CircleProgressContainer>
  </LoadingBackground>
);

const BlurredLoadingScreen = ({ loading }) => (
  <>
    <BlurredBackgroud isLoading={loading} />
    <CircleProgressContainer isLoading={loading}>
      {loading ? <CircleProgress /> : null}
    </CircleProgressContainer>
  </>
);

export { PageLoadingScreen, ComponentLoadingScreen, BlurredLoadingScreen };
