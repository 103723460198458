import React, { useCallback, useEffect, useState } from "react";
import classes from "./index.module.scss";
import Typography from "@mui/material/Typography";
import { EmailOutlined } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Spacer from "../Spacer";

const EmailAlert = ({ title, body, sendAgain }) => {
  const [counter, setCounter] = useState(15);

  const handleSendAgain = useCallback(() => {
    if (counter >= 1) return;

    sendAgain();
    setCounter(15);
  }, [counter, sendAgain]);

  useEffect(() => {
    const intervalId = setInterval(()=>{
      setCounter(prev => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.container}>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ marginBottom: "auto", paddingRight: "1rem" }}>
          <EmailOutlined color={"primary"} fontSize={"large"} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            marginBottom={1}
            fontWeight="700"
          >
            {title}
          </Typography>
          {React.isValidElement(body) ? (
            body
          ) : (
            <Typography
              variant={"body2"}
              style={{
                marginTop: "0.5rem",
              }}
            >
              {body}
            </Typography>
          )}
          <Spacer />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleSendAgain}
              variant={"text"}
              color={"primary"}
              disabled={counter > 0}
            >
              Send again {counter > 0 ? `(${counter})` : null}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailAlert;
