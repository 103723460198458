import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React from "react";
import classes from "./index.module.scss";

const TaxTable = ({ notice }: any) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>Description</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell align={"right"}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notice.items.map((item: any) => (
              <TableRow className={classes.tax_table_row}>
                <TableCell style={{ paddingLeft: 0 }}>
                  {item.line_description}
                </TableCell>
                <TableCell>{item.rate || ""}</TableCell>
                <TableCell align={"right"}>{item.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={"flex_row_space_between"}>
        <p>Total Amount Due</p>
        <p style={{ paddingRight: "1rem" }}>{notice.amount_due}</p>
      </div>
    </>
  );
};

export default TaxTable;
