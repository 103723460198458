import React, { useState } from "react";
import { Field } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import styled from "styled-components";
import { FormControlLabel, Radio } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { startCase, toLower } from "lodash";
import { INPUT_FORMATS } from "../../../../core/constants/enums";
import { primaryTint } from "../../../../mui-theme/theme";

//TODO: separate each component to its unique folder

const StyledField = styled(Field)`
  .MuiInputLabel-asterisk {
    display: none;
  }
`;

const PASSWORD = "password";
const TEXT = "text";

const formatInput = (inputFormat, value) => {
  if (inputFormat === INPUT_FORMATS.NAME) {
    const split = value.split(" ");
    const caplitalized = split.map((x) => startCase(toLower(x)));
    value = caplitalized.join(" ");
  } else if (inputFormat === INPUT_FORMATS.EMAIL) {
    value = toLower(value);
  }

  return value;
};

function MaskedTextField(props) {
  const { inputRef, form, ...rest } = props;
  const { inputFormat, onChange } = rest;

  return (
    <input
      {...rest}
      ref={inputRef}
      onChange={(e) => {
        e.target.value = formatInput(inputFormat, e.target.value);
        onChange(e);
      }}
    />
  );
}

const TextFieldWithShowPassword = (props) => {
  const { InputProps, inputProps, type, form, inputFormat } = props;
  const [passwordType, setPasswordType] = useState(PASSWORD);

  const isPassword = type === PASSWORD;
  const showPassword = passwordType === TEXT;

  const toggleShowPassword = () => {
    setPasswordType((prev) => {
      return prev === PASSWORD ? TEXT : PASSWORD;
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={isPassword ? passwordType : type}
      InputProps={{
        inputComponent: MaskedTextField,
        endAdornment: isPassword && (
          <InputAdornment position="end">
            <Tooltip title="Show/hide password">
              <IconButton
                size="small"
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ color: primaryTint }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        form,
        inputFormat,
        autoComplete: isPassword ? PASSWORD : null,
        ...inputProps,
      }}
    />
  );
};

export function SelectInput(props) {
  const { values, name, disabled, SelectProps } = props;
  return (
    <StyledField
      {...props}
      name={name}
      SelectProps={{ ...SelectProps, disabled }}
      fullWidth
      select
      component={TextFieldWithShowPassword}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {values.map((value) => (
        <MenuItem value={value.id}>
          <em>{value.label}</em>
        </MenuItem>
      ))}
    </StyledField>
  );
}

export function RadioInput(props) {
  const { isSubmitting, values, name, form, ...fieldProps } = props;
  const formValue = form.values[name].toString();
  return (
    <StyledField
      {...fieldProps}
      name={name}
      component={RadioGroup}
      value={formValue}
      onChange={(e) => form.setFieldValue(name, e.currentTarget.value)}
    >
      {values.map((value) => (
        <FormControlLabel
          value={value.value}
          control={<Radio disabled={isSubmitting} />}
          label={value.label}
          disabled={isSubmitting}
        />
      ))}
    </StyledField>
  );
}

export default function TextInput(props) {
  return (
    <StyledField {...props} component={TextFieldWithShowPassword} fullWidth />
  );
}
