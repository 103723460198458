import React, { useCallback, useContext, useEffect, useState } from "react";
import { default as TextField } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { CheckboxCard } from "../../../components/ui-kit/Checkbox";
import {
  sendPasscodeEmail,
  verifyPasscode
} from "../../../../core/apis/auth";
import { Form, Formik } from "formik";
import { ComponentLoadingScreen } from "../../../components/ui-kit/LoadingScreen";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { routes } from "../../../routes/routesData";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as KeyEnvelope } from "../../../../assets/images/key_envelope.svg";
import * as yup from "yup";
import { ChevronLeft, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { ReactComponent as ThreeDotsSpacer } from "../../../../assets/icons/three-dot-spacer-icon.svg";
import { handleAxiosError } from "../../../../utilities";
import { ADMIN_AUTH_TYPE } from "../../../../core/constants/authTypes";
import { jwtDecode } from "jwt-decode";
import Button from "../../../components/ui-kit/Button";
import classes from "./index.module.scss";
import EmailAlert
  from "../../../components/ui-kit/email-alert/EmailAlert";
import Spacer from "../../../components/ui-kit/Spacer";
import {
  NotificationContext
} from "../../../contexts/NotificationContext";

const TwoFactorAuthentication = ({ location }) => {
  const [loading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const { handleShow } = useContext(NotificationContext);
  const history = useHistory();
  const { userData, userToken, nextPage, ...rest } = location.state || {};
  const [validateOnChange, setValidateOnChange] = useState(false);

  /**
   * With initial render and each change in the location's state:
   * - if the location's state does not exist, navigates user to the login screen
   */
  useEffect(() => {
    if (!!!location.state) {
      history.replace(routes.LOGIN.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (rest.snackbarOpen !== undefined) setSnackbarOpen(rest.snackbarOpen);
    if (rest.email !== undefined) setEmail(rest.email);
  }, [rest.email, rest.snackbarOpen, email, snackbarOpen]);

  const { login } = useContext(AuthContext);

  const initialValues = {
    passcode: "",
    isTrustedDevice: false
  };

  const handleResend = useCallback(async () => {
    try {
      await sendPasscodeEmail(userToken);
    }catch (err) {
      handleShow(err.response?.data?.message || err?.message)
    }
  }, [handleShow, userToken])

  const validPassCode = yup
    .string()
    .required("Two-factor authentication passcode is required.");

  const validation = yup.object({
    passcode: validPassCode
  });

  /**
   * Memofied callback for navigating user back to log-in screen
   * @type {(function(): void)|*}
   */
  const navigateBackToLogin = useCallback(() => {
    history.push(routes.LOGIN.path);
  }, [history]);

  const handleSubmit = async ({ passcode, token, isTrustedDevice }) => {
    try {
      await verifyPasscode({ passcode, token, isTrustedDevice });
      // Index
      await login(userData);
    } catch (err) {
      if (err?.response?.status === 404) {
        throw err;
      }
    }
  };

  return (
    <div className={classes.container}>
      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
        <Button
          className={classes.back_button}
          onClick={navigateBackToLogin}
          icon={<ChevronLeft />}
          size="medium_icon_left"
          variant="outlined"
          top="4rem"
          left="4rem"
        >
          BACK
        </Button>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          validateOnChange={validateOnChange}
          onSubmit={async ({ passcode, isTrustedDevice }, { resetForm }) => {
            try {
              await handleSubmit({
                passcode,
                token: userToken,
                isTrustedDevice
              });

              // Redirect to homepage
              const decodedData = jwtDecode(userToken);
              if (nextPage !== undefined && nextPage !== null) {
                history.replace(nextPage);
              } else if (decodedData.auth_type === ADMIN_AUTH_TYPE) {
                history.replace("/admin");
              } else {
                history.replace("/");
              }
            } catch (e) {
              if (e.response?.status >= 400) {
                resetForm({
                  errors: {
                    passcode: e.response?.data.message
                  },
                  values: { passcode, isTrustedDevice }
                });
              }

              try {
                handleAxiosError(e);
              } catch (e) {
                console.error(e);
              }
            }
          }}
        >
          {({ values, errors, handleChange, setFieldValue }) => (
            <>
              <ComponentLoadingScreen loading={loading} />
              <Form noValidate>
                <Grid container>
                  <Grid item container xs={12}>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        marginBottom: "3rem"
                      }}
                    >
                      <LockOutlined sx={{ height: "32px", width: "32px" }} />
                      <div className={classes.three_dots_spacer_wrapper}>
                        <ThreeDotsSpacer />
                      </div>
                      <KeyEnvelope />
                      <div className={classes.three_dots_spacer_wrapper}>
                        <ThreeDotsSpacer />
                      </div>
                      <LockOpenOutlined
                        sx={{
                          height: "32px",
                          width: "32px"
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                    <Typography
                      variant="h4"
                      fontWeight={"300"}
                      color={theme.palette.primary[700]}
                      style={{ paddingBottom: "10px" }}
                    >
                      Safeguard your account
                    </Typography>
                    <Typography variant="body1" style={{ maxWidth: "350px" }}>
                      Two-factor authentication adds an extra layer of
                      protection on top of your password. Note the code expires
                      after 1 hour or after you request a new one.
                      <br />
                      <br />
                      If you do not see the email in a few minutes, check your
                      spam folder.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <EmailAlert
                      sendAgain={handleResend}
                      title={"Check your email"}
                      body={<>
                        <p className={"body_regular_2"}>
                          We sent the passcode to {email}.
                          It might take a few minutes.
                        </p>
                        <Spacer/>
                        <p className={"body_regular_2"} style={{fontWeight: 500}}>
                          Check your spam folder.
                        </p>
                      </>}
                    />
                    <Spacer/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="passcode"
                      label="Enter passcode"
                      value={values.passcode}
                      onChange={(e) =>
                        setFieldValue(
                          "passcode",
                          e.target.value.toUpperCase().trim()
                        )
                      }
                      error={Boolean(errors.passcode)}
                      helperText={errors.passcode}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      justifyContent: "flex-end",
                      marginBottom: "1rem"
                    }}
                  ></Grid>
                  <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    <CheckboxCard
                      name="isTrustedDevice"
                      value={values.isTrustedDevice}
                      onChange={handleChange}
                      label="This is a trusted device"
                      cardVariant="highlight"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={()=>setValidateOnChange(true)}
                      style={{ marginBottom: "3rem" }}
                      type="submit"
                      variant="contained"
                      size={"large"}
                      fullWidth
                    >
                      Confirm passcode
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
