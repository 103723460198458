import Spacer from "../../../../../components/ui-kit/Spacer";
import typographyClasses from "../../../../../../assets/styles/typography/typography.module.scss";
import PropertyTaxInfoCard from "../property-tax-info-card";

const AlreadyLinkedProperty = ({ account }) => {
  return (
    <>
      <p className={typographyClasses.h1}>Is this your property?</p>
      <p className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`}>
        This property is already linked to a user account. Double-check that it belongs to you.
        If you didn't link this account, <a rel={"noreferrer"} href={"https://www.lacombe.ca/Directory.aspx?DID=42"}
                                            target={"_blank"}>contact City Hall</a> for assistance.
      </p>
      <Spacer />
      <p className={typographyClasses.overline}>ALREADY LINKED</p>
      <PropertyTaxInfoCard account={account} />
    </>
  );
};

export default AlreadyLinkedProperty;
