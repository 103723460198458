import React, { useRef, useState } from "react";
import typographyClasses from "../../../../../../assets/styles/typography/typography.module.scss";
import classes from "../index.module.scss";
import InfoButton from "../../../../../components/ui-kit/InfoButton";
import Spacer from "../../../../../components/ui-kit/Spacer";
import { Form, Formik } from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import * as yup from "yup";
import IFindUtilityAccount from "../../../../../../core/interfaces/utility/iFindUtilityAccount";
import Modal from "../../../../../components/ui-kit/Modal";
import sampleInvoice from "../../../../../../assets/images/invoice_sample.png";
import Alerts from "../../../../../components/ui-kit/Alert";
import MaskedTextField from "../../../../../components/ui-kit/masked-text-field";
import MaskedTextFieldTypes from "../../../../../components/ui-kit/masked-text-field/maskedTextFieldTypes";
import { getGlobal } from "../../../../../../utilities";
import { LACOMBE_CITY_ID } from "../../../../../../core/constants";

const validationSchema = yup.object({
  accountNumber: yup
    .string()
    .trim()
    .required("Account number is a required field"),
  accessCode: yup.string().trim().required("Access code is a required field"),
});

type onCheckUtilityAvailableResponseType = void | boolean;

interface Step1Props {
  onCheckUtilityAvailable: (values: {
    accountNumber: string;
    accessCode: string;
  }) => Promise<onCheckUtilityAvailableResponseType>;
  alreadyLinked: boolean;
}

const Step1: React.FC<Step1Props> = ({
  onCheckUtilityAvailable,
  alreadyLinked,
}) => {
  const formikRef = useRef(null) as any;
  const [openModal, setOpenModal] = useState(false);
  const [showWrongNumbersAlert, setShowWrongNumbersAlert] = useState(false);

  const handleSubmit = async (values: IFindUtilityAccount) => {
    const response = await onCheckUtilityAvailable(values);

    if (response === false) {
      setShowWrongNumbersAlert(true);
    }
  };

  return (
    <>
      <p className={typographyClasses.h1}>Link your account</p>
      <p className={typographyClasses.body}>
        When you link a pre-existing Utilities account, you will be able to view
        account details, statements, services, and make payments.
      </p>
      {getGlobal("CCFG_CITY_ID") === LACOMBE_CITY_ID && (
        <>
          <InfoButton
            onClick={() => {
              setOpenModal(true);
            }}
            title={"Where is my account or access code?"}
          />
          <Spacer />
        </>
      )}
      <Formik
        innerRef={formikRef}
        initialValues={{ accountNumber: "", accessCode: "" }}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form>
              <Grid container className={"flex-column"} spacing={1}>
                <Grid xs={12}>
                  <MaskedTextField
                    type={MaskedTextFieldTypes.NUMBER}
                    name={"accountNumber"}
                    error={
                      Boolean(props.errors.accountNumber) &&
                      props.touched.accountNumber
                    }
                    helperText={
                      props.touched.accountNumber
                        ? props.errors.accountNumber
                        : null
                    }
                    value={props.values.accountNumber}
                    onChange={(event: any) => {
                      props.handleChange(event);
                      setShowWrongNumbersAlert(false);
                    }}
                    label={"Account number"}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <MaskedTextField
                    type={MaskedTextFieldTypes.NUMBER}
                    name={"accessCode"}
                    error={
                      Boolean(props.errors.accessCode) &&
                      props.touched.accessCode
                    }
                    helperText={
                      props.touched.accessCode ? props.errors.accessCode : null
                    }
                    value={props.values.accessCode}
                    onChange={(event: any) => {
                      props.handleChange(event);
                      setShowWrongNumbersAlert(false);
                    }}
                    label={"Access code"}
                    fullWidth
                  />
                </Grid>
                {showWrongNumbersAlert && (
                  <Grid xs={12}>
                    <Alerts
                      variant={"error"}
                      title={"Number not found"}
                      body={
                        "The provided numbers could not be found. Please double-check the number and try again."
                      }
                    />
                  </Grid>
                )}
                <Grid xs={12}>
                  <Spacer />
                  <Button
                    disabled={!props.isValid || !props.dirty}
                    size={"large"}
                    fullWidth
                    type={"submit"}
                    variant={"contained"}
                  >
                    Search number
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Modal
        title={"Where to find this information?"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div style={{ padding: "1rem" }}>
          <img
            className={classes.sample_modal}
            src={sampleInvoice}
            alt={"Sample invoice"}
          />
        </div>
      </Modal>
    </>
  );
};

export default Step1;
