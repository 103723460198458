import * as schemas from "../BusinessLicenceStepYupSchemas";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import Typography from "@mui/material/Typography";
import Spacer from "../../../../../components/ui-kit/Spacer";
import DocumentCard from "../../../../../components/ui-kit/DocumentCard";
import UploadFileModal from "../../../../../components/modals/UploadFileModal";
import Button from "@mui/material/Button";
import { CircularProgress, useTheme } from "@mui/material";
import Alerts from "../../../../../components/ui-kit/Alert";
import Grid from "@mui/material/Grid";
import { roundMb } from "../../../../../../utilities";
import RenameModal from "../../../../../components/modals/RenameModal";
import { editAttachment, getAttachment, uploadFile } from "../../../../../../core/apis/attachment";
import SaveValues from "./SaveValues";
import { EDIT, MAX_FILES_SIZE } from "../../../../../../core/constants/licences";
import { ScrollContext } from "../../../../../components/ui-kit/Main";
import MaxDocumentDisplay from "../../../../../components/ui-kit/MaxDocumentDisplay";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../../redux/slices/confrmation-dialog";

const initialValues = {
  optionalDocuments: [],
  isMaxed: false,
};

//This is the validation schema for step 4
const schema4 = schemas.step4Schema;

/**
 * Step 4 of the business licence application
 * @param onSubmit
 * @param submittedFormValues
 * @returns {JSX.Element}
 * @constructor
 */
const Step4 = ({
  submittedFormValues,
  onFinish,
  saveFormValues,
  licenceId,
  mode,
  onCancel,
}) => {
  const theme = useTheme();
  const { scrollToTop } = useContext(ScrollContext);
  const [initValues, setInitValues] = useState(initialValues);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  //Keeps track of mandatory documents
  const [mandatoryModalOpen, setMandatoryModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  //mandatory modal toggling functions
  const handleMandatoryModalClose = () => {
    setMandatoryModalOpen(false);
  };
  const handleMandatoryModalOpen = () => {
    setMandatoryModalOpen(true);
  };

  /**
   * Memo version of indicator for showing property owner permission info
   * - It should only be shown when business is home based and the user is not the owner of the property
   * @type {boolean}
   */
  const showPropertyInfo = useMemo(() => {
    return submittedFormValues.homeBased && !submittedFormValues.isOwner;
  }, [submittedFormValues.isOwner, submittedFormValues.homeBased]);


  /**
   * Memo version of indicator for rename modal being open or not
   * @type {boolean}
   */
  const renameModalOpen = useMemo(
    () => Boolean(modalData?.type === "rename"),
    [modalData]
  );

  //Handles form submit
  const handleSubmit = () => {
    //onFinish();
  };

  const customSave = (values) => {
    let newValues = { attachments: values.optionalDocuments };
    saveFormValues(licenceId, newValues);
  };

  const getFiles = async (ids) => {
    if (!ids) return;
    let newFiles = [];
    for (let i = 0; i < ids.length; i++) {
      const newFile = await getAttachment(ids[i], licenceId);
      newFiles.push(newFile);
    }
    setFiles(newFiles);
  };

  const [validateOnChange, setValidateOnChange] = useState(false);

  useEffect(() => {
    setInitValues({
      isMaxed: false,
      optionalDocuments: submittedFormValues.attachments || [],
    });
    getFiles(submittedFormValues.attachments);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Formik
        initialValues={initValues}
        validationSchema={schema4}
        validateOnChange={validateOnChange}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => {
          const getFilesSize = () => {
            let size = 0;
            for (let i = 0; i < files.length; i++) {
              size += files[i].fileSize;
            }
            return size;
          };

          const renameFile = async (id, fileName) => {
            await editAttachment(id, fileName, licenceId);
            getFiles(values.optionalDocuments);
          };

          const isMaxed = () => {
            return roundMb(getFilesSize()) > MAX_FILES_SIZE;
          };

          const _onDeleteFile = async (fileId) => {
            let newFiles = values.optionalDocuments.filter((x) => x !== fileId);
            setFieldValue("optionalDocuments", newFiles);
            dispatch(closeDialog());
          };

          return (
            <Form>
              <SaveValues save={customSave} canSave={!loading} />
              <LoadFiles getFiles={getFiles} />
              <Spacer amount={2} />
              <Typography variant={"h4"} fontWeight={"300"}>
                Supporting documents
              </Typography>
              <Spacer amount={0.5} />
              <Typography
                variant={"body1"}
                style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
              >
                City staff will contact you if any documents are required in
                support of your application. Supporting documents may include
                corporate documentation and registration statements.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}></Grid>
                {showPropertyInfo ? (
                  <Grid item xs={12}>
                    <Alerts
                      variant={"info"}
                      fill={1}
                      title={"Property owner permission"}
                      body={
                        "Please remember to include a letter" +
                        " from the business owner granting you" +
                        " permission to use the property for" +
                        " the proposed business."
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Spacer amount={2} />
              <Typography variant={"overline"}>attached documents</Typography>
              {uploadInProgress && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <FieldArray
                name={"optionalDocuments"}
                render={(arrayHelpers) => {
                  //Pushes files to the formik array
                  const addFiles = async (files) => {
                    setUploadInProgress(true);
                    for (let i = 0; i < files.length; i++) {
                      arrayHelpers.push(await uploadFile(files[i].file));
                    }

                    if (isMaxed()) {
                      setFieldValue("isMaxed", true);
                    } else if (values.isMaxed) {
                      setFieldValue("isMaxed", false);
                    }

                    await getFiles(values.optionalDocuments);
                    setUploadInProgress(false);
                  };

                  //Removes files from the formik array based on the position of the element
                  const removeFile = (index) => {
                    arrayHelpers.remove(index);
                    if (!isMaxed()) {
                      setFieldValue("isMaxed", false);
                    }
                  };

                  return (
                    <div>
                      {files &&
                        files.map((file) => {
                          return (
                            <DocumentCard
                              key={file.attachmentId}
                              id={file.attachmentId}
                              onClick={removeFile}
                              fileName={file.fileLabel}
                              size={file.fileSize}
                              onDelete={() => {
                                setModalData({
                                  name: file.fileLabel,
                                  id: file.attachmentId,
                                  type: "delete",
                                });
                                dispatch(setConfirmationDialogState({
                                  open: true,
                                  title: "Delete file",
                                  body: `Are you sure you want to delete this file? ${file.fileLabel}`,
                                  onConfirm: () => _onDeleteFile(file.attachmentId)
                                }));
                              }}
                              onRename={() => {
                                setModalData({
                                  name: file.fileLabel,
                                  id: file.attachmentId,
                                  type: "rename",
                                });
                              }}
                            />
                          );
                        })}
                      <DocumentCard
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleMandatoryModalOpen();
                        }}
                        variant={"upload"}
                        title={"Upload supporting files"}
                        subTitle={"See details"}
                      />
                      <UploadFileModal
                        fileTitle={"File Attachment"}
                        files={values.optionalDocuments}
                        handleClose={handleMandatoryModalClose}
                        modalOpen={mandatoryModalOpen}
                        addFiles={addFiles}
                      />
                      <RenameModal
                        handleClose={() => {
                          setModalData(null);
                        }}
                        open={renameModalOpen}
                        renameFile={renameFile}
                        data={modalData}
                      />
                    </div>
                  );
                }}
              />
              <MaxDocumentDisplay
                fileSize={getFilesSize()}
                fileLength={files.length}
                maxFileSize={MAX_FILES_SIZE}
              />
              {errors.isMaxed && (
                <Typography color={"error"} variant={"body2"}>
                  {errors.isMaxed}
                </Typography>
              )}

              <Spacer />

              <Typography
                variant={"body1"}
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                All information supplied in conjunction with this application is
                true and correct. Any misleading information may result in the
                refusal or revocation of such business licence.
              </Typography>

              <Spacer />
              <Button
                fullWidth
                size="large"
                variant="contained"
                type="submit"
                onClick={() => {
                  setValidateOnChange(true);
                  scrollToTop();
                  if (roundMb(getFilesSize()) <= MAX_FILES_SIZE) {
                    onFinish(mode === EDIT ? "Documents" : "General");
                  }
                }}
              >
                {mode === EDIT ? "Save" : "Finish and review"}
              </Button>
              {mode === EDIT ? (
                <>
                  <Spacer />
                  <Button
                    fullWidth
                    size={"large"}
                    variant="outlined"
                    type="cancel"
                    onClick={() => {
                      onCancel("Documents");
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const LoadFiles = ({ getFiles }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (values.optionalDocuments) {
      getFiles(values.optionalDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};

export default Step4;
