import Cookies from "js-cookie";
import moment from "moment";
import { TWO_FACTOR_AUTHENTICATION_COOKIE_KEY } from "../core/constants/twoFA";
import config from "../core/apis/_config";
import { getMinutesFromNow } from "./index";

/* Session Management */
const SESSION_EXPIRY = 1440;
const USER_PROFILE_KEY = "CCF_PROFILE";
const ADMIN_TOOL_ACTIVE = "CCF_ADKEY";

const checkTokenExpiry = () => {
  const sessionInfo = Cookies.getJSON(USER_PROFILE_KEY);
  const tokenExpiry = moment.unix(sessionInfo?.exp).utc();
  const now = moment().utc();
  return tokenExpiry.isBefore(now);
};

export const createSession = (userInfo: any) => {
  if (!Cookies.getJSON(USER_PROFILE_KEY) && userInfo) {
    const expires = getMinutesFromNow(SESSION_EXPIRY);
    // @ts-ignore
    Cookies.set(USER_PROFILE_KEY, userInfo, { expires });
    // @ts-ignore
    Cookies.set(ADMIN_TOOL_ACTIVE, String(true), { expires });
  }
};

export const extendSession = () => {
  const userProfileInCookie = Cookies.getJSON(USER_PROFILE_KEY);
  if (userProfileInCookie) {
    const expires = getMinutesFromNow(SESSION_EXPIRY);
    // @ts-ignore
    Cookies.set(USER_PROFILE_KEY, userProfileInCookie, { expires });
    // @ts-ignore
    Cookies.set(ADMIN_TOOL_ACTIVE, true, { expires });
  }
};

export const endSession = () => {
  if (Cookies.getJSON(USER_PROFILE_KEY)) {
    Cookies.remove(USER_PROFILE_KEY);
    Cookies.remove(ADMIN_TOOL_ACTIVE);
  }
};

export const getSessionInfo = () => {
  return Cookies.getJSON(USER_PROFILE_KEY);
};

export const getIsTrustedDevice = (userId: any) => {
  if (!userId) {
    const sessionInfo = getSessionInfo();
    userId = sessionInfo?.id;
  }

  if (config.skip2FA) {
    return true;
  }

  return userId && Cookies.get(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY) === userId;
};

export const updateSessionInfo = (sessionInfo: any) => {
  const userProfileInCookie = Cookies.getJSON(USER_PROFILE_KEY);
  if (userProfileInCookie && sessionInfo) {
    const expires = getMinutesFromNow(SESSION_EXPIRY);
    const newSessionInfo = { ...userProfileInCookie, ...sessionInfo };

    // @ts-ignore
    Cookies.set(USER_PROFILE_KEY, newSessionInfo, { expires });
  } else {
    console.error("Session cannot be updated because it does not exist.");
  }
};

export const manageSession = {
  checkTokenExpiry,
  createSession,
  extendSession,
  endSession,
  getSessionInfo,
  updateSessionInfo,
};
