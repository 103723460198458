import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { OUTLINE } from "../../../../mui-theme/theme";
import { FilterType } from "../../../../core/constants/enums";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { FilterContext } from "../../../contexts/FilterContext";
import DataTable from "../../../components/ui-kit/data-table";
import classes from "./index.module.scss";
import { TableSettingContext } from "../../../contexts/TableSettingContext";
import { getAccountSummaries } from "../../../../core/apis/account";
import { GiteOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const StyledContainer = styled.div`
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
`;

const StyledSecondarySidebar = styled.div`
  padding-right: 1.5rem;
  margin-right: 0;
  border-left: 1px solid ${OUTLINE};
  width: 275px;
`;

const fields = [
  {
    id: "property_id",
    label: "Property ID",
    filterType: FilterType.SEARCH,
    disableSort: false,
    format: (val) => val,
  },
  {
    id: "name_service_address",
    label: "Property Owner & Address",
    type: "search",
    disableSort: false,
    format: (val) => (
      <div className={classes.address_column_container}>
        <p>{val.name}</p>
        <p>{val.address}</p>
      </div>
    ),
  },
  {
    id: "roll_number",
    label: "Roll Number",
    disableSort: false,
    format: (val) => val,
  },
  {
    id: "access_code",
    label: "Access code/Pin",
    disableSort: true,
    format: (val) => val,
  },
  {
    id: "status",
    label: "Status",
    disableSort: false,
    type: "tag",
    format: (val) => (
      <div className={classes.status_container}>
        <p>{val}</p>
      </div>
    ),
  },
];

const PropertyTaxesScreen = (...props) => {
  const location = useLocation();
  const [tableIdentifier] = useState("propertyTaxesList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { filters, setFilters } = useContext(FilterContext);
  const [currentFilters, setCurrentFilters] = useState(
    filters["propertyTaxes"] || {}
  );
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[tableIdentifier] || props.initialFilters || {}
  );

  async function fetchData(queryOptions) {
    try {
      setLoading(true);
      queryOptions.isPropertyTax = true;
      queryOptions.accountType = "property";
      let data = await getAccountSummaries(queryOptions);
      data.result = data.result.map((summary) => {
        return {
          ...summary,
          property_id: summary.folio,
          name_service_address: {
            name: summary.name,
            address: summary.address
          },
          status: "Active",
          roll_number: summary.accountNumber,
          access_code: summary.accessCode,
        };
      });
      setData(data);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    filters["utilities"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    tableIdentifier,
    setFilters,
    setTableSettings,
  ]);

  return (
    <div className={classes.container}>
      <StyledContainer>
        <DataTable
          title="Property Tax"
          ariaLabel="property taxe account list"
          icon={
            <GiteOutlined
              sx={{ height: "45px", width: "45px", marginRight: "0.5rem" }}
            />
          }
          dataLoading={loading}
          defaultOrder="desc"
          defaultSort="billing_date"
          fields={fields}
          enableFilter
          loadData={fetchData}
          data={data}
          filters={currentFilters}
          setFilters={setCurrentFilters}
          searchBarPlaceHolder={"Account number, holder, service address..."}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          id={tableIdentifier}
          onRowClick={(account, history) => {
            history.push(`${location.pathname}/${account.id}`);
          }}
        />
      </StyledContainer>
      <StyledSecondarySidebar></StyledSecondarySidebar>
    </div>
  );
};

export default PropertyTaxesScreen;
