export const propertyType = ["property_type"];

export const waterServices = [
  "water_metre_number",
  "water_metre_size",
];

export const wasteWaterServices = [
  "waste_metre_size",
];

export const solidWasteServices = [
  "solid_waste_general",
  "solid_waste_cardboard",
  "solid_waste_size",
];

export const residentialSolidWasteServices = [
  "collection_services"
];