import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  css,
  FormControlLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { TableSettingContext } from "../../../../contexts/TableSettingContext";
import Button from "@mui/material/Button";
import {
  getLicenceInvoicePDF,
  getLicenceTransactions,
  renewLicence,
} from "../../../../../core/apis/licence";
import {
  ACTIVE_GROUP,
  INACTIVE_GROUP,
  REVIEWING_GROUP,
} from "../../../../../core/constants/licences";
import Alerts from "../../../../components/ui-kit/Alert";
import { currency, truncate } from "../../../../../utilities";
import DataTable from "../../../../components/ui-kit/data-table";
import Dialog from "../../../../components/ui-kit/Dialog";
import { useModal } from "../../../../hooks/useModal";
import {
  markInvoicePaid,
  sendInvoiceEmail,
  updateInvoiceStatus,
} from "../../../../../core/apis/invoice";
import { AddRounded, MoreVert } from "@mui/icons-material";
import { EMPTY_TABLE_VARIANTS } from "../../../../../core/constants/general";
import moment from "moment/moment";
import { invoiceStatusFormatter } from "../../../../../utilities/data-table-format-helpers";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { OPEN, PAID } from "../../../../../core/constants/invoices";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../routes/routesData";
import Spacer from "../../../../components/ui-kit/Spacer";
import classes from "../../utilities-detail/billing-payment-tab/index.module.scss";
import LinkButton from "../../../../components/ui-kit/link-button";
import typographyClasses from "../../../../../assets/styles/typography/typography.module.scss";
import { DataFormat } from "../../../../../core/constants/enums";
import FileDownload from "js-file-download";
import Tooltip from "@mui/material/Tooltip";
import SetEmailConfirmationModal from "../../../../components/modals/set-email-confirmation-modal";

export const StyledButton = styled(Button)(
  () => css`
    background-color: white;
  `,
);

const CreateSection = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.primary[50]};
    border-radius: 10px;
    padding: 0.75rem;
    margin: 1rem 0;
  `,
);

//Modal Constants
const CREATE = "create";
const VOID = "void";

const InvoicesTab = ({
  licenceData,
  handleRefresh,
  fetchHistories,
  emailsToUse,
}) => {
  const history = useHistory();
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings] = useState(tableSettings["invoices"] || {});
  const { modal, handleOpen, handleClose } = useModal();
  const { handleSuccess, handleError } =
    useContext(NotificationContext);
  const [refresh, setRefresh] = useState(false);
  const [sendEmail, setSendEmail] = useState(null);
  const [anchorElements, setAnchorElements] = useState([]);
  const [targetInvoiceData, setTargetInvoiceData] = useState({
    selectedInvoiceId: null,
    newStatus: null,
  });
  const [updateInvoiceStatusDialogOpen, setUpdateInvoiceStatusDialogOpen] =
    useState(false);
  const [sendInvoiceEmailDialogOpen, setSendInvoiceEmailDialogOpen] =
    useState(false);

  const invoiceEmails = useMemo(() => {
    return !Array.isArray(emailsToUse)
      ? [emailsToUse]
      : emailsToUse && emailsToUse.length > 0
        ? emailsToUse.filter(
            (email, index) => emailsToUse.indexOf(email) === index,
          )
        : [];
  }, [emailsToUse]);

  useEffect(() => {
    tableSettings["invoices"] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [currentTableSettings, tableSettings, setTableSettings]);

  const handleCreateInvoice = async () => {
    try {
      await renewLicence(licenceData.id, sendEmail);
      await fetchHistories();
      setRefresh(!refresh);
      handleRefresh();
    } catch (e) {
      console.log(e.message);
    }

    handleClose(CREATE);
  };

  const handleVoidInvoice = async () => {
    try {
      await updateInvoiceStatus(modal[VOID].data.id, "void");
      await fetchHistories();
      setRefresh(!refresh);
      handleRefresh();
    } catch (e) {
      console.log(e.message);
    }
    handleClose(VOID);
  };

  const handleSendEmail = (event) => {
    setSendEmail(event.target.value);
  };

  const fetchData = async (queryOptions) => {
    try {
      let res = await getLicenceTransactions(licenceData.id, queryOptions);

      setData({
        total: res?.total ?? 0,
        result: res?.result?.map((e, index) => ({
          ...e,
          index: index,
        })),
        licenceId: licenceData.id,
      });
      setBalance(res.balance);
      setAnchorElements(Array(res.result?.length ?? 0).fill(null));
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Memo version of indicator for when the create invoice button should be disabled
   * @type {boolean}
   */
  const disableCreateInvoice = useMemo(() => {
    if (ACTIVE_GROUP.includes(licenceData.status?.toLowerCase())) {
      return !!REVIEWING_GROUP.find(
        (x) => x === licenceData?.change_set_status,
      );
    }
    return false;
  }, [licenceData?.change_set_status, licenceData.status]);

  /**
   * Generate renewal invoice dialog content
   * @returns {JSX.Element}
   * @constructor
   */
  function BodyComponent() {
    return (
      <div style={{ width: "400px" }}>
        <Typography
          variant={"body2"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "18.96px",
          }}
        >
          Generate a new invoice with the latest details. You can download and
          save it for future reference.
        </Typography>
        <br />
        <Typography
          variant={"body2"}
          fontWeight={500}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "18.96px",
          }}
        >
          Do you want to send the invoice via email to the customer?
        </Typography>
        <RadioGroup
          defaultValue={sendEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
          }}
          onChange={handleSendEmail}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="No, don't Email"
            style={{ background: "transparent" }}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Yes, send an Email"
            style={{ background: "transparent", marginLeft: "25px" }}
          />
        </RadioGroup>
      </div>
    );
  }

  /**
   * Opens the more options pop over for the record in the table at the passed index
   * @param event
   * @param index
   */
  const handleMoreOptionClick = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElements((prevState) =>
      prevState.map((e, _index) =>
        _index === index ? event.currentTarget : e,
      ),
    );
  };

  /**
   * Closes the more options pop over for the record in the table at the passed index
   * @param e
   * @param index
   */
  const handleMoreOptionClose = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorElements((prevState) =>
      prevState.map((e, _index) => (_index === index ? null : e)),
    );
  };

  /**
   * Checks if more options pop over is open for the record at the passed index
   * @type {function(*): boolean}
   */
  const isMoreOptionOpen = (index) => Boolean(anchorElements[index]);

  /**
   * Opens invoice pdf downloader
   * @param e
   * @param index
   * @param invoiceNumber
   * @param invoicePdf
   * @returns {Promise<void>}
   */
  const downloadInvoicePdf = async (e, index, invoiceNumber, invoicePdf) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorElements((prevState) =>
      prevState.map((e, _index) => (_index === index ? null : e)),
    );
    const invoice = await getLicenceInvoicePDF(licenceData.id, invoiceNumber);
    if (invoice) {
      FileDownload(invoice, invoicePdf, "application/pdf");
    }
  };

  /**
   * Opens send invoice email dialog
   * @param e
   * @param index
   * @param id
   */
  const handleSendInvoiceByEmail = (e, index, id) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorElements((prevState) =>
      prevState.map((e, _index) => (_index === index ? null : e)),
    );
    setTargetInvoiceData({
      selectedInvoiceId: id,
      newStatus: null,
    });
    setSendInvoiceEmailDialogOpen(true);
  };

  /**
   * Navigates user to the invoice editor screen
   */
  const handleOpeningInvoiceEditor = (e, invoiceId, pdfLink) => {
    e?.stopPropagation();
    history.push(routes.INVOICE_EDITOR.path.replace(":id", invoiceId), {
      ...licenceData,
      pdfLink: pdfLink,
    });
  };

  /**
   * Opens confirmation dialog for updating invoice status
   * @param e
   * @param id
   * @param status
   */
  const handleUpdatingInvoiceStatus = (e, id, status) => {
    e.stopPropagation();
    setTargetInvoiceData({
      selectedInvoiceId: id,
      newStatus: status,
    });
    setUpdateInvoiceStatusDialogOpen(true);
  };

  /**
   * Updates the invoice status by calling the api regarding this action with the new status
   * @returns {Promise<void>}
   */
  const updateInvStatus = async () => {
    setUpdateInvoiceStatusDialogOpen(false);
    if (targetInvoiceData.newStatus === OPEN) {
      await updateInvoiceStatus(
        targetInvoiceData.selectedInvoiceId,
        targetInvoiceData.newStatus,
      );
    } else {
      await markInvoicePaid(targetInvoiceData.selectedInvoiceId);
    }
    setRefresh(!refresh);
    handleRefresh();
  };

  /**
   * Calls the api for sending invoice emails
   * @param emails received emails from the modal
   * @returns {Promise<void>}
   */
  const handleInvoiceEmail = async (emails) => {
    try {
      await sendInvoiceEmail(targetInvoiceData.selectedInvoiceId, emails, false);
      handleSuccess("Invoice sent");
    }catch (e) {
      console.error(e.message);
      handleError(`Error: ${e.message}`);
    }
  };

  /**
   * Data table row fields
   * @type {[{hideFromTable: boolean, id: string},{format: (function(*): string), id: string, label: string, disableSort: boolean},{format: (function(*): *), id: string, label: string, disableSort: boolean},{format: (val: string) => JSX.Element, id: string, label: string, disableSort: boolean},{format: (function(*, *): string), id: string, label: string, align: string, disableSort: boolean},null]}
   */
  const fields = [
    {
      id: "id",
      hideFromTable: true,
    },
    {
      id: "date",
      label: "Invoice Date",
      format: (val) => moment(val).format("YYYY-MM-DD"),
    },
    {
      id: "title",
      label: "Description",
      disableSort: true,
      format: (val, { index, payment, subTitle, invoiceNumber, pdfLink }) =>
        payment ? (
          <Tooltip
            children={<p className={classes.payment_description}>{val}</p>}
            title={val}
          />
        ) : (
          <>
            <LinkButton
              tooltip={val}
              color={"blue"}
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await downloadInvoicePdf(e, index, invoiceNumber, pdfLink);
              }}
            >
              {truncate(val, 35)}
            </LinkButton>
            <p
              className={`${typographyClasses.body2} ${typographyClasses.small_font}`}
            >
              {subTitle}
            </p>
          </>
        ),
    },
    {
      id: "status",
      label: "Status",
      disableSort: true,
      format: (val, { payment }) => {
        return payment ? <div /> : invoiceStatusFormatter(val);
      },
    },
    {
      id: "payment",
      label: "Payment",
      align: "right",
      disableSort: true,
      format: (val, { amount }) => {
        return (
          <p className={classes.payment}>{!!val ? currency(amount) : ""}</p>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      disableSort: true,
      align: "right",
      format: (val, { payment }) => (
        <p>{!payment ? DataFormat.CURRENCY(val) : ""}</p>
      ),
    },
    {
      id: "more_option",
      label: "",
      disableSort: true,
      align: "center",
      format: (_, { id, payment, index, status, pdfLink, invoiceNumber }) => {
        return !payment ? (
          <>
            <IconButton
              children={<MoreVert />}
              onClick={(e) => handleMoreOptionClick(e, index)}
            />
            <Popover
              open={isMoreOptionOpen(index)}
              anchorEl={anchorElements[index]}
              onClose={(e) => handleMoreOptionClose(e, index)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={(e) =>
                  downloadInvoicePdf(e, index, invoiceNumber, pdfLink)
                }
                className={"menu-item"}
              >
                Download PDF
              </MenuItem>
              <MenuItem
                onClick={(e) => handleSendInvoiceByEmail(e, index, id)}
                className={"menu-item"}
              >
                Send by Email
              </MenuItem>
              {status === OPEN && (
                <MenuItem
                  onClick={(e) => handleOpeningInvoiceEditor(e, id, pdfLink)}
                  className={"menu-item"}
                >
                  Edit Invoice
                </MenuItem>
              )}
              {status === OPEN && (
                <MenuItem
                  onClick={(e) => handleUpdatingInvoiceStatus(e, id, PAID)}
                  className={"menu-item"}
                >
                  Mark as Paid
                </MenuItem>
              )}
              {status === OPEN && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpen?.("void", { id: id });
                  }}
                  className={"menu-item"}
                >
                  Mark as Void
                </MenuItem>
              )}
              {status === VOID && (
                <MenuItem
                  onClick={(e) => handleUpdatingInvoiceStatus(e, id, OPEN)}
                  className={"menu-item"}
                >
                  Mark as Open
                </MenuItem>
              )}
            </Popover>
          </>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <div>
      <Spacer />
      <h2 style={{ fontWeight: 500 }}>Invoices & Payments</h2>
      <Spacer />
      {disableCreateInvoice ? (
        <Alerts
          variant={"info"}
          title={"Review changes"}
          body={
            "Before you can create a new invoice, please note that there are" +
            " changes that require approval. Once the review is completed and " +
            "the changes are approved or declined, you may proceed to create a " +
            "new invoice."
          }
        />
      ) : null}
      <CreateSection>
        <Grid container>
          <Grid item xs={6}>
            <StyledButton
              startIcon={<AddRounded />}
              variant={"outlined"}
              size={"small"}
              disabled={
                REVIEWING_GROUP.find(
                  (x) => x === licenceData.status?.toLowerCase(),
                ) ||
                INACTIVE_GROUP.find(
                  (x) => x === licenceData.status?.toLowerCase(),
                ) ||
                REVIEWING_GROUP.find(
                  (x) => x === licenceData.change_set_status?.toLowerCase(),
                ) ||
                licenceData?.balance > 0
              }
              onClick={() => handleOpen(CREATE)}
            >
              New invoice
            </StyledButton>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography>
              <span style={{ fontWeight: 500 }}>BALANCE OWING:</span>
              <span style={{ marginLeft: 10 }}>{currency(balance)}</span>
            </Typography>
          </Grid>
        </Grid>
      </CreateSection>
      <DataTable
        id={"invoices"}
        fields={fields}
        data={data}
        dataLoading={loading}
        loadData={fetchData}
        tableSettings={currentTableSettings}
        setTableSettings={setTableSettings}
        onClickHandlers={{
          handleOpen,
          licenceStatus: licenceData.status,
        }}
        refresh={refresh}
        emptyTableVariant={EMPTY_TABLE_VARIANTS.BASIC}
        onRowClick={(record) => {
          return !record.payment
            ? handleOpeningInvoiceEditor(null, record.id, record.pdfLink)
            : false;
        }}
      />
      <Spacer amount={6} />
      <Dialog
        variant={"updated"}
        open={modal[CREATE]?.open}
        handleClose={() => {
          handleClose(CREATE);
          setSendEmail(null);
        }}
        title={"Generate a renewal invoice"}
        BodyComponent={BodyComponent()}
        buttonOneText={"Cancel"}
        buttonTwoText={"Confirm"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          handleClose(CREATE);
          setSendEmail(null);
        }}
        handleButtonTwo={handleCreateInvoice}
        buttonTwoDisable={!sendEmail}
      />
      <Dialog
        variant={"updated"}
        open={modal[VOID]?.open}
        handleClose={() => handleClose(VOID)}
        title={"Mark invoice as void"}
        body={
          'Voiding this invoice will cancel it and add a "Void" stamp to the PDF.'
        }
        buttonOneText={"Cancel"}
        buttonTwoText={"Mark as Void"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => handleClose(VOID)}
        handleButtonTwo={handleVoidInvoice}
      />
      <Dialog
        variant={"updated"}
        open={updateInvoiceStatusDialogOpen}
        handleClose={() => {
          setUpdateInvoiceStatusDialogOpen(false);
          setTargetInvoiceData({
            selectedInvoice: null,
            newStatus: null,
          });
        }}
        title={`Mark as ${targetInvoiceData?.newStatus}`}
        body={`Are you sure you want to mark invoice as ${targetInvoiceData?.newStatus}?`}
        buttonOneText={"Cancel"}
        buttonTwoText={"Confirm"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          setUpdateInvoiceStatusDialogOpen(false);
          setTargetInvoiceData({
            selectedInvoiceId: null,
            newStatus: null,
          });
        }}
        handleButtonTwo={updateInvStatus}
      />
      <SetEmailConfirmationModal
        open={sendInvoiceEmailDialogOpen}
        setOpen={setSendInvoiceEmailDialogOpen}
        defaultEmails={invoiceEmails}
        handleClose={() => {
          setTargetInvoiceData({
            selectedInvoiceId: null,
            newStatus: null,
          });
        }}
        onSubmit={handleInvoiceEmail}
        title={"Send invoice by Email"}
        buttonTwoLabel={"Email invoice"}
        emailsListTitle={"Share Email invoice with recipient(s):"}
      />
    </div>
  );
};

export default InvoicesTab;
