import React from "react";
import { Skeleton } from "@mui/material";
import Spacer from "../../../../components/ui-kit/Spacer";
import { tabs } from "../../../../../core/constants/utility";
import Grid from "@mui/material/Unstable_Grid2";

const UtilityDetailSkeleton = ({ tab = tabs[0] }) => {
  return (
    <div>
      <Skeleton height={70} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={50} />
      <Spacer amount={0.5} />
      {tab === tabs[0] ? (
        <>
          <Spacer />
          <Grid container spacing={2}>
            <Grid xs={5}>
              <Skeleton variant={"rectangular"} height={365} />
            </Grid>
            <Grid xs={7}>
              <Skeleton variant={"rectangular"} height={50} />
              <Spacer />
              <Skeleton variant={"rectangular"} height={300} />
            </Grid>
          </Grid>
        </>
      ) : null}
      {tab === tabs[1] ? (
        <>
          <Skeleton height={70} />
          <Skeleton variant={"rectangular"} height={500} />
        </>
      ) : null}
      {tab === tabs[2] ? (
        <>
          <Skeleton height={70} />
          <Skeleton height={300} variant={"rectangular"} />
        </>
      ) : null}
      {tab === tabs[3] ? (
        <>
          <Skeleton height={70} />
          <Skeleton height={300} variant={"rectangular"} />
        </>
      ) : null}
    </div>
  );
};

export default UtilityDetailSkeleton;
