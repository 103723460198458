export const AddressCountry = {
  US: "US",
  CA: "CA",
};

export const provinces = [
  { label: "British Columbia", value: "BC", country: "Canada" },
  { label: "Alberta", value: "AB", country: "Canada" },
  { label: "Manitoba", value: "MB", country: "Canada" },
  { label: "New Brunswick", value: "NB", country: "Canada" },
  { label: "Newfoundland", value: "NL", country: "Canada" },
  { label: "Northwest Territories", value: "NT", country: "Canada" },
  { label: "Nova Scotia", value: "NS", country: "Canada" },
  { label: "Nunavut", value: "NU", country: "Canada" },
  { label: "Ontario", value: "ON", country: "Canada" },
  { label: "Prince Edward Island", value: "PE", country: "Canada" },
  { label: "Quebec", value: "QC", country: "Canada" },
  { label: "Saskatchewan", value: "SK", country: "Canada" },
  { label: "Yukon", value: "YT", country: "Canada" },
];

export const LOWER = "lower";
export const UPPER = "upper";

export const LACOMBE_CITY_ID = "dc9a56b4-f11c-4d71-89df-91ac898b2ee6";

export const DEMO_CITY_ID = "e15d7817-c970-40a0-825a-8b74f07c3645";
