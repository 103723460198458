import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import tClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Modal from "../../../../../../components/ui-kit/Modal";
import { formatCurrency } from "../../../../../../../utilities";
import { useTheme } from "@mui/material";

interface IPaymentModal {
  open: boolean;
  amountPaid: number | undefined;
  accountNumber: number | undefined;
  onClick: any | undefined;
  status: string | undefined;
  invoiceNumber: number | undefined;
  contacts: any;
  typeLabel: string;
  invoiceLabel: string;
  isRenewal: boolean;
}

const PaymentModal = ({
  amountPaid = 0,
  open,
  onClick,
  status,
  invoiceNumber,
  accountNumber,
  contacts = [],
  typeLabel = "",
  invoiceLabel = "",
                        isRenewal = false
}: IPaymentModal) => {
  const theme = useTheme();
  return (
    <Modal
      // @ts-ignore
      maxWidth={"700px"}
      aria-labelledby="Payment successful"
      aria-describedby="Displays information regarding the payment details of your latest successful payment."
      open={open}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 750,
          width: "100%",
          boxSizing: "border-box",
          padding: "1.5rem 1rem",
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 400,
            width: "100%",
          }}
        >
          <CheckCircleOutlineIcon
            style={{ fontSize: 72, marginBottom: "1rem" }}
            color={"primary"}
          />
          <p className={tClasses.h2}>Your payment was successful</p>
          <Spacer />
          <p className={tClasses.body}>
            Your payment of <strong>{formatCurrency(amountPaid)}</strong> has
            been received.
          </p>
          <p className={tClasses.body}>
            <strong>Please note: </strong>
            {
              `It may take a few days for the payment
              to be processed and reflect on your account. ${isRenewal ? "You'll receive your new licence via email, and it will be valid starting Jan 1, 2024." : ""}`
            }
          </p>
          {contacts.length > 0 ? (
            <p className={tClasses.body}>
              A receipt has been emailed to the following:
              {contacts.map((contact: any, index: number) => {
                let str = contact.email;
                return ` ${str}${index + 1 === contacts.length ? "." : ","}`;
              })}
            </p>
          ) : (
            <></>
          )}
          <div
            style={{
              boxSizing: "border-box",
              borderRadius: 10,
              border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
              padding: "1rem 2rem",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <Grid container spacing={1.5}>
              <Grid xs={6} textAlign={"left"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  {typeLabel}
                </p>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  {accountNumber}
                </p>
              </Grid>
              <Grid xs={6} textAlign={"left"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  Status
                </p>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  {status}
                </p>
              </Grid>
              <Grid xs={6} textAlign={"left"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  {invoiceLabel}
                </p>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  {invoiceNumber}
                </p>
              </Grid>
              <Grid xs={6} textAlign={"left"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  Payment amount
                </p>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                <p className={tClasses.body} style={{ margin: 0 }}>
                  {formatCurrency(amountPaid)}
                </p>
              </Grid>
            </Grid>
          </div>
          <Button
            onClick={onClick}
            style={{ width: 300 }}
            size={"large"}
            variant={"contained"}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
