import { mapInto, mapStep1 } from "../mappings/mapping";
import { createChangeSet, getLicence, updateChangeSet, updateLicence } from "../../../../../../core/apis/licence";
import { ADD } from "../../../../../../core/constants/licences";
import moment from "moment/moment";

export const saveFormValues = async (
  licenceId,
  values,
  mode,
  changesId,
  licenceStatus
) => {
  try {
    let newValues = Object.keys(values).map((value) => {
      return mapStep1(value, values[value]);
    });
    let filteredValues = newValues.filter((x) => x);
    let results = [];
    let renewalChangeSetId;
    if (
      mode === ADD ||
      licenceStatus === "saved" ||
      Object.keys(values).includes("attachments")
    ) {
      results = await updateLicence(licenceId, filteredValues);
    } else {
      // When in renew flow, change set needs to be created with first edit
      if (!changesId) {
        const response = await createChangeSet(licenceId, "business_licence/edit");
        renewalChangeSetId = response?.data?.id;
      }
      const updated = await updateChangeSet(licenceId, changesId ?? renewalChangeSetId, filteredValues);
      if (updated) {
        results = await getLicence(licenceId);
      }
    }

    let refractedValues = {};
    results.forEach((val) => {
      let newVal = mapInto(val);
      if (!newVal) return;
      if (newVal.property === "tmpOwners") {
        refractedValues["owners"] = newVal.value?.owners
          ? newVal.value?.owners
          : [];
        refractedValues["managers"] = newVal.value?.managers
          ? newVal.value?.managers
          : [];
      } else if (newVal.property === "businessType") {
        refractedValues[newVal.property] = {
          value: newVal.value,
          categories: newVal.categories,
        };
      } else {
        refractedValues[newVal.property] = newVal.value;
      }
    });
    if (!!values?.balanceOwing) {
      return { ...refractedValues, balanceOwing: values?.balanceOwing };
    }
    return refractedValues;
  } catch (e) {
    console.log(e.message);
  }
};

export const getExpiryRenewDate = (expireDate, plusOne) => {
  if (!plusOne) {
    return moment(expireDate).format("MMMM DD, YYYY");
  }

  return moment(expireDate).add(1, "d").format("MMMM DD, YYYY");
};
