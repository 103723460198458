import React from "react";
import {
  IManageAccountService,
  IManageAccountServicesContainer
} from "../../../../../../../../core/interfaces/utility/IManageAccountServicesContainer";
import ManageAccountService from "./ManageAccountService";

const ManageAccountServiceContainer = ({availableServices, servicesDisabled}: IManageAccountServicesContainer) => {
  return (
    <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
      {availableServices?.map((service: IManageAccountService) => (
        <ManageAccountService service={service} servicesDisabled={servicesDisabled}/>
      ))}
    </div>
  )
}

export default ManageAccountServiceContainer