import Spacer from "../../ui-kit/Spacer";
import BarGraph from "../../ui-kit/bar-graph";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";

interface IAnnualPropertyAssessmentGraph {
  loading: boolean;
  assessments: any;
}

const AnnualPropertyAssessmentGraph = ({
  loading,
  assessments,
}: IAnnualPropertyAssessmentGraph) => {
  const [graphData, setGraphData] = useState({
    values: [],
    labels: [],
  } as any);

  useEffect(() => {
    const tmpValues: number[] = [];
    const tmpLabels: string[] = [];
    assessments.forEach((assessment: any) => {
      tmpLabels.push(moment(assessment.periodStart).format("YYYY"));
      tmpValues.push(Number((assessment.amount / 1000).toFixed(0)));
    });
    setGraphData({
      values: tmpValues.reverse(),
      labels: tmpLabels.reverse(),
    });
  }, [assessments]);

  return (
    <>
      {!loading ? (
        <>
          <Spacer />
          <h2>Annual Property Assessment</h2>
          <p className={"sub_label"}>
            {`${graphData.labels[0]} - ${
              graphData.labels[graphData.labels.length - 1]
            }`}
          </p>
          <Spacer amount={2} />
          <BarGraph
            columnScale={"K"}
            data={graphData.values}
            title={""}
            labels={graphData.labels}
            bottomTitle={"YEARS"}
          />
          <Spacer amount={3} />
        </>
      ) : (
        <Skeleton
          variant={"rectangular"}
          style={{ borderRadius: 10 }}
          height={290}
        />
      )}
    </>
  );
};

export default AnnualPropertyAssessmentGraph;
