import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Prompt, useHistory } from "react-router-dom";
import Modal from "../components/ui-kit/Modal";
import Button from "../components/ui-kit/Button";

const ModalContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ModalContent = styled.div`
  width: 60%;
  margin: 2rem;
`;

export default function useWarnBeforeLeaving() {
  const [isDirty, setIsDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const [attemptedNav, setAttemptedNav] = useState(null);
  const [allowNav, setAllowNav] = useState(false);

  const history = useHistory();
  useEffect(() => {
    window.onbeforeunload = isDirty && (() => true);

    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    if (attemptedNav && allowNav) {
      const { location } = attemptedNav;
      history.push(location.pathname);

      setAttemptedNav(null);
      setAllowNav(false);
    }
  }, [allowNav, attemptedNav, history]);

  useEffect(() => {
    if (!open) {
      setAttemptedNav(null);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleProceed = () => {
    setAllowNav(true);
  };
  const PromptComponent = ({
    title = "Leave site?",
    message = "Changes you made may not be saved.",
  }) => (
    <>
      <Prompt
        when={isDirty}
        message={(location, action) => {
          setAttemptedNav({
            location,
            action,
          });
          setOpen(true);
          return allowNav;
        }}
      />
      <Modal open={open} title={title} onClose={handleClose}>
        <ModalContentContainer>
          <ModalContent>
            {message}
            <br />
            <Button
              margin="1rem 0 0.5rem 0"
              fullWidth
              onClick={() => {
                handleClose();
              }}
            >
              TAKE ME BACK
            </Button>
            <Button fullWidth variant="outlined" onClick={handleProceed}>
              YES, I'M SURE
            </Button>
          </ModalContent>
        </ModalContentContainer>
      </Modal>
    </>
  );
  return [PromptComponent, setIsDirty];
}
