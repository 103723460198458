import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getAccountDetail } from "../../../../../core/apis/account";
import WizardStepTracker from "../../../../components/ui-kit/wizard-step-tracker";
import Spacer from "../../../../components/ui-kit/Spacer";
import DatePicker from "../../../../components/ui-kit/DatePicker";
import moment from "moment";
import UtilityBanner from "../link-utility/widgets/UtilityBanner";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import HighlightedAutoComplete from "../../../../components/ui-kit/HighlightedAutoComplete";
import { CanadaProvinces } from "../../../../../core/constants/strings";
import MaskedTextField from "../../../../components/ui-kit/masked-text-field";
import * as yup from "yup";
import * as v from "../../../../../utilities/yupValidators";
import { RED } from "../../../../../mui-theme/theme";
import ListItem from "../../../../components/ui-kit/list-item";
import { EventOutlined, LocationOnOutlined } from "@mui/icons-material";
import { CheckboxCard } from "../../../../components/ui-kit/Checkbox";
import LinkButton from "../../../../components/ui-kit/LinkButton";
import Modal from "../../../../components/ui-kit/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  setState as setConfirmationDialogState,
} from "../../../../redux/slices/confrmation-dialog";

const wizardSteps = [
  { id: "step1", label: "Service End Date" },
  { id: "step2", label: "New Service Start Date" },
  { id: "step3", label: "Review and Submit" },
];

const stepTwoValidation = yup.object().shape({
  serviceAddress: yup
    .string()
    .required("Service address is required.")
    .nullable(),
  addressTwo: yup.string().optional().nullable().nullable(),
  city: yup.string().required("City is required.").nullable(),
  province: v.validState,
  postalCode: v.validPostalZipCode("general").nullable(),
  startDate: yup
    .date()
    .typeError("Start date is required")
    .required("Start date is required"),
});

const MoveTransferUtilityWizard = () => {
  const history = useHistory();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState(null);
  const [updateInfo, setUpdateInfo] = useState({
    endDate: null,
  });
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [showStartDateError, setShowStartDateError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountInformation().then();
    //eslint-disable-next-line
  }, []);

  const getAccountInformation = async () => {
    const results = await getAccountDetail(id);
    setData(results);
  };

  const onWizardClosed = () => {
    if (currentStep !== 0) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _handleConfirmationDialogAction,
        })
      );
      return;
    }
    history.goBack();
  };

  const _handleConfirmationDialogAction = () => {
    dispatch(closeDialog());
    history.goBack();
  };

  const stepOne = useMemo(() => {
    return (
      <>
        <h2>Service end date</h2>
        <p className={"body"}>
          Please provide the end date of your current service address below.
        </p>
        <div style={{ width: "100%" }}>
          <p className={"overline_light"}>Your Current service address</p>
          <UtilityBanner
            account={{
              accountNumber:
                data?.find((e) => e.name === "account_number")?.value?.value ??
                "",
              address:
                data?.find((e) => e.name === "service_address")?.value?.value ??
                "",
            }}
            hasPadding={false}
          />
        </div>
        <Spacer amount={2} />
        <p className={"overline_light"}>SERVICE END DATE</p>
        <DatePicker
          sx={{ width: "100%" }}
          label={"Add end date"}
          onChange={(value) => {
            setUpdateInfo((prevState) => ({
              ...prevState,
              endDate: value,
            }));
          }}
          value={updateInfo.endDate}
          minDate={moment()}
        />
        <Spacer />
        <Button
          variant={"contained"}
          sx={{ width: "100%" }}
          disabled={
            !moment(updateInfo.endDate).isValid() ||
            !moment(updateInfo.endDate).isSameOrAfter(moment(), "day")
          }
          onClick={() => setCurrentStep(1)}
        >
          Next
        </Button>
      </>
    );
  }, [data, updateInfo.endDate]);

  const onStepTwoSubmit = useCallback(
    (values) => {
      if (
        !moment(values.startDate).isValid() ||
        !moment(values.startDate).isSameOrAfter(updateInfo.endDate)
      ) {
        setShowStartDateError(true);
        return;
      }
      setUpdateInfo((prevState) => ({
        ...prevState,
        ...values,
      }));
      setCurrentStep(2);
    },
    [updateInfo.endDate]
  );

  const stepTwo = useMemo(() => {
    return (
      <>
        <h2>Add new service address</h2>
        <p className={"body"}>
          Please provide the new service address where you will be moving to, as
          well as the start date for the utilities service.
        </p>
        <Spacer />
        <Formik
          initialValues={{
            serviceAddress: updateInfo.serviceAddress ?? null,
            addressTwo: updateInfo.addressTwo ?? null,
            city: updateInfo.city ?? "Lacombe",
            province: updateInfo.province ?? "Alberta",
            postalCode: updateInfo.postalCode ?? null,
            startDate: updateInfo.startDate ?? null,
          }}
          validationSchema={stepTwoValidation}
          onSubmit={onStepTwoSubmit}
          validateOnChange={validateOnChange}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({
            dirty,
            handleBlur,
            handleChange,
            values,
            errors,
            validateForm,
            touched,
            setFieldValue,
            isValid,
          }) => {
            return (
              <Form noValidate>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p className={"overline_light"}>NEW SERVICE ADDRESS</p>
                    <TextField
                      label="Service Address"
                      fullWidth
                      name="serviceAddress"
                      id="serviceAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.serviceAddress}
                      error={Boolean(errors.serviceAddress)}
                      touched={touched.serviceAddress ? "true" : "false"}
                      helperText={errors.serviceAddress}
                    />
                    <TextField
                      optional={"true"}
                      fullWidth
                      label="Address 2 (optional)"
                      name="addressTwo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addressTwo}
                      error={Boolean(errors.addressTwo)}
                      touched={touched.addressTwo ? "true" : "false"}
                      helperText={errors.addressTwo}
                      style={{ marginTop: "0.5rem" }}
                    />
                    <TextField
                      label="City"
                      fullWidth
                      name="city"
                      id="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      error={Boolean(errors.city)}
                      touched={touched.city ? "true" : "false"}
                      inputProps={{ maxLength: 35 }}
                      helperText={errors.city}
                      style={{ marginTop: "0.5rem" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HighlightedAutoComplete
                      label="Province"
                      fullWidth
                      name="province"
                      id="province"
                      onChange={(_, value) => {
                        setFieldValue("province", value.label);
                      }}
                      onBlur={handleBlur}
                      value={values["province"]}
                      error={Boolean(errors.province)}
                      helperText={errors.province ? "Province required." : ""}
                      touched={touched.province ? "true" : "false"}
                      options={CanadaProvinces}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MaskedTextField
                      fullWidth
                      type={"POSTAL"}
                      label="Postal code"
                      name="postalCode"
                      id="postalCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      initialValue={values.postalCode}
                      error={Boolean(errors.postalCode)}
                      helperText={errors.postalCode}
                      touched={touched.postalCode ? "true" : "false"}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Spacer />
                    <p className={"overline_light"}>NEW SERVICE START DATE</p>
                    <DatePicker
                      name={"startDate"}
                      textFieldError={Boolean(errors.startDate)}
                      textFieldHelperText={errors.startDate}
                      sx={{ width: "100%" }}
                      label={"Add start date"}
                      onChange={(value) => {
                        setShowStartDateError(false);
                        setFieldValue("startDate", value);
                      }}
                      value={values.startDate}
                      minDate={moment(updateInfo.endDate)}
                    />
                    {showStartDateError && (
                      <p
                        style={{
                          margin: "0",
                          marginTop: "0.4rem",
                          color: RED,
                          fontSize: "0.75rem",
                          fontWeight: "400",
                        }}
                      >
                        Start date is required and should be later than end date
                      </p>
                    )}
                    <Spacer />
                    <Button
                      fullWidth
                      size={"large"}
                      variant="contained"
                      type="submit"
                      disabled={!dirty || !isValid}
                      onClick={async () => {
                        const validationResults = await validateForm();
                        const err = Object.keys(validationResults);
                        if (err.length) {
                          console.log(`#${err[0]}`);
                          const input =
                            document.querySelector(`#${err[0]}`) ||
                            document.querySelector(`input[name='${err[0]}']`);
                          if (input !== null) {
                            input.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                              inline: "start",
                            });
                          }
                        }
                        setValidateOnChange(true);
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }, [
    onStepTwoSubmit,
    showStartDateError,
    updateInfo.addressTwo,
    updateInfo.city,
    updateInfo.endDate,
    updateInfo.postalCode,
    updateInfo.province,
    updateInfo.serviceAddress,
    updateInfo.startDate,
    validateOnChange,
  ]);

  const handleConfirmModalClose = useCallback(() => {
    setShowConfirmationModal(false);
    history.goBack();
  }, [history]);

  const confirmTransfer = () => {
    setShowConfirmationModal(true);
  };

  const stepThree = useMemo(() => {
    return (
      <>
        <h2>Review before submitting</h2>
        <p className={"body"}>
          Before proceeding with your request to change your service address,
          please carefully review the information provided below.
        </p>
        <Spacer />
        <Spacer />
        <p className={"overline_light"}>Review information</p>
        <div className={"outlined_container"}>
          <ListItem
            startOrnament={<LocationOnOutlined />}
            variant={"detail"}
            label={"Service address to end"}
            subLabel={
              data?.find((e) => e.name === "service_address")?.value?.value
            }
          />
          <ListItem
            startOrnament={<EventOutlined />}
            variant={"detail"}
            label={"Service end date"}
            subLabel={moment(updateInfo.endDate).format("MMMM D, YYYY")}
          />
          <ListItem
            startOrnament={<LocationOnOutlined />}
            variant={"detail"}
            label={"New service address"}
            subLabel={
              updateInfo?.serviceAddress +
              `${
                !!updateInfo?.addressTwo ? ", " + updateInfo?.addressTwo : ""
              }` +
              `${!!updateInfo?.city ? ", " + updateInfo?.city : ""}` +
              `${!!updateInfo?.province ? ", " + updateInfo?.province : ""}` +
              `${!!updateInfo?.postalCode ? ", " + updateInfo?.postalCode : ""}`
            }
          />
          <ListItem
            startOrnament={<EventOutlined />}
            variant={"detail"}
            label={"Service start date"}
            subLabel={moment(updateInfo.startDate).format("MMMM D, YYYY")}
          />
        </div>
        <Spacer />
        <p className={"body_regular"}>
          <span className={"bold"}>Please note:</span> City Staff will review
          your request before processing the moving request. You'll receive an
          email once your request is approved. For any questions, call City Hall
          at (403) 782-6666.
        </p>
        <Spacer />
        <p className={"overline_light"}>Service Agreement</p>
        <CheckboxCard
          label={
            <div style={{ display: "flex" }}>
              <p>I agree to the </p>
              <LinkButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowTOSModal(true);
                }}
              >
                Terms of Service
              </LinkButton>
            </div>
          }
          value={termsAccepted}
          onChange={(e) => {
            e.stopPropagation();
            setTermsAccepted((prevState) => !prevState);
          }}
        />
        <Spacer />
        <Button
          onClick={confirmTransfer}
          variant={"contained"}
          disabled={!termsAccepted}
          size={"large"}
          fullWidth
        >
          Request transfer
        </Button>
        <Spacer />
        <Modal
          open={showTOSModal}
          title={"Term of Service"}
          onClose={() => setShowTOSModal(false)}
          maxWidth={"700px"}
        >
          <div style={{ paddingInline: "1.3rem", paddingBlock: "1rem" }}>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "140%",
              }}
            >
              THE UNDERSIGNED HEREBY REPRESENTS that he/she is the Owner or an
              authorized agent for the Owner, and that he/she has the authority
              to sign this contract for utility services consisting of Water,
              Wastewater and Solid Waste Collection. The Owner agrees to comply
              with all provisions of all applicable Bylaws and Policies of the
              City of Lacombe, including payment for all utilities services
              supplied by the City to the above service address until such time
              as the Owner or authorized agent requests in writing that the
              contract be terminated. It is understood that in the event of late
              payment or non-payment, in addition to other remedies the City of
              Lacombe may have, the amount owing for utilities services shall
              bear a penalty charge in accordance with the Municipal Government
              Act and City of Lacombe Bylaws and that non-compliance with the
              provisions of said Bylaws may result in transfer to tax account in
              accordance with the City of Lacombe Bylaws. The Owner acknowledges
              that the failure to receive or the loss of a utility bill will not
              be accepted as a reason for non-payment. THE OWNER HEREBY AGREES
              to abide by the terms and conditions specified in the City of
              Lacombe Bylaws.
            </p>
          </div>
        </Modal>
        <Modal
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 750,
              width: "100%",
              boxSizing: "border-box",
              padding: "1.5rem 3rem",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: 453,
                width: "100%",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ fontSize: 72, marginBottom: "1rem" }}
                color={"primary"}
              />
              <h2>Your moving request has been sent</h2>
              <p className={"body"} style={{ lineHeight: "22.4px" }}>
                Your service moving request has been submitted to City Hall.
                Please allow a few days for review and processing. Once
                approved, you'll receive a confirmation email. Thank you for
                your patience!
              </p>
              <Button
                style={{ width: 300 }}
                size={"large"}
                variant={"contained"}
                onClick={handleConfirmModalClose}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }, [
    data,
    handleConfirmModalClose,
    showConfirmationModal,
    showTOSModal,
    termsAccepted,
    updateInfo?.addressTwo,
    updateInfo?.city,
    updateInfo.endDate,
    updateInfo?.postalCode,
    updateInfo?.province,
    updateInfo?.serviceAddress,
    updateInfo.startDate,
  ]);

  return (
    <>
      <WizardStepTracker
        steps={wizardSteps}
        activeStep={currentStep}
        setActiveStep={setCurrentStep}
        onClose={onWizardClosed}
      />
      <Spacer amount={2} />
      <div
        style={{
          maxWidth: 360,
          margin: "0 auto",
        }}
      >
        {currentStep === 0 ? stepOne : currentStep === 1 ? stepTwo : stepThree}
      </div>
    </>
  );
};

export default MoveTransferUtilityWizard;
