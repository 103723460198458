import { manageSession } from "../../utilities/manageSession";
import { jwtDecode } from "jwt-decode";
import base from "./axiosInstance";

// GET pending user links
export const get_pending_links = async () => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded = jwtDecode(token);

  try {
    const response = await base.get(`/linkedaccounts/pending/${decoded.id}`);
    return response.data;
  } catch (error) {}
};

export const get_user_links = async () => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded = jwtDecode(token);

  try {
    const response = await base.get(`/linkedaccounts/links/${decoded.id}`);
    return response.data;
  } catch (error) {}
};

export const post_linking_request = async (data, userId) => {
  try {
    const response = await base.post("/linkedaccounts/requestLinking", {
      data,
      userId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
