import React from "react";
import { Skeleton } from "@mui/material";
import Spacer from "../../../../components/ui-kit/Spacer";
import Grid from "@mui/material/Unstable_Grid2";
import { tabs } from "../../../../../core/constants/licences";

const LicenceDetailsSkeleton = ({ tab = tabs[0] }) => {
  return (
    <>
      <Skeleton height={70} />
      <Spacer />
      <Skeleton height={30} width={140} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={35} width={140} />
      <Skeleton height={80} />
      {tab === tabs[0] ? (
        <>
          <Skeleton height={70} />
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Skeleton variant={"rectangular"} height={365} />
            </Grid>
            <Grid xs={6}>
              <Skeleton variant={"rectangular"} height={50} />
              <Spacer />
              <Skeleton variant={"rectangular"} height={300} />
            </Grid>
          </Grid>
        </>
      ) : null}
      {tab === tabs[1] ? (
        <>
          <Skeleton height={70} />
          <Grid container spacing={2}>
            <Grid xs={3}>
              <Skeleton variant={"rectangular"} height={210} />
            </Grid>
            <Grid xs={3}>
              <Skeleton variant={"rectangular"} height={210} />
            </Grid>
          </Grid>
        </>
      ) : null}
      {tab === tabs[2] ? (
        <>
          <Skeleton height={70} />
          <Skeleton height={80} />
          <Skeleton height={300} variant={"rectangular"} />
        </>
      ) : null}
      {tab === tabs[3] ? (
        <>
          <Skeleton height={70} />
          <Skeleton height={300} variant={"rectangular"} />
        </>
      ) : null}
    </>
  );
};

export default LicenceDetailsSkeleton;
