import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import styled from "styled-components";
import { reset_password } from "../../../../core/apis/user";
import { routes } from "../../../routes/routesData";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import TextField from "../../../components/ui-kit/TextField";
import Typography from "@mui/material/Typography";
import * as v from "../../../../utilities/yupValidators";
import * as yup from "yup";
import { utilGenerateStrongPassword } from "../../../../utilities";
import classes from "./index.module.scss";
import config from "../../../../core/apis/_config";

interface FormValues {
  password: string;
}

const initialValues = {
  password: "",
};

const SmallLinkButton = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary[200]} !important;
  margin-top: 5px !important;

  :hover {
    color: ${({ theme }) => theme.palette.primary[500]} !important;
    cursor: pointer;
  }
`;

const ReadMoreLessButton = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};

  :hover {
    cursor: pointer;
  }
`;

/**
 * Password validators
 */
export const passwordValidationMessages2 = [
  { text: "MUST contain at least 8 characters (12+ recommended)" },
  { text: "MUST contain at least one uppercase letter" },
  { text: "MUST contain at least one lowercase letter" },
  { text: "MUST contain at least one number" },
  { text: `MUST contain at least one special character (!@#$%^&*()_+)` },
];

const securePassTopText =
  "We only accept secure passwords. For the full list of requirements.";

const PasswordHelpTitle = () => {
  const theme = useTheme();

  return (
    <Typography
      variant="body1"
      style={{
        color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
        fontWeight: 500,
      }}
    >
      Secure password
    </Typography>
  );
};

const validation = yup.object({
  password: v.validStrongPassword,
});

const ResetPasswordScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const [validToken, setTokenState] = useState("unknown");
  const [showMore, setShowMore] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [validateOnChange, setValidateOnChange] = useState(false);
  const passwordRef: React.MutableRefObject<any> = useRef(null);

  const handleSubmit = async (values: FormValues) => {
    try {
      // @ts-ignore
      await reset_password(token, values.password);
      history.push({
        pathname: routes.LOGIN.path,
        state: {
          isPasswordReset: true,
        },
      });
    } catch (err) {}
  };

  useEffect(() => {
    async function checkToken() {
      try {
        // @ts-ignore
        await reset_password(token, "--");
      } catch (e) {
        history.push({
          pathname: routes.LOGIN.path,
          state: {
            resetTokenIsNotValid: true,
          },
        });
      }
      setTokenState("checked");
    }

    if (validToken === "unknown") {
      checkToken();
    }
  }, [validToken, history, token]);

  /**
   * Handles changes in the password field:
   * - if the input is "space" key, it preserves the current cursor position and leaves the input un-changed
   * - otherwise, sets the form value with the value received from input
   * @param e
   * @param formikProps
   */
  const onPasswordInputChanged = async (
    e: any,
    formikProps: FormikProps<{ password: string }>
  ) => {
    if (e.nativeEvent?.data === " ") {
      const selectionEnd = e.target.selectionEnd - 1;
      await new Promise((resolve) => setTimeout(resolve, 20));
      passwordRef.current.setSelectionRange(selectionEnd, selectionEnd);
      return;
    }
    const trimmedPassword = e.target.value.trim().replace(" ", "");
    formikProps.setFieldValue("password", trimmedPassword);
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.container}>
        <img
          alt={"CityLog"}
          src={`https://images.cloudcityhall.com/${config.cityId}/interface/logo.svg`}
          style={{
            marginLeft: "-20px",
            marginBottom: "37px",
            marginTop: "35px",
            maxWidth: "350px",
          }}
        />
        {showMore ? (
          <div style={{ width: "100%" }}>
            <PasswordHelpTitle />
            <Typography
              variant="body2"
              style={{ color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS }}
            >
              {securePassTopText + " refer to this list:"}
            </Typography>
            {passwordValidationMessages2.map(({ text }) => (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginLeft: "0.5rem",
                    color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                  }}
                >
                  {"\u2022"}
                </div>
                <Typography
                  variant="body2"
                  style={{
                    marginLeft: "0.5rem",
                    color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                  }}
                >
                  {text}
                </Typography>
              </div>
            ))}
            <Typography variant="body2">
              <ReadMoreLessButton onClick={() => setShowMore(false)}>
                Read less
              </ReadMoreLessButton>
            </Typography>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <PasswordHelpTitle />
            <Typography
              variant="body2"
              style={{ color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS }}
            >
              {securePassTopText}..
              <ReadMoreLessButton onClick={() => setShowMore(true)}>
                read more
              </ReadMoreLessButton>
            </Typography>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            validateOnChange={validateOnChange}
            validateOnBlur={validateOnChange}
            onSubmit={handleSubmit}
          >
            {(formikProps) => {
              let isFilledPassword =
                formikProps.values.password !== null &&
                formikProps.values.password !== "";

              const generateStrongPassword = () => {
                const suggestion = utilGenerateStrongPassword();
                formikProps.setFieldValue("password", suggestion);
              };

              return (
                <Form noValidate>
                  <TextField
                    ref={passwordRef}
                    key={"password"}
                    name={"password"}
                    type={"password"}
                    label={"Password"}
                    mask={passwordType}
                    fullWidth
                    onChange={(e: any) =>
                      onPasswordInputChanged(e, formikProps)
                    }
                    value={formikProps.values.password}
                    error={Boolean(formikProps.errors["password"])}
                    filled={isFilledPassword.toString()}
                    style={{ marginTop: "1rem" }}
                    form={formikProps}
                    helperText={formikProps.errors["password"]}
                  />
                  <div className={classes.forgot_button_wrapper}>
                    {passwordType === "text" ? (
                      <Button
                        style={{
                          textTransform: "none",
                          color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                        }}
                        size={"small"}
                        variant="text"
                        onClick={() => setPasswordType("password")}
                      >
                        Hide
                      </Button>
                    ) : (
                      <Button
                        style={{
                          textTransform: "none",
                          color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                        }}
                        size={"small"}
                        variant="text"
                        onClick={() => setPasswordType("text")}
                      >
                        Show
                      </Button>
                    )}
                    <SmallLinkButton
                      variant="body1"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        generateStrongPassword();
                      }}
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Suggest a strong password
                    </SmallLinkButton>
                  </div>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    size="large"
                    style={{ marginTop: "1rem" }}
                    onClick={() => {
                      setValidateOnChange(true);
                    }}
                  >
                    SAVE PASSWORD
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
