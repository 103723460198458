import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Form, Formik } from "formik";
import ModalTitle from "../../../../../../components/ui-kit/modal-title";
import Alerts from "../../../../../../components/ui-kit/Alert";
import Select from "../../../../../../components/ui-kit/select";
import Grid from "@mui/material/Unstable_Grid2";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import {
  collectionServiceOptions,
  disposableServiceSizeOptions,
  metreSizeOptions,
  pickupOptions,
  typeOptions,
  wasteMetreOptions,
  I_DONT_KNOW,
} from "./options";
import { validationSchema } from "./validationSchema";
import MaskedTextField from "../../../../../../components/ui-kit/masked-text-field";
import MaskedTextFieldTypes from "../../../../../../components/ui-kit/masked-text-field/maskedTextFieldTypes";
import Checkbox from "../../../../../../components/ui-kit/Checkbox";

interface IFormModal {
  open: boolean;
  handleClose: any;
  onSubmit: any;
  values: any;
  adminSide?: boolean;
  changedFormKeys?: string[];
  showAlert?: boolean;
}

const ServicesFormModal = ({
  open = false,
  handleClose = () => {},
  onSubmit,
  values,
  adminSide = false,
  changedFormKeys = [],
  showAlert = true,
}: IFormModal) => {
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState({}) as any;

  const checkForDefaults = (value: any) => {
    if (!value) return "";
    return typeof value !== "object" ? value : "";
  };

  useEffect(() => {
    if (values?.property_type === "commercial") {
      values.property_type = "Commercial, Industrial, Institutional";
    }
    setInitialValues({
      property_type: checkForDefaults(values?.property_type),
      water_metre_number: checkForDefaults(values?.water_metre_number),
      water_metre_size: checkForDefaults(values?.water_metre_size),
      waste_metre_size: checkForDefaults(values?.waste_metre_size),
      collection_services: checkForDefaults(values?.collection_services),
      solid_waste_general: checkForDefaults(values?.solid_waste_general),
      solid_waste_cardboard: checkForDefaults(values?.solid_waste_cardboard),
      solid_waste_size: checkForDefaults(values?.solid_waste_size),
    });
  }, [values]);

  return (
    <Formik
      //@ts-ignore
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ resetForm, dirty, values, setFieldValue, handleChange, isValid }) => {
        return (
          <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
            <ModalTitle
              title={"Update information"}
              handleClose={() => {
                handleClose();
                resetForm();
              }}
            />
            <DialogContent>
              {showAlert && (
                <Alerts
                  title={
                    adminSide
                      ? "Please review changes"
                      : "Before submitting to review"
                  }
                  variant={adminSide ? "info" : "warning"}
                  body={
                    adminSide
                      ? "The applicant submitted changes, highlighted below."
                      : "Please double-check all your updated information before submitting"
                  }
                />
              )}
              <Spacer />
              <Form noValidate={true}>
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <p className={"overline_bold"}>PROPERTY TYPE</p>
                    <Select
                      name={"property_type"}
                      label={"Type"}
                      value={values.property_type}
                      onChange={handleChange}
                      fullWidth
                      options={typeOptions}
                      inputProps={
                        changedFormKeys?.includes("property_type")
                          ? {
                              className: "changed-field-yellow-background",
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("property_type")
                          ? {
                              className: "changed-field-yellow-background",
                              disableUnderline: true,
                            }
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p className={"overline_bold"}>WATER SERVICES</p>
                    <MaskedTextField
                      type={
                        values.water_metre_number !== I_DONT_KNOW
                          ? MaskedTextFieldTypes.NUMBER
                          : null
                      }
                      name={"water_metre_number"}
                      label={"RF serial number"}
                      value={
                        values.water_metre_number !== I_DONT_KNOW
                          ? values.water_metre_number
                          : ""
                      }
                      onChange={handleChange}
                      fullWidth
                      inputProps={
                        changedFormKeys?.includes("water_metre_number")
                          ? {
                              className: "changed-field-yellow-background",
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("water_metre_number")
                          ? {
                              className: "changed-field-yellow-background",
                              disableUnderline: true,
                            }
                          : undefined
                      }
                      disabled={values.water_metre_number === I_DONT_KNOW}
                    />
                    <Checkbox
                      name={"water_metre_number"}
                      value={values.water_metre_number === I_DONT_KNOW}
                      label={I_DONT_KNOW}
                      onChange={(e: any, value: any) => {
                        if (value) {
                          setFieldValue("water_metre_number", I_DONT_KNOW);
                        } else {
                          setFieldValue("water_metre_number", "");
                        }
                      }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Select
                      name={"water_metre_size"}
                      label={"Water metre size"}
                      value={
                        values.water_metre_size !== I_DONT_KNOW
                          ? values.water_metre_size
                          : ""
                      }
                      onChange={handleChange}
                      fullWidth
                      options={metreSizeOptions}
                      inputProps={
                        changedFormKeys?.includes("water_metre_size")
                          ? {
                              className: "changed-field-yellow-background",
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("water_metre_size")
                          ? {
                              className: "changed-field-yellow-background",
                              disableUnderline: true,
                            }
                          : undefined
                      }
                      disabled={values.water_metre_size === I_DONT_KNOW}
                    />
                    <Checkbox
                      name={"water_metre_size"}
                      value={values.water_metre_size === I_DONT_KNOW}
                      label={I_DONT_KNOW}
                      onChange={(e: any, value: any) => {
                        if (value) {
                          setFieldValue("water_metre_size", I_DONT_KNOW);
                        } else {
                          setFieldValue("water_metre_size", "");
                        }
                      }}
                    />
                  </Grid>
                  {values.property_type !== "residential" ? (
                    <Grid xs={12}>
                      <p className={"overline_bold"}>WASTEWATER SERVICES</p>
                      <Select
                        name={"waste_metre_size"}
                        label={"Wastewater metre size"}
                        value={
                          values.waste_metre_size !== I_DONT_KNOW
                            ? values.waste_metre_size
                            : ""
                        }
                        onChange={handleChange}
                        fullWidth
                        options={wasteMetreOptions}
                        inputProps={
                          changedFormKeys?.includes("waste_metre_size")
                            ? {
                                className: "changed-field-yellow-background",
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("waste_metre_size")
                            ? {
                                className: "changed-field-yellow-background",
                                disableUnderline: true,
                              }
                            : undefined
                        }
                        disabled={values.waste_metre_size === I_DONT_KNOW}
                      />
                      <Checkbox
                        name={"waste_metre_size"}
                        value={values.waste_metre_size === I_DONT_KNOW}
                        label={I_DONT_KNOW}
                        onChange={(e: any, value: any) => {
                          if (value) {
                            setFieldValue("waste_metre_size", I_DONT_KNOW);
                          } else {
                            setFieldValue("waste_metre_size", "");
                          }
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid xs={12}>
                    <p className={"overline_bold"}>SOLID WASTE SERVICES</p>
                    {values.property_type === "residential" ? (
                      <Select
                        name={"collection_services"}
                        label={"Collection services"}
                        value={values.collection_services}
                        onChange={handleChange}
                        fullWidth
                        options={collectionServiceOptions}
                        inputProps={
                          changedFormKeys?.includes("collection_services")
                            ? {
                                className: "changed-field-yellow-background",
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("collection_services")
                            ? {
                                className: "changed-field-yellow-background",
                                disableUnderline: true,
                              }
                            : undefined
                        }
                      />
                    ) : null}
                  </Grid>
                  {values.property_type !== "residential" ? (
                    <>
                      <Grid xs={12}>
                        <Select
                          name={"solid_waste_general"}
                          label={"General waste"}
                          value={values.solid_waste_general}
                          onChange={handleChange}
                          fullWidth
                          options={pickupOptions}
                          inputProps={
                            changedFormKeys?.includes("solid_waste_general")
                              ? {
                                  className: "changed-field-yellow-background",
                                }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("solid_waste_general")
                              ? {
                                  className: "changed-field-yellow-background",
                                  disableUnderline: true,
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Select
                          name={"solid_waste_cardboard"}
                          label={"Cardboard"}
                          value={values.solid_waste_cardboard}
                          onChange={handleChange}
                          fullWidth
                          options={pickupOptions}
                          inputProps={
                            changedFormKeys?.includes("solid_waste_cardboard")
                              ? {
                                  className: "changed-field-yellow-background",
                                }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("solid_waste_cardboard")
                              ? {
                                  className: "changed-field-yellow-background",
                                  disableUnderline: true,
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Select
                          name={"solid_waste_size"}
                          label={"Disposal service size"}
                          value={
                            values.solid_waste_size !== I_DONT_KNOW
                              ? values.solid_waste_size
                              : ""
                          }
                          onChange={handleChange}
                          fullWidth
                          options={disposableServiceSizeOptions}
                          inputProps={
                            changedFormKeys?.includes("solid_waste_size")
                              ? {
                                  className: "changed-field-yellow-background",
                                }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("solid_waste_size")
                              ? {
                                  className: "changed-field-yellow-background",
                                  disableUnderline: true,
                                }
                              : undefined
                          }
                          disabled={values.solid_waste_size === I_DONT_KNOW}
                        />
                        <Checkbox
                          name={"solid_waste_size"}
                          value={values.solid_waste_size === I_DONT_KNOW}
                          label={I_DONT_KNOW}
                          onChange={(e: any, value: any) => {
                            if (value) {
                              setFieldValue("solid_waste_size", I_DONT_KNOW);
                            } else {
                              setFieldValue("solid_waste_size", "");
                            }
                          }}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty}
                size={"small"}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  if (formikRef.current) {
                    //@ts-ignore
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                {adminSide ? "Save changes " : "Submit to review"}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ServicesFormModal;
