import React from "react";
import styled from "@emotion/styled";

//language=SCSS prefix=*{ suffix=}
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-right: 1rem;

  @media (max-width: 1200px) {
    max-width: 650px;
  }

  @media (max-width: 1000px) {
    max-width: 500px;
  }

  @media (max-width: 830px) {
    max-width: 400px;
  }
  
  @media (max-width: 720px) {
    padding-right: 0;
    max-width: 345px;
  }
`;

/**
 * Contains LeftSubColumn items, and displays them according to the figma layout design for the triple column layout
 * @param children
 * @param width
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function LeftColumn({
  children,
  width = null,
  ...props
}: {
  children: React.ReactNode;
  width?: string | null;
}) {
  return <LeftContainer style={{maxWidth: width || "930px"}} {...props}>{children}</LeftContainer>;
}
