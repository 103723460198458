import React, { useEffect, useMemo, useState } from "react";
import Dialog from "../../ui-kit/Dialog";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { validEmails } from "../../../../utilities/yupValidators";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material";
import Spacer from "../../ui-kit/Spacer";
import TextField from "@mui/material/TextField";

const validation = yup.object({
  email: validEmails,
});

const SetEmailConfirmationModal = ({
  open,
  setOpen,
  handleClose,
  onSubmit,
  defaultEmails,
  title = "Send by Email",
  buttonTwoLabel = "Submit",
  emailsListTitle = "Share Email with recipient(s):",
}) => {
  const theme = useTheme();
  const [initialEmails, setInitialEmails] = useState(defaultEmails ?? []);
  const [isOptionalEmailFieldValid, setIsOptionalEmailFieldValid] =
    useState(true);
  const [optionalEmailFieldValue, setOptionalEmailFieldValue] = useState("");

  useEffect(() => {
    setInitialEmails(
      defaultEmails?.map((e) => ({
        email: e,
        checked: false,
      })) ?? [],
    );
  }, [defaultEmails]);

  const _onButtonTwoClick = () => {
    setOpen(false);
    const emails = initialEmails
      ?.filter((e) => e.checked === true)
      .map((x) => x.email);
    onSubmit([
      ...emails,
      ...(optionalEmailFieldValue.split(",")[0].length < 1
        ? []
        : optionalEmailFieldValue.split(",")),
    ]);
    setInitialEmails((prevState) =>
      prevState?.map((t) => ({
        ...t,
        checked: false,
      })),
    );
    setOptionalEmailFieldValue("");
  };

  /**
   * Indicates when the submit button should be disabled
   */
  const buttonTwoDisabled = useMemo(() => {
    return (
      !isOptionalEmailFieldValid ||
      (optionalEmailFieldValue.length < 1 &&
        !initialEmails?.find((e) => e?.checked))
    );
  }, [
    initialEmails,
    isOptionalEmailFieldValid,
    optionalEmailFieldValue.length,
  ]);

  /**
   * Handles changes in the multiple email address field
   * @param e
   */
  const onMultipleEmailFieldChange = (e) => {
    let { value } = e.target;

    // Remove spaces from the input value
    value = value.replace(/\s/g, "");

    // Check if the entered email addresses are valid
    const isValidEmailList =
      value === "" ||
      /^[^\s@,]+@[^\s@,]+\.[^\s@,]+(?:,[^\s@,]+@[^\s@,]+\.[^\s@,]+)*$/.test(
        value,
      );

    setOptionalEmailFieldValue(value);
    setIsOptionalEmailFieldValid(isValidEmailList);
  };

  /**
   * Memo version of revoke BL dialog content
   * @type {JSX.Element}
   */
  const dialogContent = useMemo(() => {
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontWeight={400}
          style={{
            marginBottom: "1rem",
            marginRight: "1rem",
          }}
        >
          {emailsListTitle}
        </Typography>

        {initialEmails?.length > 0 ? (
          <Formik
            initialValues={initialEmails}
            validationSchema={validation}
            validateOnChange={true}
          >
            {(formikProps) => {
              return (
                <Form noValidate>
                  {initialEmails.map((recipient) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Checkbox
                          key={recipient.email}
                          sx={{
                            marginLeft: 0,
                            paddingLeft: 0,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          checked={recipient.checked}
                          value={recipient.email}
                          onChange={(e) => {
                            setInitialEmails((prev) =>
                              prev.map((email) => {
                                if (email.email === recipient.email) {
                                  return {
                                    ...email,
                                    checked: e.target.checked,
                                  };
                                }
                                return email;
                              }),
                            );
                          }}
                        />
                        <p>{recipient.email}</p>
                      </div>
                    );
                  })}
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Typography
            fontWeight={400}
            style={{
              marginLeft: "0.5rem",
              marginBottom: "1rem",
              marginRight: "1rem",
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
          >
            No contacts available
          </Typography>
        )}
        <Spacer />
        <Typography
          fontWeight={500}
          style={{
            marginBottom: "1rem",
            marginRight: "1rem",
          }}
        >
          Add Email(s):
        </Typography>
        <TextField
          name="email"
          label="Email"
          fullWidth
          onChange={onMultipleEmailFieldChange}
          value={optionalEmailFieldValue}
          error={!isOptionalEmailFieldValid}
          helperText={
            isOptionalEmailFieldValid
              ? null
              : "Please enter a valid email address. You can set multiple emails separated by commas (e.g., test01@mail.com,test02@mail.com)."
          }
          style={{ marginBottom: "1rem" }}
        />
      </div>
    );
  }, [
    emailsListTitle,
    initialEmails,
    isOptionalEmailFieldValid,
    optionalEmailFieldValue,
    theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
  ]);

  return (
    <Dialog
      variant={"updated"}
      open={open}
      handleClose={() => {
        setOpen(false);
        setInitialEmails((prevState) =>
          prevState?.map((t) => ({
            ...t,
            checked: false,
          })),
        );
        setOptionalEmailFieldValue("");
        setIsOptionalEmailFieldValid(true);
        (!!handleClose ? handleClose : () => false)();
      }}
      title={title}
      body={dialogContent}
      buttonOneText={"Cancel"}
      buttonTwoText={buttonTwoLabel}
      buttonTwoVariant={"contained"}
      buttonFlexDirection={"column-reverse"}
      handleButtonOne={() => {
        setOpen(false);
        (!!handleClose ? handleClose : () => false)();
        setInitialEmails((prevState) =>
          prevState?.map((t) => ({
            ...t,
            checked: false,
          })),
        );
        setOptionalEmailFieldValue("");
        setIsOptionalEmailFieldValid(true);
      }}
      handleButtonTwo={_onButtonTwoClick}
      buttonTwoDisable={buttonTwoDisabled}
    />
  );
};

export default SetEmailConfirmationModal;
