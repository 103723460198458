import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Link from "@mui/material/Link";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 0.75rem;

  &&:hover {
    background-color: ${({ theme, enablehover }: any) =>
      enablehover ? theme.palette.primary[50] : theme.palette.whites.WHITE};
    cursor: ${({ enablehover }: any) => (enablehover ? "pointer" : "unset")};
  }

  > * {
    color: ${({ theme }) => theme.palette.primary[500]};
  }

  &&&:focus {
    border: 0 solid #0c55b5;
    box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
      0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);
    color: ${({ theme }) => theme.palette.primary[200]};

    > * {
      color: ${({ theme }) => theme.palette.primary[200]};
    }

    && > * > * > * {
      color: ${({ theme }) => theme.palette.primary[200]};
    }
  }
`;

const DisabledListItem = styled(Container)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.whites.WHITE};
    cursor: unset;
  }

  .material-symbols-rounded {
    color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
  }

  .label {
    color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
  }
`;

const StartOrnamentContainer = styled.div`
  display: flex;

  .material-symbols-rounded {
    font-size: 32px;
  }
`;

const EndOrnamentContainer = styled.div`
  align-self: center;
  display: flex;

  .material-symbols-rounded {
    font-size: 32px;
  }
`;

const ReadMoreLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.palette.blacks.BLACK_HIGH_EMPHASIS};
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

/**
 * List item component
 * @param disabled
 * @param variant
 * @param variant
 * @param endOrnament
 * @param label
 * @param labelFontWeight
 * @param onClick
 * @param startOrnament
 * @param characterLimit
 * @param subLabel
 * @param style
 * @param characterLimit
 * @param alignIcon
 * @param subLabelColor
 * @returns {JSX.Element}
 * @constructor
 */
const ListItem = ({
  disabled = false,
  variant = "",
  endOrnament = <></>,
  label = "",
  labelFontWeight = "400",
  onClick,
  startOrnament = <></>,
  subLabel = "",
  characterLimit,
  style,
  alignIcon = false,
  subLabelColor,
}: any) => {
  const theme = useTheme();
  const [showAll, setShowAll] = useState(false);
  const enableHover = Boolean(onClick);

  const DynamicContainer = (props: any) => {
    if (disabled) {
      return <DisabledListItem {...props} />;
    } else {
      return <Container {...props} />;
    }
  };

  return (
    <DynamicContainer
      style={style}
      enablehover={enableHover}
      disabled={disabled}
      tabIndex={!onClick ? null : 0}
      onClick={onClick ? onClick : null}
      onKeyPress={(e: any) => {
        e.preventDefault();
        if (e.key === "Enter") {
          onClick();
        }
      }}
    >
      <div
        style={{
          alignItems: alignIcon ? "center" : "unset",
          display: "flex",
          overflow: "hidden",
          paddingRight: "1rem",
          textOverflow: "ellipsis",
        }}
      >
        <StartOrnamentContainer style={{ alignItems: "center" }}>
          {startOrnament}
        </StartOrnamentContainer>
        <div
          style={{
            marginLeft: "10px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {variant === "detail" ? (
            <div>
              <Typography
                className={"label"}
                variant="caption"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              >
                {label}
              </Typography>
              <Typography
                color={theme.palette.primary[700]}
                style={{ width: 250, wordWrap: "break-word" }}
                variant="body1"
              >
                {!characterLimit || subLabel?.length < characterLimit ? (
                  subLabel
                ) : !showAll ? (
                  <>
                    {subLabel?.substring(0, characterLimit - 15)}
                    <ReadMoreLink
                      onClick={() => {
                        setShowAll(!showAll);
                      }}
                    >
                      ... read more
                    </ReadMoreLink>
                  </>
                ) : (
                  <>
                    {subLabel}
                    <ReadMoreLink
                      onClick={() => {
                        setShowAll(!showAll);
                      }}
                    >
                      . read less
                    </ReadMoreLink>
                  </>
                )}
              </Typography>
            </div>
          ) : (
            <>
              <Typography
                className={"label"}
                variant="body1"
                fontWeight={labelFontWeight}
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  color: style.color || "",
                }}
              >
                {label}
              </Typography>
              {Array.isArray(subLabel) ? (
                subLabel.map((label) => {
                  return (
                    <Typography
                      fontSize={14}
                      color={
                        subLabelColor ||
                        theme.palette.blacks.BLACK_MEDIUM_EMPHASIS
                      }
                      style={{ lineHeight: 1.5 }}
                    >
                      {label}
                    </Typography>
                  );
                })
              ) : (
                <Typography
                  color={
                    subLabelColor || theme.palette.blacks.BLACK_MEDIUM_EMPHASIS
                  }
                  variant="body2"
                  style={{ lineHeight: 1.3 }}
                >
                  {subLabel}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>

      <EndOrnamentContainer>{endOrnament}</EndOrnamentContainer>
    </DynamicContainer>
  );
};

export default ListItem;
