import React, { useCallback, useMemo, useState, useContext } from "react";
import typographyClasses from "../../../../../assets/styles/typography/typography.module.scss";
import Spacer from "../../../../components/ui-kit/Spacer";
import DataTable from "../../../../components/ui-kit/data-table";
import classes from "./index.module.scss";
import moment from "moment";
import { currency, truncate } from "../../../../../utilities";
import IconButton from "@mui/material/IconButton";
import { MoreVert } from "@mui/icons-material";
import { Popover } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { EMPTY_TABLE_VARIANTS } from "../../../../../core/constants/general";
import {
  getAccountInvoicePdf,
  getAccountTransactions,
} from "../../../../../core/apis/account";
import { DataFormat } from "../../../../../core/constants/enums";
import { download } from "utilities/files";
import LinkButton from "../../../../components/ui-kit/link-button";
import SetEmailConfirmationModal from "../../../../components/modals/set-email-confirmation-modal";
import { sendInvoiceEmail } from "../../../../../core/apis/invoice";
import { NotificationContext } from "../../../../contexts/NotificationContext";

const RecordStatus: {
  [key: string]: {
    name: string;
    class: string;
  };
} = {
  paid: { name: "Paid", class: classes["paid_status"] },
  open: { name: "Pending", class: classes["pending_status"] },
  closed: { name: "Closed", class: classes["closed_status"] },
};

interface WithId {
  id: string;
}

type WithIdAndMore = WithId & {
  [key: string]: any;
};

const BillingTab = ({ loading = false, id = "0", emailsToUse = [] }) => {
  const { handleSuccess, handleError } = useContext(NotificationContext);
  const [data, setData] = useState<any>([]);
  const [anchorElements, setAnchorElements] = useState<any[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<WithIdAndMore | null>(
    null,
  );
  const [sendInvoiceEmailDialogOpen, setSendInvoiceEmailDialogOpen] =
    useState(false);

  const invoiceEmails = useMemo(() => {
    return !Array.isArray(emailsToUse)
      ? [emailsToUse]
      : emailsToUse && emailsToUse.length > 0
        ? emailsToUse.filter(
            (email, index) => emailsToUse.indexOf(email) === index,
          )
        : [];
  }, [emailsToUse]);

  const downloadInvoicePdf = useCallback(
    async (index: number, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e?.stopPropagation();
      e?.preventDefault();
      setAnchorElements((prevState) =>
        prevState.map((e, _index) => (_index === index ? null : e)),
      );
      const detailLink = data?.result[index].detailLink;
      const invoiceLink = await getAccountInvoicePdf(detailLink);
      if (invoiceLink) {
        await download(invoiceLink, "Invoice");
      }
    },
    [data?.result],
  );

  const isMoreOptionOpen = useCallback(
    (index: number) => Boolean(anchorElements[index]),
    [anchorElements],
  );

  const handleMoreOptionClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    setAnchorElements((prevState) =>
      prevState.map((e, _index) =>
        _index === index ? event.currentTarget : e,
      ),
    );
  };

  const handleMoreOptionClose = (index: number) => {
    setAnchorElements((prevState) =>
      prevState.map((e, _index) => (_index === index ? null : e)),
    );
  };

  const handleSendByEmail = useCallback(
    (index: number, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e?.stopPropagation();
      e?.preventDefault();
      setAnchorElements((prevState) =>
        prevState.map((e, _index) => (_index === index ? null : e)),
      );
      setSelectedInvoice(data?.result[index]);
      setSendInvoiceEmailDialogOpen(true);
    },
    [data?.result],
  );

  const handleSendInvoiceEmail = async (emails: string[]) => {
    try{
      await sendInvoiceEmail(selectedInvoice!.id, emails, true);
      handleSuccess("Invoice sent");
    }catch (e: any) {
      console.error(e.message);
      handleError(`Error: ${e.message}`);
    }
  };

  /**
   * Memo version of data table columns and data format.
   */
  const fields: any[] = useMemo(
    () => [
      {
        id: "date",
        label: "Invoice Date",
        disableSort: false,
        format: (val: any) => moment(val).format("YYYY-MM-DD"),
      },
      {
        id: "title",
        label: "Description",
        disableSort: true,
        size: "20px",
        format: (val: any, { payment, subTitle, detailLink }: any) =>
          payment ? (
            <p className={classes.payment_description}>{val}</p>
          ) : (
            <>
              <LinkButton
                tooltip={val}
                color={"blue"}
                onClick={async (e: any) => {
                  const invoiceLink = await getAccountInvoicePdf(detailLink);
                  if (invoiceLink) {
                    await download(invoiceLink, "Invoice");
                  }
                  e.stopPropagation();
                }}
              >
                {truncate(val, 35)}
              </LinkButton>
              <p
                className={`${typographyClasses.body2} ${typographyClasses.small_font}`}
              >
                {subTitle}
              </p>
            </>
          ),
      },
      {
        id: "status",
        label: "Status",
        disableSort: true,
        hideFromTable: false,
        align: "center",
        type: "tag",
        format: (val: any, { status }: any) => {
          const result = RecordStatus[status];

          return !!result ? (
            <div className={`${result.class}`}>
              <div>
                <p>{result.name}</p>
              </div>
            </div>
          ) : (
            <div />
          );
        },
      },
      {
        id: "payment",
        label: "Payment",
        align: "right",
        disableSort: true,
        format: (val: any, { amount }: any) => {
          return (
            <p className={classes.payment}>{!!val ? currency(amount) : ""}</p>
          );
        },
      },
      {
        id: "amount",
        disableSort: true,
        label: "Amount",
        align: "right",
        format: (val: any, { payment }: any) => (
          <p>{!payment ? DataFormat.CURRENCY(val) : ""}</p>
        ),
      },
      {
        id: "more_option",
        label: "",
        disableSort: true,
        align: "center",
        format: (val: any, { payment, index }: any) => {
          return !payment ? (
            <>
              <IconButton
                children={<MoreVert />}
                onClick={(e) => handleMoreOptionClick(e, index)}
              />
              <Popover
                open={isMoreOptionOpen(index)}
                anchorEl={anchorElements[index]}
                onClose={() => handleMoreOptionClose(index)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={(e) => downloadInvoicePdf(index, e)}
                  className={"menu-item"}
                >
                  Download PDF
                </MenuItem>
                <MenuItem
                  onClick={(e) => handleSendByEmail(index, e)}
                  className={"menu-item"}
                >
                  Send by Email
                </MenuItem>
              </Popover>
            </>
          ) : (
            <></>
          );
        },
      },
    ],
    [anchorElements, downloadInvoicePdf, handleSendByEmail, isMoreOptionOpen],
  );

  const getInvoicesAndPayments = useCallback(
    async (queryOptions: object) => {
      try {
        const result = await getAccountTransactions(id, queryOptions);
        setData({
          result: result.result.map((e: any, index: number) => ({
            ...e,
            index: index,
          })),
          total: result.total,
        });
        setAnchorElements(Array(result.result.length).fill(null));
      } catch (e) {
        console.error(e);
      }
    },
    [id],
  );

  return (
    <>
      <div>
        <p className={typographyClasses.h2}>Invoices & Payments</p>
        <Spacer />
        <DataTable
          dataLoading={loading}
          fields={fields}
          data={data}
          loadData={getInvoicesAndPayments}
          onClickHandlers={{ lastFourDigits: 1234 }}
          emptyTableVariant={EMPTY_TABLE_VARIANTS.BASIC}
        />
      </div>
      <SetEmailConfirmationModal
        open={sendInvoiceEmailDialogOpen}
        setOpen={setSendInvoiceEmailDialogOpen}
        handleClose={() => {
          setSelectedInvoice(null);
        }}
        onSubmit={handleSendInvoiceEmail}
        defaultEmails={invoiceEmails}
        title={"Send invoice by Email"}
        buttonTwoLabel={"Email invoice"}
        emailsListTitle={"Share Email invoice with recipient(s):"}
      />
    </>
  );
};

export default BillingTab;
