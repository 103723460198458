export const normalizeOnSubmit = (values: any) => {
  Object.entries(values).forEach(([field, value]) => {
    if (typeof value !== "string" || field.includes("password")) return;

    //Remove spaces around
    values[field] = value.trim();
    //Remove redundant spaces
    values[field] = values[field].replace(/\s+/g, " ");

    if (field === "phone") {
      values[field] = value.replace(/ /g, "");
    }

    if (field === "ccnumber") {
      values[field] = value.replace(/\s+/g, "");
    }
  });
  return values;
};
