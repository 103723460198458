import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { SidebarContext } from "../../../contexts/SidebarContext";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import SidebarItem, { CollapseButton } from "./SidebarItem";
import Slide from "@mui/material/Slide";
import { AuthContext } from "../../../contexts/AuthContext";
import useSidebarSettings, { DIVIDER } from "../../../hooks/useSidebarSettings";
import {
  getAuthType,
  getScreenOptions,
  routes,
} from "../../../routes/routesData";
import { theme, WHITE } from "../../../../mui-theme/theme";
import { ADMIN_AUTH_TYPE } from "../../../../core/constants/authTypes";
import config from "../../../../core/apis/_config";
import { EditingContext } from "../../../contexts/EditingContext";

const CityLogoContainer = styled.div`
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  display: flex;
  height: 167px;
`;

const Container = styled.div`
  border-right: 1px solid ${({ theme }) => theme.dividerColor};
  bottom: 1.5rem;
  left: 0;
  height: ${({ showHeader, theme }) =>
    showHeader ? `calc(100vh - ${theme.headerHeight})` : "100vh"};
  position: absolute;
  top: ${theme.headerHeight};
  width: ${({ sidebarWidth }) => sidebarWidth};
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
  background: ${WHITE};
`;

const SidebarTabsContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: ${({ showHeader, theme }) =>
    showHeader ? `calc(100vh - ${theme.headerHeight})` : "100vh"};
  right: 0;
  overflow-y: visible;
  overflow-x: hidden;
`;

const StyledDivider = styled(Divider)`
  && {
    background: ${({ theme }) => theme.dividerColor};
    margin: 1rem 0;
  }
`;

const StyledList = styled(List)`
  && {
    padding: 0;
  }
`;

export default function Sidebar({ showHeader }) {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { sidebarWidth, setSidebarWidth } = useContext(SidebarContext);
  const [selectedItem, setSelectedItem] = useState(routes.DASHBOARD.title);
  const [subSidebar, setSubSidebar] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const { handleClickWrapper } = useContext(EditingContext);

  const [sidebarSettings, sidebarLoading] = useSidebarSettings();
  const windowSize = useWindowSize();

  const { dashboard, ...services } = sidebarSettings;
  const history = useHistory();
  const backButtonContent = {
    title: subSidebar?.parent,
    icon: "far fa-arrow-alt-circle-left",
  };
  const currentPath = location.pathname;
  const isDashboardLoaded = Boolean(sidebarSettings.dashboard);

  const handleBackClick = () => {
    history.push("/admin/");
    setSubSidebar(null);
    setSelectedItem(sidebarSettings.dashboard.title);
  };

  const handleListItemClick = (path, title, subSidebarOption) => {
    if (subSidebarOption.subItems) {
      const firstSubItem = subSidebarOption.subItems.find(
        (subItem) => !subItem.disabled,
      );
      history.push(firstSubItem.path);
      setSelectedItem(firstSubItem.title);
      setSubSidebar(subSidebarOption);
    } else {
      setSelectedItem(title);
      history.push(path);
    }
  };

  useEffect(() => {
    const { width } = windowSize;
    const shouldCollapse = width && width < 1740;
    setCollapse(shouldCollapse);
  }, [windowSize]);

  useEffect(() => {
    const width = collapse ? theme.sidebarWidthCollapsed : theme.sidebarWidth;
    setSidebarWidth(width);
    // eslint-disable-next-line
  }, [collapse]);

  useEffect(() => {
    if (subSidebar) setInitialLoad(false);
  }, [subSidebar]);

  useEffect(() => {
    if (!Object.keys(sidebarSettings).length) return;

    // On home logo click, closes subTabs and removes tab highlight not Dashboard tab click
    if (currentPath === dashboard?.path && selectedItem !== dashboard?.title) {
      setSelectedItem(dashboard?.title);
      setSubSidebar(null);
      return;
    }

    for (let serviceName in services) {
      const serviceArray = services[serviceName];
      // update sidebar states when url path is changed without using UI (manually typing or browser back/forward button)
      for (let service of serviceArray) {
        if (service.subItems) {
          for (let subItem of service.subItems) {
            const path = currentPath.split("/");
            if (
              subItem !== DIVIDER &&
              subItem.path === `/admin/${path[2]}` &&
              selectedItem !== subItem.title
            ) {
              setSelectedItem(subItem.title);
              setSubSidebar({
                parent: service.title,
                subItems: service.subItems,
              });
              return;
            }
          }
        } else if (
          matchPath(currentPath, {
            path: service.path,
            exact: false,
          }) &&
          selectedItem !== service.title
        ) {
          setSelectedItem(service.title);
          setSubSidebar(null);
          return;
        }
      }
    }
  }, [
    dashboard?.path,
    dashboard?.title,
    selectedItem.sidebarSettings,
    currentPath,
    sidebarLoading,
    services,
    selectedItem,
    sidebarSettings,
    user,
  ]);

  const props = getScreenOptions(location.pathname);
  const authType = getAuthType(location.pathname);

  return !props.hideSidebar && authType === ADMIN_AUTH_TYPE ? (
    <Container
      collapse={collapse}
      sidebarWidth={sidebarWidth}
      showHeader={showHeader}
    >
      <SidebarTabsContainer showHeader={showHeader}>
        {collapse ? (
          <CityLogoContainer
            onClick={() => {
              handleClickWrapper(() => history.push("/"));
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              alt={"CityIcon"}
              src={`https://images.cloudcityhall.com/${config.cityId}/interface/icon_admin_sidebar.svg`}
              height={"80px"}
            />
          </CityLogoContainer>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
               onClick={() => {
                 handleClickWrapper(() => history.push("/"));
               }}
          >
            <img
              alt={"CityLogo"}
              src={`https://images.cloudcityhall.com/${config.cityId}/interface/logo_admin_sidebar.svg`}
              style={{
                maxHeight: "230px",
                maxWidth: "225px",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
        )}
        <Slide
          direction="left"
          in={!Boolean(subSidebar)}
          mountOnEnter
          unmountOnExit
          timeout={initialLoad ? 0 : { appear: 0, enter: 250, exit: 0 }}
        >
          <StyledList component="nav" aria-label="main navigation">
            {!isDashboardLoaded ? null : (
              <>
                {
                  <SidebarItem
                    content={dashboard}
                    collapse={collapse}
                    onListItemClick={handleListItemClick}
                    selectedItem={selectedItem}
                  />
                }
                {Object.entries(services)?.map(([_, serviceList], index) => {
                  if (!Boolean(serviceList?.length)) return null;
                  return (
                    <div key={index}>
                      <StyledDivider />
                      {serviceList.map((service, index) => {
                        return (
                          <SidebarItem
                            content={service}
                            collapse={collapse}
                            key={index}
                            onListItemClick={handleListItemClick}
                            selectedItem={selectedItem}
                            disabled={service.disabled || service.hideSidebar}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </StyledList>
        </Slide>
        <Slide
          direction="right"
          in={Boolean(subSidebar)}
          mountOnEnter
          unmountOnExit
        >
          <StyledList component="nav" aria-label="main navigation">
            {!subSidebar ? null : (
              <>
                <SidebarItem
                  backButton
                  content={backButtonContent}
                  collapse={collapse}
                  onListItemClick={() => handleBackClick()}
                  selectedItem={selectedItem}
                />
                <StyledDivider />
                {subSidebar.subItems.map((service, index) => {
                  if (service.disabled) return "";
                  if (service === DIVIDER) return <StyledDivider key={index} />;
                  return (
                    <SidebarItem
                      content={service}
                      collapse={collapse}
                      key={index}
                      onListItemClick={handleListItemClick}
                      selectedItem={selectedItem}
                    />
                  );
                })}
              </>
            )}
          </StyledList>
        </Slide>
      </SidebarTabsContainer>
      <CollapseButton
        collapse={collapse}
        onClick={() => setCollapse(!collapse)}
      />
    </Container>
  ) : null;
}
