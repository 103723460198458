import React, { useContext, useState, useEffect } from "react";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { FilterContext } from "../../../../contexts/FilterContext";
import {
  DataFormat,
  FilterType,
  INPUT_FORMATS,
} from "../../../../../core/constants/enums";
import { statusFormat } from "../../../../../utilities/data-table-format-helpers";
import {
  addUser,
  get_users,
  get_users_export,
} from "../../../../../core/apis/user";
import DataTable from "../../../../components/ui-kit/data-table";
import { TableSettingContext } from "../../../../contexts/TableSettingContext";
import { AccountCircleOutlined } from "@mui/icons-material";
import CreateModal from "../../../../components/modals/CreateModal";
import {
  validEmail,
  validFirstName,
  validLastName,
} from "../../../../../utilities/yupValidators";
import * as yup from "yup";

const fields = [
  {
    id: "fullName",
    label: "Name",
    disableSort: false,
    filterType: FilterType.SEARCH,
  },
  {
    id: "email",
    label: "Email",
    filterType: FilterType.SEARCH,
    disableSort: false,
    showUnderscore: true,
  },
  {
    id: "disabled",
    label: "Status",
    format: (val) => {
      return statusFormat(val ? "inactive" : "active");
    },
    disableSort: false,
  },
  {
    id: "lastLoggedIn",
    label: "Last Logged In",
    format: DataFormat.DATE,
    filterType: FilterType.DATE_PICKER,
    disableSort: false,
  },
  {
    id: "createDateTime",
    label: "Created On",
    format: DataFormat.DATE,
    filterType: FilterType.DATE_PICKER,
    disableSort: false,
  },
];

const createFields = [
  {
    label: "Email",
    id: "email",
    size: 25,
    type: "email",
    validator: validEmail,
    inputFormat: INPUT_FORMATS.EMAIL,
  },
  {
    label: "First Name",
    id: "firstName",
    size: 12,
    type: "string",
  },
  {
    label: "Last Name",
    id: "lastName",
    size: 12,
    type: "string",
  },
];

const validationSchema = yup.object({
  email: validEmail,
  firstName: validFirstName,
  lastName: validLastName,
});

const initialValues = { email: "", firstName: "", lastName: "" };

const Users = (...props) => {
  const [tableIdentifier] = useState("userList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { filters, setFilters } = useContext(FilterContext);
  const [currentFilters, setCurrentFilters] = useState(filters["users"] || {});
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[tableIdentifier] || props.initialFilters || {}
  );
  const [openCreate, setOpenCreate] = useState(false);

  const fetchData = async (queryOptions) => {
    setLoading(true);
    try {
      const data = await get_users(queryOptions);
      setData(data);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const saveNewUser = async (data) => {
    await addUser(data);
  };

  useEffect(() => {
    filters["users"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    tableIdentifier,
    setFilters,
    setTableSettings,
  ]);

  return (
    <div className={"users-screen"}>
      <div className={"users-screen-content"}>
        <DataTable
          ariaLabel="Users"
          data={data}
          dataLoading={loading}
          defaultSort="firstName"
          defaultOrder="asc"
          enableFilter={true}
          fields={fields}
          getCSV={get_users_export}
          loadData={fetchData}
          icon={
            <AccountCircleOutlined
              sx={{
                height: "45px",
                width: "45px",
                marginRight: "0.5rem",
              }}
            />
          }
          title="Users"
          filters={currentFilters}
          setFilters={setCurrentFilters}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          id={tableIdentifier}
          createLabel={"New User"}
          onCreateClick={() => {
            setOpenCreate(true);
          }}
        />
      </div>
      <CreateModal
        title="New User"
        open={openCreate}
        setOpen={setOpenCreate}
        fetchData={fetchData}
        fields={createFields}
        create_function={saveNewUser}
        successMessage="User created"
        validation={validationSchema}
        initialValues={initialValues}
      />
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Users;
