import axios from "axios";
import config, { RequestConfig } from "./_config";
import Cookies from "js-cookie";
import { AUTH_TOKEN_KEY, USER_PROFILE_KEY } from "../constants/general";

const requestConfig: RequestConfig = {
  baseURL: config.baseURL,
  crossDomain: true,
  headers: {
    "Content-Type": "application/json",
  },
};

const instance = axios.create(requestConfig);

let currentAuthToken: string | undefined = "";

instance.interceptors.request.use(function (config) {
  if (currentAuthToken !== "") {
    config.headers.Authorization = currentAuthToken;
  } else if (Cookies.get(AUTH_TOKEN_KEY) !== undefined) {
    currentAuthToken = Cookies.get(AUTH_TOKEN_KEY);
    config.headers.Authorization = currentAuthToken;
  } else if (Cookies.get(USER_PROFILE_KEY) !== undefined) {
    try {
      const profile = JSON.parse(Cookies.get(USER_PROFILE_KEY) as string);
      if (profile.userToken !== undefined) {
        currentAuthToken = "Bearer " + profile.userToken;
        Cookies.set(AUTH_TOKEN_KEY, currentAuthToken);
        config.headers.Authorization = currentAuthToken;
      }
    } catch (e) {}
  }

  return config;
});

instance.interceptors.response.use(
  function (response) {
    if (response.data?.access_token !== undefined) {
      currentAuthToken = "Bearer " + response.data?.access_token;
      Cookies.set(AUTH_TOKEN_KEY, currentAuthToken);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
