// confirmationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    title: "Confirmation",
    body: "Please confirm your action",
    onConfirm: () => false
};

const confirmationDialogSlice = createSlice({
    name: "confirmationDialog",
    initialState: initialState,
    reducers: {
        setState: (state, action) => {
            Object.assign(state, action.payload);
        },
        closeDialog: (state) => {
            state.open = false;
        }
    }
});

export const { setState, closeDialog } = confirmationDialogSlice.actions;
export default confirmationDialogSlice;
