import React from "react";
import classes from "./index.module.scss";
import Button from "@mui/material/Button";
import { formatCurrency } from "../../../../../utilities/formatter";
import {REVIEWING_GROUP} from "../../../../../core/constants/licences";

const ModuleItemCard = ({
  isBeingRemoved,
  isActionable,
  businessName,
  status,
  description,
  autoRenewMessage,
  balance,
  onClick,
  canDiscard,
  onDiscard,
  onTakeAction,
  actionToken = "Pay Dues",
  totalDueText = "Total due: ",
}) => {
  return (
    <>
      <div
        className={`${classes.container} ${
          isBeingRemoved ? classes.is_being_removed : ""
        }`}
        onClick={onClick}
      >
        <div className={classes.left_section}>
          <p className={classes.title}>{businessName}</p>
          {!!description && (
            <p className={classes.description}>{description}</p>
          )}
          {balance !== "" && balance > 0 && status && !REVIEWING_GROUP.find(val => val === status?.toLowerCase()) ? (
            <div className={classes.payment_description}>
              <p>{totalDueText}</p>
              <p>{formatCurrency(balance)}</p>
            </div>
          ) : !!autoRenewMessage ? (
            <p className={classes.description}>{autoRenewMessage}</p>
          ) : (
            <div />
          )}
        </div>
        <div>
          {isActionable ? (
            <Button
              onClick={onTakeAction}
              variant={"contained"}
              size={"small"}
            >
              {actionToken}
            </Button>
          ) : canDiscard ? (
            <Button
              onClick={(e) => onDiscard(e)}
              variant={"outlined"}
              size={"small"}
            >
              Discard
            </Button>
          ) : (
            <div
              className={
                status === "Active" ? classes.active : classes.under_review
              }
            >
              <p>{status}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModuleItemCard;
