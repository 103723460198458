import React, { createRef, useEffect, useMemo, useState } from "react";
import * as schemas from "../BusinessLicenceStepYupSchemas";
import { useTheme } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import Typography from "../../../../../components/ui-kit/typography";
import TextField from "@mui/material/TextField";
import MaskedTextField from "../../../../../components/ui-kit/masked-text-field";
import Button from "@mui/material/Button";
import MuiButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { CheckboxCard } from "../../../../../components/ui-kit/Checkbox";
import uuid from "react-uuid";
import { EDIT } from "../../../../../../core/constants/licences";
import Spacer from "../../../../../components/ui-kit/Spacer";
import Chip from "../../../../../components/ui-kit/Chip";
import { AddRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../../redux/slices/confrmation-dialog";
import { truncate } from "../../../../../../utilities";
import { BUSINESS_NAME_LENGTH } from "../../../../../../core/constants/general";

//Constants
const ADD = "ADD";
const AUTO = "AUTO";

const initialValues3Owner = {
  owners: [],
  managers: []
};

/**
 * Step 3 of the business licence creation form
 * @param onSubmit
 * @param formId
 * @param submittedFormValues
 * @returns {JSX.Element}
 * @constructor
 */
const Step3 = ({ onSubmit, submittedFormValues, mode, onCancel }) => {
  const theme = useTheme();

  //Prevents errors from being displayed before a user has a chance to enter their information
  const [validateOnChange, setValidateOnChange] = useState(false);

  //Validation for step 3
  const validation3 = schemas.ownerManagerSchema;
  const [initValues, setInitValues] = useState(initialValues3Owner);
  //Holds the state of how many people are allowed to be displayed
  const [peopleLimit, setPeopleLimit] = useState(5);
  //Toggles to display all currently added owners and managers
  const [showAll, setShowAll] = useState(false);
  const [action, setAction] = useState(AUTO);

  //Handles form submission
  const handleSubmit = (values) => {
    values.tmpOwners = {
      owners: values.owners.map(e => ({ ...e, phone: e.phone.trim().replaceAll("-", "") })),
      managers: values.managers.map(e => ({ ...e, phone: e.phone.trim().replaceAll("-", "") }))
    };
    onSubmit(values);
  };

  //Sets validate on change to true once a user attempts to move on to the next step and there are errors
  const handleButtonClick = () => {
    setValidateOnChange(true);
  };

  useEffect(() => {
    if (
      submittedFormValues.managers.length > 0 ||
      submittedFormValues.owners.length > 0
    ) {
      setInitValues({
        managers: submittedFormValues.managers,
        owners: submittedFormValues.owners
      });
    } else {
      if (submittedFormValues.isResident) {
        setInitValues({
          owners: [{ id: 1, name: "", phone: "", isOwner: true }],
          managers: []
        });
      } else {
        setInitValues({
          owners: [],
          managers: [{ id: 1, name: "", phone: "", isOwner: false }]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValueObjectsEquality = (initValue, value) => {
    if (typeof initValue === "object" && typeof value === "object" && Object.keys(initValue).length === Object.keys(value).length) {
      for (const property in initValue) {
        if (initValue[property].length !== value[property].length)
          return false;
        const initValueArray = initValue[property];
        const valueArray = value[property];
        for (const item of initValueArray) {
          const found = valueArray.some(valueObjectItem => {
            for (const key in item) {
              if (typeof item[key] === "string") {
                if (item[key].replaceAll("-", "").trim() !== valueObjectItem[key].replaceAll("-", "").trim()) {
                  return false;
                }
              } else if (item[key] !== valueObjectItem[key])
                return false;
            }
            return true;
          });
          if (!found)
            return false;
        }
      }
      return true;
    }
    return false;
  };

  return (
    <div>
      <Formik
        initialValues={initValues}
        validationSchema={validation3}
        validateOnChange={validateOnChange}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleChange, values, errors, validateForm, setFieldValue }) => {
          return (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={"h4"} fontWeight={"300"}>
                    Business managers
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
                    variant={"body1"}
                  >
                    Please list all owners and managers involved in the
                    business.
                  </Typography>
                </Grid>
                {values.owners.length > 0 && (
                  <Grid item xs={12}>
                    <OwnerManagerFieldArray
                      action={action}
                      setAction={setAction}
                      name={"owners"}
                      id
                      errors={errors}
                      handleChange={handleChange}
                      values={values}
                      theme={theme}
                      peopleLimit={peopleLimit}
                      setPeopleLimit={setPeopleLimit}
                      setShowAll={setShowAll}
                      setFieldValue={setFieldValue}
                      setValidateOnChange={setValidateOnChange}
                      showAll={showAll}
                      validateForm={validateForm}
                    />
                  </Grid>
                )}
                {values.managers.length > 0 && (
                  <Grid item xs={12}>
                    <OwnerManagerFieldArray
                      action={action}
                      setAction={setAction}
                      name={"managers"}
                      errors={errors}
                      handleChange={handleChange}
                      values={values}
                      theme={theme}
                      peopleLimit={peopleLimit}
                      setPeopleLimit={setPeopleLimit}
                      setShowAll={setShowAll}
                      setFieldValue={setFieldValue}
                      setValidateOnChange={setValidateOnChange}
                      showAll={showAll}
                      validateForm={validateForm}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Chip
                    variant={"dotted"}
                    icon={<AddRounded sx={{ width: "20px", height: "20px" }} />}
                    label={"Add owner / manager"}
                    onClick={async () => {
                      const validationResults = await validateForm();
                      if (
                        typeof validationResults.owners !== "object" &&
                        typeof validationResults.managers !== "object"
                      ) {
                        setAction(ADD);
                        setPeopleLimit(5);
                        setFieldValue("managers", [
                          ...values.managers,
                          {
                            id: uuid(),
                            name: "",
                            phone: "",
                            isOwner: false
                          }
                        ]);
                      } else {
                        setValidateOnChange(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size={"large"}
                    variant="contained"
                    disabled={mode === EDIT ? checkValueObjectsEquality(initValues, values) : false}
                    type="submit"
                    onClick={async () => {
                      const validationResults = await validateForm();
                      let err, errIndex, errType;
                      if (JSON.stringify(validationResults) !== "{}") {
                        if (Object.keys(validationResults).includes("owners")) {
                          validationResults["owners"].map((value, index) => {
                            if (value !== undefined) {
                              errIndex = index;
                              if (Object.keys(value).includes("name")) {
                                errType = "name";
                              } else {
                                errType = "phone";
                              }
                            }
                            return null;
                          });
                          err = "owners_" + errIndex + "_" + errType;
                        } else if (
                          Object.keys(validationResults).includes("managers") &&
                          Array.isArray(validationResults["managers"])
                        ) {
                          validationResults["managers"].map((value, index) => {
                            if (value !== undefined) {
                              errIndex = index;
                              if (Object.keys(value).includes("name")) {
                                errType = "name";
                              } else {
                                errType = "phone";
                              }
                            }
                            return null;
                          });
                          err = "managers_" + errIndex + "_" + errType;
                        }
                      }
                      if (err !== undefined) {
                        const input = document.querySelector(`#${err}`);

                        input.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "start"
                        });
                      }
                      handleButtonClick();
                    }}
                  >
                    {mode === EDIT ? "Save" : "Continue"}
                  </Button>
                  {mode === EDIT ? (
                    <>
                      <Spacer />
                      <Button
                        fullWidth
                        size={"large"}
                        variant="outlined"
                        type="cancel"
                        onClick={() => {
                          onCancel("General");
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

//This is the field array for people at the company
const OwnerManagerFieldArray = ({
                                  values,
                                  showAll,
                                  peopleLimit,
                                  name,
                                  handleChange,
                                  errors,
                                  theme,
                                  setShowAll,
                                  setFieldValue,
                                  setAction,
                                  action
                                }) => {

    const dispatch = useDispatch();
  /**
   * Memo version of remove button label based on the name of the field
   * @type {string}
   */
  const removeButtonLabel = useMemo(() => {
    return name === "owners" ? "Remove owner" : "Remove manager";
  }, [name]);

  return (
    <FieldArray
      name={name}
      render={({ remove }) => {
        //Copy of the list of owners and managers
        let peopleToShow = values[name];

        //Checks if there is a limit on how many owners / managers to show
        const hasLimit = () => {
          return !showAll;
        };

        if (hasLimit()) {
          peopleToShow = peopleToShow.slice(0, peopleLimit);
        }

        const getTitle = () => {
          return name === "owners" ? "Owner" : "Manager";
        };

        //Switches an owner to manager and vice versa
        const movePerson = (id) => {
          if (name === "owners") {
            let removedOwner = values.owners.find((x) => x.id === id);
            let newOwners = values.owners.filter((x) => x.id !== id);
            removedOwner.isOwner = false;
            setFieldValue("owners", [...newOwners]);
            setFieldValue("managers", [...values.managers, removedOwner]);
          } else {
            let removedManager = values.managers.find((x) => x.id === id);
            let newManagers = values.managers.filter((x) => x.id !== id);
            removedManager.isOwner = true;
            setFieldValue("managers", [...newManagers]);
            setFieldValue("owners", [...values.owners, removedManager]);
          }
        };

          const _removeItem = (index) => {
              remove(index);
              dispatch(closeDialog());
          };

        return (
          <div>
            {peopleToShow.map((people, index) => {
              return (
                <Grid key={people.id} item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <Typography variant={"overline"}>
                      {getTitle() + " " + (index + 1)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OwnerManagerFields
                      isLast={
                        peopleToShow.length === index + 1 && action === ADD
                      }
                      setAction={setAction}
                      handleChange={handleChange}
                      errors={errors}
                      index={index}
                      name={name}
                      people={people}
                      setFieldValue={setFieldValue}
                      movePerson={movePerson}
                    />
                  </Grid>
                  {
                    //If there is more than one person we can allow a user to start deleting
                    values.owners.length + values.managers.length >= 2 ? (
                      <Grid item xs={12}>
                        <MuiButton
                          sx={{
                            color: theme.palette.nonPalette.RED,
                            ".MuiTouchRipple-child": {
                              color: `${theme.palette.nonPalette.RED} !important`
                            },
                            "&:hover": {
                              backgroundColor:
                              theme.palette.nonPalette.RED_BACKGROUND,
                              ".MuiTouchRipple-child": {
                                color: theme.palette.nonPalette.RED
                              }
                            },
                            "&:active": {
                              backgroundColor:
                              theme.palette.nonPalette.RED_BACKGROUND,
                              ".MuiTouchRipple-child": {
                                color: theme.palette.nonPalette.RED
                              }
                            }
                          }}
                          onClick={() => {
                              dispatch(setConfirmationDialogState({
                                  open: true,
                                  title: `Remove ${truncate(name === "owners" ? "owner" : "manager", BUSINESS_NAME_LENGTH)}?`,
                                  body: `This ${name === "owner" ? "owner" : "manager"} will be removed along with all their information.`,
                                  onConfirm: () => _removeItem(index)
                              }));
                          }}
                          size={"small"}
                          variant={"text"}
                        >
                          {removeButtonLabel}
                        </MuiButton>
                      </Grid>
                    ) : null
                  }
                </Grid>
              );
            })}
            {
              //Displays a show more button to the user if the number of
              //owners / managers exceeds the display limit
              values.owners.length > peopleLimit && (
                <div>
                  <ShowMoreButtons showAll={showAll} setShowAll={setShowAll} />
                </div>
              )
            }
          </div>
        );
      }}
    />
  );
};

//List of fields for owners and managers
const OwnerManagerFields = ({
                              index,
                              name,
                              errors,
                              people,
                              handleChange,
                              movePerson,
                              isLast,
                              setAction,
                              setFieldValue
                            }) => {
  const nameRef = createRef();
  useEffect(() => {
    if (isLast) {
      nameRef.current.focus();
      setAction(AUTO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameRef.current]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          inputRef={nameRef}
          name={`${name}.${index}.name`}
          id={`${name}_${index}_name`}
          error={Boolean(
            errors[name] && errors[name][index]
              ? errors[name][index].name
              : null
          )}
          label={"Full name"}
          value={people.name}
          onChange={handleChange}
          fullWidth
          helperText={
            errors[name] && errors[name][index]
              ? errors[name][index].name
              : null
          }
        />
      </Grid>
      <Grid item xs={12}>
        <MaskedTextField
          type={"PHONE"}
          name={`${name}.${index}.phone`}
          id={`${name}_${index}_phone`}
          label={"Phone number"}
          error={Boolean(
            errors[name] && errors[name][index]
              ? errors[name][index].phone
              : null
          )}
          value={people.phone}
          initialValue={people.phone}
          setFieldValue={(value) =>
            setFieldValue(`${name}.${index}.phone`, value)
          }
          onChange={handleChange}
          fullWidth
          helperText={
            errors[name] && errors[name][index]
              ? errors[name][index].phone
              : null
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxCard
          name={`${name}.${index}.isOwner`}
          label={"This person is an owner"}
          value={name === "owners"}
          onChange={(e) => {
            handleChange(e);
            movePerson(people.id);
          }}
        />
      </Grid>
    </Grid>
  );
};

const ShowMoreButtons = ({ showAll, setShowAll }) => {
  return (
    <>
      {showAll ? (
        <Button
          size={"small"}
          textType={"body1"}
          variant={"text"}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={(e) => {
            e.preventDefault();
            setShowAll(false);
          }}
        >
          Show less
        </Button>
      ) : (
        <Button
          textType={"body1"}
          size={"small"}
          variant={"text"}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={() => {
            setShowAll(true);
          }}
        >
          Show more
        </Button>
      )}
    </>
  );
};

export default Step3;
