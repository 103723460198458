import React, { CSSProperties, MouseEventHandler } from "react";
import classes from "../../index.module.scss";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import { Button } from "@mui/material";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import { Skeleton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import uuid from "react-uuid";

interface IDetailsContainer {
  children: any;
  title: string;
  buttonLabel?: string;
  emptyText?: string;
  loading?: boolean;
  buttonOnClick?: MouseEventHandler<HTMLButtonElement>;
  isEditable?: boolean;
  styles?: CSSProperties;
  buttonDisable: boolean;
}

const DetailsContainer = ({
  children,
  title = "",
  buttonLabel = "",
  emptyText = "",
  loading = false,
  buttonOnClick,
  isEditable = true,
  styles,
  buttonDisable = false,
}: IDetailsContainer) => {
  return (
    <div className={classes.details_container} style={styles}>
      <div className={classes.flex_row}>
        <p className={typographyClasses.h2}>{title}</p>
        {!loading && isEditable ? (
          <Button
            size={"small"}
            startIcon={<Edit color={buttonDisable ? "inherit" : "primary"} />}
            variant={"outlined"}
            onClick={buttonOnClick}
            disabled={buttonDisable}
          >
            {buttonLabel}
          </Button>
        ) : (
          isEditable && <Skeleton width={120} height={50} />
        )}
      </div>
      {!loading ? (
        children ? (
          children
        ) : (
          <>
            <p className={classes.empty_text}>{emptyText}</p>
            <Spacer />
          </>
        )
      ) : (
        <>
          {[...Array(3)].map((item) => (
            <Skeleton key={uuid()} height={90} />
          ))}
        </>
      )}
    </div>
  );
};

export default DetailsContainer;
