import React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { InfoOutlined } from "@mui/icons-material";

const Container = styled.div`
  cursor: pointer;
  display: inline-block;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  && {
    padding-left: 5px;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.primary[200]};
    :hover {
      text-decoration: underline;
    }
  }
`;

/**
 * A button that is typically used for showing a user more information
 * @param onClick
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const InfoButton = ({ onClick = () => {}, title = "not set" }) => {
  return (
    <Container onClick={onClick}>
      <InnerContainer>
        <InfoOutlined color={"primary"} />
        <StyledTypography>{title}</StyledTypography>
      </InnerContainer>
    </Container>
  );
};

export default InfoButton;
