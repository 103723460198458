import React from "react";
import classes from "../../../screens/my-city-hall/utilities/utility-details/tabs/service-tab/index.module.scss";
import typographyClasses from "../../../../assets/styles/typography/typography.module.scss";
import Link from "@mui/material/Link";
import config from "../../../../core/apis/_config";
import Modal from "../../ui-kit/Modal";
import { useTheme } from "@mui/material";

const WaterMetreInfoModal = ({ open, setOpen }) => {
  const theme = useTheme();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={"Where can I find my water metre info?"}
      >
        <div className={classes.modal_body}>
          <div className={classes.modal_flex_group}>
            <p
              className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`}
              style={{ maxWidth: 370 }}
            >
              <span>How can you find your water metre?</span>
              <br />
              Your water meter is usually situated in a utility area like a
              basement, meter box, mechanical room, or located within a small
              underground pit or box near the sidewalk of your house.
              <br />
              <br />
              <span>How can you identify the RF serial number?</span>
              <br />
              Once you've located the water meter, inspect it closely for labels
              or markings that display a serial number, which is typically a 9
              or 10 digit number located on the front of the device.
              <br />
              <br />
              <span>How to measure your water metre size?</span>
              <br />
              Use a measuring tape or caliper to determine the diameter of the
              outlet pipe. If unsure, choose "I don't know," and we can assist
              you in checking it.
              <br />
              <br />
              If you are having difficulty finding the RF serial number or if
              it’s not visible on the module,{" "}
              <Link
                style={{ color: theme.palette.primary[200] }}
                rel={"noreferrer"}
                target={"_blank"}
                href={"https://lacombe.ca/Directory.aspx?DID=42"}
              >
                contact us
              </Link>
            </p>
            <img
              className={classes.modal_img}
              src={`https://images.cloudcityhall.com/${config.cityId}/interface/water_metre2.png`}
              alt={"water metre"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WaterMetreInfoModal;
