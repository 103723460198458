import base from "./axiosInstance";
import { manageSession } from "../../utilities/manageSession";
import { jwtDecode } from "jwt-decode";

export const get_invoices = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwtDecode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/invoice/${decoded.cityId}/summaries`, {
      params
    });
    return response.data;
  } catch (error) {
  }
};

export const updateInvoiceStatus = async (invoiceId: string, status: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/invoice/${invoiceId}/status`, {
      status: status
    });
    return response.data;
  } catch (error) {
  }
};

export const getInvoiceOptions = async () => {
  try {
    const response = await base.get("/invoice/options");
    return response.data;
  } catch (error) {

  }
};

export const updateInvoiceInPlace = async (invoiceId: string, updates: object) => {
  try {
    const dataScheme = {
      updates: updates
    };
    return await base.put(`/invoice/${invoiceId}`, dataScheme);
  } catch (e) {
    return e;
  }
};

export const getInvoiceHistory = async (invoiceId: string) => {
  try {
    const response = await base.get(`/invoice/${invoiceId}/history`);
    return response.data;
  } catch (error) {
    return false;
  }
};
export const sendInvoiceEmail = async (
  invoiceId: string,
  email: string[],
  isUtility = false
) => {
  const dataScheme = {
    email: email,
    isUtility: isUtility,
  };
  return await base.post(`/invoice/${invoiceId}/sendEmail`, dataScheme);
};

export const markInvoicePaid = async (
  invoiceId: string
) => {
  try {
    return await base.put(`/invoice/${invoiceId}/pay`);
  } catch (e) {
    return e;
  }
};