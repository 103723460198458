import React, { useState } from "react";
import classes from "./index.module.scss";
import IPropertyTaxDropdown from "../../../../core/interfaces/property-tax/IPropertyTaxDropdown";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const PropertyTaxDropdown = ({
  label = "",
  subLabel = "",
  initialOpen = false,
  BodyComponent,
}: IPropertyTaxDropdown) => {
  const [open, setOpen] = useState(initialOpen);
  return (
    <div
      className={`${classes.container} ${!open && classes.closed_background}`}
    >
      <div className={"flex_row_space_between"}>
        <div>
          <h2>{label}</h2>
          <p className={"sub_label"}>{subLabel}</p>
        </div>
        <IconButton onClick={() => setOpen((prevState) => !prevState)}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      {open && <BodyComponent />}
    </div>
  );
};

export default PropertyTaxDropdown;
