import React, { useEffect, useMemo, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import Typography from "@mui/material/Typography";
import theme, {
  BLACK_MEDIUM_EMPHASIS,
  PRIMARY_200,
  PRIMARY_500,
  WHITE,
} from "../../../../mui-theme/theme";

import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { getUser } from "../../../../core/apis/user";
import AccountBar from "../account-bar";
import LinkButton from "../link-button";
import Spacer from "../Spacer";

import {
  AccountBalanceRounded,
  CheckRounded,
  ClearRounded,
  Link,
  TrendingFlatRounded,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { convertUTCToLocalTimeZone } from "../../../../utilities";

export default function HistoryItem(props) {
  const {
    firstName,
    lastName,
    createDateTime,
    content,
    link1,
    link2,
    item,
    data,
    originalAccountName,
    onDelete,
    onSave,
    navigateToDuplicate,
  } = props;
  const [citizen, setCitizen] = useState("");

  const versionedSection = (value, section) => {
    const parts = value.split("::");
    return parts[section];
  };

  async function fetchData(item) {
    try {
      let tmpCitizen = "";
      if ("Linked" in item?.data?.historyType) {
        tmpCitizen = await getUser(item?.data?.historyType["Linked"]);
      } else if ("Unlinked" in item?.data?.historyType) {
        tmpCitizen = await getUser(item?.data?.historyType["Unlinked"]);
      } else if ("Review" in item?.data?.historyType) {
        if (
          ["assigned", "unassigned"].indexOf(
            item.data.historyType["Review"],
          ) !== -1
        ) {
          tmpCitizen = await getUser(item?.data?.historyType["Reviewer"]);
        }
      }

      setCitizen(tmpCitizen);
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    }
  }

  useEffect(() => {
    if (!item.commentId) {
      fetchData(item);
    }
  }, [item]);

  /**
   * Memo version of UT account number when the component is used in UT module the passed data is UT information
   * @type {string}
   */
  const utAccountNumber = useMemo(() => {
    if (!!data?.info) {
      return data?.info?.find((e) => e.name === "account_number")?.value;
    }
    return Array.isArray(data)
      ? data?.find((e) => e.name === "account_number")?.value
      : "";
  }, [data]);

  return item.commentId ? (
    <CommentHistoryField
      content={content}
      data={data}
      link2={link2}
      link1={link1}
      lastName={lastName}
      createDateTime={createDateTime}
      firstName={firstName}
      commentId={item.commentId}
      onDelete={onDelete}
      onSave={onSave}
      item={item}
      originalAccountName={originalAccountName}
    />
  ) : "Review" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_500,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ display: "flex", marginLeft: "1.5rem" }}>
        <Typography
          variant={"body1"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            width: 700,
          }}
        >
          <b>{`${
            item.data.firstName === null
              ? item.data.email
              : item.data.firstName + " " + item.data.lastName
          }`}</b>{" "}
          {item?.data?.historyType?.Review}{" "}
          {citizen?.firstName && citizen?.lastName ? (
            <b>{citizen?.firstName + " " + citizen?.lastName}</b>
          ) : (
            " "
          )}{" "}
          {["unassigned", "assigned"].indexOf(
            item?.data?.historyType?.Review,
          ) !== -1 &&
            (item?.data?.historyType?.Review === "unassigned" ? "from" : "to")}
          <b>
            {" "}
            <u>
              {originalAccountName ?? data?.business_name ?? utAccountNumber}
            </u>
          </b>{" "}
        </Typography>
        <Typography
          variant={"caption"}
          fontWeight={300}
          style={{ marginTop: "2px", marginLeft: "0.5rem" }}
        >
          {createDateTime &&
            moment(convertUTCToLocalTimeZone(createDateTime)).format(
              "MMMM D, YYYY - HH:mm",
            )}
        </Typography>
      </div>
    </div>
  ) : "StatusChange" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor:
            item?.data?.historyType?.["StatusChange"][0] === "draft" ||
            item?.data?.historyType?.["StatusChange"][0] === "saved"
              ? PRIMARY_200
              : PRIMARY_500,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ marginLeft: "1.5rem" }}>
        <div style={{ display: "flex" }}>
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            changed status for{" "}
            <b>
              <u>
                {originalAccountName ?? data?.business_name ?? utAccountNumber}
              </u>
            </b>{" "}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"body2"}
            style={{ marginRight: 5 }}
            color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
          >
            {item?.data?.historyType?.["StatusChange"][0]}
          </Typography>
          <TrendingFlatRounded sx={{ width: "18px", height: "18px" }} />
          <Typography
            variant={"body2"}
            color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
            style={{ marginLeft: 5 }}
          >
            {item?.data?.historyType?.["StatusChange"][1]}
          </Typography>
        </div>
      </div>
    </div>
  ) : "CitizenChanges" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_200,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ marginLeft: "1.5rem" }}>
        <div style={{ display: "flex" }}>
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            submitted edits to{" "}
            <b>
              <u>
                {originalAccountName ?? data?.business_name ?? utAccountNumber}
              </u>
            </b>{" "}
            {`${
              item.data?.message?.includes("(")
                ? item.data?.message?.includes("services")
                  ? "services"
                  : "info"
                : ""
            }`}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    </div>
  ) : "DeclineChanges" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_500,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ marginLeft: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            declined{" "}
            <b>
              {" "}
              <u>
                {originalAccountName ?? data?.business_name ?? utAccountNumber}
              </u>
            </b>
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    </div>
  ) : "Renew" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_500,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ marginLeft: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            created a new{" "}
            <b>
              <u>{item?.data?.historyType?.["Renew"]}</u>
            </b>{" "}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    </div>
  ) : "Marked" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_500,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ marginLeft: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            marked{" "}
            <b>
              <u>{item?.data?.historyType?.["Marked"]}</u>{" "}
            </b>
            as void{" "}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    </div>
  ) : "Email" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_500,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ marginLeft: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            {item.data.message}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    </div>
  ) : "Payment" in item.data.historyType ? (
    item?.data?.message.includes("user") ? (
      <div style={{ display: "flex", padding: "0 1rem" }}>
        <Avatar
          style={{
            backgroundColor: PRIMARY_200,
            padding: 1,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {item.data.firstName === null ? (
            <AccountBalanceRounded sx={{ color: WHITE }} />
          ) : (
            (item.data.firstName?.[0]?.toUpperCase() || "-") +
            (item.data.lastName?.[0]?.toUpperCase() || "-")
          )}
        </Avatar>
        <div style={{ display: "flex", marginLeft: "1.5rem" }}>
          <Typography variant={"body1"} style={{ width: 700 }}>
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            {`made a payment of ${"$" + item.data.historyType.Payment}`}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    ) : (
      <div style={{ display: "flex", padding: "0 1rem" }}>
        <Avatar
          style={{
            backgroundColor: PRIMARY_500,
            padding: 1,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {item.data.firstName === null ? (
            <AccountBalanceRounded sx={{ color: WHITE }} />
          ) : (
            (item.data.firstName?.[0]?.toUpperCase() || "-") +
            (item.data.lastName?.[0]?.toUpperCase() || "-")
          )}
        </Avatar>
        <div style={{ display: "flex", marginLeft: "1.5rem" }}>
          <Typography
            variant={"body1"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              width: 700,
            }}
          >
            <b>{`${
              item.data.firstName === null
                ? item.data.email
                : item.data.firstName + " " + item.data.lastName
            }`}</b>{" "}
            marked ${item.data.historyType.Payment} as paid for{" "}
            <b>
              <u>
                {originalAccountName ?? data?.business_name ?? utAccountNumber}
              </u>
            </b>{" "}
          </Typography>
          <Typography
            variant={"caption"}
            fontWeight={300}
            style={{ marginTop: "2px", marginLeft: "0.5rem" }}
          >
            {createDateTime &&
              moment(convertUTCToLocalTimeZone(createDateTime)).format(
                "MMMM D, YYYY - HH:mm",
              )}
          </Typography>
        </div>
      </div>
    )
  ) : "Unlinked" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: citizen?.email !== null ? PRIMARY_500 : PRIMARY_200,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ display: "flex", marginLeft: "1.5rem" }}>
        <Typography
          variant={"body1"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            width: 700,
          }}
        >
          <b>
            {item.data.firstName === null
              ? item.data.email + " "
              : item.data.firstName + " " + item.data.lastName + " "}
          </b>
          unlinked{" "}
          {citizen?.email !== null ? <b>{citizen?.email}</b> : "their account "}{" "}
          from
          <b>
            {" "}
            <u>
              {originalAccountName ?? data?.business_name ?? utAccountNumber}
            </u>
          </b>
        </Typography>
        <Typography
          variant={"caption"}
          fontWeight={300}
          style={{ marginTop: "2px", marginLeft: "0.5rem" }}
        >
          {createDateTime &&
            moment(convertUTCToLocalTimeZone(createDateTime)).format(
              "MMMM D, YYYY - HH:mm",
            )}
        </Typography>
      </div>
    </div>
  ) : "Linked" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: citizen?.email !== null ? PRIMARY_500 : PRIMARY_200,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ display: "flex", marginLeft: "1.5rem" }}>
        <Typography
          variant={"body1"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            width: 700,
          }}
        >
          <b>
            {item.data.firstName === null
              ? item.data.email + " "
              : item.data.firstName + " " + item.data.lastName + " "}
          </b>
          linked{" "}
          {citizen?.email !== null && citizen?.email !== item.data.email ? (
            <b>{citizen?.email}</b>
          ) : (
            "their account "
          )}{" "}
          to
          <b>
            {" "}
            <u>
              {originalAccountName ?? data?.business_name ?? utAccountNumber}
            </u>
          </b>
        </Typography>
        <Typography
          variant={"caption"}
          fontWeight={300}
          style={{ marginTop: "2px", marginLeft: "0.5rem" }}
        >
          {createDateTime &&
            moment(convertUTCToLocalTimeZone(createDateTime)).format(
              "MMMM D, YYYY - HH:mm",
            )}
        </Typography>
      </div>
    </div>
  ) : "Versioned" in item.data.historyType ? (
    <div style={{ display: "flex", padding: "0 1rem" }}>
      <Avatar
        style={{
          backgroundColor: PRIMARY_200,
          padding: 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {item.data.firstName === null ? (
          <AccountBalanceRounded sx={{ color: WHITE }} />
        ) : (
          (item.data.firstName?.[0]?.toUpperCase() || "-") +
          (item.data.lastName?.[0]?.toUpperCase() || "-")
        )}
      </Avatar>
      <div style={{ display: "flex", marginLeft: "1.5rem" }}>
        <Typography
          variant={"body1"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            width: 700,
          }}
        >
          <b>{`${
            item.data.firstName === null
              ? item.data.email
              : item.data.firstName + " " + item.data.lastName
          }`}</b>{" "}
          {versionedSection(item.data.historyType.Versioned, 0) === "from" ? (
            <>
              duplicated a{" "}
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigateToDuplicate(
                    versionedSection(item.data.historyType.Versioned, 1),
                    true,
                  );
                }}
              >
                previous version
              </span>{" "}
              to initiate this version
            </>
          ) : (
            <>
              used this version to create an{" "}
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigateToDuplicate(
                    versionedSection(item.data.historyType.Versioned, 1),
                    true,
                  );
                }}
              >
                updated version
              </span>
            </>
          )}
        </Typography>
        <Typography
          variant={"caption"}
          fontWeight={300}
          style={{ marginTop: "2px", marginLeft: "0.5rem" }}
        >
          {createDateTime &&
            moment(convertUTCToLocalTimeZone(createDateTime)).format(
              "MMMM D, YYYY - HH:mm",
            )}
        </Typography>
      </div>
    </div>
  ) : null;
}

const commentValidation = object({
  edit: string().required("Comment can't be left empty."),
});

/**
 * Displays a comment history item
 * @param link1
 * @param link2
 * @param firstName
 * @param lastName
 * @param content
 * @param data
 * @param createDateTime
 * @param commentId
 * @param onDelete
 * @param onSave
 * @param item
 * @param originalAccountName
 * @returns {JSX.Element}
 * @constructor
 */
const CommentHistoryField = ({
  link1,
  link2,
  firstName,
  lastName,
  content,
  data,
  createDateTime,
  commentId,
  onDelete,
  onSave,
  item,
  originalAccountName,
}) => {
  const { id: accountId } = useParams();
  const theme = useTheme();
  const [editingFieldOpen, setEditingFieldOpen] = useState(false);
  /** @type {React.MutableRefObject<HTMLDivElement>}*/
  const editCommentContainerRef = useRef(null);

  const handleEditClick = () => {
    setEditingFieldOpen(true);

    setTimeout(() => {
      if (editCommentContainerRef.current) {
        editCommentContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleDeleteClick = () => {
    onDelete(data?.id ?? accountId, commentId);
  };

  const handleSubmit = (values) => {
    onSave(data?.id ?? accountId, commentId, values.edit);
    setEditingFieldOpen(false);
  };

  const onCancel = () => {
    setEditingFieldOpen(false);
  };

  /**
   * Memo version of UT account number when the component is used in UT module the passed data is UT information
   * @type {string}
   */
  const utAccountNumber = useMemo(() => {
    if (!!data?.info) {
      return data?.info?.find((e) => e.name === "account_number")?.value;
    }
    return Array.isArray(data)
      ? data?.find((e) => e.name === "account_number")?.value
      : "";
  }, [data]);

  return (
    <>
      <div style={{ padding: "0 1rem" }}>
        <div>
          <div style={{ display: "flex" }}>
            <Avatar
              style={{
                backgroundColor: PRIMARY_500,
                padding: 1,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {firstName === "" ? (
                <AccountBalanceRounded sx={{ color: WHITE }} />
              ) : (
                (firstName?.[0]?.toUpperCase() || "-") +
                (lastName?.[0]?.toUpperCase() || "-")
              )}
            </Avatar>
            <div style={{ marginLeft: "1.5rem" }}>
              <div style={{ display: "flex" }}>
                {" "}
                <Typography
                  variant={"body1"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                    whiteSpace: "nowrap",
                    width: 700,
                  }}
                >
                  <b>{`${
                    item.firstName === ""
                      ? item.email
                      : firstName + " " + lastName
                  }`}</b>{" "}
                  left a comment on
                  <b>
                    {" "}
                    <u>
                      {originalAccountName ??
                        data?.business_name ??
                        utAccountNumber}
                    </u>
                  </b>
                </Typography>
                <Typography
                  variant={"caption"}
                  fontWeight={300}
                  style={{ marginTop: "2px", marginLeft: "0.5rem" }}
                >
                  {createDateTime &&
                    moment(convertUTCToLocalTimeZone(createDateTime)).format(
                      "MMMM D, YYYY - HH:mm",
                    )}
                </Typography>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "-1rem",
              marginLeft: 65,
            }}
          >
            {editingFieldOpen ? (
              <div
                ref={editCommentContainerRef}
                style={{
                  border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                  borderRadius: 10,
                  padding: "1rem",
                }}
              >
                <Formik
                  validationSchema={commentValidation}
                  initialValues={{ edit: content || "" }}
                  onSubmit={handleSubmit}
                >
                  {({ values, handleChange, errors }) => {
                    return (
                      <Form>
                        {" "}
                        <div style={{ height: 50 }}>
                          <textarea
                            name={"edit"}
                            style={{
                              all: "unset",
                              width: "100%",
                              wordBreak: "break-word",
                              height: "100%",
                            }}
                            value={values.edit}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {errors.edit ? (
                          <>
                            <Typography color={theme.palette.nonPalette.RED}>
                              {errors.edit}
                            </Typography>
                            <Spacer amount={0.5} />
                          </>
                        ) : null}
                        <div style={{ display: "flex" }}>
                          <Button
                            size={"small"}
                            variant={"contained"}
                            startIcon={<CheckRounded />}
                            type={"submit"}
                          >
                            Save
                          </Button>
                          <Button
                            variant={"outlined"}
                            style={{ marginLeft: 5 }}
                            size={"small"}
                            startIcon={<ClearRounded />}
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            ) : (
              <>
                <Typography
                  variant={"body2"}
                  style={{
                    wordWrap: "break-word",
                    width: 800,
                  }}
                >
                  {content}
                </Typography>
                <Typography
                  variant={"body2"}
                  style={{ marginTop: 5 }}
                  color={BLACK_MEDIUM_EMPHASIS}
                >
                  <LinkButton onClick={handleEditClick}>Edit</LinkButton> -{" "}
                  <LinkButton onClick={handleDeleteClick}>Delete</LinkButton>
                </Typography>
              </>
            )}
          </div>
        </div>
        {link1 && link2 && (
          <div
            style={{
              margin: "1rem 0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AccountBar
              disableBarClick
              hideLinkButton
              name={link1.name}
              variant={link1.variant}
            />
            <Link sx={{ margin: "0 1rem" }} />
            <AccountBar
              disableBarClick
              hideLinkButton
              name={link2.name}
              variant={link2.variant}
            />
          </div>
        )}
      </div>
    </>
  );
};
