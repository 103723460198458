import moment from "moment";
import { manageSession } from "../../utilities/manageSession";
import base from "./axiosInstance";
import { BYLAW } from "../constants/autocompleteTypes";

const fetch_bylaw_list_call = async () => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/formField/?keyword=bylaw`);
    const bylaws = Object.values(response.data.value).map((value) => {
      return { label: value, type: BYLAW };
    });
    localStorage.setItem(
      "bylaws",
      JSON.stringify({ value: bylaws, date: moment.utc().format() })
    );
    return bylaws;
  } catch (error) {
    throw error;
  }
};

export const get_bylaw_list = async () => {
  try {
    let localBylaw = localStorage.getItem("bylaws");
    if (localBylaw) {
      let bylaws = JSON.parse(localStorage.getItem("bylaws") ?? "");
      const refreshDate = moment(bylaws.date).add(1, "day").utc().format();
      const currentDate = moment().utc().format();
      const shouldRefreshData = moment(currentDate).isSameOrAfter(refreshDate);
      if (shouldRefreshData) {
        return await fetch_bylaw_list_call();
      } else {
        return bylaws.value;
      }
    } else {
      return await fetch_bylaw_list_call();
    }
  } catch (error) {
    throw error;
  }
};
