import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { OUTLINE } from "../../../../mui-theme/theme";
import { FilterType } from "../../../../core/constants/enums";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { FilterContext } from "../../../contexts/FilterContext";
import DataTable from "../../../components/ui-kit/data-table";
import classes from "./index.module.scss";
import moment from "moment/moment";
import { currency } from "../../../../utilities";
import { DATE_SHORT } from "../../../../core/constants/general";
import { useLocation } from "react-router-dom";
import { TableSettingContext } from "../../../contexts/TableSettingContext";
import { getAccountSummaries } from "../../../../core/apis/account";
import { WaterDamageOutlined } from "@mui/icons-material";

const StyledContainer = styled.div`
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
`;

const StyledSecondarySidebar = styled.div`
  padding-right: 1.5rem;
  margin-right: 0;
  border-left: 1px solid ${OUTLINE};
  width: 275px;
`;

const fields = [
  {
    id: "account_number",
    label: "Account #",
    filterType: FilterType.SEARCH,
    disableSort: false,
    format: (val) => val,
  },
  {
    id: "name_service_address",
    label: "Name & Service Address",
    type: "search",
    disableSort: false,
    format: (val) => (
      <div className={classes.address_column_container}>
        <p>{val.name}</p>
        <p>{val.address}</p>
      </div>
    ),
  },
  {
    id: "billing_date",
    label: "Billing Date",
    disableSort: false,
    format: (val) => moment(val).format(DATE_SHORT),
  },
  {
    id: "status",
    label: "Status",
    disableSort: false,
    type: "tag",
    format: (val) => (
      <div className={classes.status_container}>
        <p>{val}</p>
      </div>
    ),
  },
  {
    id: "balance",
    label: "Account Balance",
    disableSort: true,
    align: "right",
    format: currency,
  },
];

const UtilitiesScreen = (...props) => {
  const location = useLocation();
  const [tableIdentifier] = useState("utilityList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  // const [previousQueryOptions, setPreviousQueryOptions] = useState(null);
  const { filters, setFilters } = useContext(FilterContext);
  const [currentFilters, setCurrentFilters] = useState(
    filters["utilities"] || {}
  );
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[tableIdentifier] || props.initialFilters || {}
  );

  async function fetchData(queryOptions) {
    // TODO: update the functionality and remove the dummy values
    // if (queryOptions) setPreviousQueryOptions(queryOptions);
    try {
      setLoading(true);
      queryOptions.accountType = "utility";
      let data = await getAccountSummaries(queryOptions);
      data.result = data.result.map((summary) => {
        return {
          ...summary,
          account_number: summary.accountNumber,
          name_service_address: {
            name: summary.name,
            address: summary.address
          },
          status: "Active",
          billing_date: summary.lastUpdated,
        };
      });
      setData(data);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    filters["utilities"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    tableIdentifier,
    setFilters,
    setTableSettings,
  ]);

  return (
    <div className={classes.container}>
      <StyledContainer>
        <DataTable
          title="Utilities"
          ariaLabel="utilities account list"
          icon={
            <WaterDamageOutlined
              sx={{ height: "45px", width: "45px", marginRight: "0.5rem" }}
            />
          }
          dataLoading={loading}
          defaultOrder="desc"
          defaultSort="billing_date"
          fields={fields}
          onRowClick={(account, history) => {
            history.push(`${location.pathname}/${account.id}`);
          }}
          enableFilter
          loadData={fetchData}
          data={data}
          filters={currentFilters}
          setFilters={setCurrentFilters}
          searchBarPlaceHolder={"Account number, holder, service address..."}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          id={tableIdentifier}
          hasSearchResetButton={!!filters?.utilities?.search}
        />
      </StyledContainer>
      <StyledSecondarySidebar></StyledSecondarySidebar>
    </div>
  );
};

export default UtilitiesScreen;
