import React from "react";
import ListItem from "../../../../../../components/ui-kit/mch/ListItem";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { WarningRounded } from "@mui/icons-material";

const WarningAlert = ({ onClick = () => {} }) => {
  const theme = useTheme();
  return (
    <ListItem
      style={{ backgroundColor: theme.palette.nonPalette.ORANGE_BACKGROUND }}
      startOrnament={
        <WarningRounded
          color={"warning"}
          sx={{ height: "30px", width: "30px" }}
        />
      }
      endOrnament={
        <Button onClick={onClick} size={"small"} variant={"outlined"}>
          Review Services
        </Button>
      }
      label={"Action required"}
      alignIcon={true}
      subLabel={
        "To ensure accurate billing, review your services information and make any necessary updates."
      }
      subLabelColor={theme.palette.primary[700]}
      labelFontWeight={600}
    />
  );
};

export default WarningAlert;
