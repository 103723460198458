import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import WizardStepTracker from "../../../../components/ui-kit/wizard-step-tracker";
import Spacer from "../../../../components/ui-kit/Spacer";
import classes from "../../utilities/link-utility/index.module.scss";
import {
  closeDialog,
  setState as setConfirmationDialogState,
} from "../../../../redux/slices/confrmation-dialog";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useDispatch } from "react-redux";
import AlreadyLinkedProperty from "./already-linked";
import Step1 from "./step1";
import Step2 from "./step2";
import {
  findPropertyAccount,
  linkAccount,
} from "../../../../../core/apis/account";
import { routes } from "../../../../routes/routesData";
import { EditingContext } from "../../../../contexts/EditingContext";

const steps = [
  { id: "step1", label: "Link your account" },
  { id: "step2", label: "Confirm your account" },
];

const LinkPropertyTaxAccount = () => {
  const history = useHistory();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [account, setAccount] = useState(null);
  const [userAlreadyLinked, setUserAlreadyLinked] = useState(false);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { setIsEditing, setModalData } = useContext(EditingContext);

  useEffect(() => {
    if (account) {
      setCurrentPageIndex(1);
    }
  }, [account]);

  const onWizardClose = () => {
    if (currentPageIndex === 1) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _onWizardClosedWithChangeSet,
        }),
      );
      return;
    }
    history.replace("/portal");
  };

  /**
   * Callback passed to the confirmation dialog
   * @returns void
   * @private
   */
  const _onWizardClosedWithChangeSet = () => {
    dispatch(closeDialog());
    history.replace("/portal");
  };

  const getForm = useCallback(
    (stepId) => {
      switch (stepId) {
        case "step1":
          return Step1;
        case "step2":
          return userAlreadyLinked ? AlreadyLinkedProperty : Step2;
        default:
          return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, userAlreadyLinked],
  );

  const CurrentPage = useMemo(
    () => getForm(steps[currentPageIndex].id),
    [currentPageIndex, getForm],
  );

  const linkPropertyAccount = async () => {
    try {
      await linkAccount(account.accountId, user.id);
      history.push(routes.PROPERTY_TAX.codePath + account.accountId, {
        showSuccessDialog: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onCheckPropertyAvailable = async (
    values,
    alreadyLinked = false,
    address = "",
  ) => {
    if (alreadyLinked) {
      setAccount({
        accountNumber: values.folio,
        address: address,
      });
      setUserAlreadyLinked(alreadyLinked);
    } else {
      const result = await findPropertyAccount(values);

      if (result.status === 406) {
        setAccount({
          accountId: result?.data?.account?.accountId,
          accountNumber: result?.data?.account?.folio,
          address: result?.data?.account?.address,
          isInactive: true,
        });
        return;
      }

      if (result.data.account) {
        setAccount({
          accountId: result?.data?.account?.accountId,
          accountNumber: result?.data?.account?.folio,
          address: result?.data?.account?.address,
        });
        setUserAlreadyLinked(alreadyLinked);
      }
    }
  };

  useEffect(() => {
    if (currentPageIndex === 1) {
      setModalData({
        title: "You have unsaved changes",
        body: "Closing this will result in losing all your changes. Are you sure you want to close?",
        actionButtonText: "Confirm",
        variant: "updated",
        buttonTwoVariant: "contained",
        buttonTwoColor: undefined,
      });
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [currentPageIndex, setIsEditing, setModalData]);

  return (
    <div style={{ position: "relative" }}>
      <WizardStepTracker
        steps={steps}
        activeStep={currentPageIndex}
        setActiveStep={setCurrentPageIndex}
        onClose={onWizardClose}
      />
      <Spacer amount={2} />
      <div className={classes.inner_container}>
        {/* @ts-ignore */}
        <CurrentPage
          account={account}
          onCheckPropertyAvailable={onCheckPropertyAvailable}
          linkAccount={linkPropertyAccount}
          alreadyLinked={setUserAlreadyLinked}
        />
      </div>
    </div>
  );
};

export default LinkPropertyTaxAccount;
