import React, { useCallback, useState } from "react";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import DataTable from "../../../../../../components/ui-kit/data-table";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import { fields } from "./fields";
import { getAccountTransactions } from "../../../../../../../core/apis/account";

const BillingTab = ({ loading = false, id = "0" }) => {
  const [invoices, setInvoices] = useState([]);

  const fetchInvoices = useCallback(
    async (queryOptions: object) => {
      try {
        const result = await getAccountTransactions(id, queryOptions);
        setInvoices(result);
      } catch (e) {
        console.log(e);
      }
    },
    [id]
  );

  return (
    <div>
      <p className={typographyClasses.h1}>Billing & Payments</p>
      <Spacer />
      <DataTable
        isPortal={true}
        fields={fields}
        data={invoices}
        onClickHandlers={{ accountId: id }}
        defaultOrder="desc"
        defaultSort="date"
        loadData={fetchInvoices}
      />
    </div>
  );
};

export default BillingTab;
