import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LanguageRounded } from "@mui/icons-material";
import classes from "./index.module.scss";
import city from "../../../../assets/images/favicon.png";
import styled from "@emotion/styled";
import { Dialog, DialogContent, useTheme } from "@mui/material";
import Slide from "@mui/material/Slide";

const StyledDialog = styled(Dialog)`
  && .MuiDialog-container {
    align-items: flex-end;
  }

  && .MuiPaper-root {
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const Transition = React.forwardRef(function Transition(
  { children, ...props },
  ref
) {
  return <Slide direction="up" ref={ref} {...props} children={children} />;
});

const Content = styled(DialogContent)`
  && {
    padding: 1rem;
  }
`;

const appStoreLink =
  "https://apps.apple.com/ca/app/mycityhall-mobile/id1664017433";
const playStoreLink =
  "https://play.google.com/store/apps/details?id=com.cocoflo.mycityhall";


const MobileAppModal = () => {
  const theme = useTheme();
  const isAndroid = /Android/i.test(window.navigator.userAgent);
  const isIOS = /iPhone|iPod|iPad/i.test(window.navigator.userAgent);
  const isMobile = isAndroid || isIOS;
  const useBrowser = sessionStorage.getItem("useBrowser");
  const [open, setOpen] = useState(isMobile && !useBrowser);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem("useBrowser", "1");
  };

  const handleRedirect = () => {
    window.location.href = isIOS ? appStoreLink : playStoreLink;
    setOpen(false);
  };

  return (
    <>
      <div>
        <StyledDialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Transition}
        >
          <Content>
            <Typography
              color={theme.palette.primary[700]}
              fontSize={24}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              See MyCityHall in...
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "2rem" }}>
              Our MyCityHall app is optimized for a better experience on mobile
              devices.
            </Typography>
            <div
              style={{
                display: "flex",
                marginBottom: "2rem",
                alignItems: "center",
                width: "inherit"
              }}
            >
              <div className={classes.logo_container}>
                <img
                  src={city}
                  alt="Logo"
                  style={{ size: "fit", width: "100%" }}
                />
              </div>
              <Typography variant="body1" fontWeight={900}>
                MyCityHall app
              </Typography>
              <Button
                variant="contained"
                style={{
                  marginLeft: "auto",
                  width: "120px"
                }}
                onClick={handleRedirect}
              >
                Open
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "3rem",
                alignItems: "center",
                width: "inherit"
              }}
            >
              <LanguageRounded
                fontSize={"large"}
                fontWeight={400}
                sx={{
                  height: "50px",
                  width: "50px",
                  marginRight: "0.4rem"
                }}
              />
              <Typography variant="body1" fontWeight={900}>
                Web browser
              </Typography>
              <Button
                variant="outlined"
                style={{
                  marginLeft: "auto",
                  width: "118px"
                }}
                onClick={handleClose}
              >
                Continue
              </Button>
            </div>
          </Content>
        </StyledDialog>
      </div>
    </>
  );
};

export default MobileAppModal;    