import React, { useRef, useState } from "react";
import { Grid, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ReactComponent as UsFlagLogo } from "../../../../assets/icons/usa_flag_icon.svg";
import { ReactComponent as CanadaFlagLogo } from "../../../../assets/icons/canada_flag_icon.svg";
import TextField from "@mui/material/TextField";
import {
  abbreviator,
  CanadaProvinces,
  UsStates,
} from "../../../../core/constants/province-states";
import ListItem from "../list-item";
import MaskedTextField from "../masked-text-field";
import ProvinceAutoComplete from "../province-auto-complete";

const AddressDisplayEditor = ({
  editing,
  onSaveEdit,
  isChanged,
  address_street,
  address_street2,
  address_city,
  address_province_state,
  address_zip_postal_code,
  address_country,
  original_address_street,
  original_address_street2,
  original_address_city,
  original_address_province_state,
  orginal_address_zip_postal_code,
  original_address_country,
  icon,
  widget_label,
  errors,
}) => {
  const theme = useTheme();
  const [addressStreet, setAddressStreet] = useState(address_street);
  const [addressStreet2, setAddressStreet2] = useState(address_street2);
  const [addressCity, setAddressCity] = useState(address_city);
  const [addressProvinceState, setAddressProvinceState] = useState(
    address_province_state
  );
  const [addressZipPostalCode, setAddressZipPostalCode] = useState(
    address_zip_postal_code
  );
  const [addressCountry, setCountry] = useState(address_country);
  const [isChanging, setChanging] = useState(false);

  const handleSave = (values) => {
    setAddressStreet(values.streetAddress);
    setAddressStreet2(values.streetAddress2);
    setAddressCity(values.city);
    setAddressProvinceState(values.province);
    setAddressZipPostalCode(values.postalzip);
    setCountry(values.country);
    setChanging(false);
    onSaveEdit(values);
  };

  const getOriginalAddress = () => {
    if (
      original_address_street ||
      original_address_street2 ||
      original_address_city ||
      original_address_province_state ||
      orginal_address_zip_postal_code ||
      original_address_country
    ) {
      const street1 = original_address_street || address_street;
      const street2 = original_address_street2 || address_street2;
      const city = original_address_city || address_city;
      const state = original_address_province_state || address_province_state;
      const postal = orginal_address_zip_postal_code || address_zip_postal_code;
      const country = original_address_country || address_country;
      return street1
        ? street1 +
            ", " +
            (street2 !== "" ? street2 + ", " : "") +
            (city !== "" ? city + ", " : "") +
            (state !== "" ? abbreviator(state, country) + ", " : "") +
            postal +
            " " +
            (country === "CA" ? "Canada" : "USA")
        : null;
    }
  };

  const handleClose = () => {
    setChanging(false);
  };

  const divBackground = getOriginalAddress()
    ? theme.palette.nonPalette.YELLOW_BACKGROUND
    : theme.palette.whites.WHITE;

  return (
    <div
      style={{
        backgroundColor: divBackground,
        display: "flex",
        paddingRight: "10px",
        marginBottom: "0.1rem",
        borderRadius: "10px",
      }}
    >
      {!isChanging ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ListItem
              error={errors}
              label={widget_label}
              subLabel={
                addressStreet
                  ? addressStreet +
                    ", " +
                    (addressStreet2 !== "" ? addressStreet2 + ", " : "") +
                    (addressCity !== "" ? addressCity + ", " : "") +
                    (addressProvinceState !== ""
                      ? abbreviator(addressProvinceState, addressCountry) + ", "
                      : "") +
                    addressZipPostalCode +
                    " " +
                    (addressCountry === "CA" ? "Canada" : "USA")
                  : "-"
              }
              originalValue={getOriginalAddress()}
              variant="detail"
              props="address"
              startOrnament={icon}
              characterLimit={80}
            />
          </div>
          {!isChanging && editing && (
            <Button
              onClick={() => {
                setChanging(true);
                // setLocalErrors([]);
              }}
              size="small"
              style={{
                minWidth: 82,
                height: 33,
                padding: "8px 12px",
                fontFamily: "Rubik",
                fontWeight: 400,
                fontSize: 16,
                marginLeft: "auto",
                marginTop: "10px",
              }}
              variant="outlined"
            >
              Change
            </Button>
          )}
        </>
      ) : (
        <AddressForm
          streetAddress={addressStreet}
          streetAddress2={addressStreet2}
          city={addressCity}
          province={addressProvinceState}
          country={addressCountry}
          postal={addressZipPostalCode}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

const validAddress = yup.string().required("Address is required.");
const validCity = yup.string().required("City is required.");
const validProvinceState = yup
  .string()
  .required("A valid selection is required.");
const validCountry = yup.string().required("Country is required.").matches("");
const validPostalZipCode = (name) =>
  yup
    .string()
    .required("Postal/ZIP code is required.")
    .max(10, "Postal/ZIP code is too long.")
    .test({
      name: "POSTAL_CODE_MATCH_COUNTRY",
      test: function (value) {
        const postalCodeInput = value;
        const fieldName = name || "postalzip";
        const countryValue = this.parent["country"];
        const isCA = !countryValue || countryValue === "CA";
        // when no
        // value,
        // default to IsCA: true
        const regex = isCA
          ? /^[a-zA-Z][0-9][a-zA-Z]\s?[0-9][a-zA-Z][0-9]$/
          : /^\d{5}(-\d{4})?$/;
        const match = regex.exec(postalCodeInput);
        return match
          ? true
          : this.createError({
              message: isCA ? "Invalid postal code." : "Invalid ZIP code.",
              path: fieldName,
            });
      },
    });

const addressFormSchema = yup.object().shape({
  country: validCountry,
  streetAddress: validAddress,
  city: validCity,
  province: validProvinceState,
  postalzip: validPostalZipCode("postalzip"),
});

const AddressForm = ({
  streetAddress,
  streetAddress2,
  city,
  province,
  country,
  postal,
  handleSave,
  handleClose,
}) => {
  const theme = useTheme();
  const formRef = useRef();
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [currentProvince, setCurrentProvince] = useState(
    province !== undefined ? province : ""
  );
  const [provinceFieldLabel, setProvinceLabel] = useState(
    country === "" || country === "CA" ? "Province" : "State"
  );
  const [postalFieldLabel, setPostalLabel] = useState(
    country === "" || country === "CA" ? "Postal Code" : "Zip Code"
  );
  const [postalFieldType, setPostalType] = useState(
    country === "" || country === "CA" ? "POSTAL" : "ZIP"
  );
  const [currentCountry, setCurrentCountry] = useState(
    country !== "" ? country : "CA"
  );

  const [initialValues] = useState({
    streetAddress: streetAddress,
    streetAddress2: streetAddress2,
    city: city,
    province: province !== undefined ? province : "",
    postalzip: postal,
    country: country || "CA",
  });

  const checkAddressChange = (values) => {
    for (const key in values) {
      if (values[key] !== initialValues[key]) {
        return false;
      }
    }
    return true;
  };

  const handleToggle = (event, country, setFieldValue) => {
    if (country !== null && country !== currentCountry) {
      setCurrentCountry(country);
      setCurrentProvince("");
      setFieldValue("streetAddress", "");
      setFieldValue("streetAddress2", "");
      setFieldValue("city", "");
      setFieldValue("province", "");
      setFieldValue("postalzip", "");
      setFieldValue("country", country);
      if (country === "US") {
        setProvinceLabel("State");
        setPostalLabel("Zip Code");
        setPostalType("ZIP");
      } else {
        setProvinceLabel("Province");
        setPostalLabel("Postal Code");
        setPostalType("POSTAL");
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressFormSchema}
      innerRef={formRef}
      onSubmit={handleSave}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
      }) => {
        return (
          <Form
            noValidate
            style={{
              margin: "0.5rem",
              marginRight: "-0.2rem",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      padding: "0.5rem",
                      gap: "2px",
                      border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                      borderRadius: "15px",
                    }}
                  >
                    <ToggleButtonGroup
                      value={currentCountry ?? "CA"}
                      exclusive
                      onChange={(e, v) => {
                        handleToggle(e, v, setFieldValue);
                      }}
                      fullWidth
                    >
                      <ToggleButton
                        value={"CA"}
                        style={{
                          marginRight: "3px",
                        }}
                      >
                        <CanadaFlagLogo
                          style={{
                            marginRight: "0.5rem",
                          }}
                        />
                        CANADA
                      </ToggleButton>
                      <ToggleButton value={"US"}>
                        <UsFlagLogo
                          style={{
                            marginRight: "0.5rem",
                          }}
                        />
                        USA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Grid>
                <TextField
                  label="Address"
                  fullWidth
                  name="streetAddress"
                  id="streetAddress"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.streetAddress}
                  error={Boolean(errors.streetAddress)}
                  touched={touched.streetAddress ? "true" : "false"}
                  helperText={errors.streetAddress}
                />
                <TextField
                  optional={"true"}
                  fullWidth
                  label="Address 2 (optional)"
                  name="streetAddress2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.streetAddress2}
                  error={Boolean(errors.streetAddress2)}
                  touched={touched.unit ? "true" : "false"}
                  helperText={errors.unit}
                  style={{ marginTop: "0.5rem" }}
                />
                <TextField
                  label="City"
                  fullWidth
                  name="city"
                  id="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  error={Boolean(errors.city)}
                  touched={touched.city ? "true" : "false"}
                  inputProps={{ maxLength: 35 }}
                  helperText={errors.city}
                  style={{ marginTop: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <ProvinceAutoComplete
                  label={provinceFieldLabel}
                  fullWidth
                  name="province"
                  id="province"
                  onChange={(e, value) => {
                    setCurrentProvince(value.label);
                    setFieldValue("province", value.label);
                  }}
                  onBlur={handleBlur}
                  value={currentProvince}
                  error={Boolean(errors.province)}
                  helperText={
                    errors.province
                      ? currentCountry === "CA"
                        ? "Province required."
                        : "State required."
                      : ""
                  }
                  touched={touched.province ? "true" : "false"}
                  options={currentCountry === "CA" ? CanadaProvinces : UsStates}
                />
              </Grid>
              <Grid item xs={6}>
                <MaskedTextField
                  isFormField={true}
                  fullWidth
                  type={postalFieldType}
                  label={postalFieldLabel}
                  name="postalzip"
                  id="postalzip"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postalzip}
                  initialValue={values.postalzip}
                  error={Boolean(errors.postalzip)}
                  helperText={errors.postalzip}
                  touched={touched.postalzip ? "true" : "false"}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                gap: 10,
                marginTop: 10,
              }}
            >
              <Button
                type={"submit"}
                disabled={checkAddressChange(values)}
                style={{
                  width: 120,
                  height: 48,
                  padding: 0,
                  margin: 0,
                }}
                size="medium"
                variant={"contained"}
                onClick={() => {
                  setValidateOnChange(true);
                }}
              >
                Done
              </Button>
              <Button
                onClick={() => handleClose()}
                style={{
                  width: 120,
                  height: 48,
                  padding: 0,
                  margin: 0,
                  fontWeight: 700,
                  fontSize: 16,
                  borderRadius: 10,
                  fontFamily: "Rubik",
                }}
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddressDisplayEditor;
