import axios from "axios_0.27.2";

const base = axios.create({
  baseURL: "https://api.na.bambora.com",
  crossDomain: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const tokenize = async (values) => {
  const number = values.ccnumber;
  const cardValues = values.expiry.split("/");
  const expiry_month = cardValues[0];
  const expiry_year = cardValues[1];
  const cvd = values.cvc;

  const dataForBambora = {
    number,
    expiry_month,
    expiry_year,
    cvd,
  };
  try {
    const response = await base.post(
      "/scripts/tokenization/tokens",
      dataForBambora
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export default tokenize;
