import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import WizardStepTracker from "../../../../components/ui-kit/wizard-step-tracker";
import Spacer from "../../../../components/ui-kit/Spacer";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  setState as setConfirmationDialogState,
} from "../../../../redux/slices/confrmation-dialog";
import { validationSchema } from "../utility-details/widgets/services-form-modal/validationSchema";
import { Form, Formik } from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import Select from "../../../../components/ui-kit/select";
import {
  collectionServiceOptions,
  disposableServiceSizeOptions,
  I_DONT_KNOW,
  metreSizeOptions,
  pickupOptions,
  typeOptions,
  wasteMetreOptions
} from "../utility-details/widgets/services-form-modal/options";
import MaskedTextField from "../../../../components/ui-kit/masked-text-field";
import MaskedTextFieldTypes from "../../../../components/ui-kit/masked-text-field/maskedTextFieldTypes";
import { Button } from "@mui/material";
import InfoButton from "../../../../components/ui-kit/InfoButton";
import { routes } from "../../../../routes/routesData";
import Checkbox from "@mui/material/Checkbox";
import WaterMetreInfoModal from "../../../../components/modals/water-metre-info";
import "./index.scss";
import { areObjectsEqual } from "../../../../../utilities";
import { DRAFT } from "../../../../../core/constants/licences";
import { SUBMITTED } from "../../../../../core/constants/statuses";
import {
  getAccountStatus,
  updateAccountChangeSet,
  updateAccountChangeSetStatus,
} from "../../../../../core/apis/account";
import { NotificationContext } from "../../../../contexts/NotificationContext";

const wizardSteps = [
  { id: "step1", label: "Update Service Info" },
  { id: "step2", label: "Review and Submit" },
];

const stepTwoInfoSections = {
  propertyType: [{ name: "property_type", label: "Type", residential: null }],
  waterServices: [
    {
      name: "water_metre_number",
      label: "RF Serial Number",
      residential: null,
    },
    { name: "water_metre_size", label: "Water metre size", residential: null },
  ],
  wasteWaterServices: [
    {
      name: "waste_metre_size",
      label: "Wastewater metre size",
      residential: false,
    },
  ],
  solidWasteServices: [
    {
      name: "collection_services",
      label: "Collection services",
      residential: true,
    },
    {
      name: "solid_waste_general",
      label: "General waste",
      residential: false,
    },
    {
      name: "solid_waste_cardboard",
      label: "Cardboard",
      residential: false,
    },
    {
      name: "solid_waste_size",
      label: "Disposal service size",
      residential: false,
    },
  ],
};

const EditUtilityServicesWizard = ({ changedFormKeys = [] }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const [initialValuesCopy, setInitialValuesCopy] = useState({});
  const [originalValue, setOriginalValue] = useState({});
  const [openWhereToFindDialog, setOpenWhereToFindDialog] = useState(false);
  const [stepOneValues, setStepOneValues] = useState({});
  const [stepOneDirty, setStepOneDirty] = useState(null);
  const { handleError, handleSuccess } = useContext(NotificationContext) || {};

  const checkForDefaults = (value) => {
    if (!value) return "";
    return typeof value !== "object" ? value : "";
  };

  useEffect(() => {
    if (!location.state?.values) {
      history.replace(routes.UTILITY_DETAILS.path.replace(":id", id));
    }
  }, [history, id, location.state?.values]);

  /**
   * With each change in the selected tab, brings the title into the view
   */
  useEffect(() => {
    const title = document.getElementById("__section_title");
    if (title) {
      title.scrollIntoView(false);
    }
  }, [currentStep]);

  useLayoutEffect(() => {
    const values = location.state?.values;
    if (values?.property_type === "commercial") {
      values.property_type = "Commercial, Industrial, Institutional";
    }
    const initialSeed = {
      property_type: checkForDefaults(values?.property_type),
      water_metre_number:
        checkForDefaults(values?.water_metre_number),
      water_metre_size:
        checkForDefaults(values?.water_metre_size),
      waste_metre_size:
        checkForDefaults(values?.waste_metre_size),
      collection_services: checkForDefaults(values?.collection_services),
      solid_waste_general: checkForDefaults(values?.solid_waste_general),
      solid_waste_cardboard: checkForDefaults(values?.solid_waste_cardboard),
      solid_waste_size:
        checkForDefaults(values?.solid_waste_size),
    };
    setOriginalValue({
      property_type: checkForDefaults(values?.property_type),
      water_metre_number: checkForDefaults(values?.water_metre_number),
      water_metre_size: checkForDefaults(values?.water_metre_size),
      waste_metre_size: checkForDefaults(values?.waste_metre_size),
      collection_services: checkForDefaults(values?.collection_services),
      solid_waste_general: checkForDefaults(values?.solid_waste_general),
      solid_waste_cardboard: checkForDefaults(values?.solid_waste_cardboard),
      solid_waste_size: checkForDefaults(values?.solid_waste_size),
    });
    setInitialValues(initialSeed);
    setInitialValuesCopy(initialSeed);
  }, [location.state?.values]);

  const onWizardClosed = () => {
    if (currentStep !== 0) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _handleConfirmationDialogAction,
        }),
      );
      return;
    }
    history.replace(routes.UTILITY_DETAILS.path.replace(":id", `${id}#1`));
  };

  const _handleConfirmationDialogAction = () => {
    dispatch(closeDialog());
    history.replace(routes.UTILITY_DETAILS.path.replace(":id", `${id}#1`));
  };

  /**
   * Handles submission of step one
   * @param values
   */
  const onStepOneSubmit = (values) => {
    setStepOneValues(values);
    setInitialValues(values);
    setCurrentStep(1);
    setStepOneDirty(true);
  };

  const getStatus = useCallback(async () => {
    const statuses = await getAccountStatus(id);
    return statuses?.serviceChangesStatus;
  }, [id]);

  const createChangeShape = useCallback(
    (values) => {
      let updates = [];
      const serviceData = location.state?.serviceData;
      const isResidential =
        values.property_type.toLowerCase() === "residential";
      if (
        serviceData?.propertyType[0].value.value.toLowerCase() !==
        values.property_type.toLowerCase()
      ) {
        updates.push({
          id: serviceData?.propertyType[0]?.id,
          value: { value: values?.property_type },
        });
      }
      if (
        !isResidential &&
        serviceData?.wasteWaterServices[0].value.value !==
          values.waste_metre_size
      ) {
        updates.push({
          id: serviceData.wasteWaterServices[0]?.id,
          value: { value: values.waste_metre_size },
        });
      }
      for (let i = 0; i < serviceData?.solidWasteServices?.length; i++) {
        if (
          serviceData?.solidWasteServices[i]?.name === "solid_waste_general" &&
          serviceData?.solidWasteServices[i].value.value !==
            values.solid_waste_general &&
          !isResidential
        ) {
          updates.push({
            id: serviceData?.solidWasteServices[i]?.id,
            value: { value: values.solid_waste_general },
          });
        } else if (
          serviceData.solidWasteServices[i]?.name === "solid_waste_cardboard" &&
          serviceData?.solidWasteServices[i].value.value !==
            values.solid_waste_cardboard &&
          !isResidential
        ) {
          updates.push({
            id: serviceData?.solidWasteServices[i]?.id,
            value: { value: values.solid_waste_cardboard },
          });
        } else if (
          serviceData.solidWasteServices[i]?.name === "solid_waste_size" &&
          serviceData?.solidWasteServices[i].value.value !==
            values.solid_waste_size &&
          !isResidential
        ) {
          updates.push({
            id: serviceData?.solidWasteServices[i]?.id,
            value: { value: values.solid_waste_size },
          });
        } else if (
          serviceData.solidWasteServices[i]?.name === "collection_services" &&
          serviceData?.solidWasteServices[i].value.value !==
            values.collection_services &&
          isResidential
        ) {
          updates.push({
            id: serviceData?.solidWasteServices[i]?.id,
            value: { value: values.collection_services },
          });
        }
      }
      for (let i = 0; i < serviceData?.waterServices?.length; i++) {
        if (
          serviceData?.waterServices[i]?.name === "water_metre_number" &&
          serviceData?.waterServices[i].value.value !==
            values.water_metre_number
        ) {
          updates.push({
            id: serviceData?.waterServices[i]?.id,
            value: { value: values.water_metre_number },
          });
        } else if (
          serviceData.waterServices[i]?.name === "water_metre_size" &&
          serviceData?.waterServices[i].value.value !== values.water_metre_size
        ) {
          updates.push({
            id: serviceData?.waterServices[i]?.id,
            value: { value: values.water_metre_size },
          });
        }
      }
      return updates;
    },
    [location.state?.serviceData],
  );

  const _onStepTwoConfirmed = useCallback(async () => {
    const fullData = location.state?.fullData;
    const serviceChangesStatus = String(await getStatus());
    if (serviceChangesStatus === DRAFT || serviceChangesStatus === SUBMITTED) {
      const updateData = createChangeShape(stepOneValues);
      const _updatedDataWithApproval = [
        ...updateData,
        {
          id: fullData?.otherInfo.find((e) => e.name === "services_reviewed")
            .id,
          value: {
            value: true,
          },
        },
      ];
      await updateAccountChangeSet(
        id,
        location?.state?.servicesChangesetId,
        _updatedDataWithApproval,
        "",
      );
      if (serviceChangesStatus !== SUBMITTED) {
        await updateAccountChangeSetStatus(
          id,
          location?.state?.servicesChangesetId,
          SUBMITTED,
          "",
        );
      }
      dispatch(closeDialog());
      history.replace(routes.UTILITY_DETAILS.path.replace(":id", `${id}#1`));
      handleSuccess("Service information submitted");
    } else {
      handleError(
        "This utility account is being reviewed, unable to submit more changes",
      );
    }
  }, [
    createChangeShape,
    dispatch,
    getStatus,
    handleError,
    handleSuccess,
    history,
    id,
    location.state?.fullData,
    location.state?.servicesChangesetId,
    stepOneValues,
  ]);

  const onStepTwoSubmitChanges = useCallback(() => {
    dispatch(
      setConfirmationDialogState({
        open: true,
        title: "Confirm Submission",
        body: "By submitting, your service info changes will be forwarded to City Hall for review. The review process may take a few days. Do you wish to proceed?",
        onConfirm: _onStepTwoConfirmed,
      }),
    );
  }, [_onStepTwoConfirmed, dispatch]);

  /**
   * The form component for step one of the wizard
   * Warning: do not move to memo as fields values act un-predictable
   * @returns {Element}
   * @constructor
   */
  const StepOne = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={onStepOneSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange, dirty, isValid, setFieldValue }) => {
          return (
            <Form noValidate={true}>
              <h2 id={"__section_title"}>Update service info</h2>
              <p className={"body"}>
                After updating your service info, an inspector must visit your
                property to verify the changes. Please make the necessary
                adjustments below, confirm, and submit.
              </p>
              <InfoButton
                title={"Where do I find my water metre info?"}
                onClick={() => setOpenWhereToFindDialog(true)}
              />
              <Spacer amount={1} />
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <p className={"overline_bold"}>PROPERTY TYPE</p>
                  <Select
                    name={"property_type"}
                    label={"Type"}
                    value={values.property_type}
                    onChange={handleChange}
                    fullWidth
                    options={typeOptions}
                    inputProps={
                      changedFormKeys?.includes("property_type")
                        ? {
                            className: "changed-field-yellow-background",
                          }
                        : undefined
                    }
                    InputProps={
                      changedFormKeys?.includes("property_type")
                        ? {
                            className: "changed-field-yellow-background",
                            disableUnderline: true,
                          }
                        : undefined
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <p className={"overline_bold"}>WATER SERVICES</p>
                  <MaskedTextField
                    disabled={values.water_metre_number === I_DONT_KNOW}
                    type={MaskedTextFieldTypes.NUMBER}
                    name={"water_metre_number"}
                    label={"RF serial number"}
                    value={values.water_metre_number !== I_DONT_KNOW ? values.water_metre_number : ""}
                    onChange={handleChange}
                    fullWidth
                    inputProps={
                      changedFormKeys?.includes("water_metre_number")
                        ? {
                            className: "changed-field-yellow-background",
                          }
                        : undefined
                    }
                    InputProps={
                      changedFormKeys?.includes("water_metre_number")
                        ? {
                            className: "changed-field-yellow-background",
                            disableUnderline: true,
                          }
                        : undefined
                    }
                  />
                  <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                    <Checkbox
                      sx={{
                        marginLeft: 0,
                        paddingLeft: 0,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      disableFocusRipple
                      disableTouchRipple
                      disableRipple
                      name={"water_metre_number"}
                      checked={values.water_metre_number === I_DONT_KNOW}
                      onChange={(e, value) => {
                        setFieldValue("water_metre_number", value ? I_DONT_KNOW : "");
                      }}
                    />
                    <p>I don't know</p>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <Select
                    disabled={values.water_metre_size === I_DONT_KNOW}
                    name={"water_metre_size"}
                    label={"Water metre size"}
                    value={values.water_metre_size !== I_DONT_KNOW ? values.water_metre_size : ""}
                    onChange={handleChange}
                    fullWidth
                    options={metreSizeOptions}
                    inputProps={
                      changedFormKeys?.includes("water_metre_size")
                        ? {
                            className: "changed-field-yellow-background",
                          }
                        : undefined
                    }
                    InputProps={
                      changedFormKeys?.includes("water_metre_size")
                        ? {
                            className: "changed-field-yellow-background",
                            disableUnderline: true,
                          }
                        : undefined
                    }
                  />
                  <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                    <Checkbox
                      sx={{
                        marginLeft: 0,
                        paddingLeft: 0,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      disableFocusRipple
                      disableTouchRipple
                      disableRipple
                      name={"water_metre_size"}
                      checked={values.water_metre_size === I_DONT_KNOW}
                      onChange={(e, value) => {
                        setFieldValue("water_metre_size", value ? I_DONT_KNOW : "");
                      }}
                    />
                    <p>I don't know</p>
                  </div>
                </Grid>
                {values.property_type !== "residential" ? (
                  <Grid xs={12}>
                    <p className={"overline_bold"}>WASTEWATER SERVICES</p>
                    <Select
                      disabled={values.waste_metre_size === I_DONT_KNOW}
                      name={"waste_metre_size"}
                      label={"Wastewater metre size"}
                      value={values.waste_metre_size !== I_DONT_KNOW ? values.waste_metre_size : ""}
                      onChange={handleChange}
                      fullWidth
                      options={wasteMetreOptions}
                      inputProps={
                        changedFormKeys?.includes("waste_metre_size")
                          ? {
                              className: "changed-field-yellow-background",
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("waste_metre_size")
                          ? {
                              className: "changed-field-yellow-background",
                              disableUnderline: true,
                            }
                          : undefined
                      }
                    />
                    <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                      <Checkbox
                        sx={{
                          marginLeft: 0,
                          paddingLeft: 0,
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                        name={"waste_metre_size"}
                        checked={values.waste_metre_size === I_DONT_KNOW}
                        onChange={(e, value) => {
                          setFieldValue("waste_metre_size", value ? I_DONT_KNOW : "");
                        }}
                      />
                      <p>I don't know</p>
                    </div>
                  </Grid>
                ) : null}
                <Grid xs={12}>
                  <p className={"overline_bold"}>SOLID WASTE SERVICES</p>
                  {values.property_type === "residential" ? (
                    <Select
                      name={"collection_services"}
                      label={"Collection services"}
                      value={values.collection_services}
                      onChange={handleChange}
                      fullWidth
                      options={collectionServiceOptions}
                      inputProps={
                        changedFormKeys?.includes("collection_services")
                          ? {
                              className: "changed-field-yellow-background",
                            }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("collection_services")
                          ? {
                              className: "changed-field-yellow-background",
                              disableUnderline: true,
                            }
                          : undefined
                      }
                    />
                  ) : null}
                </Grid>
                {values.property_type !== "residential" ? (
                  <>
                    <Grid xs={12}>
                      <Select
                        name={"solid_waste_general"}
                        label={"General waste"}
                        value={values.solid_waste_general}
                        onChange={handleChange}
                        fullWidth
                        options={pickupOptions}
                        inputProps={
                          changedFormKeys?.includes("solid_waste_general")
                            ? {
                                className: "changed-field-yellow-background",
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("solid_waste_general")
                            ? {
                                className: "changed-field-yellow-background",
                                disableUnderline: true,
                              }
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Select
                        name={"solid_waste_cardboard"}
                        label={"Cardboard"}
                        value={values.solid_waste_cardboard}
                        onChange={handleChange}
                        fullWidth
                        options={pickupOptions}
                        inputProps={
                          changedFormKeys?.includes("solid_waste_cardboard")
                            ? {
                                className: "changed-field-yellow-background",
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("solid_waste_cardboard")
                            ? {
                                className: "changed-field-yellow-background",
                                disableUnderline: true,
                              }
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Select
                        disabled={values.solid_waste_size === I_DONT_KNOW}
                        name={"solid_waste_size"}
                        label={"Disposal service size"}
                        value={values.solid_waste_size !== I_DONT_KNOW ? values.solid_waste_size : ""}
                        onChange={handleChange}
                        fullWidth
                        options={disposableServiceSizeOptions}
                        inputProps={
                          changedFormKeys?.includes("solid_waste_size")
                            ? {
                                className: "changed-field-yellow-background",
                              }
                            : undefined
                        }
                        InputProps={
                          changedFormKeys?.includes("solid_waste_size")
                            ? {
                                className: "changed-field-yellow-background",
                                disableUnderline: true,
                              }
                            : undefined
                        }
                      />
                      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                        <Checkbox
                          sx={{
                            marginLeft: 0,
                            paddingLeft: 0,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          name={"solid_waste_size"}
                          checked={values.solid_waste_size === I_DONT_KNOW}
                          onChange={(e, value) => {
                            setFieldValue("solid_waste_size", value ? I_DONT_KNOW : "");
                          }}
                        />
                        <p>I don't know</p>
                      </div>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Spacer amount={2} />
              <Button
                disabled={
                  !isValid ||
                  !(stepOneDirty ?? dirty) ||
                  areObjectsEqual(values, initialValuesCopy)
                }
                type={"submit"}
                fullWidth={true}
                variant={"contained"}
              >
                Next
              </Button>
              <Spacer amount={6} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  const stepTwo = useMemo(() => {
    return (
      <>
        <h2 id={"__section_title"}>Review before submitting</h2>
        <p className={"body"}>
          Before proceeding with your request to change your service
          information, please carefully review the information provided below.
        </p>
        <Spacer />
        <p className={"review_container_title"}>REVIEW INFORMATION</p>
        <div className={"review_container"}>
          <p className={"section_title"}>PROPERTY TYPE</p>
          <div className={"section_container"}>
            {stepTwoInfoSections.propertyType.map((e) => (
              <div
                className={`section_items ${
                  stepOneValues[e.name] !== originalValue[e.name] && "changed"
                }`}
              >
                <p className={"label"}>{e.label}</p>
                <p className={"value"}>
                  {(stepOneValues[e.name]?.charAt(0)?.toUpperCase() ?? "") +
                    stepOneValues[e.name]?.slice(1) ?? ""}
                </p>
              </div>
            ))}
          </div>
          <p className={"section_title"}>WATER SERVICES</p>
          <div className={"section_container"}>
            {stepTwoInfoSections.waterServices.map((e) => (
              <div
                className={`section_items ${
                  stepOneValues[e.name] !== originalValue[e.name] && "changed"
                }`}
              >
                <p className={"label"}>{e.label}</p>
                <p className={"value"}>
                  {stepOneValues[e.name] === "IDK"
                    ? "I don't know"
                    : stepOneValues[e.name]}
                </p>
              </div>
            ))}
          </div>
          {stepOneValues["property_type"] !== "residential" && (
            <>
              <p className={"section_title"}>WASTE WATER SERVICES</p>
              <div className={"section_container"}>
                {stepTwoInfoSections.wasteWaterServices.map((e) => (
                  <div
                    className={`section_items ${
                      stepOneValues[e.name] !== originalValue[e.name] &&
                      "changed"
                    }`}
                  >
                    <p className={"label"}>{e.label}</p>
                    <p className={"value"}>
                      {stepOneValues[e.name] === "IDK"
                        ? "I don't know"
                        : stepOneValues[e.name]}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
          <p className={"section_title"}>SOLID WASTE SERVICES</p>
          <div className={"section_container"}>
            {stepTwoInfoSections.solidWasteServices
              .filter(
                (e) =>
                  e.residential ===
                  (stepOneValues["property_type"] === "residential"),
              )
              .map((e) => (
                <div
                  className={`section_items ${
                    stepOneValues[e.name] !== originalValue[e.name] && "changed"
                  }`}
                >
                  <p className={"label"}>{e.label}</p>
                  <p className={"value"}>
                    {stepOneValues[e.name] === "IDK"
                      ? "I don't know"
                      : stepOneValues[e.name]}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <Spacer amount={2} />
        <Button
          fullWidth={true}
          variant={"contained"}
          onClick={onStepTwoSubmitChanges}
        >
          Submit changes
        </Button>
        <Spacer amount={6} />
      </>
    );
  }, [onStepTwoSubmitChanges, originalValue, stepOneValues]);

  return (
    <>
      <WizardStepTracker
        steps={wizardSteps}
        activeStep={currentStep}
        setActiveStep={setCurrentStep}
        onClose={onWizardClosed}
      />
      <Spacer amount={2} />
      <div
        style={{
          maxWidth: 360,
          margin: "0 auto",
        }}
      >
        {currentStep === 0 ? <StepOne /> : stepTwo}
      </div>
      <WaterMetreInfoModal
        open={openWhereToFindDialog}
        setOpen={setOpenWhereToFindDialog}
      />
    </>
  );
};

export default EditUtilityServicesWizard;
