import React from "react";
import styled from "styled-components";
import {Tab as MuiTab} from "@mui/material";
import Typography from "@mui/material/Typography";

const StyledTab = styled(MuiTab)`
  && {
    color: ${({theme})=> theme.palette.primary[700]};
    min-width: 200px;
    min-height: 48px;
    &.Mui-selected{
      color: ${({theme})=> theme.palette.primary[700]};
    }
  }
`;

const Tab = ({ label = "", ...rest }) => {
  return (
    <StyledTab
      label={
        label ? (
          <Typography fontWeight={500} variant="button" color="inherit">
            {label}
          </Typography>
        ) : null
      }
      iconPosition={"start"}
      {...rest}
    />
  );
};

export default Tab;
