import React, { useEffect, useState } from "react";
import Modal from "../ui-kit/Modal";
import Button from "../ui-kit/Button";
import { Form, Formik } from "formik";
import FileUpload from "../ui-kit/FileUpload";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";

const Body = styled.div`
  display: block;
  max-width: 295px;
  margin: 0.5rem;
  padding: 1rem;
`;

const ListText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
  }
`;

const StyledModal = styled(Modal)`
  .MuiDialog-paperScrollPaper {
    max-width: 500px;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

//Initial form values for formik
const initialValues = {
  files: [],
};

/**
 * This modal allows users to add files to upload.
 * @param modalOpen
 * @param handleClose
 * @param addFiles
 * @param maxFiles
 * @param files an array of objects that contain a file under the file property, like {file: File Object}
 * @param fileTitle
 * @returns {JSX.Element}
 * @constructor
 */
export default function UploadFileModal({
  modalOpen,
  handleClose,
  addFiles,
  maxFiles,
  files,
  fileTitle,
}) {
  //Keeps track of how many files are being uploaded
  const [localFilesCount, setLocalFilesCount] = useState(0);
  const [readMore, setReadMore] = useState(false);

  const [error, setError] = useState(null);
  //Error to display
  const [errorFiles, setErrorFiles] = useState(null);

  const theme = useTheme();

  //Local instance of files array
  //Files are structured like {file: File}
  const [filesToBeAdded, setFilesToBeAdded] = useState([]);

  const [uploadDisabled, setUploadDisabled] = useState(true);

  //Closes the modal after adding the files to the parent component by using the addFiles function prop
  const handleSubmit = () => {
    addFiles(filesToBeAdded);
    handleOnClose();
  };

  //Resets the files in the modal so if a user doesn't upload they won't still be selected
  const handleOnClose = () => {
    setFilesToBeAdded([]);
    setError(null);
    setErrorFiles(null);
    handleClose();
  };

  //If files are provided they will be added to the selected file list.
  //This stops users from adding more files than they are allowed.
  useEffect(() => {
    if (files) {
      setLocalFilesCount(files.length);
    }
  }, [files]);

  //Disables the upload button if there are no files selected
  useEffect(() => {
    if (filesToBeAdded.length > 0) {
      setUploadDisabled(false);
    } else {
      setUploadDisabled(true);
    }
  }, [filesToBeAdded]);

  return (
    <StyledModal
      open={modalOpen}
      title={"Upload documents"}
      onClose={handleOnClose}
    >
      <Body>
        <Typography
          variant={"overline"}
          style={{
            fontWeight: 500,
            marginBottom: 5,
            fontSize: 10,
          }}
        >
          Instructions
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
          }}
        >
          If filling in forms in paper:
        </Typography>
        <ul style={{ paddingLeft: 20, marginTop: 0 }}>
          <li>
            <ListText>Write in ALL CAPS</ListText>
          </li>
          <li>
            <ListText>
              Make sure the calligraphy{" "}
              {readMore ? (
                "is readable."
              ) : (
                <span
                  onClick={() => setReadMore(true)}
                  style={{ cursor: "pointer", fontWeight: 500 }}
                >
                  ... read more.
                </span>
              )}
            </ListText>
          </li>
        </ul>
        {readMore && (
          <>
            <Typography
              variant={"body1"}
              style={{ color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS }}
            >
              If taking a photo:
            </Typography>
            <ul
              style={{
                paddingLeft: 20,
                marginTop: 0,
              }}
            >
              <li>
                <ListText>Place the paper under a bright light</ListText>
              </li>
              <li>
                <ListText>
                  When you aim the camera at the paper, ensure the document is
                  not cropped and looks flat on the screen
                </ListText>
              </li>
            </ul>
            <Typography
              variant={"body1"}
              style={{
                fontWeight: 500,
                cursor: "pointer",
                color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                marginTop: -15,
                marginBottom: 5,
              }}
              onClick={() => {
                setReadMore(false);
              }}
            >
              . Read less
            </Typography>
          </>
        )}
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {() => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FileUpload
                      error={errorFiles}
                      setErrorFiles={setErrorFiles}
                      setError={setError}
                      fileTitle={fileTitle}
                      name={"files"}
                      files={filesToBeAdded}
                      numOfFiles={localFilesCount}
                      setFiles={setFilesToBeAdded}
                      maxFiles={maxFiles}
                    />
                    {error && (
                      <Typography
                        variant={"body1"}
                        style={{ color: theme.palette.nonPalette.RED }}
                      >
                        {error}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant={"text"}
                      fullWidth
                      onClick={handleOnClose}
                      style={{ color: theme.palette.primary[500] }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant={"contained"}
                      type={"submit"}
                      onClick={() => {
                        if (!uploadDisabled) {
                          handleSubmit();
                        } else {
                          setError("Must select at least one file");
                        }
                      }}
                      fullWidth
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Body>
    </StyledModal>
  );
}
