import React, { useContext } from "react";
import { Menu, MenuItem } from "@mui/material";
import classes from "./index.module.scss";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes/routesData";
import { userLogout } from "../../../../core/apis/user";
import { AuthContext } from "../../../contexts/AuthContext";
import { EditingContext } from "../../../contexts/EditingContext";
import Avatar from "@mui/material/Avatar";
import { jwtDecode } from "jwt-decode";
import {
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  PaymentOutlined,
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

const ProfileHamburgerMenu = ({ admin = false }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const { user, logout } = useContext(AuthContext);
  const { handleClickWrapper } = useContext(EditingContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutRedirect = async () => {
    logout();
    history.push(routes.LOGOUT.path);
    await userLogout();
  };

  const getAvatarName = (user: any) => {
    if (!user || !user.userToken) return "--";
    const decodedUser = jwtDecode(user.userToken) as any;
    const fName = decodedUser?.user?.profile?.firstName;
    const lName = decodedUser?.user?.profile?.lastName;

    return !fName || !lName
      ? decodedUser?.user?.email?.charAt(0)?.toUpperCase()
      : fName.charAt(0)?.toUpperCase() + lName.charAt(0)?.toUpperCase();
  };

  return (
    <>
      <Button onClick={handleClick} className={classes.hamburger_menu}>
        <Avatar className={classes.hamburger_menu_avatar}>
          <Typography className={classes.hamburger_menu_avatar_text}>
            {getAvatarName(user)}
          </Typography>
        </Avatar>
        <MenuOutlined className={classes.hamburger_menu_icon} />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleClickWrapper(() => history.push("/"))}>
          <HomeOutlined className={classes.hamburger_menu_list_item_icon} />
          Home
        </MenuItem>
        {!admin ? (
          <MenuItem
            onClick={() =>
              handleClickWrapper(() =>
                history.push(routes.MANAGE_PAYMENT_METHODS.path),
              )
            }
          >
            <PaymentOutlined
              className={classes.hamburger_menu_list_item_icon}
            />
            Manage Payments
          </MenuItem>
        ) : null}
        {/*{admin ? (*/}
        {/*  <MenuItem disabled={true}>*/}
        {/*    <PersonOutlined className={classes.hamburger_menu_list_item_icon} />*/}
        {/*    Edit profile*/}
        {/*  </MenuItem>*/}
        {/*) : null}*/}
        <MenuItem onClick={() => handleClickWrapper(logoutRedirect)}>
          <LogoutOutlined className={classes.hamburger_menu_list_item_icon} />
          Log-out
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileHamburgerMenu;
