import { useState } from "react";

export function useModal() {
  const [modal, setModalData] = useState({});

  const update = (name: any, open: any, data: any) => {
    setModalData((prevState) => ({
      ...prevState,
      [name]: { open, data },
    }));
  };

  const handleOpen = (name: any, data = null) => {
    update(name, true, data);
  };
  const handleClose = (name: any) => {
    update(name, false, null);
  };

  return { modal, handleOpen, handleClose };
}
