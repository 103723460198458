import React from "react";
import styled from "styled-components";
import { default as MuiDivider } from "@mui/material/Divider";

const StyledDivider = styled(MuiDivider)`
  && {
    background-color: ${({ color }) => color};
    margin: ${({ margin }) => margin};
  }
`;

export default function Divider(props) {
  return <StyledDivider {...props} />;
}
