import { useTheme } from "@mui/material";
import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";

const LinkButtonStyled = styled.button(({ color }) => ({
  all: "unset",
  color: color,

  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
  "&:focus-visible": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export default function LinkButton({
  children,
  color,
  onClick,
  tooltip,
  ...props
}) {
  const theme = useTheme();
  if (color === "blue") {
    color = theme.palette.primary[200];
  } else {
    color = theme.palette.blacks.BLACK_MEDIUM_EMPHASIS;
  }

  return (
    <Tooltip title={tooltip}>
      <LinkButtonStyled onClick={onClick} color={color} {...props}>
        {children}
      </LinkButtonStyled>
    </Tooltip>
  );
}
