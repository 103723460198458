import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { Box, css, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { FieldArray, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import Spacer from "../../../../components/layout-helpers/Spacer";
import PaymentMethodBar from "../../../../components/ui-kit/PaymentMethodBar";
import { formatCurrency } from "../../../../../utilities/formatter";
import Alerts from "../../../../components/ui-kit/Alert";
import { pay_with_profile } from "../../../../../core/apis/payment";
import { getLicence, getLicenseInvoices } from "../../../../../core/apis/licence";
import { Container, InnerContainer } from "../../../../components/layout-helpers/pageLayout";
import useQuery from "../../../../hooks/useQuery";
import DeleteModal from "./DeleteModal";
import { prices } from "../../../../../core/constants/licences";
import { ArrowBackIosRounded, InfoRounded } from "@mui/icons-material";
import classes from "./index.module.scss";
import { getAccountDetail, getAccountInvoices } from "../../../../../core/apis/account";
import LinkButton from "../../../../components/ui-kit/link-button";
import PaymentModal from "./widgets/payment-modal";
import { AuthContext } from "../../../../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";
import { NotificationContext } from "../../../../contexts/NotificationContext";

const PaymentContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary[100]};
  border-radius: 10px;
  padding-top: 1rem;
`;

const initialItemsValues = {
  amountToBePaid: 0,
  amountDue: 0,
  items: [],
};

// TODO: refactor the components implemented below to their own separate file
const Checkout = ({ isRenewalFlow = false, licenceId, renewalWithChanges = false }) => {
  const user = useContext(AuthContext);
  const [canEdit, setCanEdit] = useState(false);
  const [initValues, setInitValues] = useState(initialItemsValues);
  const [cartItems, setCartItems] = useState([]);
  const [paymentProfileId, setPaymentProfileId] = useState(null);
  const [shouldMoveFormik, setShouldMoveFormik] = useState(true);
  const [total, setTotal] = useState(0);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [modalData, setModalData] = useState({});
  const modalOpen = Boolean(modalData?.amountPaid);

  const history = useHistory();
  const theme = useTheme();
  const query = useQuery();
  const { handleError } = useContext(NotificationContext);

  /**
   * With each change in the query, gets the invoice items
   */
  useEffect(() => {
    fetchItems().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  //Modal functions
  const handleModalOpen = (index) => {
    setDeleteModalData({ index });
  };
  const handleModalClose = () => {
    setDeleteModalData(null);
  };

  const handleModalClick = useCallback(() => {
    if (query.get("account")) {
      history.push(`utility/${query.get("account")}`);
    }
    if (isRenewalFlow) {
      return history.replace(`/business-licence/${licenceId}`, { renewalMode: false });
    } else if (query.get("licence")) {
      history.push(`business-licence/${query.get("licence")}`);
    }
  }, [history, isRenewalFlow, licenceId, query]);

  const handleSubmit = async (values) => {
    if (!paymentProfileId) {
      history.push("/cc-add");
      return;
    }
    setLoading(true);
    setPaymentError(false);
    try {
      let tmpModalData = {};
      const response = await pay_with_profile({
        amount: Number(values.amountToBePaid),
        profileId: paymentProfileId,
        invoiceId: cartItems?.map((item) => item.id)[0],
        sendEmail: true,
        withChanges: renewalWithChanges
      });

      if (!response || response.status >= 400) {
        setPaymentError(true);
      } else {
        if (query.get("licence") || isRenewalFlow) {
          const licence = await getLicence(query.get("licence") ?? licenceId);
          const licenceNumber = licence.find(
            (item) => item.name === "license_number"
          );
          tmpModalData = {
            ...tmpModalData,
            typeLabel: "Licence #",
            invoiceLabel: "Invoice #",
            amountPaid: response.data.content.amount,
            accountNumber: licenceNumber?.value?.value,
            status:
              Number(values.amountToBePaid) >= values.amountDue
                ? "Paid"
                : "Partially Paid",
          };
        } else if (query.get("account")) {
          tmpModalData = {
            ...tmpModalData,
            typeLabel: "Account #",
            invoiceLabel: "Batch #",
            amountPaid: response.data.content.amount,
            status:
              Number(values.amountToBePaid) >= values.amountDue
                ? "Paid"
                : "Partially Paid",
          };
        }

        setModalData((prev) => ({ ...prev, ...tmpModalData }));
      }
    } catch (e) {
      setPaymentError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const fetchItems = async () => {
    let result = null;
    let contacts = [{ email: jwtDecode(user.user.userToken).user.email }];
    let tmpModalData = {};

    try {
      if (query.get("licence") || isRenewalFlow) {
        result = await getLicenseInvoices(query.get("licence") ?? licenceId, {
          status: "open",
        });

        tmpModalData = {
          ...tmpModalData,
          invoiceNumber: result.invoices[0].invoice_number,
        };
        result = result.invoicesMCH.sort((a, b) =>
          a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0
        )[0];

        result = result?.status === "open" ? result : null;
      } else if (query.get("account")) {
        result = await getAccountInvoices(query.get("account"), {
          status: "open",
        });
        const batchNumber = result?.invoices[0]?.extra_info?.batchNumber;
        const account = await getAccountDetail(query.get("account"));
        const accountNumber = account.find(
          (item) => item.name === "account_number"
        );

        result = result.invoicesMCH.sort((a, b) =>
          a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0
        )[0];

        result = result?.status === "open" ? result : null;
        tmpModalData = {
          ...tmpModalData,
          accountNumber: accountNumber?.value?.value || "",
          contacts: contacts || [],
          invoiceNumber: batchNumber,
        };
      }

      setModalData(tmpModalData);

      if (!result) {
        setCartItems([]);
        setTotal(0);
        return;
      }

      setInitValues({
        items: [],
        amountDue: result?.checkoutItem.balance,
        amountToBePaid: result?.checkoutItem.balance.toFixed(2),
      });

      if (result?.checkoutItem.partialAllowed) {
        setCanEdit(true);
      }

      setCartItems([
        {
          ...result,
          amountToBePaid: result.amountDue - result.amountPaid,
          title: result.checkoutItem.displayItem,
        },
      ]);
      setTotal(result?.balance || 0);
    } catch (e) {
      if (e.response.status === 404) {
        history.replace("/404");
      }
      if (e.response.status === 403) {
        history.replace("/403");
      } else {
        handleError("Unable to load account details");
      }
    }
  };

  const isPayDisabled = useCallback((values)=>{
    if(cartItems.length < 1 || values.amountToBePaid <= 0){
      return true;
    }else return loading;
  }, [cartItems.length, loading])

  return (
    <Container>
      <InnerContainer>
        <Box p={1}>
          {
              !isRenewalFlow && <Button
                  size={"medium"}
                  onClick={handleBack}
                  variant={"outlined"}
                  startIcon={<ArrowBackIosRounded />}
          >
            Back
          </Button>
          }
          <div
            style={{
              maxWidth: 780,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Spacer amount={4} />
            <Typography variant={"h3"} style={{ fontWeight: 700 }}>
              Checkout
            </Typography>
            <Spacer
              amount={
                total === prices.discountedLocalBusiness ||
                total === prices.discountedNonLocalBusiness
                  ? 0
                  : 2
              }
            />
            {(total === prices.discountedLocalBusiness ||
              total === prices.discountedNonLocalBusiness) && (
              <>
                <div className={"discount-alert-container"}>
                  <InfoRounded />
                  <p>
                    Applications issued from July 1st to December 31st will
                    receive a one-time 50% discount off the fee.
                  </p>
                </div>
                <Spacer amount={1} />
              </>
            )}
            <Formik initialValues={initValues} enableReinitialize={true}>
              {({ setFieldValue, values, errors }) => {
                if (values.items.length < 1 && cartItems.length > 0) {
                  if (shouldMoveFormik) {
                    setTimeout(() => setShouldMoveFormik(false), 0);
                    setFieldValue("items", cartItems);
                  }
                }

                const calculateTotal = () => {
                  let sum = 0;
                  cartItems.forEach(({ amountToBePaid }) => {
                    sum += amountToBePaid;
                  });
                  setTimeout(() => setTotal(sum), 0);
                };

                calculateTotal();

                return (
                  <Form noValidate>
                    <PaymentContainer>
                      <FieldArray
                        name={"items"}
                        render={({ remove }) => {
                          const handleRemove = (index) => {
                            remove(index);
                            if (values.items.length - 1 < 1) {
                              setTimeout(() => history.goBack(), 0);
                            }
                          };
                          return (
                            <div>
                              {cartItems.map((item, index) => (
                                <div key={index}>
                                  <PaymentItem
                                    item={item}
                                    index={index}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    handleModalOpen={handleModalOpen}
                                  />
                                </div>
                              ))}
                              <DeleteModal
                                data={deleteModalData}
                                open={Boolean(deleteModalData)}
                                handleModalClose={handleModalClose}
                                handleRemove={handleRemove}
                              />
                            </div>
                          );
                        }}
                      />
                      <AdministrationFeeItem></AdministrationFeeItem>
                      <TotalItem
                        values={values}
                        total={total}
                        canEdit={canEdit}
                        setFieldValue={setFieldValue}
                      />
                    </PaymentContainer>
                    <Spacer />
                    <Box display={"flex"}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                          <PaymentMethodBar
                            setPaymentProfileId={setPaymentProfileId}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Button
                            style={{ height: 69 }}
                            disabled={isPayDisabled(values)}
                            variant={"contained"}
                            size={"large"}
                            fullWidth
                            type={"button"}
                            onClick={() => handleSubmit(values)}
                          >
                            <div
                              style={{
                                alignItems: "center",
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{ color: theme.palette.primary[50] }}
                                />
                              ) : (
                                <span>PAY NOW</span>
                              )}
                            </div>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    {paymentError && (
                      <>
                        <Spacer />
                        <Alerts
                          variant={"error"}
                          title={"Payment didn't go through"}
                          body={
                            "Please, make sure you are connected to the Internet and try again."
                          }
                        />
                        <Spacer />
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>
      </InnerContainer>
      <PaymentModal
          isRenewal={isRenewalFlow}
          open={modalOpen}
          amountPaid={modalData?.amountPaid}
          accountNumber={modalData?.accountNumber}
          onClick={handleModalClick}
          status={modalData?.status}
          invoiceNumber={modalData?.invoiceNumber}
          contacts={modalData?.contacts}
          typeLabel={modalData?.typeLabel}
          invoiceLabel={modalData?.invoiceLabel}
      />
    </Container>
  );
};

const PaymentItemContainer = styled.div`
  padding: 1rem 55px 1rem 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PaymentItem = ({ item, key }) => {
  return (
    <PaymentItemContainer key={key}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p className={"body"}>{item.title}</p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {item.amountToBePaid === prices.discountedLocalBusiness ||
        item.amountToBePaid === prices.discountedNonLocalBusiness ? (
          <div className={classes.price_container}>
            <p className={"body"}>
              {formatCurrency(
                item.amountToBePaid === prices.discountedLocalBusiness
                  ? prices.localBusiness
                  : prices.nonLocalBusiness
              )}
            </p>
            <Typography>{formatCurrency(item.amountToBePaid)}</Typography>
          </div>
        ) : (
          <Typography>{formatCurrency(item.amountToBePaid)}</Typography>
        )}
      </div>
    </PaymentItemContainer>
  );
};

const AdministrationFeeItemContainer = styled.div`
  padding: 0 55px 1rem 55px;
`;

const AdministrationFeeItem = () => {
  return (
    <AdministrationFeeItemContainer>
      <Divider />
    </AdministrationFeeItemContainer>
  );
};

const TotalItemContainer = styled.div`
  padding: 0.5rem 55px 1rem 55px;
`;

const StyledTextField = styled(TextField)(
  () => css`
    && {
      .MuiFilledInput-input {
        padding-left: 9px;
      }

      .MuiFilledInput-input.Mui-focused {
        padding-left: 9px;
      }
    }
  `
);

const TotalItem = ({ canEdit, total, setFieldValue, values }) => {
  const ref = useRef(null);
  return (
    <TotalItemContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: canEdit ? "unset" : "1rem",
        }}
      >
        <div style={{ marginTop: canEdit ? "-1rem" : "0" }}>
          <Typography variant={"body1"} fontWeight={"500"}>
            Payment amount
          </Typography>
          {canEdit && (
            <Typography variant={"body2"}>
              Pay in full or opt for partial payments
            </Typography>
          )}
        </div>
        <div>
          {canEdit ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <StyledTextField
                onBlur={(e)=>{
                  let newValue = e.target.value;
                  if(newValue) {
                    setFieldValue("amountToBePaid", String(Number(e.target.value).toFixed(2)))
                  }
                }}
                placeholder={"0.00"}
                inputRef={ref}
                style={{ maxWidth: 197 }}
                inputType={"number"}
                label={"Payment amount"}
                name={`amountToBePaid`}
                value={values.amountToBePaid}
                maxAmount={values.amountDue}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon
                        style={{
                          position: "relative",
                          left: 15,
                          fontSize: 22,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  let num = e.target.value;
                  if (num.includes(".")) {
                    num = num.substring(0, num.indexOf(".") + 3);
                  }
                  num = num.replace(/[^0-9.]/g, '');
                  setFieldValue(`amountToBePaid`, String(num));
                }}
              />
              <Spacer amount={0.5} />
              <LinkButton
                type={"button"}
                color={"blue"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFieldValue(`amountToBePaid`, "");
                  ref.current.focus();
                }}
              >
                Clear amount
              </LinkButton>
            </div>
          ) : (
            <Typography variant={"body1"} fontWeight={"700"}>
              {formatCurrency(total)}
            </Typography>
          )}
        </div>
      </div>
    </TotalItemContainer>
  );
};

export default Checkout;
