import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Spacer from "../../../components/ui-kit/Spacer";
import { Box } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { createLicence, getUserLicenses } from "../../../../core/apis/licence";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  ACTIVE,
  APPROVED,
  DECLINED,
  DISCARDED,
  DRAFT,
  EXPIRING,
  ISSUED,
  RENEWING,
  REVIEWING,
  SAVED,
  SUBMITTED,
} from "../../../../core/constants/licences";
import { routes } from "../../../routes/routesData";
import classes from "./index.module.scss";
import {
  Add,
  GiteRounded,
  Home,
  Link,
  Storefront,
  WaterDamageOutlined,
} from "@mui/icons-material";
import ModuleSectionCard from "../../../components/ui-kit/mch/module-section-card";
import { ModuleTypes } from "../../../../core/constants/enums";
import { getGlobal } from "../../../../utilities";
import WBCBreadcrumbs from "../../../components/ui-kit/breadcrumbs";
import { getAccountSummaries } from "../../../../core/apis/account";
import config from "../../../../core/apis/_config";

const InnerContainer = styled.div`
  max-width: 480px;
  padding: 2rem 0;
  width: 100%;
`;

/**
 * Displays a users licences and drafts
 * @returns {JSX.Element}
 * @constructor
 */
const LandingPage = () => {
  const { state } = useLocation();
  const { handleError, handleSuccess } = useContext(NotificationContext);
  const history = useHistory();
  const [licences, setLicences] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [propertyTaxes, setPropertyTaxes] = useState([]);
  const [loadingLicences, setLoadingLicences] = useState(false);
  const [loadingUtilities, setLoadingUtilities] = useState(false);
  const [loadingPropertyTaxes, setLoadingPropertyTaxes] = useState(false);
  const [licencesAutoPay, setLicenceAutoPay] = useState(false);
  const accountUtilitiesEnabled =
    getGlobal("CCFG_ACCOUNTS_UTILITIES") === "true";
  const licenceBusinessEnabled = getGlobal("CCFG_LICENCES_BUSINESS") === "true";
  const propertyTaxesEnabled = getGlobal("CCFG_PROPERTY_TAXES") === "true";

  /**
   * With initial render of the component:
   * - Calls the function for getting the list of business licences
   * - Calls the function for getting the list of utilities accounts
   * - if the cancelledSuccess property of location state is true, calls the handle success function of notification context
   */
  useEffect(() => {
    fetchLicences().then();
    fetchUtilities().then();
    fetchPropertyTaxes().then();
    if (state?.cancelledSuccess) {
      handleSuccess("Your licence was cancelled");
    } else if (state?.expiredSuccess) {
      handleSuccess("Your licence was set to expire");
    }
    // eslint-disable-next-line
  }, []);

  //Navigates to the licence form
  const navigateToNewLicence = async () => {
    try {
      const { licenseId } = await createLicence();

      history.push(`business-licence/form/${licenseId}#1`);
    } catch (e) {
      handleError(e);
    }
  };

  /**
   * Navigates user to linking a utility account screen
   */
  const linkUtilityAccount = (e) => {
    history.push(routes.LINK_UTILITY.path);
  };

  const linkLicence = () => {
    history.push(routes.LINK_LICENCE.codePath);
  };

  const linkProperty = (e) => {
    history.push(routes.LINK_PROPERTY_TAX.path);
  };

  /**
   * Gets the list of landing-page by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchLicences = async () => {
    setLoadingLicences(true);
    try {
      let items = [];
      const summaryInfo = await getUserLicenses();
      setLicenceAutoPay(summaryInfo.autoPay);
      summaryInfo.licences.forEach((item) => {
        switch (item.status) {
          case DRAFT:
          case SAVED:
          case SUBMITTED:
          case REVIEWING:
          case APPROVED:
          case ISSUED:
          case ACTIVE:
          case RENEWING:
          case EXPIRING:
            items.push(item);
            break;
          case DISCARDED:
          case DECLINED:
          default:
            break;
        }
      });
      setLicences(items);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoadingLicences(false);
    }
  };

  const fetchUtilities = async () => {
    setLoadingUtilities(true);
    try {
      let items = [];
      const allAccounts = await getAccountSummaries({ accountType: "utility" });
      allAccounts.result.forEach((item) => {
        items.push(item);
      });
      setUtilities(items);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoadingUtilities(false);
    }
  };

  const fetchPropertyTaxes = async () => {
    setLoadingPropertyTaxes(true);
    try {
      const response = await getAccountSummaries({ accountType: "property" });
      setPropertyTaxes(response?.result ?? []);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoadingPropertyTaxes(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.top_bar}>
        <WBCBreadcrumbs
          children={[
            {
              icon: <Home fontSize={"small"} />,
              text: "HOME",
            },
          ]}
        />
        <div />
      </div>
      <InnerContainer>
        <Box p={1}>
          <p className={classes.title}>Manage your accounts</p>
          <p className={classes.sub_title}>
            {accountUtilitiesEnabled
              ? `MyCityHall is City of ${config.cityName}'s one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, managing utilities accounts, making payments, and more.`
              : `MyCityHall is City of ${config.cityName}'s one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, making payments, and more.`}
          </p>
          <Spacer amount={0.8} />
          {accountUtilitiesEnabled && (
            <>
              <ModuleSectionCard
                title={"Utilities"}
                icon={<WaterDamageOutlined />}
                moduleType={ModuleTypes.utilities}
                fetchItems={fetchUtilities}
                actionsLabel={"Add account"}
                items={utilities ?? []}
                loading={loadingUtilities}
                actions={[
                  {
                    icon: <Link />,
                    onClick: linkUtilityAccount,
                    label: "Link an existing account",
                  },
                ]}
                backgroundImage={`https://images.cloudcityhall.com/${config.cityId}/interface/utilities_topper.png`}
              />
              <div style={{ marginBottom: "2rem" }} />
            </>
          )}
          {licenceBusinessEnabled && (
            <>
              <ModuleSectionCard
                loading={loadingLicences}
                title={"Business Licences"}
                icon={<Storefront />}
                moduleType={ModuleTypes.businessLicence}
                fetchItems={fetchLicences}
                actionsLabel={"Add licence"}
                setItems={setLicences}
                hasAutoPay={licencesAutoPay}
                items={licences ?? []}
                actions={[
                  {
                    icon: <Link />,
                    onClick: linkLicence,
                    label: "Link an existing licence",
                  },
                  {
                    icon: <Add />,
                    onClick: navigateToNewLicence,
                    label: "Create a new licence",
                  },
                ]}
                backgroundImage={`https://images.cloudcityhall.com/${config.cityId}/interface/licence_topper.png`}
              />
              <div style={{ marginBottom: "2rem" }} />
            </>
          )}
          {propertyTaxesEnabled && (
            <>
              <ModuleSectionCard
                title={"Property Tax"}
                icon={<GiteRounded />}
                moduleType={ModuleTypes.propertyTaxes}
                fetchItems={fetchPropertyTaxes}
                actionsLabel={"Add property"}
                items={propertyTaxes ?? []}
                loading={loadingPropertyTaxes}
                actions={[
                  {
                    icon: <Add />,
                    onClick: linkProperty,
                    label: "Add a property",
                  },
                ]}
                backgroundImage={`https://images.cloudcityhall.com/${config.cityId}/interface/property_topper.png`}
              />
              <div style={{ marginBottom: "2rem" }} />
            </>
          )}
        </Box>
      </InnerContainer>
    </div>
  );
};

export default LandingPage;
