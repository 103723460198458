import React from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { PRIMARY_700 } from "../../../../../mui-theme/theme";
import { formatDisplayValue } from "../../../../../utilities";
import DataTableCell from "../data-table-cell";
import { Storefront } from "@mui/icons-material";

const TagsContainer = styled.div`
  display: flex;
`;

const TagWrapper = styled.div`
  padding: 0 0.3rem;
`;

const Tags = ({ data }) => {
  let tags = [];
  if (!Array.isArray(data)) tags = [data];
  else tags = [...data];
  return (
    <TagsContainer>
      {tags.map((tag, index) => (
        <TagWrapper key={index}>
          <Chip
            style={{
              backgroundColor: "#fff",
              paddingLeft: "10px",
            }}
            variant={"outlined"}
            label={tag}
            type="filter"
            icon={
              <Storefront
                color={"primary"}
                sx={{ width: "16px", height: "16px" }}
              />
            }
          />
        </TagWrapper>
      ))}
    </TagsContainer>
  );
};

const ExpandedCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowValue = styled.span`
  color: ${({ theme }) => theme.PRIMARY_700};
  font-weight: normal;
  text-transform: ${({ transform }) => transform || "none"};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  & .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RowPrimary = styled.span`
  color: ${({ theme }) => theme.headerBackground};
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: ${({ transform }) => transform || "none"};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RowSecondary = styled.span`
  color: ${({ theme }) => theme.textSecondary};
`;

const StyledCell = styled(DataTableCell)`
  && {
    color: ${PRIMARY_700};
    padding-right: 43px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default function DataTableRow(props) {
  const { field, row, index, loadData, onClickHandlers } = props;
  const { format, transform, showUnderscore } = field;

  const value = row[field.id];
  const suffixValue = row[field.suffixField];
  let display = value && suffixValue ? `${value} - ${suffixValue}` : value;
  display = formatDisplayValue(
    display,
    format,
    transform,
    showUnderscore,
    row,
    loadData,
    onClickHandlers
  );

  const subValueDisplay =
    Boolean(row[field.subField]) &&
    formatDisplayValue(row[field.subField], field.subFieldFormat);

  return (
    <StyledCell key={index} align={field.align}>
      {field.type === "tags" ? (
        <Tags data={display} />
      ) : subValueDisplay ? (
        <ExpandedCell>
          <RowPrimary transform={field.transform}>{display}</RowPrimary>
          <RowSecondary>{subValueDisplay}</RowSecondary>
        </ExpandedCell>
      ) : field.id === "price" ? (
        <RowValue
          style={{
            display: "flex",
            justifyContent: "right",
            marginRight: "0.5rem",
          }}
        >
          {display}
        </RowValue>
      ) : (
        <RowValue transform={field.transform} align={field.align}>
          {display}
        </RowValue>
      )}
    </StyledCell>
  );
}
