import base from "./axiosInstance";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { TWO_FACTOR_AUTHENTICATION_COOKIE_KEY } from "../constants/twoFA";
import uuid from "react-uuid";
import config from "./_config";

export const auth_token = async ({ username, password }: any) => {
  let deviceId = Cookies.get(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY);
  if (!deviceId) {
    Cookies.set(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY, uuid());
  }

  try {
    const data = {
      grant_type: "password",
      username,
      password,
      cityId: config.cityId,
      deviceId: Cookies.get(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY),
    };

    return await base.post("/auth/token", data);
  } catch (error) {
    return error;
  }
};

export const sendPasscodeEmail = async (userToken: string) => {
  // @ts-ignore
  const { id: userId } = jwtDecode(userToken);
  const data = { userId };
  return await base.post("/user/send-passcode-email", data);
};

interface VerifyPasscodeProps {
  passcode: string;
  token: string;
  isTrustedDevice: boolean;
}

export const verifyPasscode = async ({
  passcode,
  token,
  isTrustedDevice,
}: VerifyPasscodeProps) => {
  // @ts-ignore
  const { id: userId } = jwtDecode(token);
  let deviceId = Cookies.get(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY);
  if (!deviceId) {
    Cookies.set(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY, uuid());
  }

  const data = {
    userId,
    passcode,
    isTrustedDevice,
    deviceId: Cookies.get(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY),
  };
  return await base.post("/user/verify-passcode", data);
};
