import React, { useMemo, useState } from "react";
import typographyClasses from "../../../../../assets/styles/typography/typography.module.scss";
import classes from "../../../my-city-hall/utilities/utility-details/tabs/service-tab/index.module.scss";
import ServiceRow from "../../../my-city-hall/utilities/utility-details/widgets/service-row";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import Alerts from "../../../../components/ui-kit/Alert";
import Spacer from "../../../../components/ui-kit/Spacer";
import ServicesFormModal from "../../../my-city-hall/utilities/utility-details/widgets/services-form-modal";
import Dialog from "../../../../components/ui-kit/Dialog";
import {
  update_account,
  updateAccountChangeSet,
} from "../../../../../core/apis/account";
import { REVIEWING_GROUP } from "../../../../../core/constants/licences";

const ServicesTab = ({
  id,
  loading,
  data,
  waitingForCitizenUpdates,
  showAlert,
  changesetId,
  changesetStatus,
  refresher,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [confirmChangesDialogOpen, setConfirmChangesDialogOpen] =
    useState(false);
  const [updateInfo, setUpdateInfo] = useState(null);

  /**
   * Memo version of indicator if the utility is residential
   * @type {boolean}
   */
  const isResidential = useMemo(() => {
    return data?.propertyType[0].value.value.toLowerCase() === "residential";
  }, [data?.propertyType]);

  /**
   * Handles submitting the changes made in the dialog
   */
  const handleEditSubmit = (values, { resetForm }) => {
    let updates = [];

    const isResidential = values.property_type.toLowerCase() === "residential";
    if (data?.propertyType[0]?.value?.value !== values?.property_type) {
      updates.push({
        id: data?.propertyType[0]?.id,
        value: { value: values?.property_type },
      });
    }
    if (!isResidential) {
      if (
        data.wasteWaterServices[0]?.value?.value !== values.waste_metre_size
      ) {
        updates.push({
          id: data.wasteWaterServices[0]?.id,
          value: { value: values.waste_metre_size },
        });
      }
    }
    for (let i = 0; i < data?.solidWasteServices?.length; i++) {
      if (
        data?.solidWasteServices[i]?.name === "solid_waste_general" &&
        !isResidential
      ) {
        if (
          data?.solidWasteServices[i]?.value?.value !==
          values.solid_waste_general
        ) {
          updates.push({
            id: data?.solidWasteServices[i]?.id,
            value: { value: values.solid_waste_general },
          });
        }
      } else if (
        data.solidWasteServices[i]?.name === "solid_waste_cardboard" &&
        !isResidential
      ) {
        if (
          data?.solidWasteServices[i]?.value?.value !==
          values.solid_waste_cardboard
        ) {
          updates.push({
            id: data?.solidWasteServices[i]?.id,
            value: { value: values.solid_waste_cardboard },
          });
        }
      } else if (
        data.solidWasteServices[i]?.name === "solid_waste_size" &&
        !isResidential
      ) {
        if (
          data?.solidWasteServices[i]?.value?.value !== values.solid_waste_size
        ) {
          updates.push({
            id: data?.solidWasteServices[i]?.id,
            value: { value: values.solid_waste_size },
          });
        }
      } else if (
        data.solidWasteServices[i]?.name === "collection_services" &&
        isResidential
      ) {
        if (
          data?.solidWasteServices[i]?.value?.value !==
          values.collection_services
        ) {
          updates.push({
            id: data?.solidWasteServices[i]?.id,
            value: { value: values.collection_services },
          });
        }
      }
    }
    for (let i = 0; i < data?.waterServices?.length; i++) {
      if (data?.waterServices[i]?.name === "water_metre_number") {
        if (
          data?.waterServices[i]?.value?.value !== values.water_metre_number
        ) {
          updates.push({
            id: data?.waterServices[i]?.id,
            value: { value: values.water_metre_number },
          });
        }
      } else if (data.waterServices[i]?.name === "water_metre_size") {
        if (data?.waterServices[i]?.value?.value !== values.water_metre_size) {
          updates.push({
            id: data?.waterServices[i]?.id,
            value: { value: values.water_metre_size },
          });
        }
      }
    }

    setUpdateInfo(updates);
    setIsEditModalOpen(false);
    setConfirmChangesDialogOpen(true);
    resetForm();
  };

  /**
   * Submits the admin changes without updating change-sets for the services tab when there are no citizen changes
   */
  const submitAdminChanges = async () => {
    if (REVIEWING_GROUP.includes(changesetStatus)) {
      await updateAccountChangeSet(id, changesetId, updateInfo);
    } else {
      await update_account(id, updateInfo);
    }
    setConfirmChangesDialogOpen(false);
    refresher(true);
  };

  const createAccountDataShape = () => {
    const accountDetailsModalData = {
      property_type: "",
      water_metre_number: "",
      water_metre_size: "",
      waste_metre_size: "",
      collection_services: "",
      solid_waste_general: "",
      solid_waste_cardboard: "",
      solid_waste_size: "",
    };
    accountDetailsModalData.property_type = data?.propertyType[0]?.value?.value;
    accountDetailsModalData.waste_metre_size =
      data?.wasteWaterServices[0]?.value?.value;
    for (let i = 0; i < data?.solidWasteServices?.length; i++) {
      if (data?.solidWasteServices[i]?.name === "solid_waste_general") {
        accountDetailsModalData.solid_waste_general =
          data?.solidWasteServices[i]?.value?.value;
      } else if (data.solidWasteServices[i]?.name === "solid_waste_cardboard") {
        accountDetailsModalData.solid_waste_cardboard =
          data?.solidWasteServices[i]?.value?.value;
      } else if (data.solidWasteServices[i]?.name === "solid_waste_size") {
        accountDetailsModalData.solid_waste_size =
          data?.solidWasteServices[i]?.value?.value;
      } else if (data.solidWasteServices[i]?.name === "collection_services") {
        accountDetailsModalData.collection_services =
          data?.solidWasteServices[i]?.value?.value;
      }
    }
    for (let i = 0; i < data?.waterServices?.length; i++) {
      if (data?.waterServices[i]?.name === "water_metre_number") {
        accountDetailsModalData.water_metre_number =
          data?.waterServices[i]?.value?.value;
      } else if (data.waterServices[i]?.name === "water_metre_size") {
        accountDetailsModalData.water_metre_size =
          data?.waterServices[i]?.value?.value;
      }
    }
    return accountDetailsModalData;
  };

  /**
   * Memo version of changed form key names for edit dialog background highlight
   * @type {string[]}
   */
  const changedFormKeys = useMemo(() => {
    let changedFormKeysNames = [];
    Object.keys(data ?? {})?.forEach((objectKey) => {
      data[objectKey]?.forEach((items) => {
        if (
          items?.originalValue !== undefined &&
          items.value?.value !== items?.originalValue
        )
          changedFormKeysNames.push(items.name);
      });
    });
    return changedFormKeysNames;
  }, [data]);

  return (
    <>
      <div>
        <p className={typographyClasses.h2}>Services</p>
        <div className={classes.services_details_container}>
          <p className={typographyClasses.sub_heading}>Utilities Systems</p>
          {showAlert && (
            <>
              <Spacer />
              <Alerts
                title={
                  waitingForCitizenUpdates
                    ? "Update pending"
                    : "Review the Utilities Services details"
                }
                variant={waitingForCitizenUpdates ? "warning" : "info"}
                body={
                  waitingForCitizenUpdates
                    ? "The account holder needs to review their services below and update their information."
                    : "Please review the details submitted by the account holder below and approve or make any necessary edits."
                }
              />
            </>
          )}
          <div className={"divider_bold"} />
          <ServiceRow
            accessor={(f) => f}
            loading={loading}
            title={"Property Type"}
            services={data?.propertyType}
            isAdminSide={true}
          />
          <ServiceRow
            accessor={(f) => f}
            loading={loading}
            title={"Water Services"}
            services={data?.waterServices}
            isAdminSide={true}
          />
          {!isResidential ? (
            <ServiceRow
              accessor={(f) => f}
              loading={loading}
              title={"WasteWater Services"}
              services={data?.wasteWaterServices}
              isAdminSide={true}
            />
          ) : null}
          <ServiceRow
            accessor={(f) => f}
            loading={loading}
            title={"Solid Waste services"}
            services={
              isResidential
                ? [
                    data?.solidWasteServices.find(
                      (e) => e.name === "collection_services"
                    ),
                  ]
                : data?.solidWasteServices.filter(
                    (e) => e.name !== "collection_services"
                  )
            }
            isAdminSide={true}
          />
          <div className={"flex_end_container"}>
            <Button
              disabled={loading}
              startIcon={<Edit color={"primary"} />}
              size={"small"}
              variant={"outlined"}
              onClick={() => setIsEditModalOpen(true)}
            >
              Edit information
            </Button>
          </div>
        </div>
      </div>
      <ServicesFormModal
        adminSide={true}
        changedFormKeys={changedFormKeys}
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        onSubmit={handleEditSubmit}
        values={createAccountDataShape()}
        showAlert={showAlert}
      />
      <Dialog
        variant={"updated"}
        open={confirmChangesDialogOpen}
        handleClose={() => {
          setConfirmChangesDialogOpen(false);
        }}
        title={"Apply changes"}
        body={"Your changes will be saved. Do you want to proceed?"}
        buttonOneText={"Cancel"}
        buttonTwoText={"Save"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          setConfirmChangesDialogOpen(false);
        }}
        handleButtonTwo={submitAdminChanges}
        buttonTwoDisable={false}
      />
    </>
  );
};

export default ServicesTab;
