//Calculates the amount of days that have passed since an item was last edited
import moment from "moment";

export const showTimeAgo = (date, showSuffix = true) => {
  try {
    const momentDate = moment(date);

    return momentDate.fromNow(!showSuffix);
  } catch (e) {
    return "date unavailable";
  }
};
