import React, { createContext, useState, useMemo } from "react";
import theme from "../../mui-theme/theme";

export const SidebarContext = createContext({
  sidebarWidth: "",
  setSidebarWidth: (prevState) => {},
});

export const SidebarContextProvider = (props) => {
  const [sidebarWidth, setSidebarWidth] = useState(theme.sidebarWidth);

  const sidebarContext = useMemo(() => {
    return {
      sidebarWidth,
      setSidebarWidth,
    };
  }, [sidebarWidth]);

  return (
    <SidebarContext.Provider value={sidebarContext}>
      {props.children}
    </SidebarContext.Provider>
  );
};
