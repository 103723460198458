import { Box, Skeleton } from "@mui/material";
import classes from "../right-sidebar/index.module.scss";
import Spacer from "../../../../components/ui-kit/Spacer";

const RightSidebarSkeleton = () => {
  return (
    <div className={classes.main_container}>
      <Box p={2}>
        <Skeleton variant={"rectangular"} height={70} />
        <Spacer />
        <Skeleton variant={"rectangular"} height={120} />
        <Spacer />
        <Skeleton variant={"rectangular"} height={60} />
        <Spacer />
        <Skeleton variant={"rectangular"} height={60} />
      </Box>
    </div>
  );
};

export default RightSidebarSkeleton;
