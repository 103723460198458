import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { OUTLINE, PRIMARY_500 } from "../../../mui-theme/theme";
import HighlightedAutoComplete from "./HighlightedAutoComplete";
import { SearchRounded } from "@mui/icons-material";

export const SearchTextField = styled(TextField)`
  && .MuiOutlinedInput-root {
    background-color: unset;
    border-color: ${OUTLINE};
    width: ${({ width }) => width};
    height: 48px;
    border-radius: 50px;
    padding: 0 !important;

    &:hover fieldset {
      border-color: ${OUTLINE};
    }

    & fieldset {
      border-color: ${OUTLINE};
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary[50]};
    }

    &.Mui-focused {
      background-color: white;
    }

    &&.MuiOutlinedInput-adornedEnd {
      padding: 0;
      padding-right: 5px;
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.primary[200]};
      box-shadow:
        0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08),
        0 3px 5px rgba(1, 18, 32, 0.1);
      border-width: 2px;
      height: 50px;
    }
  }
`;

export const SearchButton = styled(IconButton)`
  && {
    font-size: 1.1rem;
    color: ${PRIMARY_500};
    margin-left: 5px;
  }
`;

/**
 * Search bar component
 * @param autoFocus
 * @param handleChange
 * @param handleClear
 * @param handleSubmit
 * @param hideSearchBar
 * @param textValue
 * @returns {JSX.Element}
 * @constructor
 */
const SearchBar = ({
  handleChange,
  handleClear,
  handleSubmit,
  hideSearchBar,
  textValue,
  options,
  tooltipTitle,
  placeholder = null,
  ...props
}) => {
  if (hideSearchBar) return <></>;
  return (
    <div style={{ width: 400 }}>
      <HighlightedAutoComplete
        autoHighlight={false}
        freeSolo={true}
        tooltipTitle={tooltipTitle}
        RenderComponent={SearchTextField}
        value={textValue}
        onChange={handleChange}
        variant={"outlined"}
        hideExpandIcon={true}
        onSubmit={handleSubmit}
        onClear={handleClear}
        options={options || []}
        InputProps={{
          placeholder: !!placeholder ? placeholder : "",
          startAdornment: (
            <InputAdornment position="start">
              <SearchButton onClick={handleSubmit}>
                <SearchRounded />
              </SearchButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </div>
  );
};

export default SearchBar;
