import React from "react";
import styled from "@emotion/styled";

//language=SCSS prefix=*{ suffix=}
const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 345px;
  min-width: 320px;
  width: 100%;
`;

/**
 * The third column for a triple column layout
 * @param children
 * @param style {React.CSSProperties}
 * @returns {JSX.Element}
 * @constructor
 */
export default function RightColumn({
      children, style, className
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) {
  return <RightContainer className={className} style={style}>{children}</RightContainer>;
}
