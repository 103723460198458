import React, { useLayoutEffect, useState } from "react";
import MainContainer from "../../../../components/layout-helpers/triple-column-layout/MainContainer";
import LeftColumn from "../../../../components/layout-helpers/triple-column-layout/LeftColumn";
import RightColumn from "../../../../components/layout-helpers/triple-column-layout/RightColumn";
import WBCBreadcrumbs from "../../../../components/ui-kit/breadcrumbs";
import classes from "../../utilities/utility-details/index.module.scss";
import {
  AlternateEmail,
  CheckCircleOutlineRounded,
  DescriptionOutlined,
  ForwardToInboxOutlined,
  Home,
  PersonOutline,
  SmsOutlined,
  StickyNote2Outlined,
  Tag,
} from "@mui/icons-material";
import Tabs from "../../../../components/ui-kit/tabs";
import Tab from "../../../../components/ui-kit/tab";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TabPanel from "../../../../components/ui-kit/tab-pannel";
import useTab from "../../../../hooks/useTab";
import { routes } from "../../../../routes/routesData";
import Dialog from "../../../../components/ui-kit/Dialog";
import typographyClasses from "../../../../../assets/styles/typography/typography.module.scss";
import Spacer from "../../../../components/ui-kit/Spacer";
import { ReactComponent as WorkInProgress } from "../../../../../assets/images/work-in-progress.svg";
import OverviewTab from "./tabs/overview";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import moment from "moment";
import AssessmentTab from "./tabs/assessment";
import usePropertyTaxDetails from "../../../../hooks/property-tax/usePropertyTaxDetails";
import TaxNoticeTab from "./tabs/tax-notice";
import usePropertyTaxNotices from "../../../../hooks/property-tax/usePropertyTaxNotices";

const TIPP_URL =
  "https://lacombeca.sharepoint.com/public/Department%20Postings/Forms/AllItems.aspx?id=%2Fpublic%2FDepartment%20Postings%2FFinance%2FTax%20Assessment%2FProperty%20Taxes%2FTIPP%20Application%20Form%2Epdf&parent=%2Fpublic%2FDepartment%20Postings%2FFinance%2FTax%20Assessment%2FProperty%20Taxes&p=true&ga=1";

const tabs = [
  { id: "0", label: "Overview" },
  { id: "1", label: "Assessment" },
  { id: "2", label: "Property Tax" },
  { id: "3", label: "Documents" },
];

/**
 * Display - Property tax details screen
 * @constructor
 */
const PropertyTaxDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { tab } = useTab();
  const { assessments, loading } = usePropertyTaxDetails(id);
  const { loading: loadingNotices, notices } = usePropertyTaxNotices(id);

  const [showSuccessLinkModal, setShowSuccessLinkModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [timeLineContent, setTimeLineContent] = useState([
    {
      label: "Assessment mailed out",
      subLabel: "February 17",
    },
    {
      label: "Deadline to fill complaint",
      subLabel: "April 26",
    },
    {
      label: "Tax rates finalized",
      subLabel: "Early May",
    },
    {
      label: "Property tax bills mailed out",
      subLabel: "Late May",
    },
    {
      label: "Tax payments are due",
      subLabel: "June 30",
    },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [accountHolderInfo, setAccountHolderInfo] = useState([
    {
      id: 1,
      label: "Property Owner",
      subLabel: "Ryan Clark",
      icon: <PersonOutline />,
    },
    {
      id: 2,
      label: "Property Address",
      subLabel: "5432 56 Avenue Lacombe, AB, T4L 1E9",
      icon: <ForwardToInboxOutlined />,
    },
    {
      id: 3,
      label: "Roll Number",
      subLabel: "194 010024941",
      icon: <AlternateEmail />,
    },
    {
      id: 4,
      label: "Access Code/PIN",
      subLabel: "27557",
      icon: <Tag />,
    },
    {
      id: 5,
      label: "Legal Description",
      subLabel: "27557",
      icon: <DescriptionOutlined />,
    },
    {
      id: 6,
      label: "Communication Preference",
      subLabel: "Paperless (By Email)",
      icon: <SmsOutlined />,
    },
  ]);

  useLayoutEffect(() => {
    if (location.state?.showSuccessDialog) {
      setShowSuccessLinkModal(true);
    }
  }, [location.state]);

  const changeTab = (e, newTab) => {
    history.push(`/property-tax/${id}#${newTab}`);
  };

  const handleClosingSuccessLinkModal = () => {
    setShowSuccessLinkModal(false);
    history.replace(routes.PROPERTY_TAX.codePath + id);
  };

  return (
    <div>
      <WBCBreadcrumbs
        className={classes.bread_crumbs}
        children={[
          {
            icon: <Home fontSize={"small"} />,
            text: "HOME",
            onClick: () => history.replace("/"),
          },
          {
            text: "PROPERTY TAX",
          },
        ]}
      />
      <Tabs onChange={changeTab} value={tab} className={"padding_left"}>
        <Tab value={tabs[0].id} label={tabs[0].label} />
        <Tab value={tabs[1].id} label={tabs[1].label} />
        <Tab value={tabs[2].id} label={tabs[2].label} />
        <Tab value={tabs[3].id} label={tabs[3].label} />
      </Tabs>
      <MainContainer>
        <LeftColumn>
          <TabPanel value={tab} index={tabs[0].id}>
            <OverviewTab
              id={id}
              loading={loading}
              accountDetailsItem={accountHolderInfo}
              assessmentInfo={{
                date: "2024-06-30",
                assessedValue: 353000,
                assessmentClass: "Residential",
                improvement: 206000,
                land: 158000,
                complaintDate: "2023-08-23",
              }}
              taxNotice={{
                dueDate: "2024-06-30",
                items: [
                  {
                    description: "Municipal Taxes",
                    rate: 8.6448,
                    amount: 3146.71,
                  },
                  {
                    description: "Provincial Taxes",
                    rate: 0.0838,
                    amount: 962.49,
                  },
                  {
                    description: "Other Taxes",
                    rate: 2.6442,
                    amount: 26.42,
                  },
                  {
                    description: "Prepayment Arrears",
                    rate: null,
                    amount: -1810.99,
                  },
                ],
              }}
              assessments={assessments}
            />
          </TabPanel>
          <TabPanel value={tab} index={tabs[1].id}>
            <AssessmentTab assessments={assessments} loading={loading} />
          </TabPanel>
          <TabPanel value={tab} index={tabs[2].id}>
            <TaxNoticeTab loading={loadingNotices} notices={notices} />
          </TabPanel>
          <TabPanel value={tab} index={tabs[3].id}>
            <>
              <p className={typographyClasses.h1}>Documents</p>
              <Spacer />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <WorkInProgress />
              </div>
            </>
          </TabPanel>
        </LeftColumn>
        <RightColumn>
          <Spacer amount={4.75} />
          <div className={classes.details_container}>
            <p className={typographyClasses.h2}>
              {"2024 Assessment & Tax Notice Timeline"}
            </p>
            <Stepper activeStep={-1} orientation={"vertical"}>
              {timeLineContent.map((e) => (
                <Step key={e.label}>
                  <StepLabel
                    optional={
                      <p
                        style={{
                          margin: "0",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {e.subLabel}
                      </p>
                    }
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        margin: "0",
                        color: "black",
                      }}
                    >
                      {e.label}
                    </p>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <Spacer />
          <div className={classes.details_container}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                margin: "0",
              }}
            >
              {"Tax Installment Payment Plan (TIPP)"}
            </p>
            <p
              style={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
              }}
            >
              Sign up for TIPP prior to{" "}
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {moment("2024-06-30").format("MMMM DD, YYYY")}
              </span>{" "}
              to have your annual property tax divided into 12 equal consecutive
              monthly payments rather than one lump sum.
            </p>
            <Spacer />
            <Button
              size={"small"}
              startIcon={<StickyNote2Outlined color={"primary"} />}
              variant={"outlined"}
              onClick={() => window.open(TIPP_URL, "_blank", "noreferrer")}
            >
              Apply for TIPP
            </Button>
          </div>
        </RightColumn>
      </MainContainer>
      <Dialog
        variant={"updated"}
        open={showSuccessLinkModal}
        handleClose={handleClosingSuccessLinkModal}
        body={
          <div className={classes.flex_column}>
            <CheckCircleOutlineRounded
              color={"primary"}
              sx={{ width: "64px", height: "64px" }}
            />
            <p className={classes.dialog_body_text}>
              You linked your account successfully
            </p>
          </div>
        }
        buttonOneText={"Link another account"}
        buttonTwoText={"Continue"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          history.replace(routes.LICENCES.path);
        }}
        handleButtonTwo={handleClosingSuccessLinkModal}
      />
    </div>
  );
};

export default PropertyTaxDetails;
