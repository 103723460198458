import React, { useContext } from "react";
import styled from "@emotion/styled";
import { getAuthType, getScreenOptions } from "../../routes/routesData";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { PRIMARY_500 } from "../../../mui-theme/theme";
import CocofloLogo from "../../../assets/images/cocofloLogo.svg";
import { USER_AUTH_TYPE } from "../../../core/constants/authTypes";
import config from "../../../core/apis/_config";
import { EditingContext } from "../../contexts/EditingContext";
import ProfileHamburgerMenu from "./profile-hamburger-menu";

const AdminContainer = styled.header`
  align-items: center;
  background: ${PRIMARY_500};
  display: flex;
  height: 4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: top 200ms ease-in-out;
`;

const Left = styled.div`
  margin-left: 1rem;
  width: ${({ theme }) => theme.sidebarWidth};
`;

const Right = styled.div`
  display: flex;
  margin-right: 1rem;
  position: fixed;
  right: 0;
  justify-content: flex-end;
`;

const UserContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.whites.WHITE};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.31);
  display: ${({ hideHeader }) => (hideHeader ? "none" : "flex")};
  height: 90px;
  padding: 0 1rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  justify-content: space-between;
  z-index: 3;
`;

const NavContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const { hideHeader } = getScreenOptions(location.pathname);
  const { user } = useContext(AuthContext);
  const { handleClickWrapper } = useContext(EditingContext);

  const isIE = Boolean(document.documentMode);

  return !hideHeader ? (
    getAuthType(location.pathname) === USER_AUTH_TYPE ? (
      <UserContainer>
        <NavContainer>
          <div
            onClick={() => {
              handleClickWrapper(() => history.push("/"));
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              alt={"logo"}
              src={`https://images.cloudcityhall.com/${config.cityId}/interface/logo.svg`}
              height={"80px"}
            />
          </div>
        </NavContainer>
        <ProfileHamburgerMenu />
      </UserContainer>
    ) : (
      <AdminContainer>
        {isIE ? null : (
          <>
            <Left>
              <img src={CocofloLogo} alt={"Logo"} style={{ height: "40px" }} />
            </Left>
            <Right>{user && <ProfileHamburgerMenu admin={true} />}</Right>
          </>
        )}
      </AdminContainer>
    )
  ) : null;
};

export default Header;
