import Spacer from "../../../../../../components/ui-kit/Spacer";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Skeleton, useTheme } from "@mui/material";
import PropertyTaxDropdown from "../../../../../../components/property-tax/property-tax-dropdown";
import moment from "moment";
import TaxTable from "../../../../../../components/property-tax/tax-table";
import { Pie } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  ArcElement,
} from "chart.js";

//Sets the font for the graph
defaults.font.family = "Rubik";

//Must register items before they can be rendered by ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

//TODO - Move mock data to BE
const mockDataLabels = [
  "Municipal Levy ($0.756)",
  "School ($0.230)",
  "Foundation ($0.06)",
  "Paving ($0.07)",
];
const mockData = [0.756, 0.23, 0.06, 0.07];

const TaxNoticeTab = ({ loading, notices }) => {
  const theme = useTheme();
  return (
    <>
      <h1>Property Tax</h1>
      <Spacer />
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={6}>
          {!loading ? (
            notices ? (
              notices.map((notice, index) => (
                <PropertyTaxDropdown
                  initialOpen={index === 0}
                  label={`${moment(notice.extra_info.billingDate).format(
                    "YYYY",
                  )} Property Tax Notice`}
                  subLabel={`Due Date is ${moment(notice.due_date).format(
                    "MMMM DD, YYYY",
                  )}`}
                  BodyComponent={() => <TaxTable notice={notice} />}
                />
              ))
            ) : (
              <h2>No data to display</h2>
            )
          ) : (
            <Skeleton variant={"rectangular"} height={400} />
          )}
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          {!loading ? (
            <>
              <Spacer />
              <h2>
                {moment(notices[0]?.extra_info?.billingDate).format("YYYY")} Tax
                Levies
              </h2>
              <div style={{ position: "relative" }}>
                <Pie
                  style={{marginTop: -64}}
                  options={{
                    plugins: {
                      legend: {
                        display: true,
                        position: "right",
                        labels: {
                          padding: 22,
                          font: "Rubik",
                          color: theme.palette.primary[700],
                        },
                      },
                    },
                  }}
                  data={{
                    labels: mockDataLabels,
                    datasets: [
                      {
                        label: "center",
                        data: mockData,
                        backgroundColor: [
                          "#0C55B5",
                          "#96C0EF",
                          "#011C3099",
                          "#011C30DE",
                        ],
                      },
                    ],
                  }}
                />
              </div>
            </>
          ) : (
            <Skeleton variant={"rectangular"} height={400} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TaxNoticeTab;
