import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { manageSession } from "../../utilities/manageSession";
import { jwtDecode } from "jwt-decode";
import { ADMIN_AUTH_TYPE } from "../../core/constants/authTypes";
import { FilterContext } from "./FilterContext";
import { TableSettingContext } from "./TableSettingContext";

export const AuthContext = createContext({
  authenticate: (authType: string | null, skipAuthType: boolean): boolean =>
    false,
  user: null,
  login: (loginUser: any) => {},
  logout: () => {},
  isInitialLogin: true,
  loggedIn: (userData: any): boolean => false,
  getDefaultURL: (): string => "/login",
});

export const AuthContextProvider = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [user, setUser] = useState(manageSession.getSessionInfo()) as any;
  const [isInitialLogin, setIsInitialLogin] = useState(true);
  const [hasLoggedIn, setHasLoggedIn] = useState(true);
  const { resetFilters } = useContext(FilterContext);
  const { resetTableSettings } = useContext(TableSettingContext);

  const loggedIn = (userData: { userToken: string } | null) => {
    if (!userData) return false;
    const decoded = jwtDecode(userData.userToken) as {
      user: { loginCount: number };
    };

    if (decoded.user.loginCount >= 2) {
      setHasLoggedIn(true);
      return true;
    } else {
      setHasLoggedIn(false);
      return false;
    }
  };

  const logout = useCallback(async () => {
    loggedIn(null);
    await setIsInitialLogin(true);
    const userToken = user.userToken;
    await manageSession.endSession();
    await setUser(null);
    return userToken;
  }, [user]);

  const authContext = useMemo(() => {
    return {
      user,
      setUser,
      isInitialLogin,
      hasLoggedIn,
      login: async (loginUser: React.SetStateAction<null>) => {
        await setIsInitialLogin(false);
        await manageSession.createSession(loginUser);
        await setUser(loginUser);
        resetFilters();
        resetTableSettings();
      },
      load: async () => {
        await setIsInitialLogin(false);
        const userProfile = manageSession.getSessionInfo();
        await setUser(userProfile);
      },
      loggedIn,
      update: (newUserData: any) => {
        // @ts-ignore
        const newUser = { ...user, ...newUserData };
        manageSession.updateSessionInfo(newUser);
        setUser(newUser);
      },
      logout,
      getDefaultURL: () => {
        if (user?.userToken !== null) {
          const userInfo = jwtDecode(user.userToken) as any;
          if (userInfo.auth_type === ADMIN_AUTH_TYPE) {
            return "/admin";
          } else {
            return  "/portal";
          }
        }
        return "/login";
      },
      authenticate: (authType: string | null, skipAuthType: boolean) => {
        const sessionInfo = manageSession.getSessionInfo();
        const isTokenExpired = manageSession.checkTokenExpiry();
        if (
          user &&
          !skipAuthType &&
          // @ts-ignore
          jwtDecode(user.userToken).auth_type !== authType
        ) {
          return false;
        }
        if (!sessionInfo || isTokenExpired) {
          if (sessionInfo) {
            manageSession.endSession();
          }
          if (user) {
            setUser(null);
          }
          return false;
        } else {
          return true;
        }
      },
    };
  }, [
    user,
    isInitialLogin,
    logout,
    hasLoggedIn,
    resetFilters,
    resetTableSettings,
  ]);

  useEffect(() => {
    if (!user && manageSession.getSessionInfo()) {
      authContext.load();
    }
  }, [authContext, user]);

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
};
