import { useEffect, useMemo, useState } from "react";
import { routes } from "../routes/routesData";
import { getGlobal } from "../../utilities";
import { Group } from "@mui/icons-material";

export const DIVIDER = "DIVIDER";

const folders = {
  USER_MANAGEMENT: {
    icon: <Group />,
    title: "People",
    subItems: [routes.USERS, routes.ADMINS],
  },
};

export default function useSidebarSettings() {
  const [sidebarLoading, setSidebarLoading] = useState(true);

  /**
   * Memo version of the admin sidebar items
   */
  const sidebarSettings = useMemo(() => {
    return {
      dashboard: routes.DASHBOARD,
      ...(getGlobal("CCFG_ACCOUNTS_UTILITIES") === "true" ? { utilities: [routes.ADMIN_UTILITIES] } : {}),
      ...(getGlobal("CCFG_PROPERTY_TAXES") === "true" ? { propertyTaxes: [routes.ADMIN_PROPERTY_TAXES] } : {}),
      ...(getGlobal("CCFG_LICENCES_BUSINESS") === "true" ? { licences: [routes.ADMIN_LICENCES] } : {}),
      ...{ reporting: [routes.ADMIN_REPORTING] },
      managementServices: [folders.USER_MANAGEMENT]
    };
  }, []);

  useEffect(() => {
    setSidebarLoading(false);
  }, [sidebarSettings]);

  return [sidebarSettings, sidebarLoading];
}
