import React from "react";
import ListItem from "@mui/material/ListItem";
import {
  OUTLINE,
  primary,
  warning,
  warningLight,
} from "../../../mui-theme/theme";
import styled from "styled-components";
import { useTheme } from "@mui/material";

const StyledListItem = styled(ListItem)`
  && {
    margin-bottom: 5px;
    border-radius: 10px;
    min-height: 100px;
    box-shadow: 0 3px 10px 0 rgba(1, 48, 78, 0.15);

    :hover {
      background: ${({ theme }) => theme.palette.primary[50]};
      cursor: pointer;
    }

    ${({ state }) => {
      switch (state) {
        case "flagged":
          return `border: solid 1px ${warning}; background:${warningLight};`;
        case "selected":
          return `border: solid 2px ${primary};`;
        default:
          return `border-bottom: solid 1px ${OUTLINE}; background: white;`;
      }
    }}
  }
`;

const ActionCard = ({ onClickRow, children, ...rest }) => {
  const theme = useTheme();

  return (
    <StyledListItem
      alignItems="flex-start"
      onClick={onClickRow}
      {...rest}
      theme={theme}
    >
      {children}
    </StyledListItem>
  );
};

export default ActionCard;
