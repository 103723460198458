import React, { useContext, useEffect, useState } from "react";
import {
  getAccountReports,
  getLicenceReports,
  getTransactionReports,
} from "../../../../core/apis/report";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import {
  AttachMoneyOutlined,
  CheckBoxOutlined,
  LocationOnOutlined,
  PersonPinOutlined,
  SummarizeOutlined,
  PaymentsOutlined,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import { BLUE, borderColor, PRIMARY_200, PRIMARY_50 } from "mui-theme/theme";
import Select from "@mui/material/Select";
import { formatCurrency } from "../../../../utilities";
import { NotificationContext } from "ui/contexts/NotificationContext";

const columns: GridColDef[] = [
  {
    field: "licence_number",
    headerName: "LICENCE NO.",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "business_name",
    headerName: "BUSINESS NAME",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "is_resident",
    headerName: "RESIDENT/NON",
    editable: false,
    flex: 1,
    filterable: false,
    valueGetter: ({ value }) =>
      value === "true" ? "Resident" : "Non-resident",
  },
  {
    field: "is_homebased",
    headerName: "LOCATION",
    editable: false,
    flex: 1,
    filterable: false,
    valueGetter: ({ value }) => (value === "true" ? "Homebased" : "Storefront"),
  },
  {
    field: "status",
    headerName: "STATUS",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "balance",
    headerName: "BALANCE",
    editable: false,
    type: "number",
    flex: 0.5,
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "issue_date",
    headerName: "ISSUE DATE",
    editable: false,
    flex: 1,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "expiry_date",
    headerName: "EXPIRY DATE",
    editable: false,
    flex: 1,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "last_payment",
    headerName: "LAST PAYMENT",
    editable: false,
    flex: 1,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "billed_2023",
    headerName: "BILLED IN 2023",
    editable: false,
    flex: 1,
    type: "number",
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "paid_2023",
    headerName: "PAID IN 2023",
    editable: false,
    flex: 1,
    type: "number",
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "billed_2024",
    headerName: "BILLED IN 2024",
    editable: false,
    flex: 1,
    type: "number",
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "paid_2024",
    headerName: "PAID IN 2024",
    editable: false,
    flex: 1,
    type: "number",
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
];

const transactionsColumn: GridColDef[] = [
  {
    field: "name",
    headerName: "NAME",
    editable: false,
    flex: 1,
    filterable: false,
    valueGetter: ({ value }) => value.value,
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    editable: false,
    flex: 1,
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "payment_method",
    headerName: "PAYMENT METHOD",
    editable: false,
    flex: 1,
    filterable: false,
  },
  // TODO use when we have utility reports
  // {
  //   field: "object_type",
  //   headerName: "Entitiy",
  //   editable: false,
  //   flex: 1,
  //   filterable: false,
  // },
  {
    field: "created_at",
    headerName: "TRANSACTION DATE",
    editable: false,
    flex: 1,
    type: "date",
    valueFormatter: (params) => {
      const dateObj = new Date(params.value);
      return dateObj.toLocaleString(); // Format date and time
    },
  },
  {
    field: "transaction_number",
    headerName: "TRANSACTION NUMBER",
    editable: false,
    flex: 1,
  },
];

export const StyledPopover = styled(Popover)(
  `
    border: 0px;
    .MuiPaper-root {
      border-radius: 20px;
    }
    `
);

export const StyledFormControlLabel = styled(FormControlLabel)(
  `
  background: transparent;
  :hover {
    background: transparent;
  }
    `
);

const Reporting = () => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoadng] = useState(false);
  const [selectedValue, setSelectedValue] = useState("businessLicence");
  const [residencyValue, setResidencyValue] = useState("");
  const [paymentMethodValue, setPaymentMethodValue] = useState("");
  const [homebasedValue, setHomebasedValue] = useState("");
  const [balanceValue, setBalanceValue] = useState("all");
  const [statusValue, setStatusValue] = useState<any>([]);
  const [tagsValue, setTagsValue] = useState<string[]>([]);
  const { handleError } = useContext(NotificationContext) || {};

  const [filters, setFilters] = useState({
    total: true,
    sortBy: "lastUpdated",
    ascending: false,
    filters: {},
    balance: balanceValue,
    offset: 0,
    limit: 1000000,
  });
  const [refresh, setRefresh] = useState(false);
  const [reportType, setReportType] = useState("all");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      balance: balanceValue,
      tags: tagsValue,
      filters: {
        is_resident: residencyValue,
        is_homebased: homebasedValue,
        payment_method: paymentMethodValue,
        ...(statusValue.length === 0 ? {} : { status: statusValue }),
      },
    }));
    setRefresh(!refresh);
    //eslint-disable-next-line
  }, [
    residencyValue,
    homebasedValue,
    statusValue,
    balanceValue,
    tagsValue,
    paymentMethodValue,
  ]);

  function CustomToolbar(type: string) {
    const handlePaymentMethodChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setPaymentMethodValue("");
        return;
      }
      setPaymentMethodValue(event.target.value as string);
    };
    const handleResidencyChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setResidencyValue("");
        return;
      }
      setResidencyValue(event.target.value as string);
    };

    const handleHomebasedChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setTagsValue([]);
        setHomebasedValue("");
        return;
      }
      if (event.target.value === "secondary") {
        setTagsValue(["secondary"]);
        setHomebasedValue("");
        return;
      }
      setTagsValue([]);
      setHomebasedValue(event.target.value as string);
    };

    const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setStatusValue([]);
        return;
      } else if (event.target.value === "active") {
        setStatusValue(["active"]);
        return;
      } else if (event.target.value === "issued") {
        setStatusValue(["issued"]);
        return;
      } else if (event.target.value === "renewing") {
        setStatusValue(["renewing"]);
        return;
      } else if (event.target.value === "inactive") {
        setStatusValue(["expired", "revoked", "cancelled", "declined"]);
        return;
      }

      setStatusValue(event.target.value as any);
    };

    const handleBalanceChange = (event: SelectChangeEvent<unknown>) => {
      setBalanceValue(event.target.value as string);
    };

    const getLocationValue = () => {
      if (tagsValue.length !== 0) {
        return "secondary";
      }
      return homebasedValue === "" ? "Home-based" : homebasedValue;
    };

    return selectedValue === "transaction" ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <PaymentsOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={
                paymentMethodValue === ""
                  ? "Payment Method"
                  : paymentMethodValue
              }
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handlePaymentMethodChange}
            >
              <MenuItem disabled value="Payment Method">
                Payment Method
              </MenuItem>
              <MenuItem value={"CC"}>CC</MenuItem>
              <MenuItem value={"MAN"}>MAN</MenuItem>
              <MenuItem value={"IMP"}>IMP</MenuItem>
              <MenuItem value={"any"}>any</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <GridToolbarFilterButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <GridToolbarExport
          style={{
            color: PRIMARY_200,
          }}
          csvOptions={{
            fileName:
              selectedValue === "transaction"
                ? "Cocoflo Business Transaction Report"
                : "Cocoflo Business Licences Report",
          }}
        />
      </GridToolbarContainer>
    ) : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonPinOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={residencyValue === "" ? "Residency" : residencyValue}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handleResidencyChange}
            >
              <MenuItem disabled value="Residency">
                Residency
              </MenuItem>
              <MenuItem value={"true"}>Resident</MenuItem>
              <MenuItem value={"false"}>Non-resident</MenuItem>
              <MenuItem value={"any"}>any</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationOnOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={getLocationValue()}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handleHomebasedChange}
            >
              <MenuItem disabled value="Home-based">
                Location
              </MenuItem>
              <MenuItem value={"true"}>Homebased</MenuItem>
              <MenuItem value={"false"}>Storefront</MenuItem>
              <MenuItem value={"secondary"}>Secondary</MenuItem>
              <MenuItem value={"any"}>any</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <CheckBoxOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={
                statusValue.length === 0
                  ? "Status"
                  : statusValue.length > 1
                    ? "inactive"
                    : statusValue
              }
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handleStatusChange}
            >
              <MenuItem disabled value="Status">
                Status
              </MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"issued"}>Issued</MenuItem>
              <MenuItem value={"renewing"}>Renewing</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
              <MenuItem value={"any"}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <AttachMoneyOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={balanceValue === "all" ? "balance" : balanceValue}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handleBalanceChange}
            >
              <MenuItem disabled value="balance">
                Balance
              </MenuItem>
              <MenuItem value={"paid"}>Paid</MenuItem>
              <MenuItem value={"unpaid"}>Unpaid</MenuItem>
              <MenuItem value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <GridToolbarFilterButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <GridToolbarExport
          style={{
            color: PRIMARY_200,
          }}
          csvOptions={{
            fileName: "Cocoflo Business Licences Report",
          }}
        />
      </GridToolbarContainer>
    );
  }

  // TODO leave to be reused
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const fetchData = async () => {
    setDataLoadng(true);
    let summaries;
    if (selectedValue === "utility") {
      summaries = await getAccountReports();
    } else if (selectedValue === "transaction") {
      summaries = await getTransactionReports(filters);
    } else {
      summaries = await getLicenceReports(reportType, filters);
    }
    setData(summaries);
    setDataLoadng(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchData();
      } catch (error) {
        handleError("Something went wrong.");
      }
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, reportType, refresh]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginLeft: "3rem",
        marginRight: "3rem",
        marginTop: "2rem",
        marginBottom: "auto",
      }}
    >
      <div style={{ display: "flex" }}>
        <SummarizeOutlined style={{ width: "35px", height: "45px" }} />
        <Typography
          fontSize={"32px"}
          fontWeight={500}
          style={{ marginLeft: "0.5rem", marginTop: "0.75rem" }}
        >
          Reports
        </Typography>
      </div>
      <div>
        <Chip
          label="All Licences"
          color={reportType === "all" ? "success" : "primary"}
          variant={reportType !== "all" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("all");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Unpaid 2023"
          color={reportType === "unpaid2023" ? "success" : "primary"}
          variant={reportType !== "unpaid2023" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("unpaid2023");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Unpaid 2024"
          color={reportType === "unpaid2024" ? "success" : "primary"}
          variant={reportType !== "unpaid2024" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("unpaid2024");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Early 2024 Paid"
          color={reportType === "early2024" ? "success" : "primary"}
          variant={reportType !== "early2024" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("early2024");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Transactions"
          color={reportType === "transaction" ? "success" : "primary"}
          variant={reportType !== "transaction" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("transaction");
            setSelectedValue("transaction");
          }}
          style={{ margin: "5px" }}
        />
      </div>
      <div
        style={{
          marginTop: "2rem",
          border: `1px solid ${borderColor}`,
          borderRadius: "10px",
        }}
      >
        {/* /// TODO leave this feature for future */}
        {/* <StyledButton startIcon={<ViewModuleIcon />} onClick={handleClick}>
          MODULE
        </StyledButton> */}
        {dataLoading ? (
          <Skeleton variant={"rectangular"} height={500} />
        ) : (
          <DataGrid
            loading={dataLoading}
            rows={data ? data : []}
            columns={
              selectedValue === "transaction" ? transactionsColumn : columns
            }
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  billed_2023: false,
                  paid_2023: false,
                  billed_2024: false,
                  paid_2024: false,
                },
              },
            }}
            pageSizeOptions={[10, 25]}
            disableRowSelectionOnClick
            style={{ borderColor: "transparent" }}
            slots={{ toolbar: CustomToolbar }}
            // filterModel={filterModel}
            localeText={{
              toolbarFilters: "Time",
            }}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        )}
      </div>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ width: "200px", padding: "0.5rem" }}>
          <RadioGroup
            aria-label="options"
            name="options"
            value={selectedValue}
            onChange={handleChange}
          >
            <StyledFormControlLabel
              value="businessLicence"
              control={<Radio />}
              label="Business Licence"
            />
            <StyledFormControlLabel
              value="utility"
              control={<Radio />}
              label="Utility"
            />
          </RadioGroup>
        </div>
      </StyledPopover>
    </div>
  );
};

export default Reporting;
