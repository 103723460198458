export const CanadaProvinces = [
  { label: "Alberta", abbrev: "AB" },
  { label: "British Columbia", abbrev: "BC" },
  { label: "Manitoba", abbrev: "MB" },
  { label: "New Brunswick", abbrev: "NB" },
  { label: "Newfoundland and Labrador", abbrev: "NL" },
  { label: "Northwest Territories", abbrev: "NT" },
  { label: "Nova Scotia", abbrev: "NS" },
  { label: "Nunavut", abbrev: "NU" },
  { label: "Ontario", abbrev: "ON" },
  { label: "Prince Edward Island", abbrev: "PE" },
  { label: "Quebec", abbrev: "QC" },
  { label: "Saskatchewan", abbrev: "SK" },
  { label: "Yukon", abbrev: "YT" },
];

export const UsStates = [
  { label: "Alabama", abbrev: "AL" },
  { label: "Alaska", abbrev: "AK" },
  { label: "Arizona", abbrev: "AZ" },
  { label: "Arkansas", abbrev: "AR" },
  { label: "California", abbrev: "CA" },
  { label: "Colorado", abbrev: "CO" },
  { label: "Connecticut", abbrev: "CT" },
  { label: "Delaware", abbrev: "DE" },
  { label: "Florida", abbrev: "FL" },
  { label: "Georgia", abbrev: "GA" },
  { label: "Hawaii", abbrev: "HI" },
  { label: "Idaho", abbrev: "ID" },
  { label: "Illinois", abbrev: "IL" },
  { label: "Indiana", abbrev: "IN" },
  { label: "Iowa", abbrev: "IA" },
  { label: "Kansas", abbrev: "KS" },
  { label: "Kentucky", abbrev: "KY" },
  { label: "Louisiana", abbrev: "LA" },
  { label: "Maine", abbrev: "ME" },
  { label: "Maryland", abbrev: "MD" },
  { label: "Massachusetts", abbrev: "MA" },
  { label: "Michigan", abbrev: "MI" },
  { label: "Minnesota", abbrev: "MN" },
  { label: "Mississippi", abbrev: "MS" },
  { label: "Missouri", abbrev: "MO" },
  { label: "Montana", abbrev: "MT" },
  { label: "Nebraska", abbrev: "NE" },
  { label: "Nevada", abbrev: "NV" },
  { label: "New Hampshire", abbrev: "NH" },
  { label: "New Jersey", abbrev: "NJ" },
  { label: "New Mexico", abbrev: "NM" },
  { label: "New York", abbrev: "NY" },
  { label: "North Carolina", abbrev: "NC" },
  { label: "North Dakota", abbrev: "ND" },
  { label: "Ohio", abbrev: "OH" },
  { label: "Oklahoma", abbrev: "OK" },
  { label: "Oregon", abbrev: "OR" },
  { label: "Pennsylvania", abbrev: "PA" },
  { label: "Rhode Island", abbrev: "RI" },
  { label: "South Carolina", abbrev: "SC" },
  { label: "South Dakota", abbrev: "SD" },
  { label: "Tennessee", abbrev: "TN" },
  { label: "Texas", abbrev: "TX" },
  { label: "Utah", abbrev: "UT" },
  { label: "Vermont", abbrev: "VT" },
  { label: "Virginia", abbrev: "VA" },
  { label: "Washington", abbrev: "WA" },
  { label: "West Virginia", abbrev: "WV" },
  { label: "Wisconsin", abbrev: "WI" },
  { label: "Wyoming", abbrev: "WY" },
];

export const ProvinceStates = [
  { label: "Alabama", abbrev: "AL" },
  { label: "Alaska", abbrev: "AK" },
  { label: "Arizona", abbrev: "AZ" },
  { label: "Arkansas", abbrev: "AR" },
  { label: "California", abbrev: "CA" },
  { label: "Colorado", abbrev: "CO" },
  { label: "Connecticut", abbrev: "CT" },
  { label: "Delaware", abbrev: "DE" },
  { label: "Florida", abbrev: "FL" },
  { label: "Georgia", abbrev: "GA" },
  { label: "Hawaii", abbrev: "HI" },
  { label: "Idaho", abbrev: "ID" },
  { label: "Illinois", abbrev: "IL" },
  { label: "Indiana", abbrev: "IN" },
  { label: "Iowa", abbrev: "IA" },
  { label: "Kansas", abbrev: "KS" },
  { label: "Kentucky", abbrev: "KY" },
  { label: "Louisiana", abbrev: "LA" },
  { label: "Maine", abbrev: "ME" },
  { label: "Maryland", abbrev: "MD" },
  { label: "Massachusetts", abbrev: "MA" },
  { label: "Michigan", abbrev: "MI" },
  { label: "Minnesota", abbrev: "MN" },
  { label: "Mississippi", abbrev: "MS" },
  { label: "Missouri", abbrev: "MO" },
  { label: "Montana", abbrev: "MT" },
  { label: "Nebraska", abbrev: "NE" },
  { label: "Nevada", abbrev: "NV" },
  { label: "New Hampshire", abbrev: "NH" },
  { label: "New Jersey", abbrev: "NJ" },
  { label: "New Mexico", abbrev: "NM" },
  { label: "New York", abbrev: "NY" },
  { label: "North Carolina", abbrev: "NC" },
  { label: "North Dakota", abbrev: "ND" },
  { label: "Ohio", abbrev: "OH" },
  { label: "Oklahoma", abbrev: "OK" },
  { label: "Oregon", abbrev: "OR" },
  { label: "Pennsylvania", abbrev: "PA" },
  { label: "Rhode Island", abbrev: "RI" },
  { label: "South Carolina", abbrev: "SC" },
  { label: "South Dakota", abbrev: "SD" },
  { label: "Tennessee", abbrev: "TN" },
  { label: "Texas", abbrev: "TX" },
  { label: "Utah", abbrev: "UT" },
  { label: "Vermont", abbrev: "VT" },
  { label: "Virginia", abbrev: "VA" },
  { label: "Washington", abbrev: "WA" },
  { label: "West Virginia", abbrev: "WV" },
  { label: "Wisconsin", abbrev: "WI" },
  { label: "Wyoming", abbrev: "WY" },
  { label: "Alberta", abbrev: "AB" },
  { label: "British Columbia", abbrev: "BC" },
  { label: "Manitoba", abbrev: "MB" },
  { label: "New Brunswick", abbrev: "NB" },
  { label: "Newfoundland and Labrador", abbrev: "NL" },
  { label: "Northwest Territories", abbrev: "NT" },
  { label: "Nova Scotia", abbrev: "NS" },
  { label: "Nunavut", abbrev: "NU" },
  { label: "Ontario", abbrev: "ON" },
  { label: "Prince Edward Island", abbrev: "PE" },
  { label: "Quebec", abbrev: "QC" },
  { label: "Saskatchewan", abbrev: "SK" },
  { label: "Yukon", abbrev: "YT" },
];

export const abbreviator = (fullName: string, country: string) => {
  let searchArray = CanadaProvinces;
  if (country === "US") {
    searchArray = UsStates;
  }
  let retVal = fullName;
  searchArray.forEach((sub) => {
    if (sub.label === fullName) {
      retVal = sub.abbrev;
    }
  });
  if (retVal === fullName) {
    console.log("bad full name: " + fullName);
  }
  return retVal;
};

export const abbreviatorUsCanada = (fullName: string) => {
  const searchArray = ProvinceStates;

  let retVal = fullName;
  searchArray.forEach((sub) => {
    if (sub.label === fullName) {
      retVal = sub.abbrev;
    }
  });
  if (retVal === fullName) {
    console.log("bad full name: " + fullName);
  }
  return retVal;
};
