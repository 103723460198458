import React from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Typography from "./typography";

const Container = styled.div`
  position: relative;
`;

const ShowCharCount = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
    font-size: 0.9rem;
    position: absolute;
    right: 15px;
  }
`;

/**
 * Text area using mui's TextField
 * @param value
 * @param label
 * @param rows
 * @param fullWidth
 * @param maxLength - add this prop to enable char count
 * @param error
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TextArea({
  value,
  label = "",
  rows = 3,
  fullWidth = true,
  maxLength,
  error,
  ...props
}) {
  return (
    <Container>
      <TextField
        {...props}
        error={error}
        value={value}
        label={label}
        multiline={true}
        fullWidth={fullWidth}
        inputProps={{ maxLength: maxLength }}
        InputProps={{
          style: {
            fontWeight: 700,
            paddingBottom: 30,
            paddingTop: 30,
          },
          disableUnderline: true,
        }}
      />
      {maxLength && (
        <ShowCharCount style={{ bottom: error ? 30 : 10 }}>
          {value?.length} / {maxLength}
        </ShowCharCount>
      )}
    </Container>
  );
}
