import React, { useState, useRef, useEffect } from "react";
import { primaryTint } from "../../../../mui-theme/theme";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import classes from "./index.module.scss";

const PASSWORD = "password";
const TEXT = "text";

const TextFieldWithShowPassword = (props) => {
  const { customOnChange, showRequirementsOnFocus, ...filteredProps } = props;
  const { disabled, form, field, InputProps, inputProps, required, type } =
    props;
  const [passwordType, setPasswordType] = useState(PASSWORD);
  const inputRef = useRef();

  const name = field.name;
  const isDisabled = form.isSubmitting || disabled;
  const isPassword = type === PASSWORD;
  const showPassword = passwordType === TEXT;
  const error = form.errors[name];
  const touched = form.touched[name];

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleBlur = () => {
    form.setFieldTouched(name, true);
  };

  const toggleShowPassword = () => {
    setPasswordType((prev) => {
      return prev === PASSWORD ? TEXT : PASSWORD;
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //TODO: double check if styles are passed correctly
  return (
    <TextField
      className={classes.text_field}
      {...filteredProps}
      disabled={isDisabled}
      required={required}
      error={Boolean(touched && error)}
      type={isPassword ? passwordType : type}
      fullWidth
      inputRef={inputRef}
      onBlur={handleBlur}
      onChange={(e) => {
        if (customOnChange) customOnChange(e.target.value);
        form.setFieldValue(name, e.target.value);
      }}
      onFocus={showRequirementsOnFocus && handleBlur}
      inputProps={{
        autoComplete: isPassword ? PASSWORD : null,
        ...inputProps,
      }}
      InputProps={{
        endAdornment: isPassword && (
          <InputAdornment position="end">
            <Tooltip title="Show/hide password">
              <IconButton
                size="small"
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ color: primaryTint }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
};

export default TextFieldWithShowPassword;
