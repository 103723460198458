import React from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { css } from "@mui/material";
import { borderColor } from "../../../../mui-theme/theme";
import Typography from "../typography";

const StyledTextArea = styled(TextField)`
  && {
    margin: ${({ margin }) => margin || "0 0 10px 0"};
  }

  && * {
    font-family: ${({ theme }) => theme.fontRegular};
  }

  .MuiFilledInput-root {
    border: 1px solid ${borderColor};
    background: #fff;
    border-radius: 10px;
    min-width: 7rem;
    width: ${({ width }) => width};
  }

  .MuiFilledInput-root.Mui-disabled,
  .MuiFilledInput-root.Mui-disabled:hover {
    /* background: #f2f6fb; */

    .MuiFilledInput-root {
      opacity: 0.5;
    }

    /* Greys/Grey – Light */
    background: #f4f4f4;
    /* Outline */
    border: 1px solid #ccd6dc;
    box-sizing: border-box;
    border-radius: 10px;

    .MuiFilledInput-input,
    .MuiFilledInput-multiline {
      /* Body 1 Bold */
      font-family: Rubik, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height, or 130% */
      /* Greys/Grey – Dark */
      color: #b1b1b1 !important;
    }
  }

  .MuiFilledInput-root:hover {
    background: #fff;
  }

  .MuiFilledInput-root.Mui-error {
    border: 1px solid #f44336;
  }

  .MuiFilledInput-root.Mui-focused {
    background: #fff;
    border: 2px solid #0c55b5;
    box-shadow: ${({ theme }) => theme.buttonShadow2};
    margin: -1px;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.textSecondary};
    font-weight: bold;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.textSecondary};
  }

  .MuiFilledInput-input,
  .MuiFilledInput-multiline {
    padding: ${({ label }) => (label ? `27px 1rem 10px 1rem` : `1rem`)};
    color: ${({ theme }) => theme.textColor};
  }

  .MuiFilledInput-inputMultiline {
    padding: 0;
  }

  .MuiFilledInput-input:-webkit-autofill {
  }

  .MuiFilledInput-underline:before {
    display: none;
  }

  .MuiFilledInput-underline:after {
    display: none;
  }

  .MuiFilledInput-underline.Mui-disabled:before {
    display: none;
  }

  .MuiInputLabel-asterisk {
    display: none;
  }

  && .MuiList-root {
    border-radius: 10px;
  }
`;

const Container = styled.div`
  position: relative;
`;

const ShowCharCount = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.blacks.BLACK_LOW_EMPHASIS};
    font-size: 0.9rem;

    position: absolute;

    right: 8px;
    bottom: 12px;
  `
);

/**
 * Text area using mui's TextField
 * @param value
 * @param label
 * @param rows
 * @param fullWidth
 * @param maxLength - add this prop to enable char count
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TextArea({
  value,
  label = "",
  rows = 3,
  fullWidth = true,
  maxLength,
  ...props
}) {
  return (
    <Container>
      <StyledTextArea
        {...props}
        value={value}
        label={label}
        multiline={true}
        rows={rows}
        fullWidth={fullWidth}
        inputProps={{ maxLength }}
        InputProps={{ style: { fontWeight: 700, paddingBottom: 22 } }}
      />
      {maxLength && (
        <ShowCharCount>
          {value.length} / {maxLength}
        </ShowCharCount>
      )}
    </Container>
  );
}
