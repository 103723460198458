import React, { useContext, useEffect, useState } from "react";
import WarningAlert from "../../widgets/warning-alert";
import Grid from "@mui/material/Unstable_Grid2";
import DetailsContainer from "../../widgets/details-container";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import classes from "../../index.module.scss";
import BarGraph from "../../../../../../components/ui-kit/bar-graph";
import ListItem from "../../../../../../components/ui-kit/list-item";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import AccountFormModal from "../../widgets/account-form-modal";
import ContactFormModal from "../../widgets/contacts-form-modal";
import { IFormField } from "../../../../../../../core/interfaces/utility/IFormField";
import { IContact } from "../../interfaces/IContact";
import IAccountFormValues from "../../interfaces/IAccountFormValues";
import { formatPhoneNumber } from "../../../../../../../utilities/masked-input-formatters";
import {
  CallOutlined,
  Done,
  ForwardToInboxOutlined,
  HomeOutlined,
  LocalPostOfficeOutlined,
  LocationOnOutlined,
  PersonOutline,
  Tag,
} from "@mui/icons-material";
import {
  createAccountChangeSet,
  getAccountStatus,
  update_account,
  updateAccountChangeSet,
  updateAccountChangeSetStatus,
} from "../../../../../../../core/apis/account";
import {
  REVIEWING_GROUP,
  SUBMITTED,
} from "../../../../../../../core/constants/licences";
import ShareEBill from "../../../../../../components/ui-kit/ShareEBill";
import { NotificationContext } from "ui/contexts/NotificationContext";
import { DRAFT } from "core/constants/statuses";
import { abbreviatorUsCanada } from "core/constants/province-states";
import UtilityStatusCard from "ui/components/ui-kit/admin-utility-status-card";
import moment from "moment";

const accountDetailsItems = [
  {
    name: "account_number",
    icon: <Tag />,
  },
  {
    name: "access_code",
    icon: <Done />,
  },
  {
    name: "account_holder",
    icon: <PersonOutline />,
  },
  {
    name: "service_address",
    icon: <LocationOnOutlined />,
  },
  {
    name: "mailing_address",
    icon: <ForwardToInboxOutlined />,
  },
  {
    name: "phone_number",
    icon: <CallOutlined />,
  },
  {
    name: "billing_method",
    icon: <LocalPostOfficeOutlined />,
  },
  {
    name: "is_owner",
    icon: <HomeOutlined />,
  },
];

const initialDetails: IAccountFormValues = {
  account_holder: "",
  country: "",
  address1: "",
  address2: "",
  city: "",
  province_state: "",
  postal_zip: "",
  phone_number: "",
  is_owner: true,
  mailing_same_as_service_address: false,
  billing_method: "",
};

/**
 * Info tab for the utilities details screen
 * @constructor
 */
const InfoTab = ({
  id = "0",
  accessor = (el: string): IFormField => {
    return {} as IFormField;
  },
  loading = false,
  showReviewWarning = false,
  changeSetStatus = undefined,
  changeSetId = "",
  refresher,
}: {
  id?: string;
  accessor?: (el: string) => IFormField;
  loading?: boolean;
  showReviewWarning?: boolean;
  changeSetStatus?: any;
  changeSetId?: string;
  refresher: any;
}) => {
  const history = useHistory();
  const [openEditAccountModal, setOpenEditAccountModal] = useState(false);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [contactItems, setContactItems] = useState<IContact[]>([]);
  const [waterConsumptionData, setWaterConsumptionData] = useState<any[]>([]);
  const { handleError } = useContext(NotificationContext) || {};
  const [editableInfo, setEditableInfo] =
    useState<IAccountFormValues>(initialDetails);

  useEffect(() => {
    const contacts = accessor("other_contacts");
    if (contacts?.value !== undefined && contacts.value.value !== "-") {
      setContactItems(contacts.value.value || []);
    }
    const consumption = accessor("consumption");
    if (consumption?.value !== undefined && consumption.value.value !== "-") {
      let _data = [
        ...[consumption?.value?.value?.latest ?? null],
        ...(consumption?.value?.value?.previous ?? []),
      ]
        .sort((a, b) => {
          if (moment(a?.periodEnd).isAfter(b?.periodEnd)) return 1;
          if (!moment(a?.periodEnd).isAfter(b?.periodEnd)) return -1;
          return 0;
        })
        .reduce((prev, current) => {
          if (prev[current?.["periodEnd"]] === undefined)
            prev[current?.["periodEnd"]] = 0;
          prev[current?.["periodEnd"]] =
            (current?.amount ?? 0) + (prev[current?.["periodEnd"]] ?? 0);
          return prev;
        }, {});
      let __data: any = {};
      for (const key in _data) {
        const formattedKey = moment(key).format("MMM 'YY");
        __data[formattedKey] = _data[key];
      }
      setWaterConsumptionData(__data);
    }
  }, [accessor]);

  const handleServiceWarningClick = () => {
    history.push(`/utility/${id}#${1}`);
  };

  const createChangeShape = (values: any) => {
    let updates = [];
    const address = accessor("mailing_address");
    const name = accessor("account_holder");
    const owner = accessor("is_owner");
    const phoneNumber = accessor("phone_number");
    const billingMethod = accessor("billing_method");
    const contacts = accessor("other_contacts");
    if (
      values.hasOwnProperty("account_holder") &&
      name.value.value !== values.account_holder
    ) {
      updates.push({
        id: name.id,
        value: { value: values.account_holder },
      });
    }
    if (
      values.hasOwnProperty("is_owner") &&
      owner.value.value !== values.is_owner
    ) {
      updates.push({
        id: owner.id,
        value: { value: values.is_owner.value },
      });
    }

    if (
      values.hasOwnProperty("phone_number") &&
      phoneNumber.value.value !== values.phone_number
    ) {
      updates.push({
        id: phoneNumber.id,
        value: { value: values.phone_number.replace(/[^a-zA-Z0-9]/g, "") },
      });
    }

    if (values.hasOwnProperty("other_contacts")) {
      updates.push({
        id: contacts.id,
        value: { value: values.other_contacts },
      });
    }

    if (
      values.hasOwnProperty("billing_method") &&
      billingMethod.value.value !== values.billing_method
    ) {
      updates.push({
        id: billingMethod.id,
        value: { value: values.billing_method.value },
      });
    }

    let {
      firstName,
      lastName,
      isOwner,
      mailing_same_as_service_address,
      phone,
      other_contacts,
      ...rest
    } = values;

    if (
      !Object.entries(rest).every(
        ([key, value]) => address.value.value[key] === value
      )
    ) {
      updates.push({
        id: address.id,
        value: { value: rest },
      });
    }

    return updates;
  };

  const onSubmitChanges = async (
    values: any,
    { resetForm }: any,
    skipAction = false
  ) => {
    const updateData = createChangeShape(values);
    if (skipAction) {
      await update_account(id, updateData);
    } else {
      const changeStatus = String(await getStatus());
      if (changeStatus === DRAFT || changeStatus === SUBMITTED) {
        const changeId = changeSetId;

        // TODO keep the code for now figure out why we needed to create a change set in 2 places
        // if (!changeId) {
        //   const resultChangeSet = (await createAccountChangeSet(
        //     id,
        //     "utility_account/edit_info"
        //   )) as any;
        //   changeId = resultChangeSet.data.id;
        // }

        await updateAccountChangeSet(id, changeId, updateData, "");
        if (!REVIEWING_GROUP.includes(changeSetStatus)) {
          await updateAccountChangeSetStatus(
            id,
            changeId,
            SUBMITTED,
            "",
            skipAction
          );
        }
      } else {
        handleError(
          "This licence is being reviewed, unable to submit more changes"
        );
      }
    }
    setOpenEditAccountModal(false);
    setOpenEditContactModal(false);
    resetForm();
    populateEditableInfo();
    refresher();
  };

  const decorateValue = (name: string, value: any) => {
    switch (name) {
      case "account_number":
        return value.replace(/(\d{3})(\d{7})(\d{3})/, "$1 $2 $3");
      case "mailing_address":
      case "service_address":
        if (value.address1 === undefined) return value;
        else
          return `${value?.address1 || ""}\n${value?.city || ""} ${
            abbreviatorUsCanada(value?.province_state) || ""
          } ${value?.postal_zip || ""}`;
      case "phone_number":
        return formatPhoneNumber(value);
      case "is_owner":
        return value ? "I'm the owner" : "I'm a tenant";
    }
    return value;
  };

  const onEditDetails = async () => {
    const changeStatus = String(await getStatus());
    if (!changeSetId || changeStatus === DRAFT || changeStatus === SUBMITTED) {
      try {
        if (!changeSetId) {
          await createAccountChangeSet(id, "utility_account/edit_info");
        }
        refresher();
      } finally {
        populateEditableInfo();
        setOpenEditAccountModal(true);
      }
    } else {
      handleError("This licence is being reviewed, editing distabled");
      refresher();
    }
  };

  const getStatus = async () => {
    const statuses = await getAccountStatus(id);
    return statuses?.infoChangesStatus;
  };

  const populateEditableInfo = () => {
    let info = initialDetails;
    info.account_holder = accessor("account_holder").value.value;
    info.phone_number = accessor("phone_number").value.value;
    info.billing_method = accessor("billing_method").value.value;
    const address = accessor("mailing_address");
    if (address !== undefined) {
      info = {
        ...info,
        ...address.value.value,
      };
    }
    setEditableInfo(info);
  };

  const getOriginalValue = (value: any, name = "") => {
    if (value !== undefined) {
      if (name === "phone_number") {
        return decorateValue("phone_number", value);
      } else if (name === "is_owner") {
        return decorateValue("is_owner", value);
      }
      if (value.city) {
        return decorateValue("service_address", value);
      } else {
        return value;
      }
    }
    return undefined;
  };

  return (
    <>
      <p className={typographyClasses.h1}>Utilities Information</p>
      <Spacer />
      {showReviewWarning ? (
        <WarningAlert onClick={handleServiceWarningClick} />
      ) : (
        <Spacer />
      )}
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={6}>
          <DetailsContainer
            loading={loading}
            title={"Account Details"}
            buttonLabel={"Edit details"}
            buttonOnClick={() => onEditDetails()}
            buttonDisable={
              REVIEWING_GROUP.includes(changeSetStatus) &&
              changeSetStatus !== SUBMITTED
            }
          >
            <UtilityStatusCard
              isActive={true}
              linkedDate={accessor("linked_date").value.value}
              changeDate={accessor("info_change_set_creation_date")}
              prtl={true}
              isLinked={true}
              hasChanges={
                changeSetStatus !== undefined && changeSetStatus !== DRAFT
              }
            />
            {accountDetailsItems.map((item) => {
              const field = accessor(item.name);
              return (
                //@ts-ignore
                <ListItem
                  key={field.id}
                  variant="detail"
                  label={field.label}
                  subLabel={decorateValue(field.name, field.value.value)}
                  originalValue={getOriginalValue(
                    field?.originalValue?.value,
                    field.name
                  )}
                  site={"portal"}
                  startOrnament={item.icon}
                />
              );
            })}
          </DetailsContainer>
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <div className={classes.right_column}>
            {!loading ? (
              <>
                <p className={typographyClasses.h2}>
                  Water Consumption History
                </p>
                <BarGraph
                  data={waterConsumptionData}
                  title={"VOLUME (m³)"}
                  labels={Object.keys(waterConsumptionData)}
                  bottomTitle={""}
                />
                <Spacer />
              </>
            ) : (
              <Skeleton
                variant={"rectangular"}
                style={{ borderRadius: 10 }}
                height={290}
              />
            )}
            <Spacer />
            <ShareEBill
              setOpenEditContactModal={setOpenEditContactModal}
              contactItems={contactItems}
              loading={loading}
            />
          </div>
        </Grid>
      </Grid>
      <AccountFormModal
        open={openEditAccountModal}
        handleClose={() => {
          setOpenEditAccountModal(false);
          refresher();
        }}
        helperValues={{
          serviceAddress: accessor("service_address").value.value,
        }}
        onSubmit={onSubmitChanges}
        initValues={editableInfo}
      />
      <ContactFormModal
        open={openEditContactModal}
        handleClose={() => setOpenEditContactModal(false)}
        onSubmit={onSubmitChanges}
        values={contactItems}
      />
    </>
  );
};

export default InfoTab;
