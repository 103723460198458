import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { Box, Menu, MenuItem, useTheme } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import PlaceholderImg from "../../../assets/images/document.png";
import { roundMb } from "../../../utilities";
import IconButton from "@mui/material/IconButton";
import { MoreVert, UploadRounded } from "@mui/icons-material";

const Container = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    width: 100%;
    padding: 10px 5px 10px 0;
    border-radius: 10px;
    align-items: unset;
    justify-content: unset;

    &.Mui-focusVisible {
      background-color: ${({ theme }) => theme.palette.whites.WHITE};
      outline: 3px solid ${({ theme }) => theme.palette.primary[200]};
      box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);

      & .MuiTypography-body1 {
        color: ${({ theme }) => theme.palette.primary[200]};
      }

      & .material-symbols-rounded {
        color: ${({ theme }) => theme.palette.primary[200]};
      }
    }
  }

  & .MuiTypography-body1 {
    color: ${({ theme }) => theme.palette.primary[200]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary[50]};

    & .material-symbols-rounded {
      color: ${({ theme }) => theme.palette.primary[500]};
    }
  }
`;

const AttachedContainer = styled(Box)`
  & {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 10px 0;
    border-radius: 10px;

    &.Mui-focusVisible {
      background-color: ${({ theme }) => theme.palette.whites.WHITE};
      outline: 3px solid ${({ theme }) => theme.palette.primary[200]};
      box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);
    }
  }

  & .MuiTypography-body1 {
    color: ${({ theme }) => theme.palette.primary[700]};
  }
`;

const IconContainer = styled(Box)`
  && .material-symbols-rounded {
    color: ${({ theme }) => theme.palette.primary[200]};
  }
`;

/**
 * Document card, displays an uploaded file and offers an onClick function that takes index as a parameter
 * @param title
 * @param subTitle
 * @param onClick
 * @param index
 * @param variant
 * @returns {JSX.Element}
 * @constructor
 */
export default function DocumentCard({
  title,
  subTitle,
  size,
  fileName,
  onClick,
  variant,
  onDelete,
  onRename,
}) {
  const theme = useTheme();

  //Used to anchor the menu to the component
  const [anchorEl, setAnchorEl] = React.useState(null);

  //Checks if the menu is open
  const open = Boolean(anchorEl);

  //Sets the anchor to the pressed component
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //Removes the anchor for the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (variant === "upload") {
    return (
      <UploadDocument title={title} subTitle={subTitle} onClick={onClick} />
    );
  }

  return (
    <AttachedContainer>
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          paddingRight: "1rem",
          textOverflow: "ellipsis",
        }}
      >
        <Box
          alignItems={"center"}
          display={"flex"}
          height={81}
          justifyContent={"center"}
          style={{
            backgroundColor: theme.palette.primary[50],
            borderRadius: 5,
          }}
          width={81}
        >
          <img
            src={PlaceholderImg}
            alt={"file attached"}
            style={{ borderRadius: 5 }}
          />
        </Box>
        <Box textAlign={"left"} overflow={"hidden"}>
          <Typography
            style={{
              marginLeft: 10,
              lineHeight: 1.3,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              fontWeight: 500,
            }}
            variant={"body1"}
          >
            {fileName}
          </Typography>
          <Typography
            style={{
              marginLeft: 10,
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
            variant={"body2"}
          >
            {roundMb(size) + " MB"}
          </Typography>
        </Box>
      </div>
      <Box display={"flex"}>
        <IconButton
          onClick={handleClick}
          style={{
            height: 50,
            width: 50,
            alignSelf: "center",
            justifySelf: "right",
            marginLeft: 5,
          }}
          size="large"
        >
          <MoreVert />
        </IconButton>
      </Box>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            onRename();
            setAnchorEl(null);
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete();
            setAnchorEl(null);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </AttachedContainer>
  );
}

const UploadDocument = ({ onClick }) => {
  const theme = useTheme();

  return (
    <Container onClick={onClick}>
      <IconContainer
        variant={"outlined"}
        alignItems={"center"}
        display={"flex"}
        height={81}
        justifyContent={"center"}
        style={{
          backgroundColor: theme.palette.primary[50],
          borderRadius: 5,
        }}
        width={81}
      >
        <UploadRounded
          color={"primary"}
          sx={{ height: "40px", width: "40px" }}
        />
      </IconContainer>
      <Typography style={{ marginLeft: 10, fontWeight: 500 }} variant={"body1"}>
        Upload document
      </Typography>
    </Container>
  );
};
