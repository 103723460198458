import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import { PRIMARY_700 } from "../../../../../mui-theme/theme";

const DataTableCell = styled(TableCell)`
  && {
    color: ${PRIMARY_700};
    border-bottom: 2px solid ${({ theme }) => theme.textColor};
  }
`;

export default DataTableCell;
