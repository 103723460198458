import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { Chip as MuiChip } from "@mui/material";
import { darken, transparentize } from "polished";
import {
  BLACK_HIGH_EMPHASIS,
  OUTLINE,
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_50,
  primaryLight,
  textColor,
  WHITE,
} from "../../../mui-theme/theme";
import { CancelRounded } from "@mui/icons-material";

const defaultPrimary = PRIMARY_200;
const primaryDark = textColor;

const StyledChip = styled(MuiChip)`
  && {
    padding: 12px 6px;
  }

  ${({ type, theme }) => {
    switch (type) {
      case "new_optional_field":
        return `
          && {
            border: 1px dashed ${OUTLINE};
            background: ${WHITE};
            color: ${BLACK_HIGH_EMPHASIS};
          }
          &&:hover {
            background: ${PRIMARY_50};
          }
          &&:focus {
            border: 1px dashed ${OUTLINE};
            background: ${WHITE};
            color: ${BLACK_HIGH_EMPHASIS};
          }
        `;
      case "active":
        return `
            && {
              background: ${PRIMARY_100};
              color: ${PRIMARY_200};
              font-weight: 500;
            }
            &&:hover {
              // background: ${PRIMARY_50};
            }
            &&:focus {
              background: ${PRIMARY_100};
              color: ${PRIMARY_200};
              font-weight: 500;
            }
          `;
      case "dotted":
        return `
            && {
              border: 2px dashed ${OUTLINE};
              transition: 0.2s;
              box-shadow: none;
              border-radius: 5px;
              background-color: ${theme.palette.whites.WHITE};
              color: ${theme.palette.blacks.BLACK_HIGH_EMPHASIS};
              font-size: 1rem;
              font-weight: 400;
              padding: 12px 0;
            }
            &&:hover {
              background: ${PRIMARY_50};
            }
            &&:focus {
              background: ${PRIMARY_50};
            }
          `;
      case "filter":
        return `
                && {
                  background: transparent;
                  color: ${BLACK_HIGH_EMPHASIS};
                  font-weight: 400;
                  border: 1px solid ${OUTLINE};
                }
                &&:hover {
                  background: ${PRIMARY_100};
                }
                &&:focus {
                  background: ${PRIMARY_100};
                  color: ${PRIMARY_200};
                  font-weight: 500;
                }
              `;
      default:
        return `
        && {
          transition: 0.2s;
          background: white;
          font-size: ${({ size }) => (size === "small" ? "0.85rem;" : "1rem;")}
          font-weight: 600;
          ${({ primarydark, primary, type }) =>
            `color: ${type === "contained" ? primary : primarydark};`}
          ${({ type, selected, primary, primarylight }) => {
            switch (type) {
              case "outlined":
                return `border: 1px solid ${selected ? primary : OUTLINE};`;
              case "contained":
                return `background:${primarylight}; border: none;`;
              default:
                return `border: none;`;
            }
          }}
          ${({ selected, primary, primarylight }) => {
            if (selected)
              return `background: ${primarylight}; color: ${primary};`;
          }}
        }
        &&:hover {
          background: ${({ primarylight }) => primarylight};
        }
        &&:focus {
          ${({ selected, primary, primarylight }) =>
            selected
              ? `background: ${primarylight}; color: ${primary};`
              : `background: none; color: none;`}
        }
        `;
    }
  }}

  && .MuiChip-deleteIcon {
    color: ${PRIMARY_200};
  }

,
`;

// This component is used in the add-bylaw section
const Chip = forwardRef(
  (
    {
      color,
      variant,
      icon,
      iconColor,
      onSelect,
      selected,
      size,
      disabled,
      onClick,
      className,
      ...rest
    },
    ref
  ) => {
    const [mouseOver, setMouseOver] = useState(false);
    const [mouseOverDeleteIcon, setMouseOverDeleteIcon] = useState(0);
    const primary = color ? color : defaultPrimary;
    const primaryDarkened = color ? darken(0.2, primary) : primaryDark;
    const primaryLightened = color
      ? transparentize(0.9, primary)
      : primaryLight;

    return (
      <StyledChip
        ref={ref}
        onClick={onClick}
        clickable={!!onClick}
        className={className}
        type={variant}
        size={size}
        primary={primary}
        selected={selected}
        primarydark={primaryDarkened}
        primarylight={primaryLightened}
        disabled={disabled}
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
        icon={icon ? icon : null}
        deleteIcon={
          <CancelRounded
            className={mouseOverDeleteIcon}
            onMouseEnter={() => {
              setMouseOverDeleteIcon(1);
            }}
            style={{
              marginLeft: "-0.3rem",
              display: mouseOver ? "inline" : "none",
            }}
          />
        }
        {...rest}
      />
    );
  }
);

export default Chip;
