import { ErrorMessage, Field } from "formik";
import classes from "./index.module.scss";
import TextFieldWithShowPassword from "../text-field-with-show-password";

/**
 * Shows errors under the password input (e.g. user create modal)
 */
export default function TextFieldWithErrorList(props) {
  return (
    <>
      <Field {...props} component={TextFieldWithShowPassword} />
      <ErrorMessage
        name={props.name}
        render={(messages) => {
          return (
            <div className={classes.errorList}>
              {typeof messages === "string"
                ? messages
                : messages.map((message) => <div>{message}</div>)}
            </div>
          );
        }}
      />
    </>
  );
}
