import React from "react";
import typographyClasses from "../../../../assets/styles/typography/typography.module.scss";
import { DialogTitle, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

const ModalTitle = ({ title = "Title", handleClose = () => {} }) => {
  return (
    <DialogTitle>
      <div className={"flex_row_space_between"}>
        <p className={typographyClasses.h2}>{title}</p>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </div>
    </DialogTitle>
  );
};

export default ModalTitle;
