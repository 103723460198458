import { LOCALE, CURRENCY } from "../core/constants/general";

export const formatCurrency = (num: any) => {
  if (num === undefined || num === null) return null;

  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: CURRENCY,
  }).format(num);
};

export const formatUtilityAccountNumber = (num: string) => {
  if (num.length !== 13) {
    return num;
  }
  return num.replace(/^(.{3})(.{7})(.{3})$/, "$1 $2 $3");
}
