const CURRENCY = "CURRENCY";
const DATE = "DATE";
const PHONE = "PHONE";
const POSTAL = "POSTAL";
const NUMBER = "NUMBER";
const CREDIT_CARD = "CREDIT_CARD";
const EXPIRY = "EXPIRY";
const ZIP = "ZIP";

const MaskedTextFieldTypes = {
  CURRENCY,
  DATE,
  PHONE,
  POSTAL,
  NUMBER,
  CREDIT_CARD,
  EXPIRY,
  ZIP,
};
export default MaskedTextFieldTypes;
