import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { forgot_password } from "../../../../core/apis/user";
import { routes } from "../../../routes/routesData";
import { AuthContext } from "../../../contexts/AuthContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import TextField from "../../../components/ui-kit/TextField";
import Button from "../../../components/ui-kit/Button";
import { default as Typography } from "@mui/material/Typography";
import { validEmail } from "../../../../utilities/yupValidators";
import { ChevronLeft, MailOutlineRounded } from "@mui/icons-material";
import classes from "./index.module.scss";
import EmailAlert from "../../../components/ui-kit/email-alert/EmailAlert";
import Spacer from "../../../components/ui-kit/Spacer";
import LinkButton from "../../../components/ui-kit/link-button";

const initialValues = {
  email: "",
};

const validation = yup.object({
  email: validEmail,
});

const CenteredText = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LowerContainer = styled.div``;

export default function Index() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { handleError } = useContext(NotificationContext);
  const [validateEmailInput, setValidationState] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState(null);
  const [emailExists, setEmailExists] = useState(true);
  useEffect(() => {
    if (user) return history.push({ pathname: "/admin/", state: null });
  }, [user, history]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await forgot_password(values.email);
      await setEmailSentTo(values.email);
    } catch (err) {
      if (err.response?.data?.message === "Admin does not exist") {
        resetForm({
          errors: {
            email:
              "This account does not exist. Please, check the spelling or contact your manager",
          },
          values: { email: values.email },
        });
        setEmailExists(!emailExists);
      } else {
        handleError(err.response?.data?.message || err?.message);
      }
    } finally {
    }
  };

  const handleResend = async () => {
    try {
      await forgot_password(emailSentTo);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    }
  };

  return (
    <div className={classes.container}>
      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
        <Button
          className={classes.back_button}
          onClick={() => {
            history.push(routes.LOGIN.path);
          }}
          icon={<ChevronLeft />}
          size="medium_icon_left"
          variant="outlined"
          top="4rem"
          left="4rem"
        >
          BACK
        </Button>
        <div className={classes.inner_container}>
          <CenteredText>
            <MailOutlineRounded
              color={"primary"}
              sx={{ height: "64px", width: "64px", marginBottom: "3rem" }}
            />
          </CenteredText>
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            Reset password
          </Typography>
          <Typography variant={"body1"} style={{ marginBottom: "1rem" }}>
            Please enter the email associated with your MyCityHall account and
            we’ll send you the steps to get you back into your account.
          </Typography>
          <LowerContainer>
            {emailSentTo ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <EmailAlert
                  sendAgain={handleResend}
                  title={"Check your email"}
                  body={
                    <>
                      <p className={"body_regular_2"}>
                        Follow the instructions we sent to <b>{emailSentTo}</b>.
                      </p>
                      <Spacer />
                      <p className={"body_regular_2"}>
                        Please check your "Spam" or "Junk" folder if you can’t
                        see the reset password email in your inbox.
                      </p>
                    </>
                  }
                />
                <Spacer amount={2} />
                <LinkButton
                  color={"blue"}
                  onClick={() => setEmailSentTo(null)}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  Change email address
                </LinkButton>
              </div>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validation}
                validateOnChange={validateEmailInput}
                validateOnBlur={validateEmailInput}
                onSubmit={handleSubmit}
              >
                {(formikProps) => {
                  return (
                    <Form noValidate>
                      <TextField
                        name="email"
                        label="Email address"
                        fullWidth
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "email",
                            e.target.value.trim()
                          );
                        }}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.email}
                        error={Boolean(formikProps.errors.email)}
                        helperText={formikProps.errors.email}
                        style={{ marginBottom: "1rem" }}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        style={{ marginTop: "0.5rem" }}
                        fullWidth
                        onClick={(e) => {
                          setValidationState(true);
                        }}
                      >
                        SEND
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </LowerContainer>
        </div>
      </div>
    </div>
  );
}
