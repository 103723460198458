import React, { useContext, useMemo, useState } from "react";
import classes from "../../index.module.scss";
import Spacer from "../../../../../components/ui-kit/Spacer";
import Typography from "@mui/material/Typography";
import ApprovalProgress from "../../../../../components/ui-kit/approval-progress";
import Button from "@mui/material/Button";
import theme from "../../../../../../mui-theme/theme";
import SearchDropDown from "../../../../../components/ui-kit/SearchDropDown";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { get_admins } from "../../../../../../core/apis/admin";
import { AddRounded } from "@mui/icons-material";
import { userHasRole } from "../../../../../../utilities";

const ApprovalSection = ({
  assignedAdmins,
  onReviewerApprove,
  onReviewerDeclined,
  onUndoReviewerAction,
  unAssignReviewer,
  utilityInfo,
  issuer,
  unAssignIssuer,
  handleAssignReviewer,
  handleAssignIssuer,
}) => {
  const { user } = useContext(AuthContext);
  const [anchorElReviewer, setAnchorElReviewer] = useState(null);
  const [anchorElIssuer, setAnchorElIssuer] = useState(null);

  const isUser = (id) => {
    return id === user.id;
  };

  const getAdmins = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    let preAssignedIds = [];
    assignedAdmins.map((element) => preAssignedIds.push(element.id));
    return adminsRes.result.filter(
        (element) => !preAssignedIds.includes(element.id) && element.roles.find(e => e.roleName === "reviewer")
    ).map(e => ({
      ...e,
      roles: e.roles.filter(t => t.roleName === "reviewer")
    }));
  };

  const disableAssignSupervisor = useMemo(() => {
    const isReviewer = userHasRole("reviewer");
    if (isReviewer) {
      return true;
    }
    if (assignedAdmins.length <= 0) return true;
    let disable = false;
    assignedAdmins.forEach((admin) => {
      if (admin.approvalStatus === "assigned") {
        disable = true;
      }
    });
    return disable;
  }, [assignedAdmins]);

  const fetchIssuers = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    return adminsRes?.result.filter(admin => admin.roles.find(e => e.roleName === "supervisor")).map(e => ({
      ...e,
      roles: e.roles.filter(t => t.roleName === "supervisor")
    }));
  };

  const openReviewerSelection = (e) => {
    e.stopPropagation();
    setAnchorElReviewer(e.currentTarget);
  };

  const handleOpenIssuerSelection = (e) => {
    e.stopPropagation();
    setAnchorElIssuer(e.currentTarget);
  };

  return (
    <>
      <div className={classes.approval_section}>
        <Spacer />
        <Typography variant={"h4"} fontWeight={400} fontSize={24}>
          Approval process
        </Typography>
        <Spacer amount={1} />
        <Typography variant={"body1"} fontWeight={700}>
          1. Review the account (
          {`${
            assignedAdmins.filter((item) => item.approvalStatus === "approved")
              .length
          }/${assignedAdmins.length}`}
          )
        </Typography>
        <Typography variant={"body1"}>
          Assign as many reviewers as necessary. At least one reviewer is
          required.
        </Typography>
        {assignedAdmins?.map((admin) => (
          <ApprovalProgress
            type="reviewer"
            id={admin.id}
            key={admin.id}
            isUser={isUser(admin.id)}
            firstName={admin.first}
            lastName={admin.last}
            approvalStatus={admin.approvalStatus}
            lastUpdated={admin.lastUpdated}
            handleApprove={(id) => onReviewerApprove(id, admin.id)}
            handleDecline={(id) => onReviewerDeclined(id, admin.id)}
            handleUndo={onUndoReviewerAction}
            handleUnassign={unAssignReviewer}
            data={utilityInfo}
          />
        ))}
        <Spacer amount={0.5} />
        <Button
          size={"small"}
          style={{ color: theme.palette.primary[200] }}
          startIcon={<AddRounded color={"primary"} />}
          onClick={openReviewerSelection}
        >
          Assign reviewer
        </Button>
        <SearchDropDown
          titleLabel="Approval Required"
          title={"Assign Reviewers:"}
          onSelect={(values) => handleAssignReviewer(values)}
          fetchData={getAdmins}
          anchorEl={anchorElReviewer}
          setAnchorEl={setAnchorElReviewer}
          searchBarPlaceholder={"Search name"}
        />
        <Spacer amount={1} />
        <Typography variant={"body1"} fontWeight={700}>
          2. Approve the account
        </Typography>
        <Typography variant={"body1"}>
          All assigned reviewers must give their approval for the changes to be
          published.
        </Typography>
        {issuer?.userId && (
          <ApprovalProgress
            type="issuer"
            id={issuer?.userId}
            key={issuer?.userId}
            isUser={isUser(issuer?.userId)}
            firstName={issuer.profile.firstName}
            lastName={issuer.profile.lastName}
            approvalStatus={issuer.status}
            lastUpdated={issuer.lastUpdated}
            handleUnassign={unAssignIssuer}
            data={utilityInfo}
          />
        )}
        {!issuer?.userId && (
          <Button
            size={"small"}
            style={{
              color: disableAssignSupervisor
                ? theme.palette.greys.GREY_DARK
                : theme.palette.primary[200],
            }}
            startIcon={
              <AddRounded
                color={disableAssignSupervisor ? "disabled" : "primary"}
              />
            }
            onClick={handleOpenIssuerSelection}
            disabled={disableAssignSupervisor}
          >
            Assign supervisor
          </Button>
        )}
        <Spacer amount={0.5} />
        <SearchDropDown
          titleLabel="Approval Required"
          title={"Assign Supervisor:"}
          onSelect={(values) => handleAssignIssuer(values)}
          fetchData={fetchIssuers}
          anchorEl={anchorElIssuer}
          setAnchorEl={setAnchorElIssuer}
          searchBarPlaceholder={"Search name"}
        />
      </div>
    </>
  );
};

export default ApprovalSection;
