import React, { useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  css,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  add_issuer,
  add_reviewer,
  applyChangesToLicence,
  expireLicence,
  get_issuer,
  get_reviewer,
  getPDF,
  mark_license_paid,
  remove_issuer,
  remove_reviewer,
  renewLicence,
  restartStatus,
  update_reviewer_status,
  update_status_discarded,
  updateChangeSetStatus,
  updateStatusDeclined,
  updateStatusIssued,
  updateStatusRevoked,
} from "../../../../../core/apis/licence";
import {
  ACTIVE,
  ACTIVE_GROUP,
  APPROVED,
  BUSINESS_NAME_LENGTH,
  CANCELLED,
  CREATED,
  DECLINED,
  DRAFT,
  EXPIRED,
  EXPIRING,
  INACTIVE_GROUP,
  ISSUED,
  RENEWING,
  REVIEWING,
  REVIEWING_GROUP,
  REVOKED,
  SUBMITTED,
} from "../../../../../core/constants/licences";
import FileDownload from "js-file-download";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { get_admins } from "../../../../../core/apis/admin";
import Spacer from "../../../../components/ui-kit/Spacer";
import SearchDropDown from "../../../../components/ui-kit/SearchDropDown";
import ListItem from "../../../../components/ui-kit/list-item";
import ApprovalProgress from "../../../../components/ui-kit/approval-progress";
import Dialog from "../../../../components/ui-kit/Dialog";
import {
  formatCurrency,
  truncate,
  userHasRole,
} from "../../../../../utilities";
import NotificationBar from "../../../../components/ui-kit/NotificationBar";
import Button from "@mui/material/Button";
import classes from "./index.module.scss";
import {
  AccessAlarmRounded,
  AddRounded,
  ApprovalOutlined,
  CheckRounded,
  DeleteOutlined,
  DeleteOutlineRounded,
  DoneRounded,
  DownloadRounded,
  EditOutlined,
  FindInPageOutlined,
  HighlightOffRounded,
  MailOutlineRounded,
  ReportOutlined,
  RestartAltRounded,
  ThumbUpAltRounded,
  UndoRounded,
  UpdateDisabled,
  Warning,
} from "@mui/icons-material";
import OnContactCustomerModal from "../../../../components/modals/onContactCustomerModal";
import config from "../../../../../core/apis/_config";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  setState as setConfirmationDialogState,
} from "../../../../redux/slices/confrmation-dialog";

const RevokeButton = styled(Button)`
  &&.MuiButton-root {
    color: ${({ theme }) => theme.palette.primary[500]};
  }

  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
    color: ${({ theme }) => theme.palette.nonPalette.RED};
  }

  && .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED};
  }
`;

const VoidingOpenInvoicesCause = {
  decline: "decline",
  revoke: "revoke",
};

/**
 * Right sidebar of the licence details screen.
 * @returns {JSX.Element}
 * @constructor
 */
export default function RightSidebar({
  emailToUse,
  status,
  data,
  preAssigned,
  refresher,
  refreshValue,
  saveAllEdits,
  editing,
  setEditing,
  editData,
  setEditData,
  setErrors,
  tab,
}) {
  const history = useHistory();
  const theme = useTheme();
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  const { handleSuccess, handleError, handleShow } =
    useContext(NotificationContext);
  const [anchorElReviewer, setAnchorElReviewer] = useState(null);
  const [anchorElIssuer, setAnchorElIssuer] = useState(null);
  const { user } = useContext(AuthContext);
  const [issuer, setIssuer] = useState({});
  const [issueNotification, setIssueNotification] = useState(false);
  const [declineNotification, setDeclineNotification] = useState(false);
  const [sendEmail, setSendEmail] = useState(null);
  const [amendmentFee, setAmendmentFee] = useState(null);
  const [sendRevokeEmail, setSendRevokeEmail] = useState(false);
  const [voidActiveInvoices, setVoidActiveInvoices] = useState(null);
  const [voidOpenInvoicesReason, setVoidOpenInvoicesReason] = useState(null);

  //Modals
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [openExpireModal, setOpenExpireModal] = useState(false);
  const [openApproveChangesModal, setOpenApproveChangesModal] = useState(false);
  const [openApplyChangesModal, setOpenApplyChangesModal] = useState(false);
  const [onContactModalOpen, setOnContactModalOpen] = useState(false);
  const [openVoidingInvoiceModal, setOpenVoidingInvoiceModal] = useState(false);

  const dispatch = useDispatch();

  //Modal Handlers
  const handleOpenRevokeModal = () => {
    setOpenRevokeModal(true);
  };
  const handleCloseRevokeModal = (resetEmail = true) => {
    setOpenRevokeModal(false);
    if (resetEmail) {
      setSendRevokeEmail(null);
    }
  };
  const handleOpenRenewModal = () => {
    setOpenRenewModal(true);
  };

  const handleOpenExpireModal = () => {
    setOpenExpireModal(true);
  };

  const handleCloseRenewModal = () => {
    setOpenRenewModal(false);
    setSendEmail(null);
  };

  const handleCloseExpireModal = () => {
    setOpenExpireModal(false);
    setSendEmail(null);
  };

  const handleCloseApprovalModal = () => {
    setOpenApproveChangesModal(false);
    setSendEmail(null);
    setAmendmentFee(null);
  };

  const handleOpenVoidingInvoiceModal = () => {
    setOpenVoidingInvoiceModal(true);
  };

  const handleCloseVoidingInvoiceModal = () => {
    setOpenVoidingInvoiceModal(false);
    setVoidActiveInvoices(null);
    setVoidOpenInvoicesReason(null);
  };

  //Modal Functions
  const onIssueLicence = async () => {
    try {
      await updateStatusIssued(data.id, false);
    } catch (e) {
      console.log(e.message);
    }
    refresher(!refreshValue);
    dispatch(closeDialog());
    setIssueNotification(true);
    handleShow(`Licence issued for\n${truncate(`${data.business_name}.`, 35)}`);
  };

  const onApproveChanges = async () => {
    try {
      if (data.change_set_status && !editing) {
        if (amendmentFee === "charge") {
          await updateChangeSetStatus(
            data.id,
            data.change_set_id,
            "issued",
            sendEmail
          );
        } else {
          await applyChangesToLicence(data.id, data.change_set_id, sendEmail);
        }
        handleShow(
          `Licence details updated for\n${truncate(
            `${data.business_name}.`,
            35
          )}`
        );
        refresher(!refreshValue);
      } else {
        if (amendmentFee === "charge") {
          await saveAllEdits(false, sendEmail);
        } else {
          await saveAllEdits(true, sendEmail);
        }
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setOpenApproveChangesModal(false);
    }
  };

  const checkChanges = () => {
    for (let i = 0; i < editData.length; i++) {
      if (Object.keys(editData[i]).length > 0) {
        return false;
      }
    }
    return true;
  };

  const handleDeclineStepOne = async () => {
    dispatch(closeDialog());
    setVoidOpenInvoicesReason(VoidingOpenInvoicesCause.decline);
    handleOpenVoidingInvoiceModal();
  };

  const handleDeclineStepTwo = async () => {
    handleCloseVoidingInvoiceModal();
    const _voidOpenInvoices = voidActiveInvoices === "void";
    try {
      await updateStatusDeclined(data.id, _voidOpenInvoices);
    } catch (e) {
      console.log(e.message);
    }
    handleShow("Business licence declined");
    refresher(!refreshValue);
  };

  const handleDeclineChanges = async () => {
    try {
      await updateChangeSetStatus(
        data.id,
        data.change_set_id,
        "declined",
        "send"
      );
    } catch (e) {
      console.log(e.message);
    }
    dispatch(closeDialog());
    handleShow("Changes declined");
    refresher(!refreshValue);
  };

  const onRestart = async () => {
    try {
      await restartStatus(data.id);
    } catch (e) {
      console.log(e.message);
    }
    handleShow("Licence restarted");
    refresher(!refreshValue);
    dispatch(closeDialog());
  };

  const onRevokeStepOne = async () => {
    handleCloseRevokeModal(false);
    setVoidOpenInvoicesReason(VoidingOpenInvoicesCause.revoke);
    handleOpenVoidingInvoiceModal();
  };

  const onRevokeStepTwo = async () => {
    handleCloseVoidingInvoiceModal();
    const _voidOpenInvoices = voidActiveInvoices === "void";
    try {
      await updateStatusRevoked(data.id, sendRevokeEmail, _voidOpenInvoices);
      handleSuccess("Licence revoked");
    } catch (e) {
      console.log(e.message);
    }
    refresher(!refreshValue);
  };

  const onRenew = async () => {
    try {
      await renewLicence(data.id, (sendEmail === "send").toString());
    } catch (e) {
      console.log(e.message);
    }
    handleCloseRenewModal();
    refresher(!refreshValue);
  };

  const onExpire = async () => {
    try {
      await expireLicence(data.id, (sendEmail === "send").toString());
    } catch (e) {
      console.log(e.message);
    }
    handleCloseExpireModal();
    refresher(!refreshValue);
  };

  // //Button Handlers
  const onMarkPaid = async () => {
    try {
      await mark_license_paid(data.id);
      handleSuccess(`Licence marked as paid`);
    } catch (e) {
      console.log(e.message);
      handleError(`Error: ${e.message}`);
    }
    dispatch(closeDialog());
    refresher(!refreshValue);
  };

  const handleEmail = () => {
    if (emailToUse) {
      window.location.href = `mailto:${emailToUse}?subject=City of ${config.cityName} - Your business licence application`;
    } else {
      setOnContactModalOpen(true);
    }
  };

  const handleApprove = async (id) => {
    await update_reviewer_status(id, "approved");
    refresher(!refreshValue);
  };

  const onDeclineReview = async () => {
    await update_reviewer_status(data?.id, "denied");
    refresher(!refreshValue);
    dispatch(closeDialog());
    handleSuccess("Licence rejected");
  };

  const onUndo = async (id) => {
    await update_reviewer_status(id, "assigned");
    refresher(!refreshValue);
  };

  const onUnassign = async (e) => {
    await remove_reviewer(e, data.id);
    refresher(!refreshValue);
  };

  const onUnassignIssuer = async (e) => {
    await remove_issuer(e, data.id);
    refresher(!refreshValue);
  };

  const handleAssignAdmin = async (e) => {
    await add_reviewer(e.id, data.id);
    refresher(!refreshValue);
  };

  const handleAssignIssuer = async (e) => {
    await add_issuer(e.id, data.id);
    refresher(!refreshValue);
  };

  const handleUndoEdit = () => {
    setEditData([]);
    setErrors({});
    setEditing(false);
    dispatch(closeDialog());
    refresher(!refreshValue);
  };

  // Core functions
  const addAdmin = (e) => {
    let tmpArray = [];
    tmpArray = tmpArray.filter((item) => item.id !== e.id);
    e.map(
      (admin) =>
        (tmpArray = [
          ...tmpArray,
          {
            id: admin.userId,
            first: admin.profile.firstName || "-",
            last: admin.profile.lastName || "-",
            approvalStatus: admin.status,
            lastUpdated: admin.lastUpdated,
          },
        ])
    );
    setAssignedAdmins(tmpArray);
  };

  /**
   * Indicates if the account has full admin approvals
   * @type {boolean}
   */
  const hasFullApproval = useMemo(() => {
    return (
      assignedAdmins.filter((e) => e.approvalStatus === "approved").length >
        0 && assignedAdmins.length > 0
    );
  }, [assignedAdmins]);

  //User Context
  const userId = 1; //TODO - This will be the user id from context
  const isUser = (id) => {
    return id === user.id;
  };
  const fetchData = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    let preAssignedIds = [];
    assignedAdmins.map((element) => preAssignedIds.push(element.id));
    return adminsRes.result
      .filter(
        (element) =>
          !preAssignedIds.includes(element.id) &&
          element.roles.find((e) => e.roleName === "reviewer")
      )
      .map((e) => ({
        ...e,
        roles: e.roles.filter((t) => t.roleName === "reviewer"),
      }));
  };

  const fetchIssuers = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    return adminsRes?.result
      .filter((admin) => admin.roles.find((e) => e.roleName === "supervisor"))
      .map((e) => ({
        ...e,
        roles: e.roles.filter((t) => t.roleName === "supervisor"),
      }));
  };

  const handleEditingModeSubmit = () => {
    if (REVIEWING_GROUP.find((x) => x === data?.status)) {
      setOpenApplyChangesModal(true);
      return;
    }
    if (
      (status === ACTIVE &&
        !REVIEWING_GROUP.find((x) => x === data?.change_set_status)) ||
      (data.balance > 0 &&
        data.status !== DRAFT &&
        data.status !== CREATED &&
        !REVIEWING_GROUP.find((x) => x === data?.change_set_status))
    ) {
      if (status === RENEWING) {
        setOpenApplyChangesModal(true);
        return;
      }
      setOpenApproveChangesModal(true);
      return;
    }
    if (editing) {
      if (status === DRAFT || status === CREATED) {
        saveAllEdits(true);
      } else {
        setOpenApplyChangesModal(true);
      }
    }
  };

  //get assigned admins
  useEffect(() => {
    //We might just accept the admins as props
    const fetchReviewer = async () => {
      let assigned = await get_reviewer(data.id);
      let assignedIssuer = await get_issuer(data.id);
      setIssuer(assignedIssuer[0]);
      addAdmin(assigned);
    };
    fetchReviewer().then();
  }, [refreshValue, status, data.id, preAssigned]);

  const handleOpenReviewer = (e) => {
    e.stopPropagation();
    setAnchorElReviewer(e.currentTarget);
  };

  const handleIssue = () => {
    if (hasFullApproval) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "Issue business licence",
          body:
            truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
            " will receive a notification email asking to pay their fee." +
            " They can download the licence PDF once they pay the fee.",
          onConfirm: () => onIssueLicence(),
        })
      );
    } else {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "Approvals pending",
          body: "Some reviewers have not approved the licence yet. Check the Approval Progress on the right sidebar.",
          onConfirm: () => closeDialog(),
        })
      );
    }
  };

  const handleOpenIssuer = (e) => {
    e.stopPropagation();
    setAnchorElIssuer(e.currentTarget);
  };

  const handleDownload = async () => {
    const license = await getPDF(data.id);
    if (license) {
      FileDownload(
        license,
        `${config.cityName} Business Licence.pdf`,
        "application/pdf"
      );
    }
  };

  const handleUndoButton = () => {
    if (status === DRAFT || status === CREATED) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "Discarding draft",
          body: "All the information contained in this draft will be deleted. A new application will require all information to be re-input from the beginning.",
          onConfirm: () => discardDraft(),
        })
      );
    } else {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "Undoing changes",
          body: "All the changes you made will be discarded and the licence will remain unchanged.",
          onConfirm: () => handleUndoEdit(),
        })
      );
    }
  };

  const discardDraft = async () => {
    try {
      dispatch(closeDialog());
      await update_status_discarded(data.id);
      setEditing(false);
      handleShow("Business licence discarded");
      history.goBack();
    } catch (e) {
      handleError("There was an issue discarding your draft. Please try again");
    }
  };

  const handleSendEmail = (event) => {
    setSendEmail(event.target.value);
  };
  const handleSetAmendmentFee = (event) => {
    setAmendmentFee(event.target.value);
  };

  const handleSendRevokeEmail = (event) => {
    setSendRevokeEmail(event.target.value !== "noEmail");
  };

  /**
   * Body component of the approve changes dialog
   * @return {JSX.Element}
   * @constructor
   */
  function BodyComponent() {
    let fee = false;
    if (data.balance === 0) {
      fee = true;
    }
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          {editing ? "Apply changes" : "Approve changes"}
        </Typography>
        {fee === true && (
          <>
            <Typography fontWeight={500} style={{ marginLeft: "0.5rem" }}>
              $20 Amendment Fee
            </Typography>
            <RadioGroup
              defaultValue={amendmentFee}
              name="radio-buttons-group"
              style={{
                display: "flex",
                flexDirection: "row",
                background: "transparent",
                marginTop: "-1rem",
              }}
              onChange={handleSetAmendmentFee}
            >
              <FormControlLabel
                value={"waive"}
                control={<Radio />}
                label="Waive fee"
                style={{ background: "transparent" }}
              />
              <FormControlLabel
                value={"charge"}
                control={<Radio />}
                label="Charge $20 fee"
                style={{ background: "transparent", marginLeft: "25px" }}
              />
            </RadioGroup>
          </>
        )}

        <Typography fontWeight={500} style={{ marginLeft: "0.5rem" }}>
          Email Notification
        </Typography>
        <RadioGroup
          defaultValue={sendEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
            marginBottom: "-0.5rem",
          }}
          onChange={handleSendEmail}
        >
          <FormControlLabel
            value={"noEmail"}
            control={<Radio />}
            label="No email"
            style={{ background: "transparent" }}
          />
          <FormControlLabel
            value={"send"}
            control={<Radio />}
            label="Send an email"
            style={{ background: "transparent", marginLeft: "30px" }}
          />
        </RadioGroup>
      </div>
    );
  }

  /**
   * Memo version of revoke BL dialog content
   * @type {JSX.Element}
   */
  const revokeLicenceDialogContent = useMemo(() => {
    let emailExists = data.email !== null || emailToUse !== null;
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          Revoke licence
        </Typography>
        <Typography
          fontWeight={400}
          style={{
            marginLeft: "0.5rem",
            marginBottom: !emailExists ? "1rem" : "0",
            marginRight: !emailExists ? "1rem" : "0",
          }}
        >
          {emailExists
            ? "Send notification email?"
            : "The licence will be revoked. Do you want to proceed?"}
        </Typography>
        {emailExists && (
          <RadioGroup
            defaultValue={sendRevokeEmail}
            name="radio-buttons-group"
            style={{
              display: "flex",
              flexDirection: "row",
              background: "transparent",
              marginTop: "-1rem",
              marginBottom: "-0.5rem",
            }}
            onChange={handleSendRevokeEmail}
          >
            <FormControlLabel
              value={"noEmail"}
              control={<Radio />}
              label="No email"
              style={{ background: "transparent" }}
            />
            <FormControlLabel
              value={"send"}
              control={<Radio />}
              label="Send an email"
              style={{ background: "transparent", marginLeft: "30px" }}
            />
          </RadioGroup>
        )}
      </div>
    );
  }, [data.email, emailToUse, sendRevokeEmail]);

  const cancelInvoiceModalContent = useMemo(() => {
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          Void open invoices
        </Typography>
        <RadioGroup
          defaultValue={voidActiveInvoices}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
            marginBottom: "-0.5rem",
          }}
          onChange={(event) => {
            setVoidActiveInvoices(event.target.value);
          }}
        >
          <FormControlLabel
            value={"noVoid"}
            control={<Radio />}
            label="Don't void"
            style={{ background: "transparent" }}
          />
          <FormControlLabel
            value={"void"}
            control={<Radio />}
            label="Void open invoices"
            style={{ background: "transparent", marginLeft: "30px" }}
          />
        </RadioGroup>
      </div>
    );
  }, [voidActiveInvoices]);

  /**
   * Memo version of set licence expiring dialog content
   * @type {JSX.Element}
   */
  const setLicenceExpiringModalContent = useMemo(() => {
    let emailExists = data.email !== null || emailToUse !== null;
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          Expire the licence
        </Typography>
        <Typography
          fontWeight={400}
          style={{
            marginLeft: "0.5rem",
            marginBottom: !emailExists ? "1rem" : "0",
            marginRight: !emailExists ? "1rem" : "0",
          }}
        >
          By confirming, the customer's business licence will expire at the end
          of the period.
        </Typography>
        {emailExists && (
          <>
            <Spacer />
            <Typography
              fontWeight={400}
              style={{
                marginLeft: "0.5rem",
                marginBottom: !emailExists ? "1rem" : "0",
                marginRight: !emailExists ? "1rem" : "0",
              }}
            >
              Would you like to send an email notification?
            </Typography>
          </>
        )}
        {emailExists && (
          <RadioGroup
            defaultValue={sendEmail}
            name="radio-buttons-group"
            style={{
              display: "flex",
              flexDirection: "row",
              background: "transparent",
              marginTop: "-1rem",
              marginBottom: "-0.5rem",
            }}
            onChange={handleSendEmail}
          >
            <FormControlLabel
              value={"noEmail"}
              control={<Radio />}
              label="No, don't email"
              style={{ background: "transparent" }}
            />
            <FormControlLabel
              value={"send"}
              control={<Radio />}
              label="Yes, send an Email"
              style={{ background: "transparent", marginLeft: "30px" }}
            />
          </RadioGroup>
        )}
      </div>
    );
  }, [data.email, emailToUse, sendEmail]);

  const disableAssignIssuer = useMemo(() => {
    const isReviewer = userHasRole("reviewer");
    if (isReviewer) {
      return true;
    }
    if (assignedAdmins.length <= 0) return true;
    let disable = false;
    assignedAdmins.forEach((admin) => {
      if (admin.approvalStatus === "assigned") {
        disable = true;
      }
    });
    return disable;
  }, [assignedAdmins]);

  /**
   * Memo version of set licence expiring dialog content
   * @type {JSX.Element}
   */
  const setLicenceRenewingModalContent = useMemo(() => {
    let emailExists = data.email !== null || emailToUse !== null;
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          Renew the licence
        </Typography>
        <Typography
          fontWeight={400}
          style={{
            marginLeft: "0.5rem",
            marginBottom: !emailExists ? "1rem" : "0",
            marginRight: !emailExists ? "1rem" : "0",
          }}
        >
          Renew the customer's business licence for the next year.
        </Typography>
        {emailExists && (
          <>
            <Spacer />
            <Typography
              fontWeight={400}
              style={{
                marginLeft: "0.5rem",
                marginBottom: !emailExists ? "1rem" : "0",
                marginRight: !emailExists ? "1rem" : "0",
              }}
            >
              Send a notification email for fee renewal?
            </Typography>
          </>
        )}
        {emailExists && (
          <RadioGroup
            defaultValue={sendEmail}
            name="radio-buttons-group"
            style={{
              display: "flex",
              flexDirection: "row",
              background: "transparent",
              marginTop: "-1rem",
              marginBottom: "-0.5rem",
            }}
            onChange={handleSendEmail}
          >
            <FormControlLabel
              value={"noEmail"}
              control={<Radio />}
              label="No, don't email"
              style={{ background: "transparent" }}
            />
            <FormControlLabel
              value={"send"}
              control={<Radio />}
              label="Yes, send an Email"
              style={{ background: "transparent", marginLeft: "30px" }}
            />
          </RadioGroup>
        )}
      </div>
    );
  }, [data.email, emailToUse, sendEmail]);

  return (
    <div className={classes.main_container}>
      <Box p={2}>
        {editing ? (
          <>
            <StatusCard status={data?.status} data={data} editing={editing} />
            <Spacer amount={3} />
            <Button
              onClick={handleEditingModeSubmit}
              variant={"contained"}
              size={"medium"}
              startIcon={<DoneRounded />}
              disabled={checkChanges()}
            >
              {status === DRAFT || status === CREATED
                ? "Submit Licence"
                : "Apply Changes"}
            </Button>
            <Button
              onClick={handleUndoButton}
              size={"medium"}
              startIcon={
                status === DRAFT || status === CREATED ? (
                  <DeleteOutlineRounded color={"error"} />
                ) : (
                  <UndoRounded />
                )
              }
              style={{ marginTop: "0.5rem" }}
              color={
                status === DRAFT || status === CREATED ? "error" : "inherit"
              }
              {...(status === DRAFT || status === CREATED
                ? {
                    sx: {
                      ".MuiTouchRipple-child": {
                        color: `${theme.palette.nonPalette.RED} !important`,
                      },
                      "&:hover": {
                        backgroundColor:
                          theme.palette.nonPalette.RED_BACKGROUND,
                        ".MuiTouchRipple-child": {
                          color: theme.palette.nonPalette.RED,
                        },
                      },
                      "&:active": {
                        backgroundColor:
                          theme.palette.nonPalette.RED_BACKGROUND,
                        ".MuiTouchRipple-child": {
                          color: theme.palette.nonPalette.RED,
                        },
                      },
                    },
                  }
                : {})}
            >
              {status === DRAFT || status === CREATED ? "Discard" : "Undo"}
            </Button>
          </>
        ) : (
          <>
            <StatusCard status={data?.status} data={data} />
            <div style={{ margin: "1rem 0" }} className={"outlined_container"}>
              <p className={"overline_bold"} style={{ marginTop: 0 }}>
                {REVIEWING_GROUP.find((x) => x === data?.status)
                  ? "Estimate:"
                  : "Balance owing:"}
              </p>
              <p className={"currency_text_large"}>
                {formatCurrency(data.balance)}
              </p>
            </div>
            {REVIEWING_GROUP.find((x) => x === data?.change_set_status) ||
            REVIEWING_GROUP.find((x) => x === data?.status) ? (
              <div>
                <Button
                  onClick={() => {
                    data.change_set_status
                      ? setOpenApproveChangesModal(true)
                      : handleIssue(data);
                  }}
                  variant={"contained"}
                  size={"medium"}
                  fullWidth
                  disabled={!hasFullApproval}
                >
                  {REVIEWING_GROUP.find((x) => x === data?.change_set_status)
                    ? "Approve Changes"
                    : "Issue licence"}
                </Button>
              </div>
            ) : null}
            {((data.status === ISSUED ||
              data.status === ACTIVE ||
              data.status === RENEWING) &&
              data.balance > 0 &&
              !data.change_set_status) ||
            data.change_set_status === ISSUED ? (
              <Button
                onClick={() =>
                  dispatch(
                    setConfirmationDialogState({
                      open: true,
                      title: "Marking licence as paid",
                      body:
                        truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
                        " will receive an email notifying them their licence has been paid.",
                      onConfirm: () => onMarkPaid(),
                    })
                  )
                }
                variant={"contained"}
                size={"medium"}
                startIcon={<ThumbUpAltRounded />}
              >
                Mark as paid
              </Button>
            ) : null}
            {INACTIVE_GROUP.find((x) => x === data?.status) ? (
              <Button
                startIcon={<RestartAltRounded />}
                onClick={() =>
                  dispatch(
                    setConfirmationDialogState({
                      open: true,
                      title: "Restarting a licence",
                      body:
                        `This will restart the business licence application for ` +
                        truncate(data?.business_name, BUSINESS_NAME_LENGTH),
                      onConfirm: () => onRestart(),
                    })
                  )
                }
                variant={"text"}
              >
                Restart
              </Button>
            ) : null}
            <Spacer amount={0.5} />
            {(status === ACTIVE ||
              status === SUBMITTED ||
              status === REVIEWING ||
              status === APPROVED ||
              status === RENEWING) &&
              tab === "General Info" && (
                <Button
                  startIcon={<EditOutlined />}
                  onClick={() => {
                    setEditing(!editing);
                  }}
                  variant={"text"}
                >
                  Edit
                </Button>
              )}
            <MoreActionsSection
              id={userId}
              data={data}
              handleDecline={() =>
                dispatch(
                  setConfirmationDialogState({
                    open: true,
                    title: "Declining business licence",
                    body:
                      truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
                      " will receive an email notifying them their licence application has been declined.",
                    onConfirm: () => handleDeclineStepOne(),
                  })
                )
              }
              handleDeclineChanges={() =>
                dispatch(
                  setConfirmationDialogState({
                    open: true,
                    title: "Declining licence changes",
                    body: "The applicant will receive an email notifying them their changes are declined.",
                    onConfirm: () => handleDeclineChanges(),
                  })
                )
              }
              handleEmail={handleEmail}
              handleOpenRevokeModal={handleOpenRevokeModal}
              handleOpenRenewModal={handleOpenRenewModal}
              handleDownloadPDF={handleDownload}
              handleOpenExpireModal={handleOpenExpireModal}
            />
          </>
        )}
        <Spacer amount={0.5} />
        <Divider />
        <Spacer />
        {REVIEWING_GROUP.find((x) => x === data?.status) ||
        REVIEWING_GROUP.find((x) => x === data?.change_set_status) ? (
          <>
            <Typography variant={"h4"}>Approval process</Typography>
            <Spacer amount={1} />
            <Typography variant={"body1"} fontWeight={700}>
              1. Review the licence (
              {`${
                assignedAdmins.filter(
                  (item) => item.approvalStatus === "approved"
                ).length
              }/${assignedAdmins.length}`}
              )
            </Typography>
            <Typography variant={"body1"}>
              Assign as many reviewers as necessary. At least one reviewer is
              required.{" "}
            </Typography>
            {assignedAdmins?.length > 0 ? <Spacer amount={0.5} /> : null}
            {assignedAdmins?.map((admin) => (
              <ApprovalProgress
                type="reviewer"
                id={admin.id}
                key={admin.id}
                isUser={isUser(admin.id)}
                firstName={admin.first}
                lastName={admin.last}
                approvalStatus={admin.approvalStatus}
                lastUpdated={admin.lastUpdated}
                handleApprove={handleApprove}
                handleDecline={() =>
                  dispatch(
                    setConfirmationDialogState({
                      open: true,
                      title: "Rejecting licence",
                      body: "If a supervisor is assigned, they will receive a ticket to review and decline the licence.",
                      onConfirm: () => onDeclineReview(),
                    })
                  )
                }
                handleUndo={onUndo}
                handleUnassign={onUnassign}
                data={data}
              />
            ))}
            <Spacer amount={0.5} />
            <Button
              size={"small"}
              style={{ color: theme.palette.primary[200] }}
              startIcon={<AddRounded />}
              onClick={handleOpenReviewer}
            >
              Assign reviewer
            </Button>
            <SearchDropDown
              titleLabel="Approval Required"
              title={"Assign Reviewers:"}
              onSelect={(values) => handleAssignAdmin(values)}
              fetchData={fetchData}
              anchorEl={anchorElReviewer}
              setAnchorEl={setAnchorElReviewer}
            />
            <Spacer amount={1} />
            <Typography variant={"body1"} fontWeight={700}>
              2. Approve the licence
            </Typography>
            <Typography variant={"body1"}>
              All assigned reviewers must approve the licence so it can be
              issued.
            </Typography>
            {issuer?.userId && (
              <ApprovalProgress
                type="issuer"
                id={issuer?.userId}
                key={issuer?.userId}
                isUser={isUser(issuer?.userId)}
                firstName={issuer.profile.firstName}
                lastName={issuer.profile.lastName}
                approvalStatus={issuer.status}
                lastUpdated={issuer.lastUpdated}
                handleUnassign={onUnassignIssuer}
                data={data}
              />
            )}
            {!issuer?.userId && (
              <Button
                size={"small"}
                style={{
                  color: disableAssignIssuer
                    ? theme.palette.greys.GREY_DARK
                    : theme.palette.primary[200],
                }}
                startIcon={
                  <AddRounded
                    color={disableAssignIssuer ? "disabled" : "primary"}
                  />
                }
                onClick={handleOpenIssuer}
                disabled={disableAssignIssuer}
              >
                Assign issuer
              </Button>
            )}
            <Spacer amount={0.5} />
            <SearchDropDown
              titleLabel="Approval Required"
              title={"Assign issuer:"}
              onSelect={(values) => handleAssignIssuer(values)}
              fetchData={fetchIssuers}
              anchorEl={anchorElIssuer}
              setAnchorEl={setAnchorElIssuer}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
      <Dialog
        variant={"updated"}
        body={revokeLicenceDialogContent}
        open={openRevokeModal}
        handleClose={handleCloseRevokeModal}
        handleButtonOne={handleCloseRevokeModal}
        buttonOneText={"Cancel"}
        buttonTwoText={"Confirm"}
        buttonFlexDirection={"column-reverse"}
        buttonTwoVariant={"contained"}
        buttonTwoDisable={
          sendRevokeEmail === null &&
          (emailToUse !== null || data.email !== null)
        }
        handleButtonTwo={onRevokeStepOne}
      />
      <Dialog
        variant={"updated"}
        open={openExpireModal}
        handleClose={handleCloseExpireModal}
        BodyComponent={setLicenceExpiringModalContent}
        buttonOneText={"Cancel"}
        buttonTwoText={"CONFIRM"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={handleCloseExpireModal}
        handleButtonTwo={onExpire}
        buttonTwoDisable={sendEmail === null}
      />
      <Dialog
        variant={"updated"}
        open={openRenewModal}
        handleClose={handleCloseRenewModal}
        BodyComponent={setLicenceRenewingModalContent}
        buttonOneText={"Cancel"}
        buttonTwoText={"CONFIRM"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={handleCloseRenewModal}
        handleButtonTwo={onRenew}
        buttonTwoDisable={sendEmail === null}
      />
      <Dialog
        variant={"updated"}
        open={openApproveChangesModal}
        handleClose={handleCloseApprovalModal}
        BodyComponent={BodyComponent()}
        buttonOneText={"Cancel"}
        buttonTwoText={"CONFIRM"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={handleCloseApprovalModal}
        handleButtonTwo={onApproveChanges}
        buttonTwoDisable={(!amendmentFee && data.balance === 0) || !sendEmail}
      />
      <Dialog
        variant={"updated"}
        open={openApplyChangesModal}
        handleClose={() => {
          setOpenApplyChangesModal(false);
        }}
        title={"Apply changes"}
        body={"Your changes will be saved. Do you want to proceed?"}
        buttonOneText={"Cancel"}
        buttonTwoText={"Save"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          setOpenApplyChangesModal(false);
        }}
        handleButtonTwo={onApproveChanges}
        buttonTwoDisable={false}
      />
      <Dialog
        variant={"updated"}
        open={openVoidingInvoiceModal}
        handleClose={handleCloseVoidingInvoiceModal}
        BodyComponent={cancelInvoiceModalContent}
        buttonOneText={"Cancel"}
        buttonTwoText={"CONFIRM"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={handleCloseVoidingInvoiceModal}
        handleButtonTwo={
          voidOpenInvoicesReason === VoidingOpenInvoicesCause.revoke
            ? onRevokeStepTwo
            : voidOpenInvoicesReason === VoidingOpenInvoicesCause.decline
              ? handleDeclineStepTwo
              : () => {}
        }
        buttonTwoDisable={voidActiveInvoices === null}
      />
      <OnContactCustomerModal
        open={onContactModalOpen}
        setOpen={setOnContactModalOpen}
      />
      <NotificationBar
        open={issueNotification}
        body={`Business licence issued.`}
        secondaryButtonLabel={"DISMISS"}
        onClickSecondary={() => {
          setIssueNotification(false);
        }}
      />
      <NotificationBar
        open={declineNotification}
        body={`Business licence declined.`}
        secondaryButtonLabel={"DISMISS"}
        onClickSecondary={() => {
          setDeclineNotification(false);
        }}
      />
    </div>
  );
}

const RedHoverButton = styled(Button)(
  ({ theme }) => css`
    .material-symbols-rounded {
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &&:hover {
      background-color: ${theme.palette.nonPalette.RED_BACKGROUND};
      color: ${theme.palette.nonPalette.RED};

      && .material-symbols-rounded {
        color: ${theme.palette.nonPalette.RED};
      }
    }
  `
);

const MoreActionsSection = ({
  data,
  handleDecline,
  handleDeclineChanges,
  handleEmail,
  handleOpenRevokeModal,
  handleOpenRenewModal,
  handleOpenExpireModal,
  handleDownloadPDF,
}) => {
  const theme = useTheme();
  const canRenewExpire = userHasRole("supervisor") || userHasRole("super");

  return (
    <Box marginBottom={"8px"}>
      {data.status === REVIEWING ||
      data.status === APPROVED ||
      data.status === SUBMITTED ||
      data.status === ISSUED ||
      REVIEWING_GROUP.find((x) => x === data?.change_set_status) ? (
        <RedHoverButton
          sx={{
            // color: theme.palette.nonPalette.RED,
            ".MuiTouchRipple-child": {
              color: `${theme.palette.nonPalette.RED} !important`,
            },
            "&:hover": {
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
              ".MuiTouchRipple-child": {
                color: theme.palette.nonPalette.RED,
              },
            },
            "&:active": {
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
              ".MuiTouchRipple-child": {
                color: theme.palette.nonPalette.RED,
              },
            },
          }}
          startIcon={<DeleteOutlined />}
          onClick={() => {
            REVIEWING_GROUP.find((x) => x === data?.change_set_status)
              ? handleDeclineChanges()
              : handleDecline();
          }}
          variant={"text"}
        >
          {REVIEWING_GROUP.find((x) => x === data?.change_set_status)
            ? "Decline Changes"
            : "Decline"}
        </RedHoverButton>
      ) : null}
      {ACTIVE_GROUP.includes(data.status) && canRenewExpire && (
        <>
          {data.balance === 0 && (
            <>
              <Button
                startIcon={<AccessAlarmRounded />}
                onClick={handleOpenRenewModal}
                variant={"text"}
              >
                Renew
              </Button>
            </>
          )}
          {![ISSUED, EXPIRING].includes(data.status) && (
            <Button
              startIcon={<UpdateDisabled />}
              onClick={handleOpenExpireModal}
              variant={"text"}
            >
              Expire
            </Button>
          )}
        </>
      )}
      {[ACTIVE, RENEWING, EXPIRING].includes(data.status) ? (
        <>
          {!REVIEWING_GROUP.find((x) => x === data?.change_set_status) && (
            <RevokeButton
              onClick={handleOpenRevokeModal}
              startIcon={<ReportOutlined />}
            >
              Revoke
            </RevokeButton>
          )}
          <Spacer />
          <Divider />
          <Spacer />
          <Button
            startIcon={<DownloadRounded />}
            onClick={() => {
              handleDownloadPDF();
            }}
            variant={"text"}
          >
            Download PDF
          </Button>
        </>
      ) : null}
      <Spacer />
      <Divider />
      <Spacer />
      <Button
        startIcon={<MailOutlineRounded />}
        onClick={() => {
          handleEmail();
        }}
        variant={"text"}
      >
        Contact customer
      </Button>
    </Box>
  );
};

const StatusCard = ({ status, data, editing }) => {
  const theme = useTheme();
  if (editing) {
    return (
      <ListItem
        style={{
          padding: 15,
          backgroundColor: theme.palette.greys.GREY_LIGHT,
        }}
        label={<span style={{ fontWeight: 500 }}>Editing</span>}
        startOrnament={<EditOutlined sx={{ height: "32px", width: "32px" }} />}
        subLabel={"Unsaved changes"}
      />
    );
  } else {
    switch (status) {
      case REVIEWING:
      case SUBMITTED:
      case APPROVED:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.primary[50],
            }}
            label={<span style={{ fontWeight: 500 }}>Under review</span>}
            labelStyles={{ color: theme.palette.primary[200] }}
            startOrnament={
              <FindInPageOutlined
                sx={{
                  height: "32px",
                  width: "32px",
                  color: theme.palette.primary[200],
                }}
              />
            }
            subLabel={"Please, complete all steps"}
          />
        );
      case ISSUED:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.primary[50],
            }}
            label={<span style={{ fontWeight: 500 }}>Issued</span>}
            labelStyles={{ color: theme.palette.primary[200] }}
            startOrnament={
              <ApprovalOutlined
                sx={{
                  height: "32px",
                  width: "32px",
                  color: theme.palette.primary[200],
                }}
              />
            }
            subLabel={"Payment pending."}
          />
        );
      case ACTIVE:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.nonPalette.GREEN_BACKGROUND,
            }}
            label={<span style={{ fontWeight: 500 }}>Active</span>}
            labelStyles={{ color: theme.palette.nonPalette.GREEN }}
            startOrnament={
              <CheckRounded
                sx={{
                  height: "32px",
                  width: "32px",
                  color: theme.palette.nonPalette.GREEN,
                }}
              />
            }
            subLabel={
              "Since " + moment(data.lastUpdated).format("MMM DD, YYYY")
            }
          />
        );
      case DECLINED:
      case EXPIRED:
      case REVOKED:
      case CANCELLED:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
            }}
            labelStyles={{ color: theme.palette.nonPalette.RED }}
            label={<span style={{ fontWeight: 500 }}>Inactive</span>}
            startOrnament={
              <HighlightOffRounded
                color={"error"}
                sx={{ height: "32px", width: "32px" }}
              />
            }
            subLabel={
              "Since " + moment(data.lastUpdated).format("MMM DD, YYYY")
            }
          />
        );
      case RENEWING:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.primary[50],
            }}
            label={<span style={{ fontWeight: 500 }}>Renewal required</span>}
            startOrnament={
              <FindInPageOutlined sx={{ height: "32px", width: "32px" }} />
            }
            subLabel={"Licence is due for renewal"}
          />
        );
      case EXPIRING:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.primary[50],
            }}
            label={
              <span
                style={{
                  fontWeight: 500,
                  color: theme.palette.nonPalette.BLUE,
                }}
              >
                Licence expiring
              </span>
            }
            startOrnament={
              <Warning
                sx={{
                  height: "32px",
                  width: "32px",
                  color: theme.palette.nonPalette.BLUE,
                }}
              />
            }
            subLabel={"Licence scheduled for expiration"}
          />
        );
      default:
        return null;
    }
  }
};
