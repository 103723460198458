import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  LICENCES_TABLE_IDS,
  licenseFieldsV2,
} from "../../../../core/constants/licences";
import DataTable from "../../../components/ui-kit/data-table";
import { FilterContext } from "../../../contexts/FilterContext";
import { TableSettingContext } from "../../../contexts/TableSettingContext";
import classes from "./index.module.scss";
import { get_bylaw_list } from "../../../../core/apis/bylaw";
import {
  create_business_license,
  get_licenses_summaries,
} from "../../../../core/apis/licence";
import { ApprovalOutlined } from "@mui/icons-material";
import { routes } from "../../../routes/routesData";

const StyledTable = styled(DataTable)`
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
  display: flex;
`;

const searchFields = ["Business name", "Licence number", "Bylaw"];

const LicenseTable = ({ ...props }) => {
  const history = useHistory();
  const { filters, setFilters } = useContext(FilterContext);
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [currentFilters, setCurrentFilters] = useState(filters[props.id] || {});
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[props.id] || {},
  );

  async function fetchData(queryOptions) {
    try {
      setLoading(true);
      let data = await get_licenses_summaries(queryOptions);
      const bylaws = await get_bylaw_list();
      data.result = data.result.map((summary) => {
        return {
          ...summary,
          applicant: `${summary.name}<br/>${summary.address}`,
        };
      });
      setData(data);
      setSearchOptions(bylaws);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleOnCreate = async () => {
    try {
      const licenseId = await create_business_license();
      history.push(routes.LICENCES_DETAIL.path.replace(":id", licenseId), {
        newLicence: true,
      });
    } catch (e) {
      handleError("Sorry, there was an issue creating your licence.");
    }
  };

  /**
   * This generates text containing all fields currently in use for
   * search functionality. If no fields are available, we return a
   * default string.
   * @type {(function(): (string|string))|*}
   */
  const generateTooltipTitle = useCallback(() => {
    if (!searchFields || searchFields.length <= 0)
      return "Enter search terms here";
    let title = `Searching by:`;
    searchFields.forEach((field, index) => {
      if (index >= searchFields.length - 1) {
        title += ` and ${field}`;
      } else {
        title += ` ${field},`;
      }
    });
    return title;
  }, []);

  useEffect(() => {
    filters[props.id] = currentFilters;
    setFilters(filters);
    tableSettings[props.id] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    props.id,
    setFilters,
    setTableSettings,
    loading,
    history,
  ]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <StyledTable
          onCreateClick={handleOnCreate}
          data={data}
          dataLoading={loading}
          loadData={fetchData}
          createLabel={"New Licence"}
          icon={
            <ApprovalOutlined
              sx={{ height: "45px", width: "45px", marginRight: "0.5rem" }}
            />
          }
          enableFilterV2={true}
          onRowClick={(account, history) => {
            history.push(
              routes.LICENCES_DETAIL.path.replace(":id", account.id),
            );
          }}
          filters={currentFilters}
          setFilters={setCurrentFilters}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          searchOptions={searchOptions}
          tooltipTitle={generateTooltipTitle()}
          {...props}
        />
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

const fields = [
  licenseFieldsV2.ref_number,
  licenseFieldsV2.applicant,
  licenseFieldsV2.statuses,
  licenseFieldsV2.last_update,
  licenseFieldsV2.balance,
  licenseFieldsV2.residency,
  licenseFieldsV2.location,
];

const AdminLicences = () => (
  <LicenseTable
    id={LICENCES_TABLE_IDS.ALL}
    title={"Licences"}
    defaultOrder={"desc"}
    defaultSort={"lastUpdated"}
    enableFilter
    fields={fields}
  />
);

export { AdminLicences };
