import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import Modal from "../../../../components/ui-kit/Modal";

const StyledModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const BackButton = styled(Button)``;

const RemoveButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.RED_BACKGROUND};
  }
  && .MuiTouchRipple-child {
    background-color: red;
  }
`;

export default function DeleteModal({
  handleModalClose,
  data,
  handleRemove,
  open,
}) {
  const theme = useTheme();

  if (!data) return null;
  const { index } = data;

  return (
    <StyledModal open={open} fullWidth={false}>
      <Typography variant={"body1"} fontWeight={"700"}>
        Remove item
      </Typography>
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        This will affect the amount you pay in this transaction. Please, note
        that you will still need to pay for this before its due date to avoid
        penalties.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <BackButton
          variant={"text"}
          onClick={() => {
            handleModalClose();
          }}
        >
          <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
            Cancel
          </Typography>
        </BackButton>
        <RemoveButton
          variant={"text"}
          onClick={() => {
            handleRemove(index);
            handleModalClose();
          }}
        >
          <Typography color={"red"} fontWeight={"700"}>
            Remove
          </Typography>
        </RemoveButton>
      </Box>
    </StyledModal>
  );
}
