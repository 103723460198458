import React, {
  createContext, useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import TemporaryDisconnectDisplay from "./display/TemporaryDisconnectDisplay";
import { useParams } from "react-router-dom";
import {getAccountDetail} from "../../../../../core/apis/account";

export const Context = createContext<{
  id: string;
  formValues: { startDate: string | null; endDate: string | null };
  setFormValues: any;
  activeStep: number;
  setActiveStep: any;
  serviceAddress: any;
}>({
  id: "",
  formValues: {
    startDate: null,
    endDate: null,
  },
  setFormValues: () => null,
  activeStep: 0,
  setActiveStep: () => null,
  serviceAddress: {},
});

const TemporaryDisconnect = () => {
  const { id }: any = useParams();
  const [formValues, setFormValues] = useState({
    startDate: null,
    endDate: null,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [serviceAddress, setServiceAddress] = useState<any>({});

  const fetchData = useCallback(async () => {
    const results = await getAccountDetail(id);
    setServiceAddress(results.find(value => value.name === "service_address"))
  }, [id])

  useEffect(() => {
    fetchData().then();
  }, [fetchData]);

  const data = useMemo(
    () => ({
      id: id,
      formValues,
      setFormValues,
      activeStep,
      setActiveStep,
      serviceAddress
    }),
    [id, formValues, activeStep, serviceAddress]
  );

  return (
    <Context.Provider value={data}>
      <TemporaryDisconnectDisplay />
    </Context.Provider>
  );
};

export default TemporaryDisconnect;
