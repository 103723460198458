import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { PRIMARY_200 } from "../../../mui-theme/theme";

const StyledNotificationBar = styled.div`
  ${({ open }) => {
    switch (open) {
      case false:
        return `
          display: none;
        `;
      default:
        return "";
    }
  }}
  position: fixed;
  background: ${({ theme }) => theme.palette.whites.WHITE};
  z-index: 3;
  bottom: 50px !important;
  left: 2rem !important;
  background-color: ${({ theme }) => theme.palette.whites.WHITE};
  border-radius: 10px;
  border-left: 10px solid ${({ theme }) => theme.palette.primary[200]};
  box-shadow:
    0 6px 10px rgba(1, 18, 32, 0.1),
    0 1px 18px rgba(1, 18, 32, 0.08),
    0 3px 5px rgba(1, 18, 32, 0.1);
  padding: 1rem 1rem 0.1rem 1rem;

  @media (max-width: 500px) {
    max-width: 290px;
  }
`;

const StyledNotificationBarContent = styled.div`
  border-radius: 10px;
`;
const StyledNotificationBarTexts = styled.div`
  display: flex;
`;
const StyledButton = styled(Button)``;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-left: auto;
`;

const NotificationBar = ({
  title = "",
  body,
  onClickPrimary = undefined,
  onClickSecondary,
  primaryButtonLabel = undefined,
  secondaryButtonLabel,
  open,
}) => {
  const theme = useTheme();

  return (
    <StyledNotificationBar open={open}>
      {title ? (
        <StyledNotificationBarContent>
          <StyledNotificationBarTexts>
            <div>
              <Typography
                fontWeight="700"
                variant="body1"
                color={theme.palette.primary[700]}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
              >
                {body}
              </Typography>
            </div>
          </StyledNotificationBarTexts>
          <StyledButtonContainer>
            {onClickPrimary && (
              <StyledButton
                style={{ color: PRIMARY_200 }}
                onClick={onClickPrimary}
              >
                {primaryButtonLabel}
              </StyledButton>
            )}
            {onClickSecondary && (
              <StyledButton onClick={onClickSecondary}>
                {secondaryButtonLabel}
              </StyledButton>
            )}
          </StyledButtonContainer>
        </StyledNotificationBarContent>
      ) : (
        <StyledNotificationBarTexts style={{ marginBottom: "1rem" }}>
          <Typography
            variant="body2"
            color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              maxWidth: "250px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            {body}
          </Typography>
          <StyledButton
            size={"medium"}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
            }}
            onClick={onClickSecondary}
          >
            {secondaryButtonLabel}
          </StyledButton>
        </StyledNotificationBarTexts>
      )}
    </StyledNotificationBar>
  );
};

export default NotificationBar;
