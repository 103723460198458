import React, { useContext } from "react";
import { NotificationContext } from "../../contexts/NotificationContext";
import NotificationBar from "./NotificationBar";

export default function NotificationBarPlaceholder() {
  const {
    message,
    title,
    open,
    handleClose,
    primaryButtonLabel,
    secondaryButtonLabel = "Dismiss",
    onClickPrimary,
  } = useContext(NotificationContext);

  return (
    <>
      <NotificationBar
        open={open}
        body={message}
        onClickPrimary={onClickPrimary}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={secondaryButtonLabel}
        title={title}
        onClickSecondary={handleClose}
      />
    </>
  );
}
