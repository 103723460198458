import {Buffer} from "buffer";
import {download_attachment} from "../core/apis/attachment";
import {fromBlob} from "file-type/browser";

/**
 * Downloads the attached documents:
 *
 * - Gets the file-link from server by calling the related api
 * - Attaches an anchor elements of type download for the link of the file
 * - Gets the file type using the file-type library by calling a xml http request
 * - Triggers a click event over the created anchor element then removes it from the document
 *
 * @param attachmentId
 * @param fileName
 * @param id
 * @param handleError
 * @returns {Promise<void>}
 */
export const handleDownloadDoc = async (attachmentId, fileName, id, handleError) => {
  if (!attachmentId) {
    handleError("Unable to process the request, please try again later");
    return;
  }

  window.Buffer = Buffer;
  const docLink = await download_attachment(attachmentId, id);

  let xhr = new XMLHttpRequest();
  xhr.responseType = "blob";

  // set up the onload event handler
  xhr.onload = async function () {
    let downloadLink = document.createElement("a");
    const fileType = await fromBlob(xhr.response);
    const fileExtension = fileType ? `.${fileType.ext}` : "";

    let url = URL.createObjectURL(xhr.response);

    downloadLink.href = url;

    downloadLink.setAttribute(
      "download",
      `${fileName.split(".")?.[0]}${fileExtension}`
    );

    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
    URL.revokeObjectURL(url);
  };

  // send the XHR request to the server
  xhr.open("GET", docLink.downloadLink);
  xhr.send();
};

/**
 * Opens a new tab for previewing the uploaded document
 * @param attachmentId
 * @param id
 * @param handleError
 * @returns {Promise<void>}
 */
export const handlePreviewDoc = async (attachmentId, id, handleError) => {
  if (!attachmentId) {
    handleError("Unable to process the request, please try again later");
    return;
  }
  const docLink = await download_attachment(attachmentId, id);
  window.open(docLink.downloadLink, "_blank", "noopener,noreferrer");
};