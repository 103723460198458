//Initial values for step 2 of the business licence form
import * as schemas from "../BusinessLicenceStepYupSchemas";
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MaskedTextField from "../../../../../components/ui-kit/masked-text-field";
import { CheckboxCard } from "../../../../../components/ui-kit/Checkbox";
import Button from "@mui/material/Button";
import Spacer from "../../../../../components/ui-kit/Spacer";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { EDIT } from "../../../../../../core/constants/licences";
import { cleanPhoneNumber } from "../../../../../../utilities/masked-input-formatters";
import { ReactComponent as UsFlagLogo } from "../../../../../../assets/images/usa_flag_icon.svg";
import { ReactComponent as CanadaFlagLogo } from "../../../../../../assets/images/canada_flag_icon.svg";
import HighlightedAutoComplete from "../../../../../components/ui-kit/HighlightedAutoComplete";
import {
  CanadaProvinces,
  UsStates,
} from "../../../../../../core/constants/strings";
import { AddressCountry } from "../../../../../../core/constants";
import config from "../../../../../../core/apis/_config";

const initialValues = {
  businessAddress: "",
  businessAddressCity: `${config.cityName}`,
  unit: "",
  code: "",
  businessCountry: "",
  mailingAddress: "",
  mailingAddressProvince: "",
  mailingAddressCity: "",
  mailingUnit: "",
  mailingCode: "",
  mailingCountry: "",
  mailingAddressCheckedAsBusinessAddress: true,
  phoneNumber: "",
  email: "",
  faxNumber: "",
  secondPhone: "",
  websiteAddress: "",
};

//Validation schema for step 2 of the business licence form
const validation2 = schemas.step2Schema;

/**
 * Step 2 of the business licence creation form
 * @param onSubmit
 * @param formId
 * @param submittedFormValues
 * @returns {JSX.Element}
 * @constructor
 */
const Step2 = ({ onSubmit, submittedFormValues, mode, onCancel }) => {
  const theme = useTheme();
  const [initValues, setInitValues] = useState(initialValues);
  const [province, setProvince] = useState("");
  const [mailingProvince, setMailingProvince] = useState("");
  const [viewBusiness, setViewBusiness] = React.useState(
    !!submittedFormValues?.businessCountry
      ? submittedFormValues?.businessCountry
      : AddressCountry.CA
  );
  const [viewMailing, setViewMailing] = React.useState(
    !!submittedFormValues?.mailingCountry
      ? submittedFormValues?.mailingCountry
      : AddressCountry.CA
  );

  const formRef = useRef();

  //Initially set too false to prevent errors before a user has a chance to enter the values
  const [validateOnChange, setValidateOnChange] = useState(false);

  //Handles the step submission
  const handleSubmit = (values) => {
    let tempValues = values;
    tempValues.mailingAddressProvince = mailingProvince;
    tempValues.mailingCountry = viewMailing;
    tempValues.businessCountry = viewBusiness;
    if (tempValues.mailingAddressCheckedAsBusinessAddress) {
      tempValues.mailingAddress = values.businessAddress;
      tempValues.mailingAddressCity = values.businessAddressCity;
      tempValues.mailingUnit = values.unit;
      tempValues.mailingCode = values.code;
      tempValues.mailingAddressProvince = province;
      tempValues.mailingCountry = viewBusiness;
    }
    tempValues.code = values.code;
    tempValues.phoneNumber = cleanPhoneNumber(values.phoneNumber);
    tempValues.secondPhone = cleanPhoneNumber(values.secondPhone);
    tempValues.faxNumber = cleanPhoneNumber(values.faxNumber);
    tempValues.mailingCode = values.mailingCode;
    tempValues.businessAddressProvince = province;

    onSubmit(tempValues);
  };

  const mailingSameAs = () => {
    let checked = true;
    if (
      submittedFormValues.businessAddress !== submittedFormValues.mailingAddress
    )
      checked = false;
    if (submittedFormValues.unit !== submittedFormValues.mailingUnit)
      checked = false;
    if (
      submittedFormValues.businessAddressCity !==
      submittedFormValues.mailingAddressCity
    )
      checked = false;
    if (
      submittedFormValues.businessAddressProvince !==
      submittedFormValues.mailingAddressProvince
    )
      checked = false;
    if (submittedFormValues.code !== submittedFormValues.mailingCode)
      checked = false;
    if (
      submittedFormValues.businessCountry !== submittedFormValues.mailingCountry
    )
      checked = false;
    return checked;
  };

  useEffect(() => {
    setProvince(submittedFormValues.businessAddressProvince);
    setMailingProvince(submittedFormValues.mailingAddressProvince);
    setInitValues({
      businessAddress: submittedFormValues.businessAddress,
      businessAddressCity: submittedFormValues.businessAddressCity,
      unit: submittedFormValues.unit,
      businessAddressProvince: submittedFormValues.businessAddressProvince,
      code: submittedFormValues.code,
      mailingAddress: submittedFormValues.mailingAddress,
      mailingAddressCity: submittedFormValues.mailingAddressCity,
      mailingUnit: submittedFormValues.mailingUnit,
      mailingAddressProvince: submittedFormValues.mailingAddressProvince,
      mailingCode: submittedFormValues.mailingCode,
      mailingAddressCheckedAsBusinessAddress: mailingSameAs(),
      phoneNumber: submittedFormValues.phoneNumber,
      email: submittedFormValues.email,
      faxNumber: submittedFormValues.faxNumber,
      secondPhone: submittedFormValues.secondPhone,
      websiteAddress: submittedFormValues.websiteAddress,
      businessCountry: submittedFormValues.businessCountry,
      mailingCountry: submittedFormValues.mailingCountry,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (event, businessCountry, setFieldValue) => {
    if (businessCountry !== null && businessCountry !== viewBusiness) {
      setViewBusiness(businessCountry);
      setProvince("");
      setFieldValue("businessAddress", "");
      setFieldValue("businessAddressCity", "");
      setFieldValue("unit", "");
      setFieldValue("businessAddressProvince", "");
      setFieldValue("code", "");
      setFieldValue("businessCountry", businessCountry);
    }
  };

  const handleToggleMailing = (event, mailingCountry, setFieldValue) => {
    if (mailingCountry !== null && mailingCountry !== viewMailing) {
      setViewMailing(mailingCountry);
      setMailingProvince("");
      setFieldValue("mailingAddress", "");
      setFieldValue("mailingAddressCity", "");
      setFieldValue("mailingUnit", "");
      setFieldValue("mailingAddressProvince", "");
      setFieldValue("mailingCode", "");
      setFieldValue("mailingCountry", mailingCountry);
    }
  };

  /**
   * Checks if form values have changed
   * @param initValue
   * @param value
   * @returns {boolean}
   */
  const formHasChanged = (initValue, value) => {
    for (const key of Object.keys(initValue)) {
      if (initValue[key] !== value[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <Formik
        initialValues={initValues}
        validationSchema={validation2}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validateOnChange={validateOnChange}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({
          handleBlur,
          handleChange,
          values,
          errors,
          validateForm,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form noValidate>
              <Typography variant={"h4"} fontWeight={"300"}>
                Business contact information
              </Typography>
              <Spacer amount={0.5} />
              <Typography
                variant={"body1"}
                color={theme.palette.blacks.highEmphasis}
              >
                We need this information to register your business and ensure
                zoning permissions are met.
              </Typography>
              <Spacer />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant={"overline"}>BUSINESS ADDRESS</Typography>
                  <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        padding: "0.5rem",
                        gap: "2px",
                        border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                        borderRadius: "15px",
                      }}
                    >
                      <ToggleButtonGroup
                        value={viewBusiness ?? AddressCountry.CA}
                        exclusive
                        onChange={(e, v) => {
                          handleToggle(e, v, setFieldValue);
                        }}
                        fullWidth
                      >
                        <ToggleButton
                          value={AddressCountry.CA}
                          style={{
                            marginRight: "3px",
                          }}
                        >
                          <CanadaFlagLogo
                            style={{
                              marginRight: "0.5rem",
                            }}
                          />
                          CANADA
                        </ToggleButton>
                        <ToggleButton value={AddressCountry.US}>
                          <UsFlagLogo
                            style={{
                              marginRight: "0.5rem",
                            }}
                          />
                          USA
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Grid>
                  <TextField
                    label="Address"
                    fullWidth
                    name="businessAddress"
                    id="businessAddress"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessAddress}
                    error={Boolean(errors.businessAddress)}
                    touched={touched.businessAddress ? "true" : "false"}
                    helperText={errors.businessAddress}
                  />
                  <TextField
                    optional={"true"}
                    fullWidth
                    label="Address 2 (optional)"
                    name="unit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.unit}
                    error={Boolean(errors.unit)}
                    touched={touched.unit ? "true" : "false"}
                    helperText={errors.unit}
                    style={{ marginTop: "0.5rem" }}
                  />
                  <TextField
                    label="City"
                    fullWidth
                    name="businessAddressCity"
                    id="businessAddressCity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessAddressCity}
                    error={Boolean(errors.businessAddressCity)}
                    touched={touched.businessAddressCity ? "true" : "false"}
                    inputProps={{ maxLength: 35 }}
                    helperText={errors.businessAddressCity}
                    style={{ marginTop: "0.5rem" }}
                  />
                </Grid>
                {viewBusiness === AddressCountry.CA ? (
                  <>
                    <Grid item xs={6}>
                      <HighlightedAutoComplete
                        label="Province"
                        fullWidth
                        name="businessAddressProvince"
                        id="businessAddressProvince"
                        onChange={(e, value) => {
                          setProvince(value.label);
                          setFieldValue("businessAddressProvince", value.label);
                        }}
                        onBlur={handleBlur}
                        value={province}
                        error={Boolean(errors.businessAddressProvince)}
                        helperText={
                          errors.businessAddressProvince
                            ? "Province required."
                            : ""
                        }
                        touched={
                          touched.businessAddressProvince ? "true" : "false"
                        }
                        options={CanadaProvinces}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MaskedTextField
                        fullWidth
                        type={"POSTAL"}
                        label="Postal code"
                        name="code"
                        id="code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                        initialValue={values.code}
                        error={Boolean(errors.code)}
                        helperText={errors.code}
                        touched={touched.code ? "true" : "false"}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <HighlightedAutoComplete
                        label="State"
                        fullWidth
                        name="businessAddressProvince"
                        id="businessAddressProvince"
                        onChange={(e, value) => {
                          setProvince(value.label);
                          setFieldValue("businessAddressProvince", value.label);
                        }}
                        onBlur={handleBlur}
                        value={province}
                        error={Boolean(errors.businessAddressProvince)}
                        helperText={
                          errors.businessAddressProvince ? "State required" : ""
                        }
                        touched={
                          touched.businessAddressProvince ? "true" : "false"
                        }
                        options={UsStates}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MaskedTextField
                        fullWidth
                        type={"ZIP"}
                        label="Zip code"
                        name="code"
                        id="code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                        initialValue={values.code}
                        helperText={errors.code}
                        error={Boolean(errors.code)}
                        touched={touched.code ? "true" : "false"}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Spacer />
                  <Typography variant={"overline"}>MAILING ADDRESS</Typography>
                  <CheckboxCard
                    label="Same as business address"
                    cardVariant="highlight"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mailingAddressCheckedAsBusinessAddress}
                    name="mailingAddressCheckedAsBusinessAddress"
                  />
                </Grid>
                {!values.mailingAddressCheckedAsBusinessAddress && (
                  <>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "auto",
                          padding: "0.5rem",
                          gap: "2px",
                          border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                          borderRadius: "15px",
                        }}
                      >
                        <ToggleButtonGroup
                          value={viewMailing}
                          exclusive
                          onChange={(e, v) => {
                            handleToggleMailing(e, v, setFieldValue);
                          }}
                          fullWidth
                        >
                          <ToggleButton
                            value={AddressCountry.CA}
                            style={{
                              marginRight: "3px",
                            }}
                          >
                            <CanadaFlagLogo
                              style={{
                                marginRight: "0.5rem",
                              }}
                            />
                            CANADA
                          </ToggleButton>
                          <ToggleButton value={AddressCountry.US}>
                            <UsFlagLogo
                              style={{
                                marginRight: "0.5rem",
                              }}
                            />
                            USA
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Mailing address"
                        fullWidth
                        name="mailingAddress"
                        id="mailingAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailingAddress}
                        error={Boolean(errors.mailingAddress)}
                        touched={touched.mailingAddress ? "true" : "false"}
                        helperText={errors.mailingAddress}
                      />
                      <TextField
                        optional={"true"}
                        fullWidth
                        label="Address 2 (optional)"
                        name="mailingUnit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailingUnit}
                        error={Boolean(errors.mailingUnit)}
                        touched={touched.mailingUnit ? "true" : "false"}
                        helperText={errors.mailingUnit}
                        style={{ marginTop: "0.5rem" }}
                      />
                      <TextField
                        label="City"
                        fullWidth
                        name="mailingAddressCity"
                        id="mailingAddressCity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailingAddressCity}
                        error={Boolean(errors.mailingAddressCity)}
                        touched={touched.mailingAddressCity ? "true" : "false"}
                        inputProps={{ maxLength: 35 }}
                        helperText={errors.mailingAddressCity}
                        style={{ marginTop: "0.5rem" }}
                      />{" "}
                    </Grid>

                    {viewMailing === AddressCountry.CA ? (
                      <>
                        <Grid item xs={6}>
                          <HighlightedAutoComplete
                            label="Province"
                            fullWidth
                            name="mailingAddressProvince"
                            id="mailingAddressProvince"
                            onChange={(e, value) => {
                              setMailingProvince(value.label);
                              setFieldValue(
                                "mailingAddressProvince",
                                value.label
                              );
                            }}
                            onBlur={handleBlur}
                            value={mailingProvince}
                            error={Boolean(errors.mailingAddressProvince)}
                            helperText={
                              errors.mailingAddressProvince
                                ? "Province required."
                                : ""
                            }
                            touched={
                              touched.mailingAddressProvince ? "true" : "false"
                            }
                            options={CanadaProvinces}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MaskedTextField
                            fullWidth
                            type={"POSTAL"}
                            label="Postal code"
                            name="mailingCode"
                            id="mailingCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingCode}
                            initialValue={values.mailingCode}
                            error={Boolean(errors.mailingCode)}
                            helperText={errors.mailingCode}
                            touched={touched.mailingCode ? "true" : "false"}
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={6}>
                          <HighlightedAutoComplete
                            label="State"
                            fullWidth
                            name="mailingAddressProvince"
                            id="mailingAddressProvince"
                            onChange={(e, value) => {
                              setMailingProvince(value.label);
                              setFieldValue(
                                "mailingAddressProvince",
                                value.label
                              );
                            }}
                            onBlur={handleBlur}
                            value={mailingProvince}
                            error={Boolean(errors.mailingAddressProvince)}
                            helperText={
                              errors.mailingAddressProvince
                                ? "State required"
                                : ""
                            }
                            touched={
                              touched.mailingAddressProvince ? "true" : "false"
                            }
                            options={UsStates}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MaskedTextField
                            fullWidth
                            type={"ZIP"}
                            label="Zip code"
                            name="mailingCode"
                            id="mailingCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingCode}
                            initialValue={values.mailingCode}
                            error={Boolean(errors.mailingCode)}
                            helperText={errors.mailingCode}
                            touched={touched.mailingCode ? "true" : "false"}
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Spacer />
                  <Typography variant={"overline"}>
                    BUSINESS CONTACT INFORMATION
                  </Typography>
                  <MaskedTextField
                    type={"PHONE"}
                    label="Phone number"
                    fullWidth
                    name="phoneNumber"
                    id="phoneNumber"
                    setFieldValue={(value) =>
                      setFieldValue("phoneNumber", value)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    error={Boolean(errors.phoneNumber)}
                    touched={touched.phoneNumber ? "true" : "false"}
                    helperText={errors.phoneNumber}
                    initialValue={values.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={Boolean(errors.email)}
                    touched={touched.email ? "true" : "false"}
                    inputProps={{ maxLength: 50 }}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MaskedTextField
                    optional={"true"}
                    type={"PHONE"}
                    label={"Fax number (optional)"}
                    fullWidth
                    name="faxNumber"
                    id="faxNumber"
                    setFieldValue={(value) => setFieldValue("faxNumber", value)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.faxNumber}
                    error={Boolean(errors.faxNumber)}
                    touched={touched.faxNumber ? "true" : "false"}
                    helperText={errors.faxNumber}
                    initialValue={values.faxNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MaskedTextField
                    optional={"true"}
                    type={"PHONE"}
                    label={"Secondary phone number (optional)"}
                    fullWidth
                    name="secondPhone"
                    id="secondPhone"
                    setFieldValue={(value) =>
                      setFieldValue("secondPhone", value)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.secondPhone}
                    error={Boolean(errors.secondPhone)}
                    touched={touched.secondPhone ? "true" : "false"}
                    helperText={errors.secondPhone}
                    initialValue={values.secondPhone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    optional={"true"}
                    label={"Website address (optional)"}
                    fullWidth
                    name="websiteAddress"
                    id="websiteAddress"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.websiteAddress}
                    error={Boolean(errors.websiteAddress)}
                    touched={touched.websiteAddress ? "true" : "false"}
                    inputProps={{ maxLength: 50 }}
                    helperText={errors.websiteAddress}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Spacer />
                  <Button
                    fullWidth
                    size={"large"}
                    variant="contained"
                    type="submit"
                    disabled={
                      mode === EDIT
                        ? !formHasChanged(initValues, values)
                        : false
                    }
                    onClick={async () => {
                      const validationResults = await validateForm();
                      const err = Object.keys(validationResults);
                      if (err.length) {
                        console.log(`#${err[0]}`);
                        const input =
                          document.querySelector(`#${err[0]}`) ||
                          document.querySelector(`input[name='${err[0]}']`);
                        if (input !== null) {
                          input.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start",
                          });
                        }
                      }
                      setValidateOnChange(true);
                      if (values.mailingAddressCheckedAsBusinessAddress) {
                        values.mailingAddress = values.businessAddress;
                        values.mailingUnit = values.unit;
                        values.mailingCode = values.code;
                      }
                    }}
                  >
                    {mode === EDIT ? "Save" : "Continue"}
                  </Button>
                  {mode === EDIT ? (
                    <>
                      <Spacer />
                      <Button
                        fullWidth
                        size={"large"}
                        variant="outlined"
                        type="cancel"
                        onClick={() => {
                          onCancel("General");
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Step2;
