import React from "react";
import classes from "./Missing.module.scss";
import WBCBreadcrumbs from "../../../components/ui-kit/breadcrumbs";
import { Home, Search } from "@mui/icons-material";
import missing from "../../../../assets/images/missing_search.png";
import { routes } from "../../../routes/routesData";
import { useHistory } from "react-router-dom";

const Missing = () => {
  const history = useHistory();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.top_bar}>
          <WBCBreadcrumbs
            children={[
              {
                icon: <Home fontSize={"small"} />,
                text: "HOME",
                onClick: () => history.replace(routes.LICENCES.path),
              },
              {
                icon: <Search fontSize={"small"} />,
                text: "MISSING",
              },
            ]}
          />
          <div />
        </div>
        <div className={classes.missing_container}>
          <div className={classes.missing}>
            <p className={classes.title}>Oops!</p>
            <p>Something went wrong. Please try again. If the error persists contact support.</p>
          </div>
          <div className={classes.missing}>
            <img src={missing} alt={"Not Found"}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Missing;
