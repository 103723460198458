import React, { useContext } from "react";
import classes from "./Unauthorized.module.scss";
import WBCBreadcrumbs from "../../../components/ui-kit/breadcrumbs";
import { Home, Lock } from "@mui/icons-material";
import missing from "../../../../assets/images/missing_search.png";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { AuthContext } from "../../../contexts/AuthContext";

const Unauthorized = () => {
    const history = useHistory();
    const { getDefaultURL } = useContext(AuthContext);

    return (
        <>
            <div className={classes.container}>
                <div className={classes.top_bar}>
                    <WBCBreadcrumbs
                        children={[
                            {
                                icon: <Home fontSize={"small"} />,
                                text: "HOME",
                                onClick: () => history.replace(getDefaultURL())
                            },
                            {
                                icon: <Lock fontSize={"small"} />,
                                text: "UNAUTHORIZED"
                            }
                        ]}
                    />
                    <div />
                </div>
                <div className={classes.unauthorized_container}>
                    <div className={classes.unauthorized}>
                        <p className={classes.title}>Access Denied</p>
                        <p>You do not have permission to view this page. Please return to the dashboard or contact
                            support for help.</p>
                        <Button
                            variant={"outlined"}
                            size={"medium"}
                            onClick={() => history.replace(getDefaultURL())}
                        >
                            Return to dashboard
                        </Button>
                    </div>
                    <div className={classes.unauthorized}>
                        <img src={missing} alt={"Not Found"} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Unauthorized;
