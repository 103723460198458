import React from "react";
import { Box, Skeleton } from "@mui/material";
import Spacer from "../../../../components/ui-kit/Spacer";

const RightSidebarSkeleton = () => {
  return (
    <Box p={2}>
      <Skeleton variant={"rectangular"} height={60} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={170} />
      <Spacer />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </Box>
  );
};

export default RightSidebarSkeleton;
