import { capitalize } from "../../utilities";

export const DRAFT = "draft";
export const SUBMITTED = "submitted";
export const REVIEWING = "reviewing";
export const SAVED = "saved";
export const CANCELLED = "cancelled";
export const DECLINED = "declined";
export const DISCARDED = "discarded";
export const ACTIVE = "active";
export const APPROVED = "approved";
export const ISSUED = "issued";
export const RENEWING = "renewing";
export const EXPIRING = "expiring";

//Only licences with these statuses can be edited
export const editStatuses = [ACTIVE, SAVED, RENEWING];
export const editDocumentStatuses = [DRAFT, SAVED, REVIEWING, SUBMITTED];
export const editDocumentChangesetStatuses = [DRAFT, REVIEWING, SUBMITTED];

export const getDisplayStatus = (status, changeSetStatus = null) => {
  let displayStatus = capitalize(status);
  switch (status) {
    case DRAFT:
    case SAVED:
      displayStatus = "Draft";
      break;
    case SUBMITTED:
      displayStatus = "Submitted";
      break;
    case REVIEWING:
      displayStatus = "Under review";
      break;
    case APPROVED:
      displayStatus = "Approved";
      break;
    case ISSUED:
      displayStatus = "Issued";
      break;
    case ACTIVE:
      displayStatus = "Active";
      break;
    case RENEWING:
      displayStatus = "Renewal required";
      break;
    default:
      break;
  }

  if (changeSetStatus === SUBMITTED || changeSetStatus === APPROVED) {
    displayStatus = "Changes under review";
  }

  return displayStatus;
};
