import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { PaginationItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import classes from "./index.module.scss";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 0;
  }

  .MuiInputBase-input {
    padding: 6px 10px 7px;
    background-color: ${({ theme }) => theme.WHITE};
  }
`;

const StyledPaginationItem = styled(PaginationItem)`
  && {
    &&.MuiPaginationItem-root {
      font-weight: ${({ visited }) =>
        visited ? "400 !important" : "500 !important"};
    }

    &&.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary[100]};
    }

    color: ${({ theme }) => theme.palette.primary[200]};

    .MuiPaginationItem-icon {
      color: ${({ theme }) => theme.palette.primary[500]};
      background-color: unset;
    }

    &&&.Mui-selected {
      color: ${({ theme }) => theme.palette.primary[700]};
    }
  }
`;

const RowsPerPageLabel = styled(Typography)`
  && {
    margin-right: 0.5rem;
  }
`;

const RowsPerPageIcon = styled.div(
  ({ theme }) => css`
    position: absolute;
    cursor: pointer;
    right: 2px;
    top: 4px;

    & .material-symbols-rounded {
      background-color: white;
    }
  `
);

const TablePagination = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
  visitedPages,
  setVisitedPages,
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    if (!visitedPages.find((x) => x === page)) {
      setVisitedPages((prevState) => {
        return [...prevState, page];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className={classes.container}>
      <div className={classes.rows_per_page}>
        <RowsPerPageLabel variant={"body1"}>Rows per page:</RowsPerPageLabel>
        <StyledSelect
          open={selectOpen}
          onClick={() => setSelectOpen(!selectOpen)}
          variant="outlined"
          value={rowsPerPage}
          onChange={onChangeRowsPerPage}
          IconComponent={(props) => (
            <RowsPerPageIcon
              onClick={() => {
                setSelectOpen(!selectOpen);
              }}
            >
              {selectOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </RowsPerPageIcon>
          )}
        >
          {rowsPerPageOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
      </div>
      <Pagination
        showFirstButton
        showLastButton
        count={totalPages}
        onChange={(_, nextPage) => {
          onChangePage(nextPage - 1);
        }}
        page={page + 1}
        renderItem={(item) => (
          <StyledPaginationItem
            visited={
              item.page === 1 ||
              Boolean(visitedPages.find((x) => x === item.page - 1))
                ? "true"
                : "false"
            }
            {...item}
          />
        )}
      />
    </div>
  );
};

export default TablePagination;
