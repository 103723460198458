import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import {
  BLACK_HIGH_EMPHASIS,
  BLACK_LOW_EMPHASIS,
} from "../../../../mui-theme/theme";

const StyledButton = styled.button`
  all: unset;
  display: flex;
  align-self: center;
  cursor: pointer;
`;

/**
 * Allows a user to toggle inactive accounts
 * @param onChange
 * @param field
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export default function StatusSelect({ onChange, field, value }) {
  const [hideInactive, setHideInactive] = useState(false);

  //Every time a user presses the button, this function is called
  useEffect(() => {
    //Sets filter to only show active accounts, otherwise it shows all
    if (hideInactive) {
      onChange(field, "ACTIVE");
    } else {
      onChange(field, "");
    }
  }, [hideInactive, field, onChange]);

  useEffect(() => {
    if (!value) {
      setHideInactive(false);
    }
  }, [value]);

  return (
    <>
      {hideInactive ? (
        <StyledButton onClick={() => setHideInactive(false)}>
          <Typography variant={"body1"} style={{ color: BLACK_LOW_EMPHASIS }}>
            Show inactive
          </Typography>
        </StyledButton>
      ) : (
        <StyledButton onClick={() => setHideInactive(true)}>
          <Typography variant={"body1"} style={{ color: BLACK_HIGH_EMPHASIS }}>
            Hide inactive
          </Typography>
        </StyledButton>
      )}
    </>
  );
}
