import React, { useCallback, useContext, useEffect, useState } from "react";
import "./index.scss";
import WBCBreadcrumbs from "../../../components/ui-kit/breadcrumbs";
import { AddRounded, Home } from "@mui/icons-material";
import { routes } from "../../../routes/routesData";
import { useHistory } from "react-router-dom";
import {
  delete_profile,
  get_payment_profiles,
} from "../../../../core/apis/payment";
import { ReactComponent as EmptyStateImage } from "../../../../assets/images/empty-screen-place-holder.svg";
import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  setState as setConfirmationDialogState,
} from "../../../redux/slices/confrmation-dialog";
import PaymentMethodItem from "./payment-method-item";
import { NotificationContext } from "../../../contexts/NotificationContext";

const ManagePaymentMethods = () => {
  const history = useHistory();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { handleError, handleSuccess } = useContext(NotificationContext);

  /**
   * With initial render of the screen, calls the function for getting list of cards
   */
  useEffect(() => {
    getUserPaymentMethods().then();
    // eslint-disable-next-line
  }, []);

  /**
   * Calls the api for getting the user payment methods and populates the [paymentMethods] in inner state with reversed list
   */
  const getUserPaymentMethods = useCallback(async () => {
    const response = await get_payment_profiles();
    setPaymentMethods(response?.cards?.reverse() ?? []);
    setLoading(false);
  }, []);

  const onAddCreditCard = () => {
    history.push("/cc-add");
  };

  const onEditCreditCard = (id, card, isDefault) => {
    history.push(`/cc-add`, {
      profileId: id,
      profileData: card,
      isDefault,
    });
  };

  /**
   * Opens confirmation dialog for removing a credit card and upon confirmation calls the [_onDeleteCreditCardConfirmed] call back
   * @param id {string} credit card id
   */
  const onDeleteCreditCard = (id) => {
    dispatch(
      setConfirmationDialogState({
        open: true,
        title: "Delete Payment Method",
        body: "Are you sure you want to delete this stored payment method?",
        onConfirm: () => _onDeleteCreditCardConfirmed(id),
      }),
    );
  };

  /**
   * Callback function passed to confirmation dialog
   * @param id
   * @returns {Promise<void>}
   * @private
   */
  const _onDeleteCreditCardConfirmed = async (id) => {
    setLoading(true);
    dispatch(closeDialog());
    const response = await delete_profile(id);
    if (response) {
      setPaymentMethods((prevState) => prevState.filter((e) => e.id !== id));
      handleSuccess("Payment method successfully deleted.");
    } else {
      handleError("Failed to delete payment method.");
    }
    setLoading(false);
  };

  return (
    <>
      <div className={"screen"}>
        <div className={"top_bar"}>
          <WBCBreadcrumbs
            children={[
              {
                icon: <Home fontSize={"small"} />,
                text: "HOME",
                onClick: () => history.replace(routes.LICENCES.path),
              },
              {
                text: "MANAGE PAYMENTS",
              },
            ]}
          />
          <div />
        </div>
        <div className={"title"}>
          <p>Manage Payments</p>
        </div>
        <div className={"content-container"}>
          {loading ? (
            <div className={"items-list-container"}>
              <Skeleton
                variant="rounded"
                width={200}
                height={20}
                style={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rounded"
                width={200}
                height={20}
                style={{ marginBottom: "2rem" }}
              />
              <div className={"items-container"}>
                {Array(8)
                  .fill(null)
                  .map((_) => (
                    <Skeleton
                      variant="rounded"
                      width={335}
                      height={170}
                      style={{ marginRight: "2rem", marginBottom: "2rem" }}
                    />
                  ))}
              </div>
            </div>
          ) : paymentMethods.length > 0 ? (
            <div className={"items-list-container"}>
              <div
                className={"place-holder-content"}
                style={{ marginBottom: "1rem" }}
              >
                <p className={"place-holder-text"}>Payment Methods</p>
                <div
                  className={`add-payment-method-btn ${
                    paymentMethods.length > 4 ? "disabled" : ""
                  }`}
                  onClick={
                    paymentMethods.length > 4 ? () => false : onAddCreditCard
                  }
                >
                  <AddRounded />
                  <p className={"text-button"}>Add a payment method</p>
                  <p className={"indicator"}>{`${paymentMethods.length}/5`}</p>
                </div>
              </div>
              <div className={"items-container"}>
                {paymentMethods.map((card) => (
                  <PaymentMethodItem
                    card={card}
                    onDeleteCreditCard={onDeleteCreditCard}
                    onEditCreditCard={onEditCreditCard}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={"place-holder-container"}>
              <div className={"place-holder-content"}>
                <p className={"place-holder-text"}>No credit card on file.</p>
                <div
                  className={"add-payment-method-btn"}
                  onClick={onAddCreditCard}
                >
                  <AddRounded />
                  <p className={"text-button"}>Add a payment method</p>
                </div>
              </div>
              <EmptyStateImage className={"place-holder-image"} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ManagePaymentMethods;
