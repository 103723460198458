import React, { useContext, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { default as Typography } from "@mui/material/Typography";
import {
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import GridWrapper from "../../../components/ui-kit/GridWrapper";
import ActionList from "../../../components/ui-kit/ActionList";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { get_admins } from "../../../../core/apis/admin";
import { get_admin_actions } from "../../../../core/apis/action";
import IconButton from "@mui/material/IconButton";
import {
  DynamicFeedRounded,
  KeyboardArrowDown,
  ListRounded,
  Refresh,
  RestoreRounded,
} from "@mui/icons-material";
import classes from "./index.module.scss";
import Checkbox from "@mui/material/Checkbox";
import useLoading from "../../../hooks/useLoading";

// un-assigned / to do / others / mine

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const { loadingStates, setLoadingStates } = useLoading([
    "todo",
    "unassigned",
  ]);
  const [unassignedDataOrg, setUnassignedDataOrg] = useState([]);
  const [unassignedData, setUnassignedData] = useState([]);
  const [todoDataOrg, setTodoDataOrg] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [doneFilter, setDoneFilter] = useState(false);
  // const [previousQueryOptions, setPreviousQueryOptions] = useState(null);
  const [view, setView] = React.useState("pending");
  const [othersOrg, setOthersOrg] = useState([]);
  const [others, setOthers] = useState([]);
  const [mineOrg, setMineOrg] = useState([]);
  const [mine, setMine] = useState([]);
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const isFilterSectionOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const [filters, setFilters] = useState({
    modules: {
      utilities: true,
      businessLicence: true,
    },
    actions: {
      review: true,
      assign: true,
      approveDecline: true,
    },
  });

  const theme = useTheme();

  const { handleError } = useContext(NotificationContext);

  const loadAdmins = async () => {
    setLoadingStates(["todo", "unassigned"], true);
    try {
      const getAdminsRes = await get_admins();
      const filters = {};
      const adminActions = await get_admin_actions({
        filters,
      });

      let unassignedDataRes = [];
      let todoRes = [];
      let otherRes = [];
      let mineRes = [];

      if (adminActions?.length) {
        unassignedDataRes = [
          ...unassignedDataRes,
          ...adminActions.filter(({ actor }) => !actor.userId),
        ];
        todoRes = [
          ...todoRes,
          ...adminActions.filter(
            ({ actionName, actor }) =>
              ((actionName === "Reviewing Application" ||
                actionName === "Reviewing Changes") &&
                actor.status === "assigned") ||
              ((actionName === "Issue/Decline Licence" ||
                actionName === "Issue/Decline Changes") &&
                actor.status === "assigned"),
          ),
        ];
        if (user?.id) {
          otherRes = [
            ...otherRes,
            ...adminActions.filter(
              ({ actor }) =>
                actor.userId &&
                actor.status !== "assigned" &&
                actor.userId !== user?.id,
            ),
          ];
          mineRes = [
            ...mineRes,
            ...adminActions.filter(
              ({ actor }) =>
                actor.userId &&
                actor.status !== "assigned" &&
                actor.userId === user?.id,
            ),
          ];
        }
      }

      todoRes.forEach((action) => {
        action.assignedAdmin = getAdminsRes.result.find(
          (admin) => admin.id === action.assignedAdminId,
        );
      });

      setUnassignedDataOrg(unassignedDataRes);
      setTodoDataOrg(todoRes);
      setAdmins(getAdminsRes?.result);
      setOthersOrg(otherRes);
      setMineOrg(mineRes);
      return { actionItems: [...unassignedDataRes, ...todoRes] };
    } catch (err) {
      console.error(err);
      console.log(admins);
      console.log(loading);
      handleError("Error while loading data. Please refresh.");
    } finally {
      setLoading(false);
      setLoadingStates(["todo", "unassigned"], false);
    }
  };

  const onClickRow = (action) => {
    if (action.targetType === "utility_account") {
      if (action.businessName.includes("Service")) {
        history.push(`/admin/utilities/${action?.licenseId}#Services`, action);
      } else {
        history.push(`/admin/utilities/${action?.licenseId}`, action);
      }
    } else {
      history.push(`/admin/licences/${action?.licenseId}`, action);
    }
  };

  const handleToggle = (event, nextView) => {
    setView(nextView);
  };

  useEffect(() => {
    loadAdmins().then();
    // eslint-disable-next-line
  }, [doneFilter]);

  useEffect(() => {
    setUnassignedData(
      unassignedDataOrg.filter(
        (e) =>
          [
            filters.modules.utilities ? "utility_account" : "",
            filters.modules.businessLicence ? "license" : "",
          ].includes(e.targetType) &&
          (e.actionName
            .toLowerCase()
            .includes(filters.actions.review ? "review " : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.assign ? "assign " : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.approveDecline ? "issue/" : "%")),
      ),
    );
    setMine(
      mineOrg.filter(
        (e) =>
          [
            filters.modules.utilities ? "utility_account" : "",
            filters.modules.businessLicence ? "license" : "",
          ].includes(e.targetType) &&
          (e.actionName
            .toLowerCase()
            .includes(filters.actions.review ? "reviewing" : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.assign ? "assign" : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.approveDecline ? "issue/" : "%")),
      ),
    );
    setTodoData(
      todoDataOrg.filter(
        (e) =>
          [
            filters.modules.utilities ? "utility_account" : "",
            filters.modules.businessLicence ? "license" : "",
          ].includes(e.targetType) &&
          (e.actionName
            .toLowerCase()
            .includes(filters.actions.review ? "reviewing" : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.assign ? "assign" : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.approveDecline ? "issue/" : "%")),
      ),
    );
    setOthers(
      othersOrg.filter(
        (e) =>
          [
            filters.modules.utilities ? "utility_account" : "",
            filters.modules.businessLicence ? "license" : "",
          ].includes(e.targetType) &&
          (e.actionName
            .toLowerCase()
            .includes(filters.actions.review ? "reviewing " : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.assign ? "assign" : "%") ||
            e.actionName
              .toLowerCase()
              .includes(filters.actions.approveDecline ? "issue/" : "%")),
      ),
    );
  }, [filters, mineOrg, othersOrg, todoDataOrg, unassignedDataOrg]);

  const onFilterSectionClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onFilterChanged = (section, filter) => {
    setFilters((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [filter]: !prevState[section][filter],
      },
    }));
  };

  /**
   * @type {React.JSX}
   */
  const filterSectionContent = useMemo(() => {
    return (
      <div style={{ display: "flex", padding: "0.5rem 2rem 0.5rem 0.5rem" }}>
        <div className={classes.filter_column}>
          <p className={classes.filter_title}>Modules</p>
          <div className={classes.filter_row}>
            <Checkbox
              color={"primary"}
              checked={filters.modules.utilities}
              onChange={() => onFilterChanged("modules", "utilities")}
            />
            <p>Utilities</p>
          </div>
          <div className={classes.filter_row}>
            <Checkbox
              color={"primary"}
              checked={filters.modules.businessLicence}
              onChange={() => onFilterChanged("modules", "businessLicence")}
            />
            <p>Business Licence</p>
          </div>
        </div>
        <div className={classes.filter_column} style={{ marginLeft: "2rem" }}>
          <p className={classes.filter_title}>Actions</p>
          <div className={classes.filter_row}>
            <Checkbox
              color={"primary"}
              checked={filters.actions.review}
              onChange={() => onFilterChanged("actions", "review")}
            />
            <p>Review</p>
          </div>
          <div className={classes.filter_row}>
            <Checkbox
              color={"primary"}
              checked={filters.actions.assign}
              onChange={() => onFilterChanged("actions", "assign")}
            />
            <p>Assign</p>
          </div>
          <div className={classes.filter_row}>
            <Checkbox
              color={"primary"}
              checked={filters.actions.approveDecline}
              onChange={() => onFilterChanged("actions", "approveDecline")}
            />
            <p>Issue/Decline</p>
          </div>
        </div>
      </div>
    );
  }, [filters]);

  return (
    <div className={"dashboard-screen"}>
      <div className={"dashboard-screen-content"}>
        <GridWrapper gap={3}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              marginBottom: "1rem",
              alignItems: "center",
            }}
          >
            <DynamicFeedRounded sx={{ width: "42px", height: "42px" }} />
            <Typography variant="h3" sx={{ marginRight: "0.5rem" }}>
              Action Centre
            </Typography>
            <IconButton
              color={"primary"}
              onClick={() => window.location.reload(false)}
            >
              <Refresh sx={{ height: "32px", width: "32px" }} />
            </IconButton>
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                padding: "0.5rem",
                gap: "2px",
                border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                borderRadius: "15px",
              }}
            >
              <ToggleButtonGroup value={view} exclusive onChange={handleToggle}>
                <ToggleButton
                  value="pending"
                  style={{
                    marginRight: "3px",
                  }}
                  onClick={() => setDoneFilter(false)}
                >
                  <ListRounded
                    sx={{
                      marginRight: "0.5rem",
                    }}
                  />
                  Pending
                </ToggleButton>
                <ToggleButton
                  value="history"
                  onClick={() => setDoneFilter(true)}
                >
                  <RestoreRounded
                    sx={{
                      marginRight: "0.5rem",
                    }}
                  />
                  History
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
          <div
            className={`${classes.drop_down} ${
              isFilterSectionOpen ? classes.open : ""
            }`}
            onClick={onFilterSectionClicked}
          >
            <p>Filter</p>
            <KeyboardArrowDown />
          </div>
          <Popover
            className={"module-section-popover"}
            open={isFilterSectionOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {filterSectionContent}
          </Popover>
          <Grid item>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              alignContent="center"
            >
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid>
                <Grid style={{ marginBottom: "1rem" }}>
                  <Typography variant="h4">
                    {!doneFilter ? "Unassigned" : "Others"} (
                    {!doneFilter ? unassignedData.length : others.length}):
                  </Typography>
                </Grid>
                <Grid>
                  <ActionList
                    loadingItems={loadingStates["unassigned"]}
                    data={doneFilter ? others : unassignedData}
                    onClickRow={onClickRow}
                    loadAdmins={loadAdmins}
                    useState={unassignedData}
                    emptyText={
                      !doneFilter ? "Nothing waiting. You're up to date!" : ""
                    }
                    user={user}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid>
                <Grid container style={{ marginBottom: "1rem" }}>
                  <Grid item xs={8}>
                    <Typography variant="h4">
                      {!doneFilter ? "In progress" : "Mine"} (
                      {!doneFilter ? todoData.length : mine.length}
                      ):
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <Grid>
                  <ActionList
                    loadingItems={loadingStates["todo"]}
                    data={doneFilter ? mine : todoData}
                    onClickRow={onClickRow}
                    loadAdmins={loadAdmins}
                    background={theme.palette.primary[100]}
                    emptyText={
                      !doneFilter
                        ? "Start by assigning tasks from the left column."
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Dashboard;
