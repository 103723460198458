import React, { useCallback, useRef } from "react";
import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { IStep, IWizardStepTracker } from "./interfaces";
import classes from "./index.module.scss";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Divider from "../Divider";

const WizardStepTracker = ({
  activeStep,
  onClose,
  steps,
  setActiveStep,
  mobileSize = "500px",
}: IWizardStepTracker) => {
  const ref = useRef() as any;
  const isMobile = useMediaQuery(`(max-width:${mobileSize})`);

  const getLabel = useCallback(
    (step: IStep, index: number) => {
      if (isMobile) return "";
      if (activeStep > index) {
        return <p className={classes.step_container_link}>{step.label}</p>;
      } else {
        return step.label;
      }
    },
    [activeStep, isMobile]
  );

  const onClick = useCallback(
    (index: number) => {
      if (activeStep > index) {
        setActiveStep(index);
      }
    },
    [activeStep, setActiveStep]
  );

  return (
    <div className={classes.stepper}>
      <div
        ref={ref}
        className={classes.step_container}
        style={{ margin: isMobile ? "0" : "0 auto" }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={() => onClick(index)}>
                {getLabel(step, index)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {onClose ? (
          <div className={classes.step_container_icon}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        ) : null}
      </div>
      <Divider />
    </div>
  );
};

export default WizardStepTracker;
