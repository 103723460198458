import React from "react";
import Grid from "@mui/material/Grid";

const GridWrapper = ({ className, children, gap, ...rest }) => {
  return (
    <Grid container className={className} {...rest}>
      {children}
    </Grid>
  );
};

export default GridWrapper;
