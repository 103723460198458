import { manageSession } from "../../utilities/manageSession";
import { jwtDecode } from "jwt-decode";
import base from "./axiosInstance";

//TODO: add the type interfaces for params and decoded result
export const get_admin_actions = async (params?: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwtDecode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/action/${decoded.cityId}/summaries`, {
      params,
    });
    return response.data;
  } catch (error) {}
};
