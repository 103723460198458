import React from "react";
import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {SxProps, Theme} from "@mui/material";

interface IDatePicker {
    value: any;
    onChange: any;
    label: string;
    minDate?: unknown;
    sx?: SxProps<Theme> | undefined;
    name?: string | undefined;
    textFieldError?: boolean | undefined;
    textFieldHelperText?: string | undefined;
}

const DatePicker = ({value, onChange, label, minDate = moment(), sx, name, textFieldError, textFieldHelperText}: IDatePicker) => {
  return (
    <MuiDatePicker
        sx={sx}
      maxDate={moment("01-01-3000")}
      minDate={minDate}
      label={label}
      views={['year', 'month', 'day']}
      onChange={(newValue) => {
        // @ts-ignore
        onChange(moment(newValue))
      }}
        value={value}
        slotProps={{textField: {name: name, error: textFieldError, helperText: textFieldHelperText}}}
    />
  );
};

export default DatePicker;
