import React from "react";
import ListItem from "./list-item";
import { PersonOutline } from "@mui/icons-material";
import classes from "../../screens/my-city-hall/utilities/utility-details/index.module.scss";
import DetailsContainer from "../../screens/my-city-hall/utilities/utility-details/widgets/details-container";
import { IContact } from "../../screens/my-city-hall/utilities/utility-details/interfaces/IContact";

interface IShareEBill {
  loading: boolean;
  setOpenEditContactModal?: any;
  contactItems: IContact[];
  emptyText?: string;
}

const ShareEBill = ({
  loading,
  setOpenEditContactModal,
  contactItems,
  emptyText = "Effortlessly share your monthly utility e-bills and payment" +
    " notifications by adding your email contacts.",
}: IShareEBill) => {
  return (
    <DetailsContainer
      loading={loading}
      title={"Share E-bill"}
      buttonLabel={"Edit contacts"}
      buttonOnClick={() => setOpenEditContactModal(true)}
      buttonDisable={!setOpenEditContactModal}
      isEditable={Boolean(setOpenEditContactModal)}
    >
      {contactItems?.length > 0 ? (
        contactItems.map((item: IContact) => {
          return (
            //@ts-ignore
            <ListItem
              key={item.email}
              label={`${item.first_name} ${item.last_name}`}
              subLabel={[item.email, item.phone]}
              startOrnament={<PersonOutline />}
            />
          );
        })
      ) : (
        <p className={classes.empty_text}>
          {emptyText}
        </p>
      )}
    </DetailsContainer>
  );
};

export default ShareEBill;
