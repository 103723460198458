import React from "react";
import styled from "@emotion/styled";

const StyledSpacer = styled.div`
  height: ${({ amount }) => `${amount}rem`};
`;

export default function Spacer({ amount = 1 }) {
  return <StyledSpacer amount={amount} />;
}

