import styled from "styled-components";
import LOGO from "../../../../assets/images/empty-table.svg";

const EmptyTable = styled.img.attrs(() => ({
  src: LOGO,
  alt: "Empty Table",
}))`
  width: 100%;
`;

export default EmptyTable;
