import React, { useEffect, useState } from "react";
import { FilterType } from "../../../../../core/constants/enums";
import moment from "moment";
import { keepFilters, sanitizeUnderscore } from "../../../../../utilities";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import StatusSelect from "../../status-select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { BLACK_HIGH_EMPHASIS, OUTLINE } from "../../../../../mui-theme/theme";
import Button from "../../Button";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import { Chip } from "@mui/material";
import MaskedTextField from "../../masked-text-field";
import Select from "../../select";
import { LOWER, UPPER } from "../../../../../core/constants";

const FilterLabel = styled.h3`
  margin-top: 1rem;
  margin-bottom: 0.7rem;
`;

const FilterOption = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  text-transform: capitalize;
  margin-bottom: 1rem;
`;

const RadioOption = styled(Grid)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  text-transform: capitalize;
  margin-bottom: 1rem;
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
    margin-right: 0.5rem;
  }
`;

const StyledChip = styled(Chip)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    padding-top: 0.3rem;
    padding-right: 0.5rem;
  }
`;

const ChipTextField = styled(MaskedTextField)`
  && {
    .MuiOutlinedInput-input {
      width: ${({ inputwidth }) => inputwidth};
    }

    .MuiOutlinedInput-root {
      border-radius: 100px;
      background-color: ${({ theme }) => theme.WHITE};
      margin-right: 0.5rem;

      &:hover fieldset {
        border-color: ${OUTLINE};
      }

      & fieldset {
        border-color: ${OUTLINE};
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary[50]};
      }

      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.primary[200]};
        border-width: 2px;
      }
    }

    .MuiInputBase-input {
      padding: 6px 0 6px 12px;
      font-weight: normal;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 5px;
    }
  }
`;

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.WHITE};
    margin: 0 5px;
  }

  .MuiInputBase-input {
    padding: 6px 10px 7px;
  }
`;

const FilterGroup = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

export default function SearchFilterV2({
  filters,
  fields,
  onFilter,
  clearFilters,
  filtersIgnoreClear,
}) {
  const [selectedFilters, setSelectedFilters] = useState(filters || {});

  const getFilterFields = (filter) =>
    fields.filter((field) => field.filterType === filter);

  const fieldsForFilter = getFilterFields(FilterType.CHECKBOX);
  const fieldsForRadio = getFilterFields(FilterType.RADIO);
  const fieldsForDatePicker = getFilterFields(FilterType.DATE_PICKER);
  const fieldsForChip = getFilterFields(FilterType.CHIP);
  const fieldsForSelect = getFilterFields(FilterType.SELECT);
  const fieldsForCurrencyTextField = getFilterFields(
    FilterType.CURRENCY_TEXT_FIELD
  );
  const fieldsForBoolean = getFilterFields(FilterType.BOOLEAN);

  useEffect(() => {
    handleApplyFilters();
    // eslint-disable-next-line
  }, [selectedFilters]);

  const createFilterObject = (value, json) => {
    if (json) return { value, json };
    return value;
  };

  const handleApplyFilters = () => {
    onFilter(selectedFilters);
  };

  const handleSelectFilter = (fieldId, optionValue) => {
    const newFilters = { ...selectedFilters };

    if (newFilters[fieldId] === undefined) {
      newFilters[fieldId] = [optionValue.value];
      setSelectedFilters(newFilters);
      return;
    }

    let filterValues = [...newFilters[fieldId]];

    if (filterValues.includes(optionValue.value)) {
      if (filterValues.length === 1) {
        delete newFilters[fieldId];
      } else {
        const index = filterValues.indexOf(optionValue.value);
        filterValues.splice(index, 1);
        newFilters[fieldId] = filterValues.sort();
      }
    } else {
      filterValues.push(optionValue.value);
      newFilters[fieldId] = filterValues.sort();
    }
    setSelectedFilters(newFilters);
  };

  const handleRadioFilter = (fieldId, optionValue) => {
    const newFilters = { ...selectedFilters };
    const field = fields?.find((field) => field.id === fieldId);
    newFilters[fieldId] = createFilterObject(optionValue.value, field.json);
    setSelectedFilters(newFilters);
  };

  const handleClearRange = (fieldName, range, e) => {
    if (e) e.stopPropagation();
    const newRanges = { ...selectedFilters[fieldName] };
    delete newRanges[range];
    const newSelectedFilters = {
      ...selectedFilters,
      [fieldName]: newRanges,
    };
    const rangeExists = Boolean(newRanges[LOWER] || newRanges[UPPER]);
    if (!rangeExists) {
      delete newSelectedFilters[fieldName];
    }
    setSelectedFilters(newSelectedFilters);
  };

  const handleSelectDate = (fieldName, range, dateVal) => {
    if (!dateVal.target.value) {
      handleClearRange(fieldName, range);
      return;
    }

    let date = moment(dateVal.target.value, "YYYY/MM/DD");

    date = range === LOWER ? date.startOf("day") : date.endOf("day");

    if (date.isValid()) {
      const newDateRanges = {
        ...selectedFilters[fieldName],
        [range]: date,
      };
      setSelectedFilters({
        ...selectedFilters,
        [fieldName]: newDateRanges,
      });
    }
  };

  const handleCurrencyRange = (fieldName, range, value) => {
    let baseValue = value.replace(/[^\d]/g, "");

    if (baseValue) {
      const newRange = {
        ...selectedFilters[fieldName],
        [range]: baseValue,
      };
      setSelectedFilters({
        ...selectedFilters,
        [fieldName]: newRange,
      });
    } else {
      handleClearRange(fieldName, range);
    }
  };

  const handleSelect = (fieldName, value) => {
    let filter = fieldName;
    if (fieldName === "show_status") filter = "status";
    let newFilters = {
      ...selectedFilters,
      [filter]: value,
    };

    if (value === "") {
      delete newFilters[filter];
    }

    setSelectedFilters(newFilters);
  };

  const isRadioChecked = (id, optionValue) => {
    if (selectedFilters[id]?.value) {
      return selectedFilters[id].value === optionValue;
    } else {
      return selectedFilters[id] === optionValue;
    }
  };

  const gridSize =
    fieldsForFilter.length +
      fieldsForDatePicker.length +
      fieldsForRadio.length >
    2
      ? 4
      : 6;

  const clear = () => {
    clearFilters();
    setSelectedFilters((prevState) => {
      return keepFilters(filtersIgnoreClear, prevState);
    });
  };

  const checkIfClearable = () => {
    let keys = Object.keys(filters);

    let shouldClearFilters = false;
    keys.forEach((filter) => {
      if (
        !filtersIgnoreClear.find((x) => {
          return x === filter;
        })
      ) {
        shouldClearFilters = true;
      }
    });

    return keys.length > 0 && shouldClearFilters;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 10,
          minHeight: 40,
        }}
      >
        {fieldsForDatePicker.map((field) => {
          const id = field.id;
          const minDate = selectedFilters[id]?.lower;
          const maxDate = selectedFilters[id]?.upper;
          return (
            <FilterGroup key={id}>
              <div style={{ display: "flex" }}>
                <ChipTextField
                  value={minDate || ""}
                  type={"DATE"}
                  format="YYYY/MM/DD"
                  variant="outlined"
                  placeholder="YYYY/MM/DD"
                  onChange={(val) => handleSelectDate(id, LOWER, val)}
                  handleClear={() => {
                    handleClearRange(id, LOWER);
                  }}
                  inputwidth={"110px"}
                />
                <StyledTypography variant="body1"> - </StyledTypography>
                <ChipTextField
                  value={maxDate || ""}
                  type={"DATE"}
                  format="YYYY/MM/DD"
                  variant="outlined"
                  placeholder="YYYY/MM/DD"
                  onChange={(val) => handleSelectDate(id, UPPER, val)}
                  handleClear={() => {
                    handleClearRange(id, UPPER);
                  }}
                  inputwidth={"110px"}
                />
              </div>
            </FilterGroup>
          );
        })}
        {fieldsForCurrencyTextField.map((field) => {
          const minAmount = selectedFilters[field.id]?.lower;
          const maxAmount = selectedFilters[field.id]?.upper;
          return (
            <FilterGroup key={field.id}>
              <div style={{ display: "flex" }}>
                <ChipTextField
                  inputwidth={"70px"}
                  type={"CURRENCY"}
                  placeholder={"$00,000"}
                  onChange={(e) => {
                    handleCurrencyRange(field.id, LOWER, e.target.value);
                  }}
                  handleClear={() => {
                    handleClearRange(field.id, LOWER);
                  }}
                  value={minAmount || null}
                  variant={"outlined"}
                  inputProps={{
                    maxLength: 12,
                  }}
                />
                <StyledTypography variant="body1"> - </StyledTypography>
                <ChipTextField
                  type={"CURRENCY"}
                  placeholder={"$00,000"}
                  onChange={(e) => {
                    handleCurrencyRange(field.id, UPPER, e.target.value);
                  }}
                  handleClear={() => {
                    handleClearRange(field.id, UPPER);
                  }}
                  value={maxAmount || null}
                  variant={"outlined"}
                  inputProps={{
                    maxLength: 12,
                  }}
                  inputwidth={"70px"}
                />
              </div>
            </FilterGroup>
          );
        })}
        {fieldsForChip.map((field) => {
          return (
            <Grid
              item
              xs={gridSize}
              key={field.id}
              style={{
                padding: "0rem",
              }}
            >
              {field.options.map((optionValue, index) => {
                return (
                  <Tooltip key={index} title={`Filter by ${optionValue.label}`}>
                    <StyledChip
                      active={
                        selectedFilters?.subType &&
                        selectedFilters?.subType.find(
                          (x) => x === optionValue.value
                        )
                      }
                      label={sanitizeUnderscore(optionValue.label)}
                      onClick={() => {
                        handleSelectFilter(field.id, optionValue);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      variant="outlined"
                    />
                  </Tooltip>
                );
              })}
            </Grid>
          );
        })}
        {fieldsForFilter.map((field) => {
          return (
            <Grid item xs={gridSize} key={field.id}>
              <FilterLabel>{field.label}</FilterLabel>
              {field.options.map((optionValue, index) => {
                const isChecked = selectedFilters[field.id]?.includes(
                  optionValue.value
                );
                return (
                  <FilterOption key={index}>
                    <StyledCheckbox
                      checked={isChecked}
                      color="primary"
                      onClick={() => handleSelectFilter(field.id, optionValue)}
                    />
                    <span>{sanitizeUnderscore(optionValue.label)}</span>
                  </FilterOption>
                );
              })}
            </Grid>
          );
        })}
        {fieldsForBoolean.map((field) => {
          return (
            <StatusSelect
              key={field.id}
              field={field.id}
              onChange={handleSelect}
              value={selectedFilters[field.id]}
            />
          );
        })}
        {fieldsForSelect.map((field) => {
          return (
            <StyledSelect
              key={field.id}
              value={
                selectedFilters[field.id] &&
                (typeof selectedFilters[field.id] === "string" ||
                  typeof selectedFilters[field.id] === "object")
                  ? selectedFilters[field.id]
                  : ""
              }
              placeholder={field.label}
              variant={"outlined"}
              options={field.options}
              onChange={(e) => {
                handleSelect(field.id, e.target.value);
              }}
              SelectProps={{
                defaultValue: field.label,
                renderValue: (value) => {
                  if (value === "") {
                    return <>{field.label}</>;
                  } else {
                    const current = field.options.find(
                      (opt) => opt.value === value
                    );
                    if (current !== undefined) {
                      return <>{current.label}</>;
                    } else {
                      return <>{value}</>;
                    }
                  }
                },
              }}
            />
          );
        })}
        {fieldsForRadio.map((field) => {
          return (
            <Grid item xs={gridSize} key={field.id}>
              <FilterLabel>{field.label}</FilterLabel>
              {field.options.map((optionValue, index) => {
                return (
                  <RadioOption item xs={12} key={index}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={isRadioChecked(field.id, optionValue.value)}
                          onChange={() =>
                            handleRadioFilter(field.id, optionValue)
                          }
                          color="primary"
                        />
                      }
                      label={sanitizeUnderscore(optionValue.label)}
                      value={optionValue.value}
                    />
                  </RadioOption>
                );
              })}
            </Grid>
          );
        })}
        {checkIfClearable() && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 15,
            }}
          >
            <Typography style={{ color: OUTLINE, fontSize: "16px" }}>
              |
            </Typography>
            <Button
              size={"small"}
              onClick={clear}
              textType={"body1"}
              style={{
                color: BLACK_HIGH_EMPHASIS,
                fontSize: "16px",
                marginLeft: 10,
              }}
            >
              Clear all
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
