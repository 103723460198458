import React from "react";
import {Skeleton} from "@mui/material";

const DataTableSkeleton = () => {
  return <>
    <Skeleton variant={"rectangular"} height={400} />
  </>;
};

export default DataTableSkeleton;
