import React from "react";
import styled from '@emotion/styled'
import PropTypes from "prop-types";

const StyledSpacer = styled.div`
  height: ${({amount})=> `${amount}rem`};
`;

export default function Spacer({amount=1}){
    return <StyledSpacer amount={amount}/>
}

Spacer.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number])
}
